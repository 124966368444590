import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm/LoginForm';
import { login } from '../../actions/actions';
import { Roles } from '../../Services/Roles';
import CustomLoader from '../../Components/loader/loader';
import Alert2 from '../../Components/Alert/Alert';

class LoginPage extends React.Component {
  state = {
    loading: false
  };
  submit = data => {
    this.dataHandler(true);
    return this.props.login(data).then(() => {

      this.dataHandler(false);
      console.log(this.props.role);
      if (this.props.role === Roles.ADMIN) {
        // return this.props.history.push(`/adduser`);
        return this.props.history.push(`/admin_dashboard`);
      } else if (this.props.role === Roles.ITADMIN) {
        return this.props.history.push(`/adminlist`);
      } else if (
        this.props.role === Roles.FINANCE ||
        this.props.role === Roles.PURCHASE ||
        this.props.role === Roles.SALES ||
        this.props.role === Roles.STORE ||
        this.props.role === Roles.PRODUCTION
      ) {
        return this.props.history.push(`/transaction_page`);
      } else {

        return this.props.history.push(`/admin_dashboard`);
      }
    });
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  render() {
    const { loading } = this.state;
    return (
      <div className='container'>
        {loading ? <CustomLoader /> : null}

        <LoginForm submit={this.submit} error={this.dataHandler} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  role: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  submit: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    role: state.user.role
  };
}

export default connect(
  mapStateToProps,
  { login }
)(LoginPage);
