import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import Select from "react-select";
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";

class OrderForm extends React.Component {
  state = {
    data: {
      selectedItemlist: {
        id: [],
        itemname: [],
        itemdescription: [],
        itemtype: [],
        itemrate: [],
        itemuom: [],
        itemlocation: [],
        costperunit: [],
        quantity: [],
        totalcost: [],
        remarks: [],
        totalweight: [],
        readystock: [],
        firstround: [],
        secondround: [],
        thirdround: []
      },
      workorderno: '',
      quotationnumber: '',
      quotationid: '',
      companydetails: '',
      contactperson: '',
      date: new Date(),
      officeno: '',
      deliveryschedule: new Date(),
      orderdate: new Date(),
      deliveryaddress: '',
      distance: '',
      sitecontactperson: '',
      gstno: '',
      pono: '',
      values: ['']
    },
    errors: {},
    selectedQuotationId: null,
    selectedQuotationNumber: null,
    quotationlistvalues: [],
    materialoptionlistvalues: [],
    supplierdetaillistvalue: [],
    quotationlistSelect: [],
    itemlistSelectedOption: [],
    supplierdetaillistvalueobject: {},
    masteritemlistvalues: [],
    masteritemlistvaluesobject: {},
    currencylistvalues: [],
    uom: [],
    locationlistvalue: [],
    loading: false,
    success: false
  };

  componentDidMount() {
    const quotationlistvalues = Object.values(this.props.quotationlistvalues);

    if (this.props.ID != undefined) {
      this.getWorkorderDetails(this.props.ID)
    }

    let arrayQuotationSet = [];
    quotationlistvalues.map((option, i) => {
      let objset = {
        value: option._id, label: option.quotationnumber
      }
      arrayQuotationSet.push(objset);
    });

    this.setState({
      ...this.state,
      quotationlistvalues,
      quotationlistSelect: arrayQuotationSet,
    });
  }

  getWorkorderDetails(id) {
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/getsingleworkorder/${id}`)
      .then(res => {

         if (res.data.orderlist.orderdata.length > 0) {
           let founddata = res.data.orderlist.orderdata;
            console.log(founddata[0])
           const newState = Object.assign({}, this.state);
           newState.data.workorderno = founddata[0].workorderno;
           newState.data.companydetails = founddata[0].companydetails;
           newState.data.contactperson = founddata[0].contactperson;
           newState.data.date = new Date(founddata[0].date);
           newState.data.officeno = founddata[0].officeno;
           newState.data.deliveryschedule =  new Date(founddata[0].deliveryschedule);
           newState.data.orderdate =  new Date(founddata[0].orderdate);
           newState.data.deliveryaddress = founddata[0].deliveryaddress;
           newState.data.gstno = founddata[0].gstno;
           newState.data.distance = founddata[0].distance;
           newState.data.values = founddata[0].addeditemlist;
           newState.data.pono = founddata[0].pono
           newState.data.sitecontactperson = founddata[0].sitecontactperson
           if(founddata[0].quotationid) {
            newState.selectedQuotationId = founddata[0].quotationid;
            newState.data.quotationid = founddata[0].quotationid;
            newState.selectedQuotationNumber = founddata[0].quotationnumber;
            newState.data.quotationnumber = founddata[0].quotationnumber;
           }
           if(founddata[0].customerid) 
            this.handleChange2(founddata[0].customerid._id)

          if (founddata[0].addeditemlist.length > 0) {

            for (var i = 0; i < founddata[0].addeditemlist.length; i++) {
         

              founddata[0].addeditemlist[i].quantity = founddata[0].addeditemlist[i].quantity == null || founddata[0].addeditemlist[i].quantity == '' ?
                founddata[0].addeditemlist[i].quantity = 0 : founddata[0].addeditemlist[i].quantity
              founddata[0].addeditemlist[i].quantity = (founddata[0].addeditemlist[i].quantity).toString()

              founddata[0].addeditemlist[i].costperunit = founddata[0].addeditemlist[i].costperunit == null || founddata[0].addeditemlist[i].costperunit == '' ?
                founddata[0].addeditemlist[i].costperunit = 0 : founddata[0].addeditemlist[i].costperunit
              founddata[0].addeditemlist[i].costperunit = (founddata[0].addeditemlist[i].costperunit).toString()

              founddata[0].addeditemlist[i].totalcost = founddata[0].addeditemlist[i].totalcost == null || founddata[0].addeditemlist[i].totalcost == '' ?
                founddata[0].addeditemlist[i].totalcost = 0 : founddata[0].addeditemlist[i].totalcost
              founddata[0].addeditemlist[i].totalcost = (founddata[0].addeditemlist[i].totalcost).toString()

              founddata[0].addeditemlist[i].remarks = founddata[0].addeditemlist[i].remarks == null || founddata[0].addeditemlist[i].remarks == '' ?
               "" : founddata[0].addeditemlist[i].remarks

              
               
              newState.data.selectedItemlist.itemname.push(founddata[0].addeditemlist[i].itemname)
              newState.data.selectedItemlist.itemdescription.push(founddata[0].addeditemlist[i].itemdescription)
              newState.data.selectedItemlist.itemtype.push(founddata[0].addeditemlist[i].itemtype._id)
              newState.data.selectedItemlist.remarks.push(founddata[0].addeditemlist[i].remarks)
              
            
              newState.data.selectedItemlist.itemuom.push(founddata[0].addeditemlist[i].itemuom._id)
              newState.data.selectedItemlist.quantity.push(founddata[0].addeditemlist[i].quantity)

              newState.data.selectedItemlist.costperunit.push(founddata[0].addeditemlist[i].costperunit)

              newState.data.selectedItemlist.totalcost.push(founddata[0].addeditemlist[i].totalcost)
              newState.data.selectedItemlist.totalweight.push(founddata[0].addeditemlist[i].totalweight)
              newState.data.selectedItemlist.readystock.push(founddata[0].addeditemlist[i].readystock)
              newState.data.selectedItemlist.firstround.push(founddata[0].addeditemlist[i].firstround)
              newState.data.selectedItemlist.secondround.push(founddata[0].addeditemlist[i].secondround)
              newState.data.selectedItemlist.thirdround.push(founddata[0].addeditemlist[i].thirdround)
   

             // setTimeout(()=>{
                newState.data.selectedItemlist.id.push(founddata[0].addeditemlist[i].id._id)
             // })
              
              
            }
          }

          
            this.setState(newState,()=>{
              console.log(this.state.data.selectedItemlist)
            });

         
         
          // if (founddata[0].addeditemlist.length > 0) {
          //   founddata[0].addeditemlist.filter((item, index) => {
          //     this.selectitemchange(item.id._id, index);
          //   })
          // }
          this.setState({ loading: false });
        }
        else {
          this.setState({ count: 0,loading: false })
          
        }
      },
        error => {
          
          this.setState({ success: false, message: 'Server error.' });
        });
  }


  async componentWillReceiveProps(props) {

    console.log(this.props.orderlistvalues);
    const materialoptionlistvalues = Object.values(
      props.materialoptionlistvalues
    );
    // const supplierdetaillistvalue = Object.values(
    //   props.supplierdetaillistvalue
    // );
    // const supplierdetaillistvalueobject = props.supplierdetaillistvalue;
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    const masteritemlistvaluesobject = props.masteritemlistvalues;
    const currencylistvalues = Object.values(props.currencylistvalues);
    const uomlistvalues = Object.values(props.uomlistvalues);
    const locationlistvalue = Object.values(props.locationlistvalue);
    const purchaseorderlist = Object.keys(this.props.purchaseorderlistvalues);
    const orderlistvalues = Object.keys(this.props.orderlistvalues);
    let newArraySet = [];
    masteritemlistvalues.map((option, i) => {
      let name = "";
      if(option.masteritemshortdescription) {
        name = option.masteritemshortdescription;
      }
      else {
        name = option.masteritemdescription;
      }
      let objset = {
        value: option.id, label: name
      }
      newArraySet.push(objset);
    });
    if (this.props.ID == undefined) {
      let workorderno =
      orderlistvalues.length > 0 ? 15 + orderlistvalues.length : 50;


      this.setState({ loading: true });
      await axios
         .get(`${process.env.REACT_APP_BASE_URL}/order/getorderno`)
         .then(res => {
           if(res.data.orderno){
             workorderno = parseInt(res.data.orderno);
           }
         },
         error =>{
           this.setState({ loading: false });
       });
   
      this.setState({ loading: false });

      this.setState({
        ...this.state,
        masteritemlistvalues,
        masteritemlistvaluesobject,
        materialoptionlistvalues,
        itemlistSelectedOption: newArraySet,
        currencylistvalues,
        locationlistvalue,
        uom: uomlistvalues,
        data: {
          ...this.state.data,
          workorderno
        }
      },() =>{
        if(orderlistvalues.length == 0)
          this.getOrderData()
      });
    }
    else{
      this.setState({
        ...this.state,
        masteritemlistvalues,
        masteritemlistvaluesobject,
        itemlistSelectedOption: newArraySet,
        materialoptionlistvalues,
        currencylistvalues,
        locationlistvalue,
        uom: uomlistvalues
      });
    }

  }

  getOrderData(){
    if(Object.keys(this.props.orderlistvalues).length == 0){
      axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/getorder`)
      .then(res => {
        console.log(res.data.orderlist.orderdata)
         //if (res.data.orderlist.orderdata.length > 0) {
           
          let workorderno =
          res.data.orderlist.orderdata.length > 0 ? 1 + res.data.orderlist.orderdata.length : 1;

          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              workorderno
            }
          });
        //}
      },
        error => {
          this.setState({ success: false, message: 'Server error.' });
        });
    }
  }

  handleChangeDeliveryDate = date => {
    this.setState({
      data: {
        ...this.state.data,
        requestdeliverydate: date
      }
    });
  };
  handleChangeDeliveryschedule = date => {
    this.setState({
      data: {
        ...this.state.data,
        deliveryschedule: date
      }
    });
  };
  handleChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  totalValue = () => {
    const { totalcost } = this.state.data.selectedItemlist;
    if (totalcost.length > 0) {
      const totalvalue = totalcost
        .map((value, i) => {
          if (value) {
            return parseFloat(value);
          } else {
            return 0;
          }
        })
        .reduce((a, b) => {
          return a + b;
        });
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          totalvalue
        }
      });
    }
  };

  selectitemchange = (e, index) => {
    let selected_id = e.value ? e.value : e;
    if (selected_id) {
      const selected_item = this.state.masteritemlistvaluesobject[selected_id];
      console.log(selected_item)
      const selectedItemlist = { ...this.state.data.selectedItemlist };
      selectedItemlist.id[index] = selected_item.id;
      selectedItemlist.itemname[index] = selected_item.masteritemtypename;
      // selectedItemlist.itemdescription[index] =
      //   selected_item.masteritemdescription;
      selectedItemlist.itemdescription[index] = selected_item.masteritemshortdescription == '' ? selected_item.masteritemdescription 
        : selected_item.masteritemshortdescription

      selectedItemlist.itemtype[index] = selected_item.masteritemtype._id;
      selectedItemlist.itemrate[index] = selected_item.masteritemrate;
      selectedItemlist.itemuom[index] = selected_item.masteritemuom._id;
     // selectedItemlist.itemlocation[index] = null;



        if (this.props.ID == undefined) {
          selectedItemlist.costperunit[index] = selected_item.masteritemcostperunit
          ? selected_item.masteritemcostperunit.toString()
          : '';
        selectedItemlist.quantity[index] = (1).toString();
        selectedItemlist.totalcost[index] = selected_item.masteritemcostperunit
          ? selected_item.masteritemcostperunit.toString()
          : '';

          selectedItemlist.totalweight[index] = 
          selected_item.masteritemunitwt != "undefined" && selected_item.masteritemunitwt != "null" && 
          selected_item.masteritemunitwt != "" ? (1 * parseFloat(selected_item.masteritemunitwt)).toFixed(2) : ''
          
        } 
      // this.state.data.selectedItemlist.id[index] = selected_item.id;
      // this.state.data.selectedItemlist.itemdescription[index] =
      //   selected_item.masteritemdescription;
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          selectedItemlist
          // selectedItemlist: {
          //   ...this.state.data.selectedItemlist,
          //   id: [...this.state.data.selectedItemlist.id, selected_item.id]
          // itemname: [...this.state.data.selectedItemlist.itemname, ""],
          // itemdescription: [
          //   ...this.state.data.selectedItemlist.itemdescription,
          //   ""
          // ],
          // itemtype: [...this.state.data.selectedItemlist.itemtype, ""],
          // itemrate: [...this.state.data.selectedItemlist.itemrate, ""],
          // itemuom: [...this.state.data.selectedItemlist.itemuom, ""],
          // costperunit: [...this.state.data.selectedItemlist.costperunit, ""]
          // }
        }
      });
    }
    this.totalValue();
  };

  locationChange = (e, index) => {
    const selected_location = e.target.value;
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.itemlocation[index] = selected_location;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  onhandlerChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  onSubjectHandler = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  filterItems(itemarray,key) {
    return itemarray.sort((a,b)=>{return a[key].toLowerCase().localeCompare(b[key].toLowerCase())})
  }

  createSelectItems = () => {
    let items = [];
    let masteritemlistvalues = this.state.masteritemlistvalues;
    masteritemlistvalues = this.filterItems(masteritemlistvalues,'masteritemtypename')
    masteritemlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectItemTypes = () => {
    let items = [];
    const materialoptionlistvalues = this.state.materialoptionlistvalues;
    materialoptionlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectCurrencyItems = () => {
    let items = [];
    const currencylistvalues = this.state.currencylistvalues;
    currencylistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.currencyname}
        </option>
      );
    });
    return items;
  };

  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uom;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };
  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  handleClick = e => {
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ''],
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id: [...this.state.data.selectedItemlist.id, ''],
          itemname: [...this.state.data.selectedItemlist.itemname, ''],
          itemdescription: [
            ...this.state.data.selectedItemlist.itemdescription,
            ''
          ],
          itemtype: [...this.state.data.selectedItemlist.itemtype, ''],
          itemrate: [...this.state.data.selectedItemlist.itemrate, ''],
          itemuom: [...this.state.data.selectedItemlist.itemuom, ''],
          itemlocation: [...this.state.data.selectedItemlist.itemlocation, ''],
          costperunit: [...this.state.data.selectedItemlist.costperunit, ''],
          quantity: [...this.state.data.selectedItemlist.quantity, 1],
          totalcost: [...this.state.data.selectedItemlist.totalcost, ''],
          remarks: [...this.state.data.selectedItemlist.remarks, ''],
          totalweight: [...this.state.data.selectedItemlist.totalweight, ''],
          readystock: [...this.state.data.selectedItemlist.readystock, ''],
          firstround: [...this.state.data.selectedItemlist.firstround, ''],
          secondround: [...this.state.data.selectedItemlist.secondround, ''],
          thirdround: [...this.state.data.selectedItemlist.thirdround, '']
        }
      }
    });
  };
  removeClick = (e, index) => {
    let removeItem = { ...this.state.data };
    let values = removeItem.values;
    let id = removeItem.selectedItemlist.id;
    let itemname = removeItem.selectedItemlist.itemname;
    let itemdescription = removeItem.selectedItemlist.itemdescription;
    let itemtype = removeItem.selectedItemlist.itemtype;
    let itemrate = removeItem.selectedItemlist.itemrate;
    let itemuom = removeItem.selectedItemlist.itemuom;
    let itemlocation = removeItem.selectedItemlist.itemlocation;
    let costperunit = removeItem.selectedItemlist.costperunit;
    let quantity = removeItem.selectedItemlist.quantity;
    let totalcost = removeItem.selectedItemlist.totalcost;

    let remarks = removeItem.selectedItemlist.remarks;
    let totalweight = removeItem.selectedItemlist.totalweight;
    let readystock = removeItem.selectedItemlist.readystock;
    let firstround = removeItem.selectedItemlist.firstround;
    let secondround = removeItem.selectedItemlist.secondround;
    let thirdround = removeItem.selectedItemlist.thirdround;


    values.splice(index, 1);
    id.splice(index, 1);
    itemname.splice(index, 1);
    itemdescription.splice(index, 1);
    itemtype.splice(index, 1);
    itemrate.splice(index, 1);
    itemuom.splice(index, 1);
    itemlocation.splice(index, 1);
    costperunit.splice(index, 1);
    quantity.splice(index, 1);
    totalcost.splice(index, 1);

    remarks.splice(index, 1);
    totalweight.splice(index, 1);
    readystock.splice(index, 1);
    firstround.splice(index, 1);
    secondround.splice(index, 1);
    thirdround.splice(index, 1);

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id,
          itemname,
          itemdescription,
          itemtype,
          itemrate,
          itemuom,
          itemlocation,
          costperunit,
          quantity,
          totalcost,
          remarks,
          totalweight,
          readystock,
          firstround,
          secondround,
          thirdround
        }
      }
    });
  };



  handleQuotationChange = async(e) => {
    console.log(e)
    let selectedquotation = e.value ? e.value : e
    console.log(selectedquotation)
    const quotationlistvalues = this.state.quotationlistvalues;
    let quotationselected = quotationlistvalues.filter(
      quotation => quotation._id === selectedquotation || quotation.quotationnumber === selectedquotation
    );
    console.log(quotationselected)
    if(quotationselected && quotationselected.length > 0){
      quotationselected = quotationselected[0];

      let contactperson = quotationselected.customername ? quotationselected.customername : '';
      let deliveryaddress = quotationselected.customeraddress ? quotationselected.customeraddress : '';
      let companydetails = quotationselected.customeraddress ? quotationselected.customeraddress : '';
      let officeno = quotationselected.customercontactnumber ? quotationselected.customercontactnumber : '';
      let gstno = quotationselected.customergstnumber ? quotationselected.customergstnumber : '';
      

      let poDate = "";
      let transId = null;
      const quotationnumber = quotationselected.quotationnumber;
      const quotationid = selectedquotation;
      this.setState({ loading: true });
      const purchaseordernumber = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/customerTransaction/getcustomertransaction/` + quotationselected.customerid)
      .then(res => {
        this.setState({ loading: false });
        if(res.data.customerlist.customerdata.length > 0){
          let customervalues = res.data.customerlist.customerdata;
          if(customervalues.length > 0){
            customervalues = customervalues.filter((item) =>{
              return item.Qno == quotationselected.quotationnumber
            });
            transId = customervalues[0]['_id'];
            poDate = customervalues[0]['po'];
            return customervalues[0]['ponum'];
          }
        }
      },
      error =>{
        this.setState({ loading: false });
      });
      const date = new Date(quotationselected.date);
      let purchaseorderdate = this.state.data.purchaseorderdate;
      if(poDate != "" && poDate != null) {
        let arrDate = poDate.split("/");
        let newDate = arrDate[1]+"/"+arrDate[0]+"/"+arrDate[2];
        purchaseorderdate = new Date(newDate);
      }


  

      let selectedItemlist = {
        id: [],
        itemname: [],
        itemdescription: [],
        itemtype: [],
        itemrate: [],
        itemuom: [],
        itemlocation: [],
        costperunit: [],
        quantity: [],
        totalcost: [],
        remarks: [],
        totalweight: [],
        readystock: [],
        firstround: [],
        secondround: [],
        thirdround: []
      }
      let values = quotationselected.addeditemlist
      if (quotationselected.addeditemlist.length > 0) {

        for (var i = 0; i < quotationselected.addeditemlist.length; i++) {


          quotationselected.addeditemlist[i].quantity = quotationselected.addeditemlist[i].quantity == null || quotationselected.addeditemlist[i].quantity == '' ?
          quotationselected.addeditemlist[i].quantity = 0 : quotationselected.addeditemlist[i].quantity
          quotationselected.addeditemlist[i].quantity = (quotationselected.addeditemlist[i].quantity).toString()

          quotationselected.addeditemlist[i].costperunit = quotationselected.addeditemlist[i].costperunit == null || quotationselected.addeditemlist[i].costperunit == '' ?
          quotationselected.addeditemlist[i].costperunit = 0 : quotationselected.addeditemlist[i].costperunit
          quotationselected.addeditemlist[i].costperunit = (quotationselected.addeditemlist[i].costperunit).toString()

          quotationselected.addeditemlist[i].totalcost = quotationselected.addeditemlist[i].totalcost == null || quotationselected.addeditemlist[i].totalcost == '' ?
          quotationselected.addeditemlist[i].totalcost = 0 : quotationselected.addeditemlist[i].totalcost
          quotationselected.addeditemlist[i].totalcost = (quotationselected.addeditemlist[i].totalcost).toString()

  



          selectedItemlist.itemdescription.push(quotationselected.addeditemlist[i].itemdescription)
          selectedItemlist.itemtype.push(quotationselected.addeditemlist[i].itemtype._id)
          selectedItemlist.itemlocation.push(quotationselected.addeditemlist[i].itemlocation)
          selectedItemlist.remarks.push(quotationselected.addeditemlist[i].remarks)
          selectedItemlist.totalweight.push(quotationselected.addeditemlist[i].totalweight)
          selectedItemlist.readystock.push(quotationselected.addeditemlist[i].readystock)
          selectedItemlist.firstround.push(quotationselected.addeditemlist[i].firstround)
          selectedItemlist.secondround.push(quotationselected.addeditemlist[i].secondround)
          selectedItemlist.thirdround.push(quotationselected.addeditemlist[i].thirdround)


         
          selectedItemlist.itemuom.push(quotationselected.addeditemlist[i].itemuom._id)
          selectedItemlist.quantity.push(quotationselected.addeditemlist[i].quantity)

          selectedItemlist.costperunit.push(quotationselected.addeditemlist[i].costperunit)

          selectedItemlist.totalcost.push(quotationselected.addeditemlist[i].totalcost)
          selectedItemlist.id.push(quotationselected.addeditemlist[i].id)

        }
      }

      
 
  
  
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
       //   values: values,
          quotationnumber,
          pono: purchaseordernumber,
          quotationid,
          contactperson,
          deliveryaddress,
          companydetails,
          officeno,
          gstno,
          purchaseorderdate,
          selectedItemlist,
          values
        },
        selectedQuotationId: selectedquotation,
        selectedQuotationNumber: quotationselected.quotationnumber,
      }, () => {
        console.log(selectedItemlist)
        this.setState({
          ...this.state.data,
          data: {
            ...this.state.data,
            quotationnumber : quotationnumber,
            quotationid : quotationid,
          //  values: values
          }
        },() =>{
          console.log(this.state.data)
          //this.setState(newState);
          
          if (quotationselected.addeditemlist.length > 0) {
            quotationselected.addeditemlist.filter((item, index) => {
              this.selectitemchange(item.id._id, index);
            })
          }
        });
      });
    }
  }


  quantityChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    console.log(selectedItemlist)
    
    const selected_item = this.state.masteritemlistvaluesobject[selectedItemlist.id[index]];
    console.log(selected_item)


    const costperunit = selectedItemlist.costperunit[index] || null;
    const wtperunit = selectedItemlist.totalweight[index] || null;
    selectedItemlist.quantity[index] = e.target.value;
    const totalValue = costperunit
      ? e.target.value * costperunit  
      : null || undefined || '';
    selectedItemlist.totalcost[index] = totalValue ? parseFloat(totalValue).toFixed(2)
      : '';


    selectedItemlist.totalweight[index] = 
    selected_item.masteritemunitwt != "undefined" && selected_item.masteritemunitwt != "null" && 
    selected_item.masteritemunitwt != "" ? (e.target.value * parseFloat(selected_item.masteritemunitwt)).toFixed(2) : ''
    
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };
  remarkChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.remarks[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  weightChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.totalweight[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  stockChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.readystock[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  secondChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.secondround[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  firstChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.firstround[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  thirdChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.thirdround[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  unitChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.costperunit[index] = e.target.value;
    const totalcost =
      parseFloat(selectedItemlist.quantity[index]) *
      parseFloat(selectedItemlist.costperunit[index]);
    selectedItemlist.totalcost[index] = totalcost
      ? totalcost.toFixed(2)
      : null || '';
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  handleValidSubmit = () => {
    let AddedIteminfo = [];
    const { supplierdetail, selectedItemlist } = this.state.data;
    const { values } = this.state.data;
    const id = selectedItemlist.id;
    const itemname = selectedItemlist.itemname;
    const itemdescription = selectedItemlist.itemdescription;
    const itemtype = selectedItemlist.itemtype;
    const itemrate = selectedItemlist.itemrate;
    const itemuom = selectedItemlist.itemuom;
    const itemlocation = selectedItemlist.itemlocation;
    const costperunit = selectedItemlist.costperunit;
    const quantity = selectedItemlist.quantity;
    const totalcost = selectedItemlist.totalcost;
    const remarks = selectedItemlist.remarks;

    const totalweight = selectedItemlist.totalweight;
    const readystock = selectedItemlist.readystock;
    const firstround = selectedItemlist.firstround;
    const secondround = selectedItemlist.secondround;
    const thirdround = selectedItemlist.thirdround;

    values.forEach(function(value, i) {
      let obj = {};
      obj.id = id[i] || null;
      obj.itemname = itemname[i] || null;
      obj.itemdescription = itemdescription[i] || null;
      obj.itemtype = itemtype[i] || null;
      obj.itemrate = itemrate[i] || null;
      obj.itemuom = itemuom[i] || null;
      obj.itemlocation = itemlocation[i] || null;
      obj.costperunit = costperunit[i] || null;
      obj.quantity = quantity[i] || null;
      obj.totalcost = totalcost[i] || null;
      obj.remarks = remarks[i] || null;
      obj.totalweight = totalweight[i] || null;
      obj.readystock = readystock[i] || null;
      obj.firstround = firstround[i] || null;
      obj.secondround = secondround[i] || null;
      obj.thirdround = thirdround[i] || null;
      AddedIteminfo.push(obj);
    });
    const { data } = this.state;
    data['AddedIteminfo'] = AddedIteminfo;
    this.setState({ loading: true });
    console.log(JSON.stringify(data));
    this.props
      .submit(data)
      .then(() => {
        this.setState({ loading: false, errors: {}, success: true });
        if (this.props.ID == undefined) 
        this.myFormRef.reset();
      })
      .catch(err => {
        console.log(err)
        this.setState({ loading: false, errors: {}, success: true });
        if (this.props.ID == undefined) 
        this.myFormRef.reset();
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  handleInvalidSubmit = () => {
    console.log('invalid submit');
  };
  render() {
    const customStyles = {
      control: base => ({
        ...base,
        height: 35,
        minHeight: 35
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '35px',
        padding: '0 6px'
      }),
  
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '35px',
      }),
    };
    const { data, errors, loading, success } = this.state;
    let SelQuota;
    if(this.state.selectedQuotationId) {
      SelQuota = {
        value: this.state.selectedQuotationId, label: this.state.selectedQuotationNumber
      }
    }
    let quotationdisp = this.state.selectedQuotationId != undefined ? (
      <Select name={`deliverychallannumber`} classNamePrefix="select" onChange={this.handleQuotationChange.bind(this)} styles={customStyles} required value={SelQuota} options={this.state.quotationlistSelect} />
    ) : (
      <Select name={`deliverychallannumber`} classNamePrefix="select" onChange={this.handleQuotationChange.bind(this)} styles={customStyles} required defaultValue={data.deliverychallannumber} options={this.state.quotationlistSelect} />
    );
    return (
      <div>
        <div>
          {!!success ? (
            // <Alert color="info">Purchase Order is generated successfully</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Work Order</ModalHeader>
              <ModalBody>Work order is edited/generated successfully</ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Work order</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='workorderno'>Work Order No</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='workorderno'
                      type='text'
                      id='workorderno'
                      value={data.workorderno || null}
                      onChange={this.handleChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter DC'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='vendorcode'>Quotation No</Label>
                  {/* <AvField
                    name='deliverychallannumber'
                    type='text'
                    id='deliverychallannumber'
                    value={data.deliverychallannumber || null}
                    onChange={e => this.onhandlerChange(e)}
                  /> */}
                  {/* <AvField
                    Label
                    type='select'
                    name={`deliverychallannumber`}
                    //required={true}          
                    value={data.deliverychallannumber}
                    onChange={e => this.handleChallanChange(e)}
                  >
                    <option value=''>select challan</option>
                    {this.createChallanList()}
                  </AvField> */}
                  {quotationdisp}
                </Col>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='companydetails'>Company Name & address </Label>
                    <AvField
                      name='companydetails'
                      type='text'
                      id='companydetails'
                      value={data.companydetails}
                      onChange={e => this.handleChange(e)}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='contactperson'>Contact Person</Label>
                  <AvField
                    name='contactperson'
                    type='text'
                    id='contactperson'
                    value={data.contactperson || ' ' || null}
                    onChange={e => this.handleChange(e)}
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='officeno'>Office Number</Label>
                  <AvField
                    name='officeno'
                    type='number'
                    id='officeno'
                    value={data.officeno || ' ' || null}
                    onChange={e => this.handleChange(e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='deliveryschedule'>Delivery Schedule</Label>
                    <DatePicker
                    autoComplete="new-password"
                      selected={data.deliveryschedule}
                      onChange={this.handleChangeDeliveryschedule}
                      className='form-control'
                      autoComplete="new-password"
                      dateFormat='dd/MM/yyyy'
                      id='deliveryschedule'
                    />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <Label className='small-font-size' for='orderdate'>Order Date</Label>
                  <DatePicker
                  autoComplete="new-password"
                    selected={data.orderdate}
                    onChange={this.handleChangeDeliveryDate}
                    className='form-control'
                    autoComplete="new-password"
                    dateFormat='dd/MM/yyyy'
                    id='orderdate'
                  />
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='deliveryaddress'>Delivery Address</Label>
                    <AvField
                      name='deliveryaddress'
                      type='text'
                      id='deliveryaddress'
                      value={data.deliveryaddress}
                      onChange={this.handleChange}
                    />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='distance'>Distance(KM)</Label>
                    <AvField
                      name='distance'
                      type='text'
                      id='distance'
                      value={data.distance}
                      onChange={this.handleChange}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='sitecontactperson'>Site contact person</Label>
                    <AvField
                      name='sitecontactperson'
                      type='text'
                      id='sitecontactperson'
                      value={data.sitecontactperson}
                      onChange={this.handleChange}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='gstno'>GST No</Label>
                    <AvField
                      name='gstno'
                      type='text'
                      id='gstno'
                      value={data.gstno}
                      onChange={this.handleChange}
                    />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='pono'>PO NO</Label>
                    <AvField
                      name='pono'
                      type='number'
                      id='pono'
                      value={data.pono}
                      onChange={this.handleChange}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <div className='mg-t-20'>
                  <Button color='info' onClick={this.handleClick} type='button'>
                    Add Item
                  </Button>
                </div>
              </Row>
              <div className='purchaseorder-item-row'>
                {data.values.map((values, index) => {

                            let optionsel = "";
                            let SelVal = {};
                            if(values.id != undefined) {
                              if(values.itemshortdescription) {
                                optionsel = values.itemshortdescription;
                              }
                              else {
                                optionsel = values.itemdescription;
                              }
                              SelVal = {
                                value: values.id._id, label: optionsel
                              }
                            }
                            const itemnameinfo = values.id != undefined ? (
                              // <AvField
                              //   type="select"
                              //   value={values.id._id}
                              //   name={`selectitem${index}`}
                              //   onChange={e => this.selectitemchange(e, index)}
                              //   required={true}
                              // >
                              //   <option value="">select Item</option>
                              //   {this.createSelectItems()}
                              // </AvField>
                              <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index)} styles={customStyles} required value={SelVal} options={this.state.itemlistSelectedOption} />            
                              ) :
                              (
                                // <AvField
                                //   type="select"
                                //   name={`selectitem${index}`}
                                //   value={
                                //     data.selectedItemlist.id[index]
                                //   }
                                //   onChange={e => this.selectitemchange(e, index)}
                                //   required={true}
                                // >
                                //   <option value="">select Item</option>
                                //   {this.createSelectItems()}
                                // </AvField>
                                <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index)} styles={customStyles} required defaultValue={data.selectedItemlist.id[index]} options={this.state.itemlistSelectedOption} />
                              )
                  return (
                    <div key={index}>
                      <div className='purchaseorder-table'>
                        <Row key={index}>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for='selectitem'>Item Code</Label>
                              <span className='required-label'>*</span>
                              {/* <AvField
                                type='select'
                                name={`selectitem${index}`}
                                value={data.selectedItemlist.id[index]}
                                onChange={e => this.selectitemchange(e, index)}
                                required={true}
                              >
                                <option value=''>select Item</option>
                                {this.createSelectItems()}
                              </AvField> */}
                              {itemnameinfo}
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemdescription${index}`}>
                                Description
                              </Label>
                              <span className='required-label'>*</span>
                              <AvField
                                name={`masteritemdescription${index}`}
                                type='text'
                                id='masteritemdescription'
                                value={
                                  data.selectedItemlist.itemdescription[index]
                                }
                                onChange={this.onChange}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      'Please enter Item Description'
                                  }
                                
                                 
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`totalweight${index}`}>Total weight</Label>
                              <AvField
                                name={`totalweight${index}`}
                                type='text'
                                id={`totalweight${index}`}
                                value={
                                  data.selectedItemlist.totalweight[index] 
                                
                                }
                                onChange={e => this.weightChange(e, index)}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`quantity${index}`}>Quantity</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                name={`quantity${index}`}
                                type='number'
                                id={`quantity${index}`}
                                value={
                                  data.selectedItemlist.quantity[index]
                                }
                                onChange={e => this.quantityChange(e, index)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter Item Quantity'
                                  },
                                  minLength: {
                                    value: 1,
                                    errorMessage: 'quantity value is required'
                                  },
                                  pattern: {
                                    value: '^[0-9.]*$',
                                    errorMessage: 'Please enter valid quantity'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`readystock${index}`}>Ready stock</Label>
                              <AvField
                                name={`readystock${index}`}
                                type='text'
                                id={`readystock${index}`}
                                value={
                                  data.selectedItemlist.readystock[index]
                                }
                                onChange={e => this.stockChange(e, index)}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`firstround${index}`} >1st round</Label>
                              <AvField
                                name={`firstround${index}`}
                                type='text'
                                id={`firstround${index}`}
                                value={
                                  data.selectedItemlist.firstround[index]
                                }
                                onChange={e => this.firstChange(e, index)}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`secondround${index}`}>2nd round</Label>
                              <AvField
                                name={`secondround${index}`}
                                type='text'
                                id={`secondround${index}`}
                                value={
                                  data.selectedItemlist.secondround[index]
                                }
                                onChange={e => this.secondChange(e, index)}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`thirdround${index}`}>3rd round</Label>
                              <AvField
                                name={`thirdround${index}`}
                                type='text'
                                id={`thirdround${index}`}
                                value={
                                  data.selectedItemlist.thirdround[index]
                                }
                                onChange={e => this.thirdChange(e, index)}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`remarks${index}`}>Remarks</Label>
                              <AvField
                                name={`remarks${index}`}
                                type='text'
                                id={`remarks${index}`}
                                value={
                                  data.selectedItemlist.remarks[index]
                                }
                                onChange={e => this.remarkChange(e, index)}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <Button
                              type='button'
                              color='danger'
                              onClick={e => this.removeClick(e, index)}
                              style={{ marginTop: '30px' }}
                            >
                              -
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Row>
                <div className='mg-20'>
                  <Button
                    color='primary'
                    disabled={loading}
                    className='lgn-loader'
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierdetaillistvalue: state.supplierdetaillistvalue,
    masteritemlistvalues: state.masteritemlistvalues,
    materialoptionlistvalues: state.masteritemtypenamevalue,
    currencylistvalues: state.currencylistvalue,
    uomlistvalues: state.uomlistvalue,
    quotationlistvalues: state.quotationlistvalues,
    locationlistvalue: state.locationlistvalue,
    purchaseorderlistvalues: state.purchaseorderlistvalue,
    challanlistvalues: state.challanlistvalues,
    orderlistvalues: state.orderlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(OrderForm);
