import axios from "axios";

export default {
  location: {
    getLocationList: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/location/getlocation`)
        .then(res => {
          return res.data.locationlist;
        });
    }
  }
};
