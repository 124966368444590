import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
  getMasterItemList,
  getMasterItemTypes
} from '../../../actions/Transaction';
import { connect } from 'react-redux';
import { getUomList } from '../../../actions/uom';
import { getCustomerList } from '../../../actions/customerContact';
import { addMoveOrder } from '../../../actions/moveorder';
import { getMoveOrder } from '../../../actions/moveorder';
import MoveOrderScreenForm from './MoveOrderScreenForm/MoveOrderScreenForm';
import CustomLoader from '../../../Components/loader/loader';
import { getLocationList } from '../../../actions/Location';

class MoveOrderScreen extends React.Component {
  state = {
    loading: false
  };
  componentDidMount() {
    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getMoveOrder();
    this.props.getLocationList();
  }
  submit = data => {
    console.log(data);
    this.dataHandler(true);
    return this.props
      .addMoveOrder(data)
      .then(data => {
        this.dataHandler(false);
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className='container mg-t-20'>
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/transaction_page'>Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/move-order'>Move Order</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Move</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <MoveOrderScreenForm submit={this.submit} />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    addMoveOrder,
    getCustomerList,
    getMoveOrder,
    getLocationList
  }
)(MoveOrderScreen);
