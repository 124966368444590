import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import AddCustomerForm from "./AddCustomer/CustomerForm/CustomerForm";
import ViewCustomerList from "./ViewCustomerList";
import {
  addCustomerContact,
  getCustomerList
} from "../../../actions/customerContact";
import CustomLoader from "../../../Components/loader/loader";

class Customer extends React.Component {
  state = {
    loading: false
  };
  componentDidMount = () => {
    this.props.getCustomerList();
  };
  submit = data => {
    this.dataHandler(true);
    return this.props
      .addCustomerContact(data)
      .then(data => {
        this.dataHandler(false);
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/inventory_page">Setup</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/customer">Customer</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>customer</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <AddCustomerForm submit={this.submit} />
        </div>
        {/* <div>
          <ViewCustomerList />
        </div> */}
      </div>
    );
  }
}

export default connect(
  null,
  { addCustomerContact, getCustomerList }
)(Customer);
