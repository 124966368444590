import {
  DataTableCell, Table, TableBody, TableCell, TableHeader
} from '@david.kucsai/react-pdf-table';
import {
  Document, Image, Page, StyleSheet, Text,
  View
} from '@react-pdf/renderer';
import React from 'react';
import client from '../../../Images/client.png';
import logo from '../../../Images/logo.png';

// Create styles
const styles = StyleSheet.create({
  textcenter: { textAlign: 'center' },
  textright: { textAlign: 'right' },
  textleft: { textAlign: 'left' },
  paddingleft: { paddingLeft: 5 },
  margin: {
    margin: 5
  },
  pagepadding: {
    padding: 20
  },
  section: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '6px',
    paddingRight: 20,
    backgroundColor: 'white'
  },
  columnSection: {
    paddingRight: 20,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  padd6: {
    paddingTop: '6px',
    paddingBottom: '6px'
  },
  padding2: {
    padding: 5
  },

  headerfont: {
    fontSize: 10,
    fontWeight: 'bold',
    padding: 3
  },
  headerfont2: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'right',
    padding: 3
  },
  headerwidth: {
    width: '100%',
    paddingTop: 20,
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: 'white',
    textAlign: 'center',
  },
  headerwidthNew: {
    width: 140,
    padding: 20
  },
  headerwidthdate: {
    fontSize: 12,
  },
  headerwidthlogo: {
    width: 350,
    fontSize: 12
  },
  logo: {
    width: 70
  },
  headerwidthAddress: {
    width: 210,
    fontSize: 10,
    padding: 2,
    textAlign: 'right'
  },
  refstyle: {
    fontSize: 12
  },
  headerpadding: {
    padding: 20
  },
  flexgrow: {
    flexGrow: 1
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  fontsize18: {
    fontSize: 10
  },
  fontsize18bold: {
    fontSize: 10,
    fontWeight: 900,
    paddingBottom: 10
  },
  kindattend: {
    paddingTop: 15,
    fontSize: 10,
    textAlign: 'center'
  },
  yourref: {
    fontSize: 10,
    paddingBottom: 10
  },
  main: {
    fontSize: 10
  },
  footerfixed: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
});

const Small = ({ children }) => {
  return (
    <Text style={{ fontSize: 10 }}>{children}</Text>
  )
}

const Description = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, textAlign: 'left', paddingLeft: 8 }}>{children}</Text>
  )
}

const SmallBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>{children}</Text>
  )
}

const Header = ({ children }) => {
  return (
    <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{children}</Text>
  )
}

// Create Document Component

const MyDocument = ({ purchaseorderlistvalue, data_cond }) => {
  let amountinwords = ''
  let Rs = function (amount) {
    var words = new Array();
    words[0] = 'Zero'; words[1] = 'One'; words[2] = 'Two'; words[3] = 'Three'; words[4] = 'Four'; words[5] = 'Five'; words[6] = 'Six'; words[7] = 'Seven'; words[8] = 'Eight'; words[9] = 'Nine'; words[10] = 'Ten'; words[11] = 'Eleven'; words[12] = 'Twelve'; words[13] = 'Thirteen'; words[14] = 'Fourteen'; words[15] = 'Fifteen'; words[16] = 'Sixteen'; words[17] = 'Seventeen'; words[18] = 'Eighteen'; words[19] = 'Nineteen'; words[20] = 'Twenty'; words[30] = 'Thirty'; words[40] = 'Forty'; words[50] = 'Fifty'; words[60] = 'Sixty'; words[70] = 'Seventy'; words[80] = 'Eighty'; words[90] = 'Ninety'; var op;
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split(" ").join(" ");
    }
    return words_string;
  }

  let formatDate = function (date) {
    date = new Date(date);
    const currentMonth = date.getMonth() + 1;
    const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
    const currentDate = date.getDate();
    const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
    return `${date.getFullYear()}-${monthString}-${currentDate}`;
}

  let RsPaise = function (n) {
    var op;
    var nums = n.toString().split('.')
    var whole = Rs(nums[0])
    if (nums[1] == null) nums[1] = 0;
    if (nums[1].length == 1) nums[1] = nums[1] + '0';
    if (nums[1].length > 2) { nums[1] = nums[1].substring(2, nums[1].length - 1) }
    if (nums.length == 2) {
      if (nums[0] <= 9) { nums[0] = nums[0] * 10 } else { nums[0] = nums[0] };
      var fraction = Rs(nums[1])
      if (whole == '' && fraction == '') { op = 'Zero only'; }
      if (whole == '' && fraction != '') { op = 'paise ' + fraction + ' only'; }
      if (whole != '' && fraction == '') { op = 'INR: ' + whole + ' only'; }
      if (whole != '' && fraction != '') { op = 'INR: ' + whole + 'and ' + fraction + ' paise only'; }
      amountinwords = op
    }
  }

  const ponumber = purchaseorderlistvalue.ponumber.toString();
  const financialYear = purchaseorderlistvalue.financialyear.toString();
  const data = purchaseorderlistvalue.addeditemlist;
  let totaleachitem = 0
  if (data.length > 0) {
    data.map((item) => {
      totaleachitem = totaleachitem + parseFloat(item.totalcost)
    })
    totaleachitem = totaleachitem.toFixed(2)
  }



  let dateNew = formatDate(purchaseorderlistvalue.created_at);
  let totalrs = purchaseorderlistvalue.totalvalue
    ? purchaseorderlistvalue.totalvalue
    : '';
  RsPaise(totalrs)
  const tax = purchaseorderlistvalue.cgst ? purchaseorderlistvalue.cgst : '';
  let cgst = purchaseorderlistvalue.cgst && purchaseorderlistvalue.cgst != '' ? purchaseorderlistvalue.cgst : 0
  cgst = parseFloat(cgst)
  let cgstval = parseFloat(totaleachitem) * (cgst / 100)
  cgstval = parseFloat(cgstval)
  cgstval = cgstval.toFixed(2)

  let sgst = purchaseorderlistvalue.sgst && purchaseorderlistvalue.sgst != '' ? purchaseorderlistvalue.sgst : 0
  sgst = parseFloat(sgst)
  let sgstval = parseFloat(totaleachitem) * (sgst / 100)
  sgstval = parseFloat(sgstval)
  sgstval = sgstval.toFixed(2)

  let igst = purchaseorderlistvalue.igst && purchaseorderlistvalue.igst != '' ? purchaseorderlistvalue.igst : 0
  igst = parseFloat(igst)
  let igstval = parseFloat(totaleachitem) * (igst / 100)
  igstval = parseFloat(igstval)
  igstval = igstval.toFixed(2)

  let transportationcharge = purchaseorderlistvalue.transportationcharge != '' && purchaseorderlistvalue.transportationcharge != null ? parseFloat(purchaseorderlistvalue.transportationcharge) : 0
  transportationcharge = transportationcharge.toFixed(2)

  const weighttons = purchaseorderlistvalue.weighttons ? purchaseorderlistvalue.weighttons : '';
  const loadingcharge = purchaseorderlistvalue.loadingcharge ? purchaseorderlistvalue.loadingcharge : 0;

  console.log(purchaseorderlistvalue.scheduledays)

  let scheduledays = purchaseorderlistvalue.scheduledays ? purchaseorderlistvalue.scheduledays : ''
  let schedulepayment = purchaseorderlistvalue.schedulepayment ? purchaseorderlistvalue.schedulepayment : ''
  const taxpercentage = purchaseorderlistvalue.gst
    ? purchaseorderlistvalue.gst / 100
    : '';
  // const grandtotal =  totalrs+igstval+cgstval+sgstval + transportationcharge;
  let grandtotal = parseFloat(totalrs) + parseFloat(transportationcharge) + parseFloat(loadingcharge);
  grandtotal = grandtotal.toFixed(2);
  RsPaise(grandtotal);
  const selectecontactlist = purchaseorderlistvalue.selectedcontactlist[0]
    ? purchaseorderlistvalue.selectedcontactlist[0].suppliercontactname
    : '';
  const suppliercontactnumber = purchaseorderlistvalue.selectedcontactlist[0]
    ? purchaseorderlistvalue.selectedcontactlist[0].suppliercontactnumber
    : '';
  const cond_data = data_cond;
  return (
    <Document>
      <Page size='A4' style={styles.pagepadding}>
        <View style={styles.section}>
          <Image src={logo} />
          {/* <Text style={styles.logo}></Text> */}
          <Text style={styles.headerwidthAddress}>
            <SmallBold>Office & Works:</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>
        {/* <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>Jakkur Post,Srirampure,</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>Bangalore - 560064</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>off No: +919900069150</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>
            works: KIADB obendanahalli,
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>
            Industrial area, Doddaballapura,
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>
            Bangalore rural dist, karnataka,INDIA
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>Email: info@pondhan.com</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>
            visit us: www.pondhan.com
          </Text>
        </View> */}
        <Text style={styles.headerwidth}><Header>Purchase Order</Header>



        </Text>

        <View style={[styles.section, { paddingBottom: 15, marginTop: 8 }]}>

          <Text style={styles.refstyle}>
            PO No. PSPL/{financialYear}/{ponumber}
          </Text>
          {/* <Text style={styles.refstyle}>
            Ref No.{purchaseorderlistvalue.referenceno}
          </Text> */}
          <Text style={styles.headerwidthdate}>
            Date:{dateNew}
          </Text>
        </View>

        <View style={styles.columnSection}>
          <View>
            <View>
              <Text style={styles.fontsize18bold}>Vendor Address:</Text>
            </View>
            <View>
              <Text style={styles.fontsize18}>M/S {purchaseorderlistvalue.suppliername}</Text>
            </View>
            <View>
              <Text style={styles.fontsize18}>
                {purchaseorderlistvalue.supplieraddress1}{'\n'}
                {purchaseorderlistvalue.supplieraddress2}
              </Text>
            </View>
            <View>
              <Text style={styles.fontsize18}>
                {purchaseorderlistvalue.suppliercity}{' '}
                {purchaseorderlistvalue.supplierstate}{' '}
                {purchaseorderlistvalue.supplierpincode}
              </Text>
            </View>
            <View>
              <Text style={styles.fontsize18}>{purchaseorderlistvalue.suppliercountry}</Text>
            </View>
            <View>
              <Text style={styles.fontsize18}>Mobile: {suppliercontactnumber}</Text>
            </View>
            <View>
              <Text style={styles.fontsize18}>GSTIN No: {purchaseorderlistvalue.suppliergstnumber}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.fontsize18}>Billing & Delivery Address: {'\n'}{'\n'}
              Pondhan Scaffolding Pvt Ltd.{'\n'}
              Plot No : 40&41, Rd. No 5{'\n'}
              3rd Phase, KIADB- Obadenahalli{'\n'}
              Industrial Area, Doddabalapura,{'\n'}
              Bangalore Rural- 561203{'\n'}
              GSTIN No : 29AAGCP4018E1ZJ{'\n'}
              Contact Number : 8971769150 / 9900069150

            </Text>
          </View>
        </View>
        <View >
          <Text style={styles.kindattend}>Kind Attn:{selectecontactlist}</Text>
        </View>

        <View >
          <Text style={styles.kindattend}>{'\n'}</Text>
        </View>
        <View>
          <Text style={styles.main}>Dear Sir,</Text>
        </View>
        <View>
          <Text style={[styles.main, styles.padd6]}>
            With ref to the discussions we had with you, we are pleased to place our order for the below mentioned
            material as per the rates quoted by you and as per the T&C given below. ( Ref No ({purchaseorderlistvalue.referenceno}))
          </Text>
        </View>
        <Table style={(styles.section, styles.padding)} data={data}>
          <TableHeader textAlign={'center'}>
            <TableCell weighting={0.08}>
              <Text style={styles.headerfont}>SL No</Text>
            </TableCell>
            <TableCell weighting={0.6}>
              <Text style={styles.headerfont}>Material Description</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont}>Qty</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont}>Rate</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont}>Total</Text>
            </TableCell>
          </TableHeader>
          <TableBody textAlign={'center'}>
            <DataTableCell

              weighting={0.08}
              getContent={r => <Small>{r.slno}</Small>}
            />
            <DataTableCell
              style={{ maxWidth: 200, wordWrap: 'break-word' }}
              weighting={0.6}
              getContent={r => <Description>{r.itemname}</Description>}
            />
            <DataTableCell
              style={styles.padding2}
              weighting={0.2}
              getContent={r => <Small>{r.itemdescription}</Small>}
            />
            <DataTableCell
              style={styles.padding2}
              weighting={0.2}
              getContent={r => <Small>{r.quantity}</Small>} />
            <DataTableCell
              style={styles.padding2}
              weighting={0.2}
              getContent={r => <Small>{r.totalcost}</Small>} />
          </TableBody>
        </Table>
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.08}>
              <Text style={styles.headerfont2}>Total.</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={[styles.headerfont2, { textAlign: 'center' }]}>{totaleachitem}</Text>
            </TableCell>
          </TableHeader>
        </Table>
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.08}>
              <Text style={styles.headerfont2}>CGST@{cgst}%. </Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={[styles.headerfont2, { textAlign: 'center' }]}>{cgstval}</Text>
            </TableCell>
          </TableHeader>
        </Table>
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.08}>
              <Text style={styles.headerfont2}>SGST@{sgst}%. </Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={[styles.headerfont2, { textAlign: 'center' }]}>{sgstval}</Text>
            </TableCell>
          </TableHeader>
        </Table>
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.08}>
              <Text style={styles.headerfont2}>IGST@{igst}%. </Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={[styles.headerfont2, { textAlign: 'center' }]}>{igstval}</Text>
            </TableCell>
          </TableHeader>
        </Table>
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>Transportation Charge</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={[styles.headerfont2, { textAlign: 'center' }]}>{transportationcharge}</Text>
            </TableCell>
          </TableHeader>
        </Table>
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>Loading And Handling Charges for {weighttons}tons</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={[styles.headerfont2, { textAlign: 'center' }]}>{loadingcharge}</Text>
            </TableCell>
          </TableHeader>
        </Table>
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.08}>
              <Text style={styles.headerfont2}>Grand Total</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={[styles.headerfont2, { textAlign: 'center' }]}>
                {grandtotal}
              </Text>
            </TableCell>
          </TableHeader>
        </Table>
        <Table style={styles.section}>
          <TableHeader>

            <TableCell weighting={1}>
              <Text style={[styles.headerfont5, { textAlign: 'right' }]}>
                {amountinwords}
              </Text>
            </TableCell>
          </TableHeader>
        </Table>
        <View style={styles.footerfixed}>
          <Image src={client} />
        </View>
      </Page>
      <Page size='A4' style={styles.pagepadding}>
        <View style={styles.section}>
          <Image src={logo} />
          <Text style={styles.logo}></Text>

          <Text style={styles.headerwidthAddress}>
            <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>

        </View>
        {/* <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>Jakkur Post,Srirampure,</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>Bangalore - 560064</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>off No: +919900069150</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>
            works: KIADB obendanahalli,
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>
            Industrial area, Doddaballapura,
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>
            Bangalore rural dist, karnataka,INDIA
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}></Text>
          <Text style={styles.headerwidthAddress}>Email: info@pondhan.com</Text>
        </View> */}
        <View style={styles.section}>
          <Text style={styles.headerwidthlogo}>Terms & conditions:</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>1. Payment Schedule: {schedulepayment} </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>2. Delivery Schedule: {scheduledays} </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            3. Unloading : Exclusive . (M/S Pondhan’s Scope)
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            4. Painting of material : If applicable
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            5. The weight mentioned above will be subject to toleranc of +/-
            0.5%. Difference in weight beyond tolerance limit will not be
            accepted and any shortfall in weight will be deducted from your
            Invoice as applicable.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            6. All material shall be as per IS standard and acceptable quality.
            You will take rejected material back at your cost.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            7. Weighing slip shall be sent along with each supply.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            8. Please specify the order No on challan & Bills.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            9. All your bills must contain our PO number, receipt along with the
            bill, failing which the same will not be passed for the payment.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            10. In the event you fail to mention GST number & HSN code of
            purchaser and seller on the Invoice, the GST component will be
            released only after the compliance of the same. (mandatory as per
            the provision of GST ACT.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            11. Please ensure your GST Invoice is submitted to us immediately
            after supply of materials, which should contain our GST number also.
            Our GST no is : 29AAGCP4018E1ZJ & PAN is AAGCP4018E.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            12. We will recover the GST amount in case of mismatch of GST credit
            with the GST return filed by the vendor.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            13. Above price is inclusive of loading but excludes transportation
            charges & unloading comes under the perview of Pondhan Scaffolding
            Pvt Ltd.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            14. The Transport Vehicle must have fitness certificate, emission
            certificate, Inscurance, RC documents and all statutory vehicle
            documents & Driver should have valid driving Liscenceas well as
            driver alongwith unloaders compulsory to wear safety helmets,
            reflection jackets and Shoes also.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            15. Special Terms : Relevant Eway bill has to be attached alongwith
            each and every consignment.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            16. Special Note : Relevant manufacturer test certificate has to be
            submittedalongwith the material. (if applicable).
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            17. Special Note : Please ensure to put up your signature alongwith
            your company seal, on TAX INVOICE without fail.
          </Text>
        </View>
        <View >
          <Text>{'\n'}</Text>
        </View>

        <View>
          <Text style={styles.main}>Note: {purchaseorderlistvalue.subject}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.main}>
            Kindly sign and return the duplicate copy of this PO as a token of
            acceptance. {'\n'} Thanking you, {'\n'} {'\n'} For Pondhan
            Scaffolding Pvt Ltd. {'\n'} {'\n'} Authorised Signatory {'\n'}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.main}>Prepared By:</Text>
          <Text style={styles.main}>Checked By:</Text>
        </View>

        <View>
          <Text
            style={[styles.main, { color: 'red' }]}
          >
            {'\n'} {'\n'}
            Note: Any legal matter should be under Bengaluru Jurisdiction </Text>
        </View>

        <View style={styles.footerfixed}>
          <Image src={client} />
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
