import React from 'react';
import { Route } from 'react-router-dom';
import Header from './Components/Header/Header';
import AddUser from './Containers/AddUser/AddUser';
import UpdateUser from './Containers/AddUser/UpdateUser';
import AdminDashboard from './Containers/Admin/AdminDashboard';
import ProductBilling from './Containers/Billing/ProductBilling';
import LoginPage from './Containers/LoginPage/LoginPage';
import AddMaterial from './Containers/Material/AddMaterial';
import AddMaterialType from './Containers/Material/AddMaterialType';
import UpdateMaterial from './Containers/Material/UpdateMaterial';
import UpdateMaterialType from './Containers/Material/UpdateMaterialType';
import ProductList from './Containers/Product/Product';
import ProductUpdate from './Containers/Product/UpdateProduct';
import ViewProduct from './Containers/Product/ViewProduct';
import ViewProductList from './Containers/Product/ViewProductList';
import AddCustomer from './Containers/SetUp/Customer/Customer';
import Transaction from './Containers/SetUp/Customer/Transaction';
import UpdateCustomer from './Containers/SetUp/Customer/UpdateCustomer';
import ViewCustomerTable from './Containers/SetUp/Customer/ViewCustomerTable';
import CustomerInfo from './Containers/SetUp/CustomerInfo/CustomerInfo';
import CustomerQuotationList from './Containers/SetUp/CustomerInfo/CustomerQuotation/CustomerQuotationList';
import AddFinancialYear from './Containers/SetUp/FinancialYear/AddFinancialYear';
import AddChalan from './Containers/Transaction/Challan/Challan/AddChalan';
import ViewChallan from './Containers/Transaction/Challan/View/ViewChallan';
import ViewChallanPdf from './Containers/Transaction/Challan/View/ViewChallanPdf';
import EnquiryPage from './Containers/Transaction/Enquiry';
import AddInvoice from './Containers/Transaction/Invoice/Invoice/AddInvoice';
import AddProformaInvoice from './Containers/Transaction/Invoice/Invoice/AddProformaInvoice';
import ViewInvoice from './Containers/Transaction/Invoice/View/ViewInvoice';
import ViewInvoicePdf from './Containers/Transaction/Invoice/View/ViewInvoicePdf';
import ViewProformaInvoice from './Containers/Transaction/Invoice/View/ViewProformaInvoice';
import ViewProformaInvoicePdf from './Containers/Transaction/Invoice/View/ViewProformaInvoicePdf';
import AddMasterItemList from './Containers/Transaction/Item/ItemList/AddMasterItemList';
import MasterItemList from './Containers/Transaction/Item/ItemList/MasterItemListValues';
import UpdateMasterItemList from './Containers/Transaction/Item/ItemList/UpdateMasterItemList';
import MasterItemType from './Containers/Transaction/Item/ItemType/ItemMasterType';
import MasterItemTypeList from './Containers/Transaction/Item/ItemType/ItemMasterTypeList';
import UpdateMasterItemType from './Containers/Transaction/Item/ItemType/UpdateItemMasterType';
import ReceiptScreen from './Containers/Transaction/ItemScreening/ReceiptScreen/ReceiptScreen';
import ViewReceiptPdf from './Containers/Transaction/ItemScreening/ReceiptScreen/ViewReceipt';
import MoveOrderScreen from './Containers/Transaction/MoveOrder/MoveOrderScreen';
import ViewMoveOrder from './Containers/Transaction/MoveOrder/View/ViewMoveOrder';
import ViewItemsMoveOrder from './Containers/Transaction/MoveOrder/View/viewOrderItems';
import AddOnHand from './Containers/Transaction/OnHand/AddOnHand';
import AddProduction from './Containers/Transaction/ProductionScreen/Production/AddProduction';
import ViewProudction from './Containers/Transaction/ProductionScreen/View/ViewProudction';
import PurchaseOrder from './Containers/Transaction/PurchaseOrder/PurchaseOrder';
import ViewPurchaseOrderList from './Containers/Transaction/PurchaseOrder/ViewPurchaseOrderList';
import ViewPurchaseOrderPdf from './Containers/Transaction/PurchaseOrder/ViewPurchaseOrderPdf';
import AddQuotation from './Containers/Transaction/Quotation/Quotation/AddQuotation';
import ViewQuotation from './Containers/Transaction/Quotation/View/ViewQuotation';
import viewQuotationListTest from './Containers/Transaction/Quotation/View/viewQuotationListTest';
import ViewQuotationPdf from './Containers/Transaction/Quotation/View/ViewQuotationPdf';
import ViewNewPDFQuuotation from './Containers/Transaction/Quotation/View/ViewNewPDFQuuotation';
import SupplierContact from './Containers/Transaction/Supplier/SupplierContact/SupplierContact';
import SupplierDetail from './Containers/Transaction/Supplier/SupplierDetail/SupplierDetail';
import SupplierDetailUpdate from './Containers/Transaction/Supplier/SupplierDetail/SupplierDetailUpdate';
import SupplierList from './Containers/Transaction/Supplier/SupplierDetail/Supplierlist';
import TotalQuantity from './Containers/Transaction/TotalQuantityScreen/TotalQuantity';
import Logs from './Containers/Transaction/Logs/Logs';
import AddOrder from './Containers/Transaction/WorkOrder/Order/AddOrder';
import ViewOrder from './Containers/Transaction/WorkOrder/View/ViewOrder';
import ViewCompletedOrder from './Containers/Transaction/WorkOrder/View/ViewCompletedOrder';
import ViewOrderPdf from './Containers/Transaction/WorkOrder/View/ViewOrderPdf';
import ViewAdmin from './Containers/View/AdminRole';
import ViewEmployee from './Containers/View/EmployeeRole';
import './global/global.scss';
import BOMPage from './Pages/BOMPage';
import ChallanPage from './Pages/Challan';
import CRMPage from './Pages/CRMPage';
import CustomerPage from './Pages/CustomerPage';
import InventoryPage from './Pages/InventoryPage';
import InvoicePage from './Pages/InvoicePage';
import InvoicePage1 from './Pages/InvoicePage1';
import InvoicePage2 from './Pages/InvoicePage2';
import ItemsPage from './Pages/ItemsPage';
import ManufacturingPage from './Pages/ManufacturingPage';
import MoveOrder from './Pages/MoveOrder';
import OnHandPage from './Pages/OnHandPage';
import ProductionPage from './Pages/Production';
import PurchaceOrderPage from './Pages/PurchaceOrderPage';
import QuantityPage from './Pages/QuantityPage';
import QuotationPage from './Pages/QuotationPage';
import SupplierPage from './Pages/SupplierPage';
import TransactionPage from './Pages/TransactionPage';
import WorkOrderPage from './Pages/WorkOrder';
import GuestRoute from './Routes/GuestRoute';
import UserRoute from './Routes/UserRoute';
import { Roles } from './Services/Roles';











class App extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <Route path='/' exact component={LoginPage} />
        <GuestRoute path='/login' exact component={LoginPage} />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/admin_dashboard'
          exact
          component={AdminDashboard}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.ITADMIN]}
          path='/adduser'
          exact
          component={AddUser}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.ITADMIN]}
          path='/adminlist'
          exact
          component={ViewAdmin}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/employeelist'
          exact
          component={ViewEmployee}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.ITADMIN]}
          path='/updateuser/:id'
          exact
          component={UpdateUser}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/enquiry'
          exact
          component={EnquiryPage}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/addproduct'
          exact
          component={ProductList}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/updateproduct/:id'
          exact
          component={ProductUpdate}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/viewproductlist'
          exact
          component={ViewProductList}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/bomproductlist'
          exact
          component={BOMPage}
        />


        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/viewproduct/:id'
          exact
          component={ViewProduct}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/add_itemtype'
          exact
          component={AddMaterialType}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/update_itemtype/:id'
          exact
          component={UpdateMaterialType}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/additem'
          exact
          component={AddMaterial}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/update_material/:id'
          exact
          component={UpdateMaterial}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/productbilling'
          exact
          component={ProductBilling}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/inventory_page'
          exact
          component={InventoryPage}
        />
        <UserRoute
          location={this.props.location}
          roles={[
            Roles.ADMIN,
            Roles.FINANCE,
            Roles.PURCHASE,
            Roles.SALES,
            Roles.STORE,
            Roles.PRODUCTION
          ]}
          path='/transaction_page'
          exact
          component={TransactionPage}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/invoice'
          exact
          component={InvoicePage}
        />
          <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/invoice1'
          exact
          component={InvoicePage1}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/invoice2'
          exact
          component={InvoicePage2}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/delivery-challan'
          exact
          component={ChallanPage}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.STORE, Roles.PRODUCTION]}
          path='/addonhand'
          exact
          component={AddOnHand}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/move-order'
          exact
          component={MoveOrder}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/move'
          exact
          component={MoveOrderScreen}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/moveorderlist'
          exact
          component={ViewMoveOrder}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/view_ItemsOrder/:id'
          exact
          component={ViewItemsMoveOrder}
        />        
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/work-order'
          exact
          component={WorkOrderPage}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/editworkorder/:id'
          exact
          component={AddOrder}
        />


        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/manufacturing'
          exact
          component={ManufacturingPage}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/customer'
          exact
          component={CustomerPage}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/financialYearAdd'
          exact
          component={AddFinancialYear}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/transaction/:id'
          exact
          component={Transaction}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/supplier'
          exact
          component={SupplierPage}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/quotationpage'
          exact
          component={QuotationPage}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.STORE]}
          path='/quantitypage'
          exact
          component={QuantityPage}
        />



        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/itemspage'
          exact
          component={ItemsPage}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/mitemtype'
          exact
          component={MasterItemType}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/mitemtypelist'
          exact
          component={MasterItemType}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/masteritemtypelist'
          exact
          component={MasterItemTypeList}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/madditem'
          exact
          component={AddMasterItemList}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/mitemlistvalues'
          exact
          component={MasterItemList}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/m_updateitemlist/:id'
          exact
          component={UpdateMasterItemList}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/update_mitemtype/:id'
          exact
          component={UpdateMasterItemType}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PURCHASE]}
          path='/purchase_order'
          exact
          component={PurchaseOrder}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PURCHASE]}
          path='/purchase_orderpage'
          exact
          component={PurchaceOrderPage}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PURCHASE]}
          path='/view_purchaseorderpdf/:id'
          exact
          component={ViewPurchaseOrderPdf}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PURCHASE]}
          path='/view_purchaseorderlist'
          exact
          component={ViewPurchaseOrderList}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/supplier_detail'
          exact
          component={SupplierDetail}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/supplier_contact'
          exact
          component={SupplierContact}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PURCHASE]}
          path='/purchase_order_update/:id'
          exact
          component={PurchaseOrder}
        />

        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/supplierdetail_update/:id'
          exact
          component={SupplierDetailUpdate}
        />


        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/supplier_list'
          exact
          component={SupplierList}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.STORE]}
          path='/receipt_screen'
          exact
          component={ReceiptScreen}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.STORE]}
          path='/viewreceipt'
          exact
          component={ViewReceiptPdf}
        />


        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.STORE]}
          path='/totalitemquantity'
          exact
          component={TotalQuantity}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.ITADMIN]}
          path='/logs'
          exact
          component={Logs}
        />        

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.STORE]}
          path='/onhand'
          exact
          component={OnHandPage}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/AddCustomer'
          exact
          component={AddCustomer}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/customerlist'
          exact
          component={ViewCustomerTable}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/crmlist'
          exact
          component={CRMPage}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/updatecustomer/:id'
          exact
          component={UpdateCustomer}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/customerinfo'
          exact
          component={CustomerInfo}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/addquotation'
          exact
          component={AddQuotation}
        />
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/editquotation/:id'
          exact
          component={AddQuotation}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/quotationlist'
          exact
          component={ViewQuotation}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/quotationlisttest'
          exact
          component={viewQuotationListTest}
        />        
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/view_quotationpdf/:id'
          exact
          component={ViewQuotationPdf}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.SALES]}
          path='/viewnewpdf/:id'
          exact
          component={ViewNewPDFQuuotation}
        />        
        <UserRoute
          location={this.props.location}
          roles={Roles.ADMIN}
          path='/customerquotationlist/:id'
          exact
          component={CustomerQuotationList}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/addinvoice'
          exact
          component={AddInvoice}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/addproformainvoice'
          exact
          component={AddProformaInvoice}
        />


        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/editinvoice/:id'
          exact
          component={AddInvoice}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/editproformainvoice/:id'
          exact
          component={AddProformaInvoice}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/addchallan'
          exact
          component={AddChalan}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/editchallan/:id'
          exact
          component={AddChalan}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/addorder'
          exact
          component={AddOrder}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/orderlist'
          exact
          component={ViewOrder}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/completedorderlist'
          exact
          component={ViewCompletedOrder}
        />        
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/invoicelist'
          exact
          component={ViewInvoice}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/proformainvoicelist'
          exact
          component={ViewProformaInvoice}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/challanlist'
          exact
          component={ViewChallan}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/productionlist'
          exact
          component={ViewProudction}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/view_invoicepdf/:id'
          exact
          component={ViewInvoicePdf}
        />

        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/view_proformainvoicepdf/:id'
          exact
          component={ViewProformaInvoicePdf}
        />


        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/view_orderpdf/:id'
          exact
          component={ViewOrderPdf}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.FINANCE]}
          path='/view_challanpdf/:id'
          exact
          component={ViewChallanPdf}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/production'
          exact
          component={ProductionPage}
        />
        <UserRoute
          location={this.props.location}
          roles={[Roles.ADMIN, Roles.PRODUCTION]}
          path='/add-production'
          exact
          component={AddProduction}
        />
      </div>
    );
  }
}

export default App;
