import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { totalitemvaluesearch } from '../../../../actions/Transaction';
import axios from "axios";

class MoveOrderScreenForm extends React.Component {
  state = {
    data: {
      selectedItemlist: {
        id: [null],
        itemname: [null],
        itemdescription: [null],
        itemtype: [null],
        itemrate: [null],
        itemuom: [null],
        itemlocation: [null],
        costperunit: [null],
        quantity: [null],
        availableQuantity: [null],
        totalcost: [null],
        remarks: [null]
      },
      moveorderno: '1',
      fromaddress: '',
      toaddress: '',
      values: ['']
    },
    errors: {},
    materialoptionlistvalues: [],
    supplierdetaillistvalue: [],
    supplierdetaillistvalueobject: {},
    masteritemlistvalues: [],
    masteritemlistvaluesobject: {},
    currencylistvalues: [],
    uom: [],
    locationlistvalue: [],
    tolocationlist: [],
    itemlist: [],
    loading: false,
    success: false
  };

  componentWillReceiveProps(props) {
    const materialoptionlistvalues = Object.values(
      props.materialoptionlistvalues
    );
    // const supplierdetaillistvalue = Object.values(
    //   props.supplierdetaillistvalue
    // );
    // const supplierdetaillistvalueobject = props.supplierdetaillistvalue;
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    const masteritemlistvaluesobject = props.masteritemlistvalues;
    const currencylistvalues = Object.values(props.currencylistvalues);
    const uomlistvalues = Object.values(props.uomlistvalues);
    const locationlistvalue = Object.values(props.locationlistvalue);
    const purchaseorderlist = Object.keys(this.props.purchaseorderlistvalues);
    const moveorderlistvalues = Object.keys(this.props.moveorderlistvalues);
    const moveorderno =
      moveorderlistvalues.length > 0 ? 1 + moveorderlistvalues.length : 1;
    this.setState({
      ...this.state,
      masteritemlistvalues,
      masteritemlistvaluesobject,
      materialoptionlistvalues,
      currencylistvalues,
      locationlistvalue,
      uom: uomlistvalues,
      data: {
        ...this.state.data,
        moveorderno
      }
    },()=>{
      if(moveorderlistvalues.length == 0)
        this.getMovedata()
    });
  }

  getMovedata(){
    if(Object.keys(this.props.moveorderlistvalues).length == 0){
      axios
      .get(`${process.env.REACT_APP_BASE_URL}/moveorder/getmoveorder`)
      .then(res => {
         //if (res.data.moveorderlist.moveorderdata.length > 0) {
           
          let moveorderno =
          res.data.moveorderlist.moveorderdata.length > 0 ? 1 + res.data.moveorderlist.moveorderdata.length : 1;
          
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              moveorderno
            }
          });
        //}
      },
        error => {
          this.setState({ success: false, message: 'Server error.' });
        });
    }
  }


  handleChangeDeliveryDate = date => {
    this.setState({
      data: {
        ...this.state.data,
        requestdeliverydate: date
      }
    });
  };
  handleChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  totalValue = () => {
    const { totalcost } = this.state.data.selectedItemlist;
    if (totalcost.length > 0) {
      const totalvalue = totalcost
        .map((value, i) => {
          if (value) {
            return parseFloat(value);
          } else {
            return 0;
          }
        })
        .reduce((a, b) => {
          return a + b;
        });
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          totalvalue
        }
      });
    }
  };

  selectitemchange = (e, index) => {
    console.log(this.state.masteritemlistvalues)
    const selected_id = e.target.value;
    if (selected_id) {
      let list = this.state.itemlist;
      const selected_item_list = list.filter(it => it.itemid === selected_id);
      const selected_item = selected_item_list[0];
      console.log(selected_item);
      let itemdesc = ''
      this.state.masteritemlistvalues.map((item) =>{
        if(item.masteritemtypename == selected_item.itemname)
          itemdesc = item.masteritemshortdescription != '' ? item.masteritemshortdescription : item.masteritemdescription
       })
      const selectedItemlist = { ...this.state.data.selectedItemlist };
      selectedItemlist.id[index] = selected_item.itemid;
      selectedItemlist.itemname[index] = selected_item.itemname;
      selectedItemlist.itemdescription[index] = itemdesc;
  
      // selectedItemlist.itemdescription[index] = selected_item.masteritemshortdescription == '' ? selected_item.masteritemdescription 
      // : selected_item.masteritemshortdescription
      // selectedItemlist.itemtype[index] = selected_item.masteritemtype._id;
      // selectedItemlist.itemrate[index] = selected_item.masteritemrate;
      // selectedItemlist.itemuom[index] = selected_item.masteritemuom._id;
      // selectedItemlist.itemlocation[index] = null;
      // selectedItemlist.costperunit[index] = selected_item.masteritemcostperunit
      //   ? selected_item.masteritemcostperunit.toString()
      //   : '';
      selectedItemlist.quantity[index] = selected_item.itemquantity;
      selectedItemlist.availableQuantity[index] = selected_item.itemquantity;
      // selectedItemlist.totalcost[index] = selected_item.masteritemcostperunit
      //   ? selected_item.masteritemcostperunit.toString()
      //   : '';
      // this.state.data.selectedItemlist.id[index] = selected_item.id;
      // this.state.data.selectedItemlist.itemdescription[index] =
      //   selected_item.masteritemdescription;
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          selectedItemlist
          // selectedItemlist: {
          //   ...this.state.data.selectedItemlist,
          //   id: [...this.state.data.selectedItemlist.id, selected_item.id]
          // itemname: [...this.state.data.selectedItemlist.itemname, ""],
          // itemdescription: [
          //   ...this.state.data.selectedItemlist.itemdescription,
          //   ""
          // ],
          // itemtype: [...this.state.data.selectedItemlist.itemtype, ""],
          // itemrate: [...this.state.data.selectedItemlist.itemrate, ""],
          // itemuom: [...this.state.data.selectedItemlist.itemuom, ""],
          // costperunit: [...this.state.data.selectedItemlist.costperunit, ""]
          // }
        }
      });
    }
    this.totalValue();
  };

  locationChange = (e, index) => {
    const selected_location = e.target.value;
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.itemlocation[index] = selected_location;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  onhandlerChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  onhandlerFromChange = e => {
    const locationlistvalue = this.state.locationlistvalue;
    let tolocationlist = locationlistvalue.filter(
      item => item.id !== e.target.value
    );
    this.setState({
      ...this.state,
      tolocationlist,
      data: { ...this.state.data, fromaddress: e.target.value }
    });
    let data = { locationid: e.target.value, itemid: '' };
    this.props.totalitemvaluesearch(data).then(data => {
      console.log(data);
      const itemlist = data.itemlist.map(item => {
        return {
          id: item.id,
          locationname: item.locationname,
          itemid:item.itemid,
          itemname: item.itemname,
          itemdescription: item.itemdescription,
          itemquantity: item.itemquantity
        };
      });
      this.setState({
        ...this.state,
        itemlist
      });
      // return data;
    });
  };
  onhandlerToChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, toaddress: e.target.value }
    });
  };
  onSubjectHandler = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  filterItems(itemarray,key) {
    return itemarray.sort((a,b)=>{return a[key].toLowerCase().localeCompare(b[key].toLowerCase())})
  }

  createSelectItems = () => {
    let items = [];
    let masteritemlistvalues = this.state.itemlist;
    masteritemlistvalues = this.filterItems(masteritemlistvalues,'itemname')
    console.log(masteritemlistvalues)
    masteritemlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.itemid}>
          {option.itemname}
        </option>
      );
    });
    return items;
  };

  createSelectItemTypes = () => {
    let items = [];
    const materialoptionlistvalues = this.state.materialoptionlistvalues;
    materialoptionlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectCurrencyItems = () => {
    let items = [];
    const currencylistvalues = this.state.currencylistvalues;
    currencylistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.currencyname}
        </option>
      );
    });
    return items;
  };

  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uom;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };
  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };
  createToLocationList = () => {
    let items = [];
    const locationlistvalue = this.state.tolocationlist;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  handleClick = e => {
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ''],
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id: [...this.state.data.selectedItemlist.id, ''],
          itemname: [...this.state.data.selectedItemlist.itemname, ''],
          itemdescription: [
            ...this.state.data.selectedItemlist.itemdescription,
            ''
          ],
          itemtype: [...this.state.data.selectedItemlist.itemtype, ''],
          itemrate: [...this.state.data.selectedItemlist.itemrate, ''],
          itemuom: [...this.state.data.selectedItemlist.itemuom, ''],
          itemlocation: [...this.state.data.selectedItemlist.itemlocation, ''],
          costperunit: [...this.state.data.selectedItemlist.costperunit, ''],
          quantity: [...this.state.data.selectedItemlist.quantity, 0],
          totalcost: [...this.state.data.selectedItemlist.totalcost, ''],
          availableQuantity: [...this.state.data.selectedItemlist.totalcost, '']
        }
      }
    });
  };
  removeClick = (e, index) => {
    const removeItem = { ...this.state.data };
    const values = removeItem.values;
    const id = removeItem.selectedItemlist.id;
    const itemname = removeItem.selectedItemlist.itemname;
    const itemdescription = removeItem.selectedItemlist.itemdescription;
    const itemtype = removeItem.selectedItemlist.itemtype;
    const itemrate = removeItem.selectedItemlist.itemrate;
    const itemuom = removeItem.selectedItemlist.itemuom;
    const itemlocation = removeItem.selectedItemlist.itemlocation;
    const costperunit = removeItem.selectedItemlist.costperunit;
    const quantity = removeItem.selectedItemlist.quantity;
    const availableQuantity = removeItem.selectedItemlist.availableQuantity;
    
    const totalcost = removeItem.selectedItemlist.totalcost;
    values.splice(index, 1);
    id.splice(index, 1);
    itemname.splice(index, 1);
    itemdescription.splice(index, 1);
    itemtype.splice(index, 1);
    itemrate.splice(index, 1);
    itemuom.splice(index, 1);
    itemlocation.splice(index, 1);
    costperunit.splice(index, 1);
    quantity.splice(index, 1);
    totalcost.splice(index, 1);
    availableQuantity.splice(index, 1);
    
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id,
          itemname,
          itemdescription,
          itemtype,
          itemrate,
          itemuom,
          itemlocation,
          costperunit,
          availableQuantity,
          quantity,
          totalcost
        }
      }
    });
  };

  quantityChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const costperunit = selectedItemlist.costperunit[index] || null;

   
 
    console.log(typeof selectedItemlist.quantity[index])
    console.log(typeof selectedItemlist.availableQuantity[index])

    if(Number(e.target.value) > selectedItemlist.availableQuantity[index]){
      window.alert('Move quantity cannot be greater than available quantity')
      console.log(this.state.data.selectedItemlist.quantity)
      selectedItemlist.quantity[index] = '0'
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          selectedItemlist
        }
      });
    }
    else{
      selectedItemlist.quantity[index] = e.target.value;
      const totalValue = costperunit
      ? e.target.value * costperunit
      : null || undefined || '';
      selectedItemlist.totalcost[index] = totalValue
        ? parseFloat(totalValue).toFixed(2)
        : '';
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          selectedItemlist
        }
      });
      this.totalValue();
    }


  };
  remarkChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.remarks[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  unitChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.costperunit[index] = e.target.value;
    const totalcost =
      parseFloat(selectedItemlist.quantity[index]) *
      parseFloat(selectedItemlist.costperunit[index]);
    selectedItemlist.totalcost[index] = totalcost
      ? totalcost.toFixed(2)
      : null || '';
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  handleValidSubmit = () => {
    let AddedIteminfo = [];
    const { supplierdetail, selectedItemlist } = this.state.data;
    const { values } = this.state.data;
    const id = selectedItemlist.id;
    const itemname = selectedItemlist.itemname;
    const itemdescription = selectedItemlist.itemdescription;
    const itemtype = selectedItemlist.itemtype;
    const itemrate = selectedItemlist.itemrate;
    const itemuom = selectedItemlist.itemuom;
    const itemlocation = selectedItemlist.itemlocation;
    const costperunit = selectedItemlist.costperunit;
    const quantity = selectedItemlist.quantity;
    const totalcost = selectedItemlist.totalcost;
    const remarks = selectedItemlist.remarks;
    values.forEach(function(value, i) {
      let obj = {};
      obj.id = id[i] || null;
      obj.itemname = itemname[i] || null;
      obj.itemdescription = itemdescription[i] || null;
      obj.itemtype = itemtype[i] || null;
      obj.itemrate = itemrate[i] || null;
      obj.itemuom = itemuom[i] || null;
      obj.itemlocation = itemlocation[i] || null;
      obj.costperunit = costperunit[i] || null;
      obj.quantity = quantity[i] || null;
      obj.totalcost = totalcost[i] || null;
      obj.remarks = remarks[i] || null;
      AddedIteminfo.push(obj);
    });
    const { data } = this.state;
    data['AddedIteminfo'] = AddedIteminfo;
    this.setState({ loading: true });
    console.log(JSON.stringify(selectedItemlist))
    console.log(JSON.stringify(data));
    this.props
      .submit(data)
      .then(() => {
        this.setState({ loading: false, errors: {}, success: true });
        this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  handleInvalidSubmit = () => {
    console.log('invalid submit');
  };
  render() {
    const { data, errors, loading, success } = this.state;
    return (
      <div>
        <div>
          {!!success ? (
            // <Alert color="info">Purchase Order is generated successfully</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Move Order</ModalHeader>
              <ModalBody>Order moved successfully</ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Move Order</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='moveorderno'>Move Order No</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='moveorderno'
                      type='text'
                      id='moveorderno'
                      value={data.moveorderno || null}
                      onChange={this.handleChange}
    
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Move Order No'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='fromaddress'>From location </Label>
                    <AvField
                      type='select'
                      name='fromaddress'
                      onChange={this.onhandlerFromChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            'Please enter to location'
                        }
                      }}
                    >
                      <option value=''>select location</option>
                      {this.createSelectLocationItems()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='toaddress'>To location </Label>
                    <AvField
                      type='select'
                      name='toaddress'
                      onChange={this.onhandlerToChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            'Please enter to location'
                        }
                      }}
                    >
                      <option value=''>select location</option>
                      {this.createToLocationList()}
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <div className='mg-t-20'>
                  <Button color='info' onClick={this.handleClick} type='button'>
                    Add Item
                  </Button>
                </div>
              </Row>
              <div
                className='purchaseorder-item-row'
                style={{ overflowX: 'unset' }}
              >
                {data.values.map((values, index) => {
                  return (
                    <div key={index}>
                      <div
                        className='purchaseorder-table'
                        style={{ width: 'auto' }}
                      >
                        <Row key={index}>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for='selectitem'>Item Name</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                type='select'
                                name={`selectitem${index}`}
                                
                                value={
                                  data.selectedItemlist.id[index]
                                }
                                onChange={e => this.selectitemchange(e, index)}
                                required={true}
                              >
                                <option value=''>select Item</option>
                                {this.createSelectItems()}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemdescription${index}`}>
                                Description
                              </Label>
                              <span className='required-label'>*</span>
                              <AvField
                                name={`masteritemdescription${index}`}
                                type='text'
                                id='masteritemdescription'
                                value={
                                  data.selectedItemlist.itemdescription[index]
                                }
                                onChange={this.onChange}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      'Please enter Item Description'
                                  }
                                
                                 
                                }}
                              />
                            </AvGroup>
                          </Col>
                          {/* <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemtype${index}`}>Type</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                style={{ width: '150px' }}
                                type='select'
                                name={`masteritemtype${index}`}
                                onChange={this.onChange}
                                required={true}
                                value={
                                  data.selectedItemlist.itemtype[index] || null
                                }
                              >
                                <option value=''>select</option>
                                {this.createSelectItemTypes()}
                              </AvField>
                            </AvGroup>
                          </Col> */}
                          {/* <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemuom${index}`}>UOM</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                type='select'
                                name={`masteritemuom${index}`}
                                onChange={this.onChange}
                                required={true}
                                value={
                                  data.selectedItemlist.itemuom[index] || null
                                }
                              >
                                <option value=''>uom</option>
                                {this.createSelectUomItems()}
                              </AvField>
                            </AvGroup>
                          </Col> */}
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`quantity${index}`}>
                                Available quantity
                              </Label>
                              <AvField
                                name={`quantity${index}`}
                                type='number'
                                id={`quantity${index}`}
                                disabled='true'
                                value={
                                  data.selectedItemlist.availableQuantity[
                                    index
                                  ]
                                }
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`movequantity${index}`}>
                                Move quantity
                              </Label>
                              <AvField
                                name={`movequantity${index}`}
                                type='number'
                                id={`movequantity${index}`}
                                
                                value={
                                  data.selectedItemlist.quantity[
                                    index
                                  ]
                                }
                                onChange={e => this.quantityChange(e, index)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter Item Quantity'
                                  },
                                  pattern: {
                                    value: '^[0-9.]*$',
                                    errorMessage: 'Please enter valid quantity'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <Button
                              type='button'
                              color='danger'
                              onClick={e => this.removeClick(e, index)}
                              style={{ marginTop: '30px' }}
                            >
                              -
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Row>
                <div className='mg-20'>
                  <Button
                    color='primary'
                    disabled={loading}
                    className='lgn-loader'
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierdetaillistvalue: state.supplierdetaillistvalue,
    masteritemlistvalues: state.masteritemlistvalues,
    materialoptionlistvalues: state.masteritemtypenamevalue,
    currencylistvalues: state.currencylistvalue,
    uomlistvalues: state.uomlistvalue,
    locationlistvalue: state.locationlistvalue,
    purchaseorderlistvalues: state.purchaseorderlistvalue,
    moveorderlistvalues: state.moveorderlistvalues
  };
}

export default connect(
  mapStateToProps,
  { totalitemvaluesearch }
)(MoveOrderScreenForm);
