import React from "react";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import PropTypes from "prop-types";
import AddMaterialOptions from "./MaterialForms/AddMaterialTypesForm";
import DataTable from "../../Components/Table/DatatablePage";
import { addMaterialTypes, getMaterialsTypes } from "../../actions/Product";

class AddMaterialType extends React.Component {
  state = {
    count: "",
    materialoptions: []
  };
  componentDidMount() {
    this.props.getMaterialsTypes();
  }

  updateButton = id => {
    let path = `/update_itemtype/${id}`;
    this.props.history.push(path);
  };
  componentWillReceiveProps(props) {
    const materialoptionslist = Object.values(props.materialoptions);
    const materialoptions = materialoptionslist.map((materialdata, i) => {
      return {
        materialname: materialdata.materialname,
        view: (
          <MDBBtn
            color="info"
            size="sm"
            onClick={() => {
              this.updateButton(materialdata.id);
            }}
          >
            view / Edit
          </MDBBtn>
        )
      };
    });
    const count = materialoptions.length;
    this.setState({ ...this.state, materialoptions, count });
  }
  submit = data => {
    return this.props.addMaterialTypes(data).then(data => {
      this.props.getMaterialsTypes();
      return data;
    });
  };
  render() {
    const { count, materialoptions } = this.state;
    const columnvalue = [
      {
        label: "Item Types",
        field: "materialname",
        sort: "asc",
        width: 100
      },
      {
        label: "View/Edit",
        field: "view",
        sort: "asc",
        width: 100
      }
    ];
    return (
      <div>
        <div className="container mg-20">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/inventory_page">Inventory</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Add Item Type</BreadcrumbItem>
          </Breadcrumb>

          <AddMaterialOptions submit={this.submit} />
        </div>
        {count > 0 && (
          <div className="container mg-20">
            <DataTable columnvalue={columnvalue} rowvalues={materialoptions} />
          </div>
        )}
      </div>
    );
  }
}

AddMaterialType.propTypes = {
  addMaterialTypes: PropTypes.func.isRequired,
  getMaterialsTypes: PropTypes.func.isRequired,
  materialoptions: PropTypes.shape({
    id: PropTypes.string.isRequired,
    materialname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    materialoptions: state.materialoption
  };
}
export default connect(
  mapStateToProps,
  { addMaterialTypes, getMaterialsTypes }
)(AddMaterialType);
