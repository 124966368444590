import { MATERIAL_OPTIONS } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case MATERIAL_OPTIONS:
      return {
        ...state,
        ...action.materialoption.entities.materialoptions
      };
    default:
      return state;
  }
}
