import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { MDBBtn } from "mdbreact";
import PropTypes from "prop-types";
import DataTable from "../../../../Components/Table/DatatablePage";
import MasterItemTypeForm from "../Form/MasterItemTypeForm";
import { connect } from "react-redux";
import {
  addMasterItemType,
  getMasterItemTypes
} from "../../../../actions/Transaction";
import CustomLoader from "../../../../Components/loader/loader";

class MasterItemType extends React.Component {
  state = {
    loading: false
  };
  submit = data => {
    this.dataHandler(true);
    return this.props.addMasterItemType(data).then(data => {
      this.dataHandler(false);
      this.props.getMasterItemTypes();
      return data;
    });
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Item Type</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <MasterItemTypeForm submit={this.submit} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    masteritemtypenamevalue: state.masteritemtypenamevalue
  };
}

export default connect(
  mapStateToProps,
  { addMasterItemType, getMasterItemTypes }
)(MasterItemType);
