import React from 'react';
import { connect } from 'react-redux';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Button, Label, Alert } from 'reactstrap';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import CustomLoader from '../../../Components/loader/loader';

class UpdateUserForm extends React.Component {
  state = {
    data: {
      id: '',
      title: '',
      firstname: this.props.firstname,
      lastname: '',
      email: '',
      phone: '',
      startdate: new Date(),
      enddate: '',
      username: '',
      password: '',
      repassword: '',
      active: '',
      persontype: '',
      second_persontype: '',
      department: '',
      job: '',
      location: ''
    },
    errors: {},
    loading: false,
    success: false,
    count: ''
  };
  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  componentWillReceiveProps(props) {
    const userRecord = props.userRecord;
    const userRecordModified = userRecord;
    userRecordModified.startdate = new Date(userRecord.startdate);
    // userRecordModified.enddate = new Date(userRecord.enddate);
    let dateEnd = new Date();
    try {
     dateEnd = new Date(userRecordModified.enddate);
     if(dateEnd == "Invalid Date") {
      dateEnd = null;
     }
    }
    catch (errors) {
      dateEnd = null;
    }
    userRecordModified.enddate = dateEnd;
    this.setState({ ...this.state.data, data: userRecordModified });
  }
  handleValidSubmit = () => {
    console.log(this.state.data);
    this.setState({ loading: true });
    this.props
      .submit(this.state.data)
      .then(data => {
        console.log('updateuserform page');
        console.log(data);
        this.setState({ loading: false, errors: {}, success: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };
  handleChangeStartDate = date => {
    const selectedDate = new Date(date);
    const formattedDate = `${selectedDate.getDate()}/${selectedDate.getMonth() +
      1}/${selectedDate.getFullYear()}`;
    console.log(formattedDate);
    this.setState({
      data: {
        ...this.state.data,
        startdate: date
      }
    });
  };

  handleChangeEndDate = date => {
    this.setState({
      data: {
        ...this.state.data,
        enddate: date
      }
    });
  };

  handleInvalidSubmit = () => {
    console.log('invalid submit');
  };
  render() {
    const { data, errors, loading, success } = this.state;
    return (
      <div className='container'>
        {loading ? <CustomLoader /> : null}
        <div>
          {!!success ? (
            <Alert color='info'>User details is updated successfully</Alert>
          ) : null}
          {!!errors.email ? <Alert color='danger'>{errors.email}</Alert> : null}
          {!!errors.username ? (
            <Alert color='danger'>{errors.username}</Alert>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Personal Details</CardHeader>
            <CardBody>
              <Row>
                <Col sm={6}>
                  <AvGroup>
                    <Label className='small-font-size' for='title'>Title</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      type='select'
                      name='title'
                      onChange={this.onChange}
                      value={data.title}
                    >
                      <option value='mr'>Mr</option>
                      <option value='ms'>Ms</option>
                    </AvField>
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for='firstname'>First Name</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='firstname'
                      type='text'
                      id='firstname'
                      value={data.firstname}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter firstname'
                        },
                        pattern: {
                          value: '^[a-zA-Z]',
                          errorMessage: 'Please enter valid name'
                        }
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for='lastname'>Last Name</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='lastname'
                      type='text'
                      id='lastname'
                      value={data.lastname}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter lastname'
                        },
            
                        pattern: {
                          value: '^[a-zA-Z]',
                          errorMessage: 'Please enter valid name'
                        }
                      }}
                    />
                  </AvGroup>
                  <div className='form-group'>
                    <Label className='small-font-size' for='startdate'>Start Date</Label>
                    <span className='required-label'>*</span>
                    <div>
                      <DatePicker
                        selected={data.startdate}
                        onChange={this.handleChangeStartDate}
                        className='form-control'
                        autoComplete="new-password"
                        minDate={new Date()}
                        dateFormat='dd/MM/yyyy'
                        maxDate={new Date()}
                        disabled={true}
                        id='startdate'
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <AvGroup>
                    <Label className='small-font-size' for='Email'>Email</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='email'
                      id='Email'
                      type='email'
                      value={data.email}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter a Email'
                        },
                        pattern: {
                          value:
                            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
                          errorMessage: 'Please enter valid Email'
                        }
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for='phone'>Phone</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      type='text'
                      name='phone'
                      id='phone'
                      value={data.phone}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter your phone number'
                        },
                        pattern: {
                          value: '^[0-9]+$',
                          errorMessage:
                            'Your phone number must be composed only with numbers'
                        },
                        minLength: {
                          value: 10,
                          errorMessage:
                            'Your phone number must be between 10 and 12 numbers'
                        },
                        maxLength: {
                          value: 12,
                          errorMessage:
                            'Your phone number be between 10 and 12 numbers'
                        }
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for='persontype'>Role</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      type='select'
                      name='persontype'
                      id='persontype'
                      onChange={this.onChange}
                      required={true}
                      value={data.persontype}
                    >
                      <option value='finance'>Finance</option>
                      <option value='sales'>Sales</option>
                      <option value='purchase'>Purchase</option>
                      <option value='stores'>Stores</option>
                      <option value='production'>Production</option>
                      <option value='management'>Super Admin</option>
                      <option value='itadmin'>IT Admin</option>
                    </AvField>
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for='second_persontype'>Second Role</Label>
                    <span className='required-label'></span>
                    <AvField
                      type='select'
                      name='second_persontype'
                      id='second_persontype'
                      onChange={this.onChange}
                      value={data.second_persontype}
                    >
                      <option value=''>Select Second Role</option>
                      <option value='finance'>Finance</option>
                      <option value='sales'>Sales</option>
                      <option value='purchase'>Purchase</option>
                      <option value='stores'>Stores</option>
                      <option value='production'>Production</option>
                      <option value='management'>Super Admin</option>
                      <option value='itadmin'>IT Admin</option>
                    </AvField>
                  </AvGroup>                  
                  <div className='form-group'>
                    <Label className='small-font-size' for='enddate'>End Date</Label>
                    <div>
                        {data.enddate && data.enddate !== '' ? (
                        <DatePicker
                        dateFormat='dd/MM/yyyy'
                        autoComplete="new-password"
                        selected={data.enddate}
                        onChange={this.handleChangeEndDate}
                        className='form-control'
                        id='enddate'
                      />                        
                      ): null}
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {/* <Card className="mg-20">
            <CardHeader>Employee Details</CardHeader>
            <CardBody>
              <Row>
                <Col sm={6}>
                  <AvGroup>
                    <Label for="persontype">Person Type</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name="persontype"
                      id="persontype"
                      onChange={this.onChange}
                      value={data.persontype}
                    >
                      <option value="admin">Admin</option>
                      <option value="employee">Employee</option>
                    </AvField>
                  </AvGroup>
                  <AvGroup>
                    <Label for="department">Department</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name="department"
                      onChange={this.onChange}
                      value={data.department}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={6}>
                  <AvGroup>
                    <Label for="job">Job</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name="job"
                      onChange={this.onChange}
                      value={data.job}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </AvField>
                  </AvGroup>
                  <AvGroup>
                    <Label for="location">Location</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name="location"
                      onChange={this.onChange}
                      value={data.location}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>
            </CardBody>
          </Card> */}
          <div className='mg-20'>
            <Button color='primary' disabled={loading} className='lgn-loader'>
              Update
              {/* {loading} */}
            </Button>
          </div>
        </AvForm>
      </div>
    );
  }
}
UpdateUserForm.propTypes = {
  submit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userlist: state.userlist
  };
}

export default connect(
  mapStateToProps,
  {}
)(UpdateUserForm);
