import axios from "axios";

export default {
  customer: {
    addcustomer: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/customer/addcustomer`, {
          data
        })
        .then(res => {
          return res.data.customerdata;
        });
    },
    getcustomer: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/customer/getcustomer`)
        .then(res => {
          return res.data.customerlist;
        });
    },
    updatecustomer: data => {
      return axios
        .patch(`${process.env.REACT_APP_BASE_URL}/customer/updatecustomer`, {
          data
        })
        .then(res => {
          return res.data.success;
        });
    }
  },
  customerTransaction: {
    getcustomerTransaction: (id) => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/customerTransaction/getcustomertransaction/${id}`)
        .then(res => {
          
          return res.data.customerlist;
        });
    },
  
  }
};
