import { SUPPLIER_DETAIL_LIST } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SUPPLIER_DETAIL_LIST:
      return {
        ...state,
        ...action.supplierdetaillistvalue.entities.supplierdetaillistvalue
      };
    default:
      return state;
  }
}
