import React from "react";
import AddMaterialForm from "./MaterialForms/AddMaterialForm";
import { Link } from "react-router-dom";
import { Button, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  addMaterial,
  getMaterial,
  getMaterialsTypes
} from "../../actions/Product";
import DataTable from "../../Components/Table/DatatablePage";

class AddMaterial extends React.Component {
  state = {
    materiallist: "",
    count: ""
  };

  componentDidMount() {
    this.props.getMaterial();
    this.props.getMaterialsTypes();
  }

  updateButton = id => {
    let path = `/update_material/${id}`;
    this.props.history.push(path);
  };

  componentWillReceiveProps(props) {
    const materiallistvalues = Object.values(props.materiallistvalues);
    const materiallist = materiallistvalues.map((materialdata, i) => {
      return {
        materialtype: materialdata.materialtype.materialname,
        materialname: materialdata.materialname,
        materialdescription: materialdata.materialdescription,
        materialuom: materialdata.materialuom,
        materialrate: materialdata.materialrate,
        costperunit: materialdata.costperunit,
        view: (
          <Button
            color="primary"
            onClick={() => {
              this.updateButton(materialdata.id);
            }}
          >
            View / Edit
          </Button>
        )
      };
    });
    const count = materiallistvalues.length;
    this.setState({ ...this.state, materiallist, count });
  }

  submit = data => {
    return this.props.addMaterial(data).then(data => {
      this.props.getMaterial();
      return data;
    });
  };
  render() {
    const { count, materiallist } = this.state;
    const columnvalue = [
      {
        label: "Item Type",
        field: "material Type",
        sort: "asc",
        width: 75
      },
      {
        label: "Item Name",
        field: "materialname",
        sort: "asc",
        width: 75
      },
      {
        label: "Item Description",
        field: "materialdescription",
        sort: "asc",
        width: 75
      },
      {
        label: "unit of measure",
        field: "materialuom",
        sort: "asc",
        width: 75
      },
      {
        label: "Item Rate",
        field: "materialrate",
        sort: "asc",
        width: 50
      },
      {
        label: "cost per unit",
        field: "costperunit",
        sort: "asc",
        width: 50
      },
      {
        label: "view / Edit",
        field: "view",
        sort: "asc",
        width: 100
      }
    ];
    return (
      <div className="container mg-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/inventory_page">Inventory</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Item</BreadcrumbItem>
        </Breadcrumb>
        <AddMaterialForm submit={this.submit} />
        <div>
          {count > 0 && (
            <div className="container mg-20">
              <DataTable columnvalue={columnvalue} rowvalues={materiallist} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

AddMaterial.propTypes = {
  addMaterial: PropTypes.func.isRequired,
  getMaterial: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    materiallistvalues: state.materiallistvalues
  };
}

export default connect(
  mapStateToProps,
  { addMaterial, getMaterial, getMaterialsTypes }
)(AddMaterial);
