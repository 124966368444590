import axios from "axios";

export default {
  user: {
    userRegister: userRegisterdata => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/auth`, {
          userRegisterdata
        })
        .then(res => {
          return res.data.userRecord;
        });
    },
    login: credentials => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/login`, {
          credentials
        })
        .then(res => {
          return res.data.user;
        });
    },
    getUser: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/getuser`)
        .then(res => {
          return res.data.userdata;
        });
    },
    getUserbyid: id => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/getuserbyid?id=${id}`)
        .then(res => {
          return res.data.userRecordData;
        });
    },
    updateUser: data => {
      return axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/api/updateuser?id=${data.id}`,
          {
            data
          }
        )
        .then(res => {
          return res.data.success;
        });
    },
    deleteUser: id => {
      return axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/deleteUser?id=${id}`)
    }
  },
  material: {
    addmaterialtype: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/materialtype/addmaterial`, {
          data
        })
        .then(res => {
          return res.data.materialdata;
        });
    },
    getmaterialstype: data => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/materialtype/getmaterials`)
        .then(res => {
          return res.data.materiallist;
        });
    },
    updatematerialtype: data => {
      return axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/materialtype/updatematerialtype?id=${data.id}`,
          {
            data
          }
        )
        .then(res => {
          return res.data.success;
        });
    },
    addmaterial: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/material/addmaterial`, {
          data
        })
        .then(res => {
          return res.data.materialdata;
        });
    },
    getmaterial: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/material/getmaterials`)
        .then(res => {
          return res.data.materiallist;
        });
    },
    updatematerial: data => {
      return axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/material/updatematerial?id=${data.id}`,
          {
            data
          }
        )
        .then(res => {
          return res.data.success;
        });
    }
  },
  product: {
    addproduct: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/product/addproduct`, {
          data
        })
        .then(res => {
          return res.data.materialdata;
        });
    },
    getproducts: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/product/getproducts`)
        .then(res => {
          return res.data.productlist;
        });
    },
    updateproduct: data => {
      return axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/product/updateproduct?id=${data.id}`,
          {
            data
          }
        )
        .then(res => {
          return res.data.success;
        });
    },
    deleteproduct: id => {
      return axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/product/deleteproduct?id=${id}`
      )
    }
  },
  year: {
    addyear: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/year/addyear`, {
          data
        })
        .then(res => {
          return res.data.success;
        });
    },
    checkexists: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/year/getyear`)
        .then(res => {
          return res.data.year;
        });
    },
    updateyear: data => {
      return axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/year/updateyear?id=${data.updateid}`,
        {
          data
        }
      )
      .then(res => {
        return res.data.success;
      })
      .catch(err => {
        return err.data.error;
      });
    }
  }
};
