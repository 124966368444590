import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import { Button, Alert } from "reactstrap";
import { searchquotation } from "../../../../actions/quotation";

class CustomerQuotationList extends React.Component {
  componentDidMount() {
    const customerid = this.props.match.params.id;
    this.props
      .searchquotation(customerid)
      .then(data => {})
      .catch(err => {});
  }
  render() {
    return (
      <div className="container mg-t-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/inventory_page">Setup</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/customer">Customer</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/customerinfo">Customer list</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>customer status</BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  }
}

export default connect(
  null,
  { searchquotation }
)(CustomerQuotationList);
