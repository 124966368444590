import { ADD_INVOICE, INVOICE_FETCHED } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case INVOICE_FETCHED:
      return {
        ...state,
        ...action.invoicelist.entities.invoicelistvalues
      };
    case ADD_INVOICE:
      return {
        ...state,
        ...action.invoicelist.entities.invoicelistvalues
      };
    default:
      return state;
  }
}
