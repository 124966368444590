import { normalize } from 'normalizr';
import api from '../Services/order';
import { ADD_ORDER, ORDER_FETCHED } from '../Services/types';
import { orderListSchema } from '../Schemas/schemas';

const orderCreated = orderlist => {
  return {
    type: ADD_ORDER,
    orderlist
  };
};

const orderFetched = orderlist => {
  return {
    type: ORDER_FETCHED,
    orderlist
  };
};

export const addorder = data => {
  return dispatch => {
    return api.order.addorder(data).then(orderlistvalues => {
      return dispatch(
        orderCreated(normalize(orderlistvalues, orderListSchema))
      );
    });
  };
};

export const updateaddorder = data => {
  return dispatch => {
    return api.order.updateaddorder(data).then(orderlistvalues => {
      return true
    });
  };
};



export const getorder = () => {
  return dispatch => {
    return api.order.getorder().then(orderlist => {
      console.log(orderlist);
      const orderlistvalues = orderlist.orderdata;
      console.log(orderlistvalues);
      return dispatch(
        orderFetched(normalize(orderlistvalues, [orderListSchema]))
      );
    });
  };
};

export const searchquotation = customerid => {
  return dispatch => {
    return api.quotation.searchquotation(customerid);
  };
};
