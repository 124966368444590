import { MASTER_ITEM_LIST,DELETE_MASTER_ITEM_LIST } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case MASTER_ITEM_LIST:
      return {
        ...state,
        ...action.MasterItemTypeList.entities.masteritemtypelistvalues
      };
    case DELETE_MASTER_ITEM_LIST  : 
      let arrayData = [];
      Object.keys(state).forEach(function(key, index) {
        arrayData.push(state[key]);
      });
      let fileteredData = arrayData.filter((item, id) => item.id !== action.payload);
      let obj = {};
      fileteredData.map((data,ind) => {
        obj[data.id] = data;
      });
      return obj;
    default:
      return state;
  }
}