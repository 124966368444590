import { ADD_ORDER, ORDER_FETCHED } from '../Services/types';

export default function(state = {}, action) {
  switch (action.type) {
    case ADD_ORDER:
    case ORDER_FETCHED:
      return {
        ...state,
        ...action.orderlist.entities.orderlistvalues
      };
    default:
      return state;
  }
}
