import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import PropTypes from "prop-types";
import { Button, Alert } from "reactstrap";
import { getPurchaseOrder } from "../../../actions/Transaction";
import DataTable from "../../../Components/Table/DatatablePage";
import CustomLoader from "../../../Components/loader/loader";
import moment from "moment";
import axios from "axios";

import { getSupplierDetails } from "../../../actions/Supplier";
import {
  getMasterItemList,
  getMasterItemTypes
} from "../../../actions/Transaction";
import { getCurrencyList } from "../../../actions/Currency";
import { getUomList } from "../../../actions/uom";
import { getLocationList } from "../../../actions/Location";

class ViewPurchaseOrderlist extends React.Component {
  state = {
    loading: true,
    purchaseorderlistvalues: [],
    count: 0
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  componentDidMount() {
    this.props.getCurrencyList();
    this.props.getSupplierDetails();
    this.props.getMasterItemTypes();

    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getLocationList();

    this.props.getPurchaseOrder();
    
    let purchaseorderlist = Object.values(this.props.purchaseorderlistvalues);
    purchaseorderlist.sort(function(a, b){
      return parseInt(b.ponumber) - parseInt(a.ponumber);
    });
    // if(purchaseorderlist.length > 0){
    //   purchaseorderlist = purchaseorderlist.filter((item) =>{
    //     if(`${process.env.REACT_APP_BASE_URL}`.indexOf('9002') > -1){
    //       return item.ponumber >= 1011
    //     }
    //     return true
    //   })
    // }
    const purchaseorderlistvalues = purchaseorderlist.map(
      (purchaseorder, i) => {
        return {
          ponumber: purchaseorder.ponumber,
          suppliername: purchaseorder.suppliername,
          suppliernumber: purchaseorder.suppliernumber,
          referenceno: purchaseorder.referenceno,
          address:
            purchaseorder.supplieraddress1 +
            " " +
            purchaseorder.supplieraddress2 +
            " " +
            purchaseorder.suppliercity +
            " " +
            purchaseorder.supplierstate +
            " " +
            purchaseorder.supplierpincode +
            " " +
            purchaseorder.supplierstate +
            "" +
            purchaseorder.suppliercountry,
          view: (
            <div className="actionbuttons">
              <Button
                color="primary"
                // style={{ 'fontSize': '0.8rem' }}
                Label
                onClick={() => {
                  this.updateButton(purchaseorder.id);
                }}
              >
                Print
            </Button>
              <Button
                color="primary"
                // style={{ 'fontSize': '0.8rem' }}
                Label
                onClick={() => {
                  this.updateButton(purchaseorder.id);
                }}
              >
                Edit
            </Button>
            </div>
          )
        };
      }
    );
    const count = purchaseorderlist.length;
    this.setState({ ...this.state, purchaseorderlistvalues, count });
    // this.dataHandler(false);
  }

  componentWillReceiveProps(props) {
    let purchaseorderlist = Object.values(props.purchaseorderlistvalues);
    console.log(purchaseorderlist)
    //purchaseorderlist= purchaseorderlist.sort((a, b) => b.ponumber.toString().toLowerCase().localeCompare(a.ponumber.toString().toLowerCase()));
    purchaseorderlist.sort(function(a, b){
      return parseInt(b.ponumber) - parseInt(a.ponumber);
    });
    // if(purchaseorderlist.length > 0){
    //   purchaseorderlist = purchaseorderlist.filter((item) =>{
    //     if(`${process.env.REACT_APP_BASE_URL}`.indexOf('9002') > -1){
    //       return item.ponumber >= 1011
    //     }
    //     return true
    //   })
    // }
    const purchaseorderlistvalues = purchaseorderlist.map(
      (purchaseorder, i) => {
        return {
          ponumber: purchaseorder.ponumber,
          suppliername: purchaseorder.suppliername,
          suppliernumber: purchaseorder.suppliernumber,
          referenceno: purchaseorder.referenceno,
          address:
            purchaseorder.supplieraddress1 +
            " " +
            purchaseorder.supplieraddress2 +
            " " +
            purchaseorder.suppliercity +
            " " +
            purchaseorder.supplierstate +
            " " +
            purchaseorder.supplierpincode +
            " " +
            purchaseorder.suppliercountry,
          view: (
            <div className="actionbuttons">
              <Button
                color="primary" style={{ 'fontSize': '0.8rem' }}
                onClick={() => {
                  this.updateButton(purchaseorder.id);
                }}
              >
                Print
            </Button>
              <Button
                color="primary"
                style={{ 'fontSize': '0.8rem' }}
                onClick={() => {
                  this.updatePO(purchaseorder.id);
                }}
              >
                Edit
            </Button>
            </div>
          )
        };
      }
    );
    const count = purchaseorderlist.length;
    this.setState({ ...this.state, purchaseorderlistvalues, count });
    this.dataHandler(false);
  }

  updateButton = id => {
    let path = `/view_purchaseorderpdf/${id}`;
    //window.open(path, "_blank");
    this.props.history.push(path);
  };


  updatePO = id => {
    let path = `/purchase_order_update/${id}`;
    this.props.history.push(path);
  };

  render() {
    const { count, purchaseorderlistvalues, loading } = this.state;
    const columnvalue = [

      {
        label: "PO Number",
        field: "ponumber",
        sort: "asc",
        width: 100
      },
      {
        label: "Supplier Name",
        field: "suppliername",
        sort: "asc",
        width: 100
      },
      {
        label: "Supplier Number",
        field: "suppliernumber",
        sort: "asc",
        width: 100
      },
      {
        label: "Reference Number",
        field: "referenceno",
        sort: "asc",
        width: 100
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 40
      },
      {
        label: "Action",
        field: "view",
        sort: "asc",
        width: 100
      }
    ];
    return (
      <div className="container mg-t-20">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/transaction_page">Transactions</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>View Purchase Order List</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          {loading ? <CustomLoader /> : null}
          <div className="container mg-20">
              <DataTable
                columnvalue={columnvalue}
                rowvalues={purchaseorderlistvalues}
                className="viewpurchaseorder"
              />
            </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    purchaseorderlistvalues: state.purchaseorderlistvalue
  };
}

export default connect(
  mapStateToProps,
  { 
    getSupplierDetails,
    getMasterItemList,
    getCurrencyList,
    getUomList,
    getLocationList,
    getMasterItemTypes,
    getPurchaseOrder }
)(ViewPurchaseOrderlist);
