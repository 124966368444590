import React from "react";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import UpdateMaterialTypeForm from "./MaterialForms/UpdateMaterialTypesForm";
import { updateMaterialType,getMaterialsTypes } from "../../actions/Product";

class UpdateMaterialType extends React.Component{
    state={
        materialoption:''
    }
    
    componentDidMount(){
        const materialid = this.props.match.params.id;
        const materialoption = this.props.materialoptions[materialid];
        this.setState({materialoption});
    }

    submit = (data) => this.props.updateMaterialType(data);


    render(){
        const { materialoption } = this.state;
        return(
            <div className="container mg-20">
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/inventory_page">Inventory</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/add_itemtype">Item type</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Item View/Edit</BreadcrumbItem>
                </Breadcrumb>
                <UpdateMaterialTypeForm materialoption={materialoption} submit={this.submit} />
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        materialoptions: state.materialoption
    }
}

export default connect(mapStateToProps,{updateMaterialType,getMaterialsTypes})(UpdateMaterialType);