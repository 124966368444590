import axios from "axios";

export default {
  quotation: {
    addquotation: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/quotation/addquotation`, {
          data
        })
        .then(res => {
          return res.data.quotationdata;
        });
    },
    updatequotation: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/quotation/updatequotation`, {
          data
        })
        .then(res => {
          return res.data.quotationdata;
        });
    },
    getquotation: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/quotation/getquotation`)
        .then(res => {
          return res.data.quotationlist;
        });
    },
    searchquotation: customerid => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/quotation/searchquotation?q=${customerid}`
        )
        .then(res => {
          return res.data.customerquotationlist;
        });
    }
  }
};
