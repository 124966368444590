import { PDFViewer } from '@react-pdf/renderer';
import axios from "axios";
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getSupplierDetails } from '../../../actions/Supplier';
import { getPurchaseOrder } from '../../../actions/Transaction';
import logo from '../../../Images/logo.png';
import MyDocument from './Component';
import './ViewPurchaseOrder.scss';

class ViewPurchaseOrderPdf extends React.Component {
  state = {
    purchaseorderlistvalues: {},
    status: false,
    link: ''
  };
  componentDidMount() {
    this.props.getPurchaseOrder();
    this.props.getSupplierDetails();
    const purchaseorderid = this.props.match.params.id;
    const purchaseorderlistvalues = this.props.purchaseorderlistvalues[
      purchaseorderid
    ];
    console.log(purchaseorderlistvalues)
    purchaseorderlistvalues.requestdeliverydate = moment(
      purchaseorderlistvalues.requestdeliverydate
    ).format('DD MMM, YYYY');
    purchaseorderlistvalues.addeditemlist = purchaseorderlistvalues.addeditemlist.map(
      (itemlist, i) => {
        return {
          slno: (i + 1).toString(),
          itemname: itemlist.itemdescription ? itemlist.itemdescription : '',
          itemdescription: itemlist.quantity
            ? itemlist.quantity.toString()
            : '',
          quantity: itemlist.costperunit ? itemlist.costperunit.toString() : '',
          totalcost: itemlist.totalcost
            ? itemlist.totalcost.toFixed(2).toString()
            : ''
        };
      }
    );
    const supplierid = purchaseorderlistvalues.supplierid;
    const suppliercontactid = purchaseorderlistvalues.suppliercontactid;
    const supplierlistvalue = this.props.supplierdetaillistvalue[supplierid];
    const selectedcontact = supplierlistvalue.suppliercontactlistvalue.filter(
      contactid => {
        if (contactid._id == suppliercontactid) {
          return contactid;
        }
      }
    );
    console.log('__SELECTED_CONTACT_LIST____');
    console.log(selectedcontact);
    purchaseorderlistvalues.selectedcontactlist = selectedcontact;
    axios.get(`${process.env.REACT_APP_BASE_URL}/year/getyear`).then(
      (res) => {
        if(res.data.year) {
          purchaseorderlistvalues.financialyear = res.data.year.currentyear;
        }
        else {
          purchaseorderlistvalues.financialyear = "2020-21";          
        }
      },
      (error) => {
        purchaseorderlistvalues.financialyear = "2020-21";
      }
    );
    setTimeout(() => {
      this.setState({
        ...this.state.purchaseorderlistvalues,
        purchaseorderlistvalues,
        status: true
      });
    },2000);
    this.dynamicClick();
  }

  dynamicClick() {
    if (document.getElementsByClassName('pdf-class')) {
      if (
        document.getElementsByClassName('pdf-class')[0].children.length <= 1
      ) {
        setTimeout(() => {
          this.dynamicClick();
        }, 7000);
      } else if (
        document.getElementsByClassName('pdf-class')[0].children.length > 1
      ) {
        this.setState({
          link: document.getElementsByClassName('pdf-class')[0].children[1].src
          // status : undefined,
        },()=>{
          setTimeout(() => {
            document.getElementById('D1').click();
            this.props.history.push('/view_purchaseorderlist');
          }, 300);
        });
      
      }
    } else {
    }
  }

  render() {
    //const link = "blob:http://localhost:3000/0ef925bb-e930-4ea4-b861-2621aa6447ad"

    const data_cond = [
      {
        slno: '1',
        title: 'Invoices',
        value: '2 copies Invoices (original & duplicate)'
      },
      {
        slno: '2',
        title: 'Tax & Duty',
        value: 'Included'
      },
      {
        slno: '3',
        title: 'Payment Schedule',
        value: '15 days from the date of delivery'
      },
      {
        slno: '4',
        title: 'Delivery Schedule',
        value: 'Immediately'
      },
      {
        slno: '5',
        title: 'Billing address & GST No',
        value:
          'M/s Pondhan Scaffolding Pvt Ltd \n Plot no. 40/41, Road no.5, Obedanahalli,'
      },
      {
        slno: '6',
        title: 'Delivery Address',
        value: 'M/s Pondhan Scaffolding Pvt Ltd'
      }
    ];
    const { status, link } = this.state;
    return (
      <div>
        <div className='pdf-class'>
          Downloading file.Please wait.
          {/* {!status && (
          <h1 style={{ 'position': 'absolute','left': '50%','top': '50%','transform': 'translate(-50%, -50%);'}}>Downloading file.Please wait.</h1>
        )} */}
          <a href={link} download id='D1'></a>
          {status && (
            <PDFViewer>
              <MyDocument
                purchaseorderlistvalue={this.state.purchaseorderlistvalues}
                data_cond={data_cond}
                logo={logo}
              />
            </PDFViewer>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    purchaseorderlistvalues: state.purchaseorderlistvalue,
    supplierdetaillistvalue: state.supplierdetaillistvalue
  };
}
export default connect(
  mapStateToProps,
  { getPurchaseOrder, getSupplierDetails }
)(ViewPurchaseOrderPdf);
