import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
class ChallanPage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/transaction_page'>Transaction</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Delivery challan</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
              <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                  <Link to='/addchallan'>
                    <i class='fas fa-plus-circle fa-3x'></i>
                    <div style={{ 'padding-top': '5px' }}>
                      <span>Add Challan</span>
                    </div>
                  </Link>
                </div>
              </li>

              <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                  <Link to='/challanlist'>
                    <i class='fas fa-list fa-3x'></i>
                    <div style={{ 'padding-top': '5px' }}>
                      <span>Challan List</span>
                    </div>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default ChallanPage;
