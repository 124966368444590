import { PO_RECEIPT_LIST } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case PO_RECEIPT_LIST:
      return {
        ...state,
        ...action.receiptlistvalues.entities.receiptlistvalues
      };
    default:
      return state;
  }
}
