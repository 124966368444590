import React from "react";
import { connect } from "react-redux";
import { Button, Alert } from "reactstrap";
import { withRouter } from "react-router-dom";
import DataTable from "../../../Components/Table/DatatablePage";

class ViewCustomerList extends React.Component {
  state = {
    count: 0,
    customerlistvalues: []
  };
  componentWillReceiveProps(props) {
    let customerlistvalues = Object.values(props.customerlistvalues);
    //customerlistvalues= customerlistvalues.sort((a, b) => b.customernumber.toString().toLowerCase().localeCompare(a.customernumber.toString().toLowerCase()));
    console.log(customerlistvalues)
    customerlistvalues.sort(function(a, b){
      return parseInt(b.customernumber) - parseInt(a.customernumber);
    });
    const count = customerlistvalues.length;
    const customervalues = customerlistvalues.map(customerdata => {
      return {
        customernumber: customerdata.customernumber,
        customername: customerdata.customername,
        customercontactnumber: customerdata.customercontactnumber,
        customergstnumber: customerdata.customergstnumber,
        customeraddress1: customerdata.customeraddress1 != '' ? customerdata.customeraddress1 : '-',
        customeraddress2: customerdata.customeraddress2 != '' ? customerdata.customeraddress2 : '-',
        city: customerdata.city != '' ? customerdata.city : '-',
        pincode: customerdata.pincode != '' ? customerdata.pincode : '-',
        state: customerdata.state != '' ? customerdata.state : '-',
        country: customerdata.country != '' ? customerdata.country : '-',
        view: (
          <Button
            Label
            // style={{ fontSize: '0.8rem' }}
            color="primary"
            onClick={() => {
              this.updateButton(customerdata.id);
            }}
          >
            Edit
          </Button>
        )

      };
    });
    this.setState({ customerlistvalues: customervalues, count });
  }

  updateButton = id => {
    let path = `/updatecustomer/${id}`;
    this.props.history.push(path);
  };
  render() {
    const { count, customerlistvalues } = this.state;
    const columnvalue = [
      {
        label: "Customer Number",
        field: "customernumber",
        sort: "asc",
        width: 50
      },
      {
        label: "Name",
        field: "customername",
        sort: "asc",
        width: 50
      },
      {
        label: "Contact",
        field: "customercontactnumber",
        sort: "asc",
        width: 50
      },
      {
        label: "GST Number",
        field: "customergstnumber",
        sort: "asc",
        width: 50
      },
      {
        label: "Customer address1",
        field: "customeraddress1",
        sort: "asc",
        width: 50
      },
      {
        label: "Customer address2",
        field: "customeraddress2",
        sort: "asc",
        width: 50
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 50
      },
      {
        label: "Pincode",
        field: "pincode",
        sort: "asc",
        width: 50
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 50
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
        width: 50
      },
      {
        label: "Edit",
        field: "view",
        sort: "asc",
        width: 50
      }
    ];

    return (
      <div className="mg-t-20">
        {count > 0 ? (
          <div>
            <DataTable
              columnvalue={columnvalue}
              rowvalues={customerlistvalues}
              className="customerlist"
            />
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerlistvalues: state.customerlistvalues
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ViewCustomerList)
);
