import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { MDBBtn } from "mdbreact";
import DataTable from "../../../../Components/Table/DatatablePage";
import CustomLoader from "../../../../Components/loader/loader";
import { connect } from "react-redux";
import {
  addMasterItemType,
  getMasterItemTypes
} from "../../../../actions/Transaction";

class ItemMasterTypeList extends React.Component {
  state = {
    loading: false,
    count: "",
    masteritemtypesvalues: []
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  componentDidMount() {
    this.dataHandler(true);
    this.props.getMasterItemTypes();
  }
  componentWillReceiveProps(props) {
    const masteritemtypenamevaluelist = Object.values(
      props.masteritemtypenamevalue
    );
    const masteritemtypesvalues = masteritemtypenamevaluelist.map(
      (masteritemtypedata, i) => {
        return {
          masteritemtypename: masteritemtypedata.masteritemtypename,
          view: (
            <MDBBtn
              color="info"
              size="sm"
              onClick={() => {
                this.updateButton(masteritemtypedata.id);
              }}
            >
              view / Edit
            </MDBBtn>
          )
        };
      }
    );
    const count = masteritemtypesvalues.length;
    this.setState({ ...this.state, masteritemtypesvalues, count });
    this.dataHandler(false);
  }
  updateButton = id => {
    let path = `/update_mitemtype/${id}`;
    this.props.history.push(path);
  };
  render() {
    const { loading, count, masteritemtypesvalues } = this.state;
    const columnvalue = [
      {
        label: "Item Types",
        field: "masteritemtypename",
        sort: "asc",
        width: 100
      },
      {
        label: "View/Edit",
        field: "view",
        sort: "asc",
        width: 100
      }
    ];
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Item List</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          {count > 0 && (
            <div>
              <DataTable
                columnvalue={columnvalue}
                rowvalues={masteritemtypesvalues}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    masteritemtypenamevalue: state.masteritemtypenamevalue
  };
}

export default connect(
  mapStateToProps,
  { addMasterItemType, getMasterItemTypes }
)(ItemMasterTypeList);
