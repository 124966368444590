import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import {
  Alert, Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

class AddYearForm extends React.Component {
  state = {
    data: {
      addyear: "",
      updateyear: "",
      updateid: "",
    },
    exists: false,
    errors: {},
    loading: false,
    success: false,
    visible: false,
    updated: false,
    suppliercontactlistvalue: [],
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    axios.get(`${process.env.REACT_APP_BASE_URL}/year/getyear`).then(
      (res) => {
        if(res.data.year) {
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              updateid: res.data.year._id,
              updateyear: res.data.year.currentyear
            },
          });
          this.setState({ exists: true });
        }
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({
          ...this.state,
          errors: {
            ...this.state.errors,
            error,
          },
          loading: false,
        });
      }
    );
  };

  onChange = (e) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };
  handleValidSubmit = () => {
    this.setState({ loading: true });
    const { data } = this.state;
    console.log(JSON.stringify(data));
    this.props
      .submit(data)
      .then((data) => {
        this.setState({ success: true, loading: false, errors: {} });
        this.onShowAlert();
        this.myFormRef.reset();
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, loading: false });
        this.onShowAlert();
      });
  };

  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
    });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  render() {
    const { data, errors, loading, success, exists, visible } = this.state;
    return (
      <div>
        <div>
          {!!success ? (
            // <Alert color="info" isOpen={visible}>
            //   Customer is added successfully.
            // </Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Item Type</ModalHeader>
              <ModalBody>Year Added successfully</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={(el) => (this.myFormRef = el)}
        >
          <div>
            {!!errors.global ? (
              <Alert color="danger">{errors.global}</Alert>
            ) : null}
          </div>
          {!!exists ? (
            <Card>
              <CardHeader>Update Year</CardHeader>
              <CardBody>
                <Row>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className="small-font-size" for="addyear">
                        Update Year
                      </Label>
                      <span className="required-label">*</span>
                      <AvField
                        name="updateyear"
                        type="text"
                        placeholder={data.updateyear.toString()}
                        id="addyear"
                        value={data.updateyear.toString()}
                        onChange={this.onChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter the year range",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                {/* Delivery Address */}
                <div className="mg-20">
                  <Button
                    color="primary"
                    disabled={loading}
                    className="lgn-loader"
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card>
              <CardHeader>AddYear</CardHeader>
              <CardBody>
                <Row>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className="small-font-size" for="addyear">
                        Add Year
                      </Label>
                      <span className="required-label">*</span>
                      <AvField
                        name="addyear"
                        type="text"
                        placeholder="ex: 2020-21"
                        id="addyear"
                        value={data.addyear.toString()}
                        onChange={this.onChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter the year range",
                          },
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                {/* Delivery Address */}
                <div className="mg-20">
                  <Button
                    color="primary"
                    disabled={loading}
                    className="lgn-loader"
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </CardBody>
            </Card>
          )}
        </AvForm>{" "}
      </div>
    );
  }
}

export default connect(
  null,
  null
)(AddYearForm);
