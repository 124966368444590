import { normalize } from 'normalizr';
import api from '../Services/productionapi';
import { ADD_PRODUCTION, PRODUCTION_FETCHED } from '../Services/types';
import { productionListSchema } from '../Schemas/schemas';

const productionCreated = productionlist => {
  return {
    type: ADD_PRODUCTION,
    productionlist
  };
};

const productionFetched = productionlist => {
  return {
    type: PRODUCTION_FETCHED,
    productionlist
  };
};

export const addproduction = data => {
  return dispatch => {
    return api.production.addproduction(data).then(productionlistvalues => {
      return dispatch(
        productionCreated(normalize(productionlistvalues, productionListSchema))
      );
    });
  };
};

export const getproduction = () => {
  return dispatch => {
    return api.production.getproduction().then(productionlist => {
      const productionlistvalues = productionlist.productiondata;
      console.log(productionlistvalues);
      return dispatch(
        productionFetched(
          normalize(productionlistvalues, [productionListSchema])
        )
      );
    });
  };
};
