import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
  addYear, updateYear
} from "../../../actions/financialYearAdd";
import CustomLoader from "../../../Components/loader/loader";
import AddYearForm from "./AddYear/AddYearForm/AddYearForm";

class AddFinancialYear extends React.Component {
  state = {
    loading: false
  };

  submit = data => {
    if(data.updateid !== "") {
      this.dataHandler(true);
    return this.props
      .updateYear(data)
      .then(data => {
        this.dataHandler(false);
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });    
    }
    else {
      this.dataHandler(true);
      return this.props
        .addYear(data)
        .then(data => {
          this.dataHandler(false);
          return data;
        })
        .catch(err => {
          this.dataHandler(false);
          throw err;
        });
    }
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/inventory_page">Setup</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/financialYearAdd">Add Year</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Year</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <AddYearForm submit={this.submit} />
        </div>
        {/* <div>
          <ViewCustomerList />
        </div> */}
      </div>
    );
  }
}

export default connect(
  null,
  { addYear,updateYear }
)(AddFinancialYear);
