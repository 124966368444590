import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import UpdateMasterItemListForm from "../Form/ItemList/UpdateItemListForm";
import { updatemasteritemlist } from "../../../../actions/Transaction";
import {
  addMasterItemList,
  getMasterItemList,
  getMasterItemTypes
} from "../../../../actions/Transaction";
import { getUomList } from "../../../../actions/uom";
import CustomLoader from "../../../../Components/loader/loader";

class UpdateMasterItemList extends React.Component {
  state = {
    id: "",
    loading: false
  };
  componentDidMount() {
    this.props.getMasterItemTypes();
    this.props.getUomList();
    this.props.getMasterItemList();
    const id = this.props.match.params.id;
    this.setState({ ...this.state, id });
  }
  dataHandler = value => {
    this.setState({ loading: value });
  };
  submit = data => {
    this.dataHandler(true);
    return this.props.updatemasteritemlist(data).then(data => {
      this.dataHandler(false);
      return data;
    });
  };
  render() {
    const { id, loading } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/admin_dashboard">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/mitemlistvalues">Item List</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Update Item</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <UpdateMasterItemListForm id={id} submit={this.submit} />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    updatemasteritemlist,
    addMasterItemList,
    getMasterItemList,
    getMasterItemTypes,
    getUomList
  }
)(UpdateMasterItemList);
