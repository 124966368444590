import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendarPlus, faCube, faDollyFlatbed, faHome, faKaaba, faUser, faUserFriends, faUsers, faUsersCog } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import jwtDecode from "jwt-decode";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { userLoggedIn } from "./actions/actions";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import persistor from "./Store/persistStore";
import store from "./Store/store";
import setAuthorizationHeader from "./utils/setAuthorizationHeader";

library.add(
  faHome,
  faDollyFlatbed,
  faCube,
  faKaaba,
  faUsers,
  faUser,
  faUsersCog,
  faUserFriends,
  faCalendarPlus
);

// const store = createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(thunk))
// );

if (localStorage.Mob_JWT) {
  const token = localStorage.Mob_JWT;
  setAuthorizationHeader(token);
  var decoded = jwtDecode(localStorage.Mob_JWT);
  const userinfo = {
    email: decoded.email,
    role: decoded.role,
    second_role: decoded.sexond_role,
    token: localStorage.Mob_JWT
  };
  store.dispatch(userLoggedIn(userinfo));
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App component={App} />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
