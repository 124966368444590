import { normalize } from "normalizr";
import api from "../Services/transactionapi";
import { UOM_LIST } from "../Services/types";
import { uomListSchema } from "../Schemas/schemas";

function uomListFetched(uomlist) {
  return {
    type: UOM_LIST,
    uomlist
  };
}

export const getUomList = () => {
  return dispatch => {
    return api.uom.getUomDetails().then(uomlist => {
      const uomlistvalue = uomlist.uomdata;
      return dispatch(uomListFetched(normalize(uomlistvalue, [uomListSchema])));
    });
  };
};
