import { normalize } from "normalizr";
import api from "../Services/supplierapi";
import { SUPPLIER_CONTACT_LIST, SUPPLIER_DETAIL_LIST } from "../Services/types";
import {
  supplierContactListSchema,
  supplierDetailListSchema
} from "../Schemas/schemas";

export const suppplierContactListFetched = suppliercontactlistvalue => {
  return {
    type: SUPPLIER_CONTACT_LIST,
    suppliercontactlistvalue
  };
};

export const suppplierDetailListFetched = supplierdetaillistvalue => {
  return {
    type: SUPPLIER_DETAIL_LIST,
    supplierdetaillistvalue
  };
};
export const addSupplierContact = data => {
  return dispatch => {
    return api.suppliercontact.addSupplierContact(data);
  };
};

export const addSupplierDetails = data => {
  return dispatch => {
    return api.supplierdetail.addsupplierdetails(data);
  };
};

export const getSupplierContact = () => {
  return dispatch => {
    return api.suppliercontact
      .getSupplierContact()
      .then(suppliercontactlist => {
        const suppliercontactlistvalue =
          suppliercontactlist.suppliercontactlistdata;
        return dispatch(
          suppplierContactListFetched(
            normalize(suppliercontactlistvalue, [supplierContactListSchema])
          )
        );
      });
  };
};

export const getSupplierDetails = () => {
  return dispatch => {
    return api.supplierdetail.getSupplierDetails().then(supplierdetaillist => {
      const supplierdetaillistvalue = supplierdetaillist.supplierdetaillistdata;
      return dispatch(
        suppplierDetailListFetched(
          normalize(supplierdetaillistvalue, [supplierDetailListSchema])
        )
      );
    });
  };
};

export const updateSupplierDetails = data => {
  return dispatch => {
    return api.supplierdetail.updateSupplierContact(data);
  };
};
