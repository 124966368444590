import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import user from '../reducer/user';
import userlist from '../reducer/userlist';
import materialoptionlist from '../reducer/materialoptionlist';
import materiallistvalues from '../reducer/materiallistvalues';
import productlistvalues from '../reducer/productlistvalues';
import masteritemtypenamevalue from '../reducer/masteritemtypenamevalue';
import masteritemtypelistvalues from '../reducer/masteritemlistnamevalue';
import suppliercontactlistvalue from '../reducer/suppliercontactlistvalue';
import supplierdetaillistvalue from '../reducer/supplierdetaillistvalue';
import uomlistvalue from '../reducer/uomlistvalue';
import currencylistvalue from '../reducer/currencylistvalue';
import locationlistvalue from '../reducer/locationlistvalue';
import purchaseorderlistvalue from '../reducer/purchaseorderlistvalue';
import receiptlistvalues from '../reducer/receiptlistvalues';
import customerlistvalues from '../reducer/customerlistvalues';
import quotationlistvalues from '../reducer/quotationlistvalues';
import invoicelistvalues from '../reducer/invoicelistvalues';
import proformainvoicelistvalues from '../reducer/proformainvoicelistvalues';

import challanlistvalues from '../reducer/challanlistvalues';
import orderlistvalues from '../reducer/orderlistvalues';
import productionlistvalues from '../reducer/productionlistvalues';
import moveorderlistvalues from '../reducer/moveorderlistvalues';
import customertransationvalues from '../reducer/customertransationvalues';

import { USER_LOGGED_OUT } from '../Services/types';

const appReducer = combineReducers({
  user: user,
  userlist: userlist,
  materialoption: materialoptionlist,
  materiallistvalues: materiallistvalues,
  productlistvalues: productlistvalues,
  masteritemtypenamevalue: masteritemtypenamevalue,
  masteritemlistvalues: masteritemtypelistvalues,
  suppliercontactlistvalue: suppliercontactlistvalue,
  supplierdetaillistvalue: supplierdetaillistvalue,
  uomlistvalue: uomlistvalue,
  currencylistvalue: currencylistvalue,
  locationlistvalue: locationlistvalue,
  purchaseorderlistvalue: purchaseorderlistvalue,
  receiptlistvalues: receiptlistvalues,
  customerlistvalues: customerlistvalues,
  customertransationvalues: customertransationvalues,
  quotationlistvalues: quotationlistvalues,
  invoicelistvalues: invoicelistvalues,
  proformainvoicelistvalues: proformainvoicelistvalues,
  challanlistvalues: challanlistvalues,
  orderlistvalues: orderlistvalues,
  productionlistvalues: productionlistvalues,
  moveorderlistvalues: moveorderlistvalues
});

const rootReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGGED_OUT:
      state = {};
      storage.removeItem('persist:root');
      return {};
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
