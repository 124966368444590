
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
class PurchaceOrderPage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/transaction_page'>Transaction</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Purchase Order</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
                <li className='bg-color-lg-blue'>
            <div className='icon-eleme'>
                <Link to='/purchase_order'>
                <i className='fas fa-gift fa-3x'></i>
                <div style={{ paddingTop: '5px' }}>
                    <span>Purchase Order</span>
                </div>
                </Link>
            </div>
            </li>
            <li className='bg-color-lg-blue'>
            <div className='icon-eleme'>
                <Link to='/view_purchaseorderlist'>
                <i className='fas fa-gift fa-3x'></i>
                <div style={{ paddingTop: '5px' }}>
                    <span>View Purchase Order List</span>
                </div>
                </Link>
            </div>
            </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default PurchaceOrderPage;
