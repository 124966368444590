export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_FETCHED = 'USER_FETCHED';
export const MATERIAL_OPTIONS = 'MATERIAL_OPTIONS';
export const MATERIAL_VALUES_FETCHED = 'MATERIAL_VALUES_FETCHED';
export const PRODUCT_VALUES_FETCHED = 'PRODUCT_VALUES_FETCHED';
export const PRODUCT_VALUES_DELETED = 'PRODUCT_VALUES_DELETED';
export const MASTER_ITEM_TYPES = 'MASTER_ITEM_TYPES';
export const MASTER_ITEM_LIST = 'MASTER_ITEM_LIST';
export const DELETE_MASTER_ITEM_LIST = 'DELETE_MASTER_ITEM_LIST';
export const SUPPLIER_CONTACT_LIST = 'SUPPLIER_CONTACT_LIST';
export const SUPPLIER_DETAIL_LIST = 'SUPPLIER_DETAIL_LIST';
export const UOM_LIST = 'UOM_LIST';
export const CURRENCY_LIST = 'CURRENCY_LIST';
export const Location_LIST = 'LOCATION_LIST';
export const PURCHASE_ORDER_LIST = 'PURCHASE_ORDER_LIST';
export const PO_RECEIPT_LIST = 'PO_RECEIPT_LIST';
export const ADD_CUSTOMER_CONTACT = 'ADD_CUSTOMER_CONTACT';
export const CUSTOMER_CONTACT_FETCHED = 'CUSTOMER_CONTACT_FETCHED';
export const CUSTOMERTRANSACTION_FETCHED = 'CUSTOMERTRANSACTION_FETCHED';
export const ADD_CUSTOMER_TRANSACTION = 'ADD_CUSTOMER_TRANSACTION';

export const QUOTATION_FETCHED = 'QUOTATION_FETCHED';
export const ADD_QUOTATION = 'ADD_QUOTATION';

export const INVOICE_FETCHED = 'INVOICE_FETCHED';
export const PROFORMAINVOICE_FETCHED = 'PROFORMAINVOICE_FETCHED';
export const ADD_PROFORMA_INVOICE = 'ADD_PROFORMA_INVOICE';

export const ADD_INVOICE = 'ADD_INVOICE';

export const CHALLAN_FETCHED = 'CHALLAN_FETCHED';
export const ADD_CHALLAN = 'ADD_CHALAN';

export const ORDER_FETCHED = 'ORDER_FETCHED';
export const ADD_ORDER = 'ADD_ORDER';

export const PRODUCTION_FETCHED = 'PRODUCTION_FETCHED';
export const ADD_PRODUCTION = 'ADD_PRODUCTION';

export const MOVEORDER_FETCHED = 'MOVEORDER_FETCHED';
export const ADD_MOVEORDER = 'ADD_MOVEORDER';
