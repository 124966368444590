import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Alert, Modal,ModalHeader,ModalBody,ModalFooter } from "reactstrap";
import { getMasterItemList } from "../../../../actions/Transaction";
import DataTable from "../../../../Components/Table/DatatablePage";
import CustomLoader from "../../../../Components/loader/loader";
import { deleteMasterItemList } from "../../../../actions/Transaction";

class ViewMasterItemList extends React.Component {
  state = {
    loading: false,
    showModal: false,
    setDeleteId: '',
    masteritemlist: [],
    count: 0
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  componentDidMount() {
    this.dataHandler(true);
    this.props.getMasterItemList();
    console.log("master item list", this.props);
    if(this.props.masteritemlistvalues) {
      let masteritemlistvalues = Object.values(this.props.masteritemlistvalues);
      masteritemlistvalues= masteritemlistvalues.sort((a, b) => b.masteritemtypename.toString().toLowerCase().localeCompare(a.masteritemtypename.toString().toLowerCase()));
      const masteritemlist = masteritemlistvalues.map((masteritemdata, i) => {
        return {
          masteritemtype: masteritemdata.masteritemtype.masteritemtypename,
          masteritemtypename: masteritemdata.masteritemtypename,
          masteritemdescription: masteritemdata.masteritemdescription,
          masteritemshortdescription:masteritemdata.masteritemshortdescription,
          masteritemuom: masteritemdata.masteritemuom.uomname,
          // masteritemrate: masteritemdata.masteritemrate,
          masteritemcostperunit: masteritemdata.masteritemcostperunit,
          view: (
            <Button
              Label
              color="primary"
              onClick={() => {
                this.updateButton(masteritemdata.id);
              }}
            >
              Edit
            </Button>
          ),
          delete: (
            <Button
            color="danger"
            onClick={() => {
              this.setState({ ...this.state, showModal: true, setDeleteId: masteritemdata.id });
            }}
          >
            Delete
          </Button>
          )        
        };
      });
      const count = masteritemlistvalues.length;
      this.setState({ ...this.state, masteritemlist, count });
      this.dataHandler(false);    
    }
  }

  toggle = () => {
    this.setState({ ...this.state, showModal: false });
  };

  componentWillReceiveProps(props) {
    console.log("master item list in props", this.props);
    let masteritemlistvalues = Object.values(props.masteritemlistvalues);
    masteritemlistvalues= masteritemlistvalues.sort((a, b) => b.masteritemtypename.toString().toLowerCase().localeCompare(a.masteritemtypename.toString().toLowerCase()));
    const masteritemlist = masteritemlistvalues.map((masteritemdata, i) => {
      return {
        masteritemtype: masteritemdata.masteritemtype.masteritemtypename,
        masteritemtypename: masteritemdata.masteritemtypename,
        masteritemdescription: masteritemdata.masteritemdescription,
        masteritemshortdescription:masteritemdata.masteritemshortdescription,
        masteritemuom: masteritemdata.masteritemuom.uomname,
        // masteritemrate: masteritemdata.masteritemrate,
        masteritemcostperunit: masteritemdata.masteritemcostperunit,
        view: (
          <Button
            Label
            color="primary"
            onClick={() => {
              this.updateButton(masteritemdata.id);
            }}
          >
            Edit
          </Button>
        ),
        delete: (
          <Button
          color="danger"
          onClick={() => {
            this.setState({ ...this.state, showModal: true, setDeleteId: masteritemdata.id });
          }}
        >
          Delete
        </Button>
        )        
      };
    });
    const count = masteritemlistvalues.length;
    this.setState({ ...this.state, masteritemlist, count });
    this.dataHandler(false);
  }

  deleteItem = () => {
    return this.props.deleteMasterItemList(this.state.setDeleteId)
    .then((data) => {
      this.props.getMasterItemList();
      window.location.reload(true);
    })
    .catch((err) => {console.log("could not delete")})
  };

  updateButton = id => {
    let path = `/m_updateitemlist/${id}`;
    this.props.history.push(path);
  };

  render() {
    const { loading, count, masteritemlist, showModal } = this.state;
    const columnvalue = [
      {
        label: "Item Type",
        field: "masteritemtype",
        sort: "asc",
        width: 75
      },
      {
        label: "Item Code",
        field: "masteritemtypename",
        sort: "asc",
        width: 75
      },
      {
        label: "Item Description",
        field: "masteritemdescription",
        sort: "asc",
        width: 75
      },
      {
        label: "Short Description",
        field: "masteritemshortdescription",
        sort: "asc",
        width: 75
      },
      {
        label: "UOM",
        field: "masteritemuom",
        sort: "asc",
        width: 75
      },
      // {
      //   label: "Item Rate",
      //   field: "masteritemrate",
      //   sort: "asc",
      //   width: 50
      // },
      {
        label: "Unit Cost",
        field: "masteritemcostperunit",
        sort: "asc",
        width: 50
      },
      {
        label: "Edit",
        field: "view",
        sort: "asc",
        width: 100
      },
      {
        label: "Delete",
        field: "delete",
        width: 100
      }
    ];
    return (
      <div className="container mg-20">
        {loading ? <CustomLoader /> : null}
        {showModal ? (
                      <Modal
                      isOpen={showModal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle}>Delete</ModalHeader>
                      <ModalBody>Do you want to delete this product?</ModalBody>
                      <ModalFooter>
                        <Button color='primary' onClick={this.deleteItem}>
                          Yes
                        </Button>
                        <Button color='primary' onClick={this.toggle}>
                          No
                        </Button>                        
                      </ModalFooter>
                    </Modal>
        ) : null
        }
        {count > 0 ? (
          <div>
            <DataTable
              columnvalue={columnvalue}
              rowvalues={masteritemlist}
              className="masteritemlist"
            />
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    masteritemlistvalues: state.masteritemlistvalues
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    { getMasterItemList, deleteMasterItemList }
  )(ViewMasterItemList)
);
