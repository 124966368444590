import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

import { totalitemvaluesearch, getMasterItemList } from '../../../../actions/Transaction';
import axios from "axios";

class AddOnHandScreenForm extends React.Component {
  state = {
    data: {
      selectedItemlist: {
        id: [null],
        itemname: [null],
        itemdescription: [null],
        itemlocation: [null],
        quantity: [null],
        availableQuantity: [null],
      },
      fromaddress: '',
      values: ['']
    },
    errors: {},
    materialoptionlistvalues: [],
    supplierdetaillistvalue: [],
    supplierdetaillistvalueobject: {},
    itemlistSelectedOption: [],
    masteritemlistvalues: [],
    masteritemlistvaluesobject: {},
    currencylistvalues: [],
    uom: [],
    locationlistvalue: [],
    tolocationlist: [],
    itemlist: [],
    loading: false,
    success: false,
    masterItemList: []
  };

  componentDidMount() {
    // this.props.getLocationList();
    this.props.getMasterItemList().then((result) => {
      console.log('result..............................');
      console.log(result);

      let items = [];
      //MasterItemTypeList.entities.masteritemtypelistvalues
      for (let key in result.MasterItemTypeList.result) {
        const data = result.MasterItemTypeList.entities.masteritemtypelistvalues[result.MasterItemTypeList.result[key]];
        console.log(data);
        items.push({
          id: data['id'],
          itemid: data['id'],
          itemname: data['masteritemtypename'],
          itemdescription: data['masteritemshortdescription'] != '' ? data['masteritemshortdescription'] : data['masteritemdescription'],
          quantity: 0,
          availableQuantity: 0
        });
      }

      console.log('UUUUUUUUUUUUUUUUUUUUUUUU');
      console.log(items);
      this.setState({
        masterItemList: items
      });
    });
  }

  componentWillReceiveProps(props) {
    const materialoptionlistvalues = Object.values(
      props.materialoptionlistvalues
    );
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    const masteritemlistvaluesobject = props.masteritemlistvalues;
    const currencylistvalues = Object.values(props.currencylistvalues);
    const uomlistvalues = Object.values(props.uomlistvalues);
    const locationlistvalue = Object.values(props.locationlistvalue);
    this.setState({
      ...this.state,
      masteritemlistvalues,
      masteritemlistvaluesobject,
      materialoptionlistvalues,
      currencylistvalues,
      locationlistvalue,
      uom: uomlistvalues,
      data: {
        ...this.state.data
      }
    }, () => {

    });
  }

  selectitemchange = (e, index) => {
    const selected_id = e.target.value;
    if (selected_id) {
      let list = this.state.itemlist;
      const selected_item_list = list.filter(it => it.itemid === selected_id);
      const selected_item = selected_item_list[0];
      console.log(selected_item);
      console.log(this.state.masterItemList)
      let itemdesc = ''
      this.state.masterItemList.map((item) =>{
        if(item.itemname == selected_item.itemname)
          itemdesc = item.itemdescription
      })

      const selectedItemlist = { ...this.state.data.selectedItemlist };
      selectedItemlist.id[index] = selected_item.itemid;
      selectedItemlist.itemname[index] = selected_item.itemname;
      selectedItemlist.itemdescription[index] = itemdesc != '' ? itemdesc : selected_item.itemdescription;
      selectedItemlist.quantity[index] = selected_item.itemquantity || 0;
      selectedItemlist.availableQuantity[index] = selected_item.itemquantity || 0;
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          selectedItemlist
        }
      });
    }
  };

  locationChange = (e, index) => {
    const selected_location = e.target.value;
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.itemlocation[index] = selected_location;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  onhandlerChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  onhandlerFromChange = e => {
    const locationlistvalue = this.state.locationlistvalue;
    let tolocationlist = locationlistvalue.filter(item => item.id !== e.target.value);
    this.setState({
      ...this.state,
      tolocationlist,
      data: { ...this.state.data, fromaddress: e.target.value }
    });
    let data = { locationid: e.target.value, itemid: '' };
    let location = e.target.value;
    this.props.totalitemvaluesearch(data).then(data => {
      console.log(data);


      const isAdded = [];
      const itemlist = [];
      data.itemlist.forEach(item => {
        if (item['itemname']) {
          itemlist.push({
            id: item.id,
            locationname: item.locationname,
            itemid: item.itemid,
            itemname: item.itemname,
            itemdescription: item.itemdescription,
            itemquantity: item.itemquantity
          });
        }
      });


      itemlist.forEach(data => {
        if (data['itemname']) {
          isAdded.push(data['itemname'])
        }
      });

      console.log('Before Adding Remaining');
      console.log(itemlist);

      const allItems = this.state.masterItemList;
      allItems.forEach((item, index) => {
        if (!(isAdded.indexOf(item.itemname) > -1)) {
          itemlist.push({
            id: item.id,
            locationname: location,
            itemid: item.itemid,
            itemname: item.itemname,
            itemdescription: item.itemdescription,
            itemquantity: item.itemquantity
          });
        }
      });

      console.log('After Adding Remaining');
      console.log(itemlist);

      this.setState({
        ...this.state,
        itemlist
      });
      // return data;
    });
  };
  onhandlerToChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, toaddress: e.target.value }
    });
  };
  onSubjectHandler = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };



  createSelectItems = () => {
    let items = [];
    let masteritemlistvalues = this.state.itemlist;
    console.log('###################################');
    console.log(masteritemlistvalues);
    // masteritemlistvalues = this.filterItems(masteritemlistvalues, 'itemname');
    console.log(masteritemlistvalues)

    masteritemlistvalues = masteritemlistvalues.sort((a,b)=>{return a['itemname'].toLowerCase().localeCompare(b['itemname'].toLowerCase())})
    

    masteritemlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.itemid}>
          {option.itemname}
        </option>
      );
    });
    return items;
  };

  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  createToLocationList = () => {
    let items = [];
    const locationlistvalue = this.state.tolocationlist;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  handleClick = e => {
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ''],
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id: [...this.state.data.selectedItemlist.id, ''],
          itemname: [...this.state.data.selectedItemlist.itemname, ''],
          itemdescription: [
            ...this.state.data.selectedItemlist.itemdescription,
            ''
          ],
          itemlocation: [...this.state.data.selectedItemlist.itemlocation, ''],
          quantity: [...this.state.data.selectedItemlist.quantity, 0],
          availableQuantity: [...this.state.data.selectedItemlist.quantity, '']
        }
      }
    });
  };
  removeClick = (e, index) => {
    const removeItem = { ...this.state.data };
    const values = removeItem.values;
    const id = removeItem.selectedItemlist.id;
    const itemname = removeItem.selectedItemlist.itemname;
    const itemdescription = removeItem.selectedItemlist.itemdescription;
    const itemlocation = removeItem.selectedItemlist.itemlocation;
    const quantity = removeItem.selectedItemlist.quantity;
    const availableQuantity = removeItem.selectedItemlist.availableQuantity;

    const totalcost = removeItem.selectedItemlist.totalcost;
    values.splice(index, 1);
    id.splice(index, 1);
    itemname.splice(index, 1);
    itemdescription.splice(index, 1);
    itemlocation.splice(index, 1);
    quantity.splice(index, 1);
    availableQuantity.splice(index, 1);

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id,
          itemname,
          itemdescription,
          itemlocation,
          availableQuantity,
          quantity
        }
      }
    });
  };

  quantityChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };

    console.log(typeof selectedItemlist.quantity[index])
    console.log(typeof selectedItemlist.availableQuantity[index])

    selectedItemlist.quantity[index] = e.target.value;

    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  handleValidSubmit = () => {
    let iteminfo = [];
    const { supplierdetail, selectedItemlist, fromaddress } = this.state.data;
    const { values } = this.state.data;
    const id = selectedItemlist.id;
    const itemname = selectedItemlist.itemname;
    const itemdescription = selectedItemlist.itemdescription;
    // const itemlocation = selectedItemlist.itemlocation;
    const quantity = selectedItemlist.quantity;
    values.forEach(function (value, i) {
      let obj = {};
      obj.id = id[i] || null;
      obj.itemid = id[i] || null;
      obj.itemdescription = itemdescription[i] || null;
      obj.itemlocation = fromaddress;//itemlocation[i] || null;
      obj.recevingqty = quantity[i] || null;
      iteminfo.push(obj);
    });
    const { data } = this.state;
    data['iteminfo'] = iteminfo;
    this.setState({ loading: true });
    // console.log(JSON.stringify(selectedItemlist))
    console.log(JSON.stringify(data));
    this.props
      .submit(data)
      .then(() => {
        this.setState({
          loading: false,
          errors: {},
          success: true,
          data: {
            selectedItemlist: {
              id: [null],
              itemname: [null],
              itemdescription: [null],
              itemlocation: [null],
              quantity: [null],
              availableQuantity: [null],
            },
            fromaddress: '',
            values: ['']
          }
        });
        this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  handleInvalidSubmit = () => {
    console.log('invalid submit');
  };
  render() {
    const { data, errors, loading, success } = this.state;
    return (
      <div>
        <div>
          {!!success ? (
            // <Alert color="info">Purchase Order is generated successfully</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Move Order</ModalHeader>
              <ModalBody>On Hand Updated successfully</ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>On Hand</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='fromaddress'>Select location </Label>
                    <AvField
                      type='select'
                      name='fromaddress'
                      onChange={this.onhandlerFromChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            'Please enter to location'
                        }
                      }}
                    >
                      <option value=''>select location</option>
                      {this.createSelectLocationItems()}
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <div className='mg-t-20'>
                  <Button color='info' onClick={this.handleClick} type='button'>
                    Add Item
                  </Button>
                </div>
              </Row>
              <div
                className='purchaseorder-item-row'
                style={{ overflowX: 'unset' }}
              >
                {data.values.map((values, index) => {
                  return (
                    <div key={index}>
                      <div
                        className='purchaseorder-table'
                        style={{ width: 'auto' }}
                      >
                        <Row key={index}>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for='selectitem'>Item Code</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                type='select'
                                name={`selectitem${index}`}

                                value={
                                  data.selectedItemlist.id[index]
                                }
                                onChange={e => this.selectitemchange(e, index)}
                                required={true}
                              >
                                <option value=''>select Item</option>
                                {this.createSelectItems()}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemdescription${index}`}>
                                Description
                              </Label>
                              <span className='required-label'>*</span>
                              <AvField
                                name={`masteritemdescription${index}`}
                                type='text'
                                id='masteritemdescription'
                                value={
                                  data.selectedItemlist.itemdescription[index]
                                }
                                onChange={this.onChange}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      'Please enter Item Description'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>

                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`quantity${index}`}>
                                Quantity
                              </Label>
                              <AvField
                                name={`quantity${index}`}
                                type='number'
                                id={`quantity${index}`}
                                value={data.selectedItemlist.availableQuantity[index]}
                                disabled={true}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`newquantity${index}`}>
                                New Quantity
                              </Label>
                              <AvField
                                name={`newquantity${index}`}
                                type='number'
                                id={`newquantity${index}`}
                                value={data.selectedItemlist.quantity[index]}
                                onChange={e => this.quantityChange(e, index)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter Item Quantity'
                                  },
                                  pattern: {
                                    value: '^[0-9.]*$',
                                    errorMessage: 'Please enter valid quantity'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <Button
                              type='button'
                              color='danger'
                              onClick={e => this.removeClick(e, index)}
                              style={{ marginTop: '30px' }}
                            >
                              -
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Row>
                <div className='mg-20'>
                  <Button
                    color='primary'
                    disabled={loading}
                    className='lgn-loader'
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierdetaillistvalue: state.supplierdetaillistvalue,
    masteritemlistvalues: state.masteritemlistvalues,
    materialoptionlistvalues: state.masteritemtypenamevalue,
    currencylistvalues: state.currencylistvalue,
    uomlistvalues: state.uomlistvalue,
    locationlistvalue: state.locationlistvalue,
    purchaseorderlistvalues: state.purchaseorderlistvalue,
    moveorderlistvalues: state.moveorderlistvalues
  };
}

export default connect(
  mapStateToProps,
  {
    totalitemvaluesearch,
    // getLocationList,
    getMasterItemList
  }
)(AddOnHandScreenForm);
