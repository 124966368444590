import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

class UpdateMasterItemTypeForm extends React.Component {
  state = {
    data: {
      id: this.props.masteritemtypenamevalue.id,
      masteritemtypename: this.props.masteritemtypenamevalue.masteritemtypename
    },
    errors: {},
    loading: false,
    success: false
  };
  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  componentWillReceiveProps(props) {
    const dataRecord = props.masteritemtypenamevalue;
    this.setState({ ...this.state.data, data: dataRecord });
  }

  handleValidSubmit = () => {
    this.setState({ loading: true });
    this.props
      .submit(this.state.data)
      .then(() => {
        this.setState({ loading: false, errors: {}, success: true });
        // this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  render() {
    const { data, errors, loading, success } = this.state;
    console.log(data);
    return (
      <div>
        {!!success ? (
          // <Alert color="info">Item Type is updated successfully</Alert>
          <Modal
            isOpen={success}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>Item Type</ModalHeader>
            <ModalBody>Item Type data is Updated successfully</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Ok
              </Button>{" "}
         
            </ModalFooter>
          </Modal>
        ) : null}
        {!!errors.MasterItemTypename ? (
          <Alert color="danger">Item data already exist.</Alert>
        ) : null}
        {!!errors.global ? (
          <Alert color="danger">
            Something went wrong,Please try again later.
          </Alert>
        ) : null}
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Update Item</CardHeader>
            <CardBody>
              <Row>
                <Col sm={6}>
                  <AvGroup>
                    <Label className='small-font-size' for="item">Item</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="masteritemtypename"
                      type="text"
                      id="item"
                      value={data.masteritemtypename}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Item name"
                        },
                   
                        pattern: {
                          value: "^[a-zA-Z]+$",
                          errorMessage: "Please enter valid item name"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="mg-20">
            <Button color="primary" disabled={loading} className="lgn-loader">
              update
              {/* {loading} */}
            </Button>
          </div>
        </AvForm>
      </div>
    );
  }
}

UpdateMasterItemTypeForm.propTypes = {
  submit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    masteritemtypenamevalueobjectlist: state.masteritemtypenamevalue
  };
}

export default connect(
  mapStateToProps,
  {}
)(UpdateMasterItemTypeForm);
