import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
  getMasterItemList,
  getMasterItemTypes
} from '../../../../actions/Transaction';
import { connect } from 'react-redux';
import { getUomList } from '../../../../actions/uom';
import { getCustomerList } from '../../../../actions/customerContact';
import { addproduction } from '../../../../actions/Production';
import { getproduction } from '../../../../actions/Production';
import { getproducts } from '../../../../actions/Product';
import ProductionForm from './ProductionForm';
import CustomLoader from '../../../../Components/loader/loader';

class AddProduction extends React.Component {
  state = {
    loading: false
  };
  componentDidMount() {
    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getproduction();
    this.props.getproducts();
  }
  componentWillReceiveProps() {
    
  }
  submit = data => {
    console.log(data);
    this.dataHandler(true);
    return this.props
      .addproduction(data)
      .then(data => {
        this.dataHandler(false);
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className='container mg-t-20'>
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/transaction_page'>Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/production'>Production</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Production</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <ProductionForm submit={this.submit} />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    getproduction,
    getCustomerList,
    addproduction,
    getproducts
  }
)(AddProduction);
