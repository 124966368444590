import { ADD_PRODUCTION, PRODUCTION_FETCHED } from '../Services/types';

export default function(state = {}, action) {
  switch (action.type) {
    case PRODUCTION_FETCHED:
    case ADD_PRODUCTION:
      return {
        ...state,
        ...action.productionlist.entities.productionlistvalues
      };
    default:
      return state;
  }
}
