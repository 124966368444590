
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
class QuantityPage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/transaction_page'>Transaction</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Quantity</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
                <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/receipt_screen'>
                    <i className='fas fa-folder fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>PO receipts</span>
                    </div>
                    </Link>
                </div>
                </li>

                <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/onhand'>
                    <i className='fas fa-folder fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>On hand</span>
                    </div>
                    </Link>
                </div>
                </li>

                {/* <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/totalitemquantity'>
                    <i className='fas fa-folder fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>On hand Old</span>
                    </div>
                    </Link>
                </div>
                </li> */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default QuantityPage;
