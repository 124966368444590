import React from 'react';
import { connect } from 'react-redux';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import PropTypes from 'prop-types';

class AddItemListForm extends React.Component {
  state = {
    data: {
      masteritemtype: '',
      masteritemname: null,
      itemName: '',
      masteritemdescription: '',
      masteritemshortdescription:'',
      masteritemuom: '',
      costperunit: null,
      masteritemrate: null,
      masteritemunitwt: null,
      permeter: null,
      perfeet: null,
      perinch: null,
      masteritemimagefile: null,
      masteritemsecondimagefile: null
    },
    materialoptionlistvalues: [],
    masteritemlistvalues: [],
    uom: [],
    errors: {},
    loading: false,
    success: false,
    filename:'',
    secondfilename:''
  };

  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  onNameChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, ['itemName']: e.target.value, ['masteritemshortdescription']: e.target.value }
    });
  }

  onChange2 = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value,
        perfeet : (e.target.value / 3.2808).toFixed(2),
        perinch : (e.target.value / 39.3701).toFixed(2)
       },

    });
  };

  componentDidMount() {
    const materialoptionlistvalues = Object.values(
      this.props.materialoptionlistvalues
    );
    const uomlistvalues = Object.values(this.props.uomlistvalues);
    const masteritemlistvalues = Object.values(this.props.masteritemlistvalues);
    this.setState({
      ...this.state,
      materialoptionlistvalues,
      uom: uomlistvalues,
      masteritemlistvalues: masteritemlistvalues
    });
  }

  componentWillReceiveProps(props) {
    const materialoptionlistvalues = Object.values(
      props.materialoptionlistvalues
    );
    const uomlistvalues = Object.values(props.uomlistvalues);
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    this.setState({
      ...this.state,
      materialoptionlistvalues,
      uom: uomlistvalues,
      masteritemlistvalues: masteritemlistvalues
    });
  }

  onItemTypeChange = e => {
    const selecteditemtype = e.target.value;
    const materialoptionlistvalues = this.state.materialoptionlistvalues;
    const masteritemlistvalues = this.state.masteritemlistvalues;
    const selecteditemtypevalue = materialoptionlistvalues.filter(
      (option, i) => {
        if (option.id === selecteditemtype) {
          return option;
        }
      }
    );
    const selectitemid = selecteditemtypevalue[0].id;
    if (selectitemid) {
      const selecteditemvalues = masteritemlistvalues.filter(item => {
        if (item.masteritemtype._id == selectitemid) {
          return item;
        }
      });
      console.log(selecteditemtypevalue);
      console.log(selecteditemvalues);
      const count = selecteditemvalues.length;
      if ((selectitemid === '5d263475917f5e27146d9ee4' || selectitemid === '5df361a6f57a6f7c679da493' || selectitemid === '5f0830f14d38d207245a37eb')) {
        let itemname = count > 0 ? 1002 + count : 1001;
        if(selecteditemvalues.length > 0) {
          let highestNo = itemname;
          selecteditemvalues.map((item,key) => {
            if(parseInt(item.masteritemtypename) >= highestNo) {
              highestNo = parseInt(item.masteritemtypename) + 1;
            }
          });
          itemname = highestNo;
        }
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            masteritemtype: selectitemid,
            masteritemname: itemname
          }
        });
      }
      if ((selectitemid === '5d263480917f5e27146d9ee5' || selectitemid == '5df361e1f57a6f7c679da494'  || selectitemid === '5f0831104d38d207245a37ec')) {
        let itemname = count > 0 ? 2002 + count : 2001;
        if(selecteditemvalues.length > 0) {
          let highestNo = itemname;
          selecteditemvalues.map((item,key) => {
            if(parseInt(item.masteritemtypename) >= highestNo) {
              highestNo = parseInt(item.masteritemtypename) + 1;
            }
          });
          itemname = highestNo;
        }
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            masteritemtype: selectitemid,
            masteritemname: itemname
          }
        });
      }
      if ((selectitemid === '5d270f30136e660798dce139' || selectitemid == '5df361eaf57a6f7c679da495'  || selectitemid === '5f0831204d38d207245a37ed')) {
        let itemname = count > 0 ? 3002 + count : 3001;
        if(selecteditemvalues.length > 0) {
          let highestNo = itemname;
          selecteditemvalues.map((item,key) => {
            if(parseInt(item.masteritemtypename) >= highestNo) {
              highestNo = parseInt(item.masteritemtypename) + 1;
            }
          });
          if(selectitemid == '5df361eaf57a6f7c679da495') {
            highestNo = highestNo + 1074
          }
          itemname = highestNo;
        }
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            masteritemtype: selectitemid,
            masteritemname: itemname
          }
        });
      }

      if ((selectitemid === '5d5e206ea24f1806712491b7' || selectitemid == '5df361f3f57a6f7c679da496'  || selectitemid === '5f08312c4d38d207245a37ee')) {
        let itemname = count > 0 ? 4002 + count : 4001;
        if(selecteditemvalues.length > 0) {
          let highestNo = itemname;
          selecteditemvalues.map((item,key) => {
            if(parseInt(item.masteritemtypename) >= highestNo) {
              highestNo = parseInt(item.masteritemtypename) + 1;
            }
          });
          itemname = highestNo;
        }
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            masteritemtype: selectitemid,
            masteritemname: itemname
          }
        });
      }
    }
  };

  createSelectItems = () => {
    let items = [];
    const materialoptionlistvalues = this.state.materialoptionlistvalues;
    materialoptionlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uom;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };

  fileSelectedHandler = e => {
    console.log(e.target.files[0]);
    let masteritemimagefile = e.target.files[0];
    let file_size = e.target.files[0].size / 1024 / 1024;
    let file_name = e.target.files[0].name;
    let file_type = e.target.files[0].type;
    console.log(`${file_size} MB`);
    console.log(file_name);
    console.log(file_type);
    if (Number(file_size) > 2) {
      this.setState({ errors: { filesize: 'File Size is greater than 2MB' } });
    }
    if (!this.hasExtension(file_name, ['.jpg', '.jpeg', '.png'])) {
      this.setState({ errors: { filesize: 'File format not supported' } });
    }
    this.setState({
      filename: 'Attached file : ' + file_name,
      data: {
        ...this.state.data,
        masteritemimagefile: e.target.files[0]
      }
    });
  };


  fileSelectedSecondHandler = e => {
    console.log(e.target.files[0]);
    let masteritemsecondimagefile = e.target.files[0];
    let file_size = e.target.files[0].size / 1024 / 1024;
    let file_name = e.target.files[0].name;
    let file_type = e.target.files[0].type;
    console.log(`${file_size} MB`);
    console.log(file_name);
    console.log(file_type);
    if (Number(file_size) > 2) {
      this.setState({ errors: { filesize: 'File Size is greater than 2MB' } });
    }
    if (!this.hasExtension(file_name, ['.jpg', '.jpeg', '.png'])) {
      this.setState({ errors: { filesize: 'File format not supported' } });
    }
    this.setState({
      secondfilename: 'Attached file : ' + file_name,
      data: {
        ...this.state.data,
        masteritemsecondimagefile: e.target.files[0]
      }
    });
  };
  hasExtension(fileName, exts) {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  handleValidSubmit = () => {
    let { data } = this.state;
    this.setState({ loading: true });
    let formdata = new FormData();
    console.log(data)
    formdata.append('imagefile', data.masteritemimagefile);
    formdata.append('secondimage', data.masteritemsecondimagefile);
    // formdata.append("filename", data.masteritemimagefile.name);
    formdata.append('masteritemtype', data.masteritemtype);
    formdata.append('masteritemname', data.masteritemname);
    formdata.append('masteritemdescription', data.masteritemdescription);
    formdata.append('masteritemshortdescription', data.masteritemshortdescription);
    formdata.append('itemName', data.itemName);
    
    formdata.append('masteritemuom', data.masteritemuom);
    formdata.append('costperunit', data.costperunit);
    formdata.append('masteritemrate', data.masteritemrate);
    formdata.append('masteritemunitwt', data.masteritemunitwt);
    formdata.append('permeter', data.permeter);
    formdata.append('perfeet', data.perfeet);
    formdata.append('perinch', data.perinch);
    if (Object.keys(this.state.errors).length === 0) {
      this.props
        .submit(formdata)
        .then(() => {
          this.setState({ loading: false, errors: {}, success: true });
          this.myFormRef.reset();
        })
        .catch(err => {
          this.setState({ errors: err.response.data.errors, loading: false });
        });
    } else if (this.state.errors.filesize) {
      alert(this.state.errors.filesize)
      this.setState({ loading: false, errors: {} });
    }
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  handleInvalidSubmit = () => {
    console.log(this.state.data)
    console.log('invalid submit');
  };

  render() {
    const { data, errors, loading, success,filename,secondfilename } = this.state;
    return (
      <div>
        <div>
          {!!success ? (
            // <Alert color="info">Item is added successfully.</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader  toggle={this.toggle}>Item</ModalHeader>
              <ModalBody >Item is added successfully.</ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={this.toggle}>
                  Ok
                </Button>{' '}
             
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <AvForm
          encType='multipart/form-data'
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader className='small-font-size-bold'>Add Item</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='masteritemtype'>Item Type</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      type='select'
                      name='masteritemtype'
                      onChange={this.onItemTypeChange}
                      required={true}
                      Label
                    >
                      <option value=''>select</option>
                      {this.createSelectItems()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='masteritemname'>Item Code</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      Label
                      name='masteritemname'
                      type='Number'
                      id='masteritemname'
                      value={data.masteritemname}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Item Code'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='itemName'>Item Name</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      Label
                      name='itemName'
                      type='text'
                      id='itemName'
                      value={data.itemName}
                      onChange={this.onNameChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Item Name'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>                
              </Row>
              <Row>
                <Col sm={8}>
                  <AvGroup>
                    <Label className='small-font-size' for='masteritemdescription'>Item Description</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      Label
                      name='masteritemdescription'
                      type='textarea'
                      id='masteritemdescription'
                      value={data.masteritemdescription}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Item Description'
                        },
                        maxLength: {value: 326, errorMessage: 'Your Item Description must be between 6 and 326 characters'}
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={4}>
                  <AvGroup>
                    <Label className='small-font-size' for='masteritemshortdescription'>Short Description</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      Label
                      name='masteritemshortdescription'
                      type='textarea'
                      id='masteritemshortdescription'
                      value={data.masteritemshortdescription}
                      onChange={this.onNameChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Item Short Description'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='masteritemuom'>UOM</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      Label
                      type='select'
                      name='masteritemuom'
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value=''>Please select value</option>
                      {this.createSelectUomItems()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='costperunit'>Unit Cost</Label>
                    <AvField
                      Label
                      name='costperunit'
                      type='number'
                      id='costperunit'
                      value={data.costperunit}
                      onChange={this.onChange}

                    />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='masteritemunitwt'>Unit weight in KG(per meter)</Label>
                    <AvField
                      Label
                      name='masteritemunitwt'
                      type='text'
                      id='masteritemunitwt'
                      value={data.masteritemunitwt}
                      onChange={this.onChange}

                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='permeter'>Weight per meter</Label>
                    <AvField
                      Label
                      name='permeter'
                      type='number'
                      id='permeter'
                      value={data.permeter || null}
                      onChange={this.onChange2}
                    />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='perfeet'>Weight per feet</Label>
                    <AvField
                      readOnly
                      className='small-font-size'
                      name='perfeet'
                      type='number'
                      id='perfeet'
                      value={data.perfeet || null}
                      onChange={this.onChange}
                    />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='perinch'>Weight per inch</Label>
                    <AvField
                      readOnly
                      className='small-font-size'
                      name='perinch'
                      type='number'
                      id='perinch'
                      value={data.perinch || null}
                      onChange={this.onChange}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <AvGroup>
                    {/* <Label className='small-font-size' for='masteritemimagefile'>Item Image</Label> */}
                    {/* <span className="required-label">*</span> */}
        

                    <AvField
                      Label
                      name='masteritemimagefile'
                      type='file'
                      id='masteritemimagefile'
                      style={{'display':'none'}}
                      onChange={this.fileSelectedHandler}
                      accept='.png,.svg,.gif,.jpg,.jpeg'
                    />
                    <label for="masteritemimagefile" style={{'color':'#379ccb','cursor':'pointer'}} >Click here to upload image</label>
                    <div style={{'color':'#707070','fontSize':'12px'}}>{filename}</div>

                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <AvGroup>
                    {/* <Label className='small-font-size' for='masteritemimagefile'>Item Image</Label> */}
                    {/* <span className="required-label">*</span> */}
        

                    <AvField
                      Label
                      name='masteritemsecondimagefile'
                      type='file'
                      id='masteritemsecondimagefile'
                      style={{'display':'none'}}
                      onChange={this.fileSelectedSecondHandler}
                      accept='.png,.svg,.gif,.jpg,.jpeg'
                    />
                    <label for="masteritemsecondimagefile" style={{'color':'#379ccb','cursor':'pointer'}} >Click here to upload second image</label>
                    <div style={{'color':'#707070','fontSize':'12px'}}>{secondfilename}</div>

                  </AvGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className='mg-20'>
            <Button color='primary' disabled={loading} className='lgn-loader small-font-size'>
              Submit
              {/* {loading} */}
            </Button>
          </div>
        </AvForm>
      </div>
    );
  }
}

AddItemListForm.propTypes = {
  submit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    materialoptionlistvalues: state.masteritemtypenamevalue,
    uomlistvalues: state.uomlistvalue,
    masteritemlistvalues: state.masteritemlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(AddItemListForm);
