import { normalize } from "normalizr";
import api from "../Services/transactionapi";
import {
  MASTER_ITEM_TYPES,
  MASTER_ITEM_LIST,
  PURCHASE_ORDER_LIST,
  PO_RECEIPT_LIST,
  DELETE_MASTER_ITEM_LIST
} from "../Services/types";
import {
  masterItemTypeNameSchema,
  masterItemTypeListSchema,
  purchaseOrderListSchema,
  receiptListSchema
} from "../Schemas/schemas";

export const masterItemTypeNameFetched = MasterItemTypeName => {
  return {
    type: MASTER_ITEM_TYPES,
    MasterItemTypeName
  };
};

export const masterItemTypeListFetched = MasterItemTypeList => {
  return {
    type: MASTER_ITEM_LIST,
    MasterItemTypeList
  };
};

export const purchaseOrderListFetched = purchaseorderlistvalues => {
  return {
    type: PURCHASE_ORDER_LIST,
    purchaseorderlistvalues
  };
};

export const ReceiptListFetched = receiptlistvalues => {
  return {
    type: PO_RECEIPT_LIST,
    receiptlistvalues
  };
};
export const addMasterItemType = data => {
  return dispatch => {
    return api.transaction.addMasterItemType(data);
  };
};

export const updateMasterItemTypes = data => {
  return dispatch => {
    return api.transaction.updateMasterItemType(data);
  };
};

export const updatemasteritemlist = data => {
  return dispatch => {
    return api.transactionMasterItemList.updatemasteritemlist(data);
  };
};
export const getMasterItemTypes = () => {
  return dispatch => {
    return api.transaction.getMasterItemType().then(masteritemtypes => {
      const masteritemtypenamevalue = masteritemtypes.masteritemtypenamedata;
      return dispatch(
        masterItemTypeNameFetched(
          normalize(masteritemtypenamevalue, [masterItemTypeNameSchema])
        )
      );
    });
  };
};

export const addMasterItemList = data => {
  return dispatch => {
    return api.transactionMasterItemList.addMasterItemList(data);
  };
};

export const deleteMasterItemList = id => {
  return dispatch => {
    return api.transactionMasterItemList.deletemasteritemlist(id).then(deletedData => {
      return dispatch({
        type: DELETE_MASTER_ITEM_LIST,
        payload: id
     })
    })
  };
}

export const getMasterItemList = () => {
  return dispatch => {
    return api.transactionMasterItemList
      .getMasterItemList()
      .then(masteritemlist => {
        const masteritemtypelistvalues = masteritemlist.masteritemtypelistdata;
        return dispatch(
          masterItemTypeListFetched(
            normalize(masteritemtypelistvalues, [masterItemTypeListSchema])
          )
        );
      });
  };
};

export const addPurchaseOrder = data => {
  return dispatch => {
    return api.purchaseorder.addPurchaseOrder(data);
  };
};


export const updatePurchaseOrder = data => {
  return dispatch => {
    return api.purchaseorder.updatePurchaseOrder(data);
  };
};

export const getPurchaseOrder = data => {
  return dispatch => {
    return api.purchaseorder.getPurchaseOrderList().then(purchaseorderlist => {
      const purchaseorderlistvalues = purchaseorderlist.purchaseorderlistdata;
      return dispatch(
        purchaseOrderListFetched(
          normalize(purchaseorderlistvalues, [purchaseOrderListSchema])
        )
      );
    });
  };
};

export const searchPoNumber = data => {
  return dispatch => {
    return api.purchaseorder.searchponumber(data);
  };
};

export const addorupdateitemsinlocation = data => {
  return dispatch => {
    return api.addorupdateitemsinlocation.addorupdateitemsinlocation(data);
  };
};

export const additemsinlocation = data => {
  return dispatch => {
    return api.addorupdateitemsinlocation.additemsinlocation(data);
  };
};

export const getReceiptlist = data => {
  return dispatch => {
    return api.receipt.getReceiptList(data);
    // .then(receiptlist => {
    //   if (receiptlist.count > 0) {
    //     const receiptlistvalues = receiptlist.receiptlist;
    //     return dispatch(
    //       ReceiptListFetched(normalize(receiptlistvalues, [receiptListSchema]))
    //     );
    //   }
    //   return receiptlist;
    // });
  };
};
export const totalitemvaluesearch = data => {
  return dispatch => {
    return api.addorupdateitemsinlocation.getitemlistavaliable(data);
  };
};
