import { MASTER_ITEM_TYPES } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case MASTER_ITEM_TYPES:
      return {
        ...state,
        ...action.MasterItemTypeName.entities.masteritemtypenamevalue
      };
    default:
      return state;
  }
}
