import storage from "redux-persist/lib/storage";
import { USER_LOGGED_IN } from "../Services/types";
import { USER_LOGGED_OUT } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case USER_LOGGED_IN:
      return action.user;
    case USER_LOGGED_OUT:
      storage.removeItem("persist:root");
      return {};
    default:
      return state;
  }
}
