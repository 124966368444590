import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
  getMasterItemList,
  getMasterItemTypes
} from '../../../../actions/Transaction';
import { connect } from 'react-redux';
import { getUomList } from '../../../../actions/uom';
import { getCustomerList } from '../../../../actions/customerContact';
import { addChallan } from '../../../../actions/challan';
import { getChallan } from '../../../../actions/challan';
import { updateChallan } from '../../../../actions/challan';
import { getinvoice } from '../../../../actions/Invoice';
import axios from 'axios';

import ChallanForm from './ChallanForm';
import CustomLoader from '../../../../Components/loader/loader';

class AddChallan extends React.Component {
  state = {
    loading: false,
    ID:this.props.match.params.id
  };
  componentDidMount() {
    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getChallan();
    this.props.getinvoice();
  }
  submit = data => {
    console.log(data);
    this.dataHandler(true);

    if(this.props.match.params.id != undefined){
      data['_id'] = this.props.match.params.id;

      return this.props
      .updateChallan(data)
      .then(submitdata => {
        console.log("data received is ", data);
        let obj = {};
        obj['_id'] = data.transactionid;
        obj['challan_link'] = data.deliverychallanno;
        axios
        .put(`${process.env.REACT_APP_BASE_URL}/customerTransaction/updatecustomertransaction`,obj)
        .then(res => {
          this.dataHandler(false);
          return submitdata;
        },
        error =>{
          this.dataHandler(false);
          throw error;
        });
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
    }
    else{
      return this.props
        .addChallan(data)
        .then(submitdata => {
          let obj = {};
          obj['_id'] = data.transactionid;
          obj['challan_link'] = data.deliverychallanno;
          axios
          .put(`${process.env.REACT_APP_BASE_URL}/customerTransaction/updatecustomertransaction`,obj)
          .then(res => {
            return submitdata;
          },
          error =>{
          });
          this.dataHandler(false);
          return submitdata;
        })
        .catch(err => {
          this.dataHandler(false);
          throw err;
        });
    }
  
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading,ID } = this.state;
    return (
      <div className='container mg-t-20'>
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/transaction_page'>Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/delivery-challan'>Delivery Challan</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Challan</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <ChallanForm submit={this.submit}  ID={ID}/>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    addChallan,
    updateChallan,
    getCustomerList,
    getChallan,
    getinvoice
  }
)(AddChallan);
