import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { userRegister } from "../../actions/actions";
import AddUserForm from "./AddUserForm/AddUserForm";

class AddUser extends React.Component {
  submit = data => this.props.userRegister(data);

  render() {
    return (
      <div className="container mg-20">
        <AddUserForm submit={this.submit} />
      </div>
    );
  }
}

AddUser.propTypes = {
  userRegister: PropTypes.func.isRequired
};
export default connect(
  null,
  { userRegister }
)(AddUser);
