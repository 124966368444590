import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import UpdateUserForm from "./AddUserForm/UpdateUserForm";
// import api from "../api";
import { updateUser } from "../../actions/actions";

class UpdateUser extends React.Component {
  state = {
    userRecord: ""
  };

  componentDidMount() {
    const userid = this.props.match.params.id;
    const userRecord = this.props.userlist[userid];
    this.setState({ userRecord });
    // api.user.getUserbyid(userid)
    // .then((userRecord)=>{
    //     this.setState({userRecord});
    // })
    // .catch((err)=>{
    //     console.log("updateuserfile err");
    //     console.log(err);
    // });
  }

  submit = data => this.props.updateUser(data);

  render() {
    const { userRecord, userid } = this.state;
    return (
      <div className="container mg-20">
        <UpdateUserForm userRecord={userRecord} submit={this.submit} />
      </div>
    );
  }
}

UpdateUser.propTypes = {
  userlist: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  userRecord: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    startdate: PropTypes.string.isRequired,
    enddate: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    persontype: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    userlist: state.userlist
  };
}

export default connect(
  mapStateToProps,
  { updateUser }
)(UpdateUser);
