import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPDF from '@react-pdf/renderer';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';

import MyDocument from './Component';
import logo from '../../../../Images/logo.png';
//import './ViewOrder';

class ViewReceiptPdf extends React.Component {
  state = {
    orderlistvalues: {},
    status: false,
    link: ''
  };
  componentDidMount() {
    let data = JSON.parse(sessionStorage.getItem("Receipt"))
    let receipts = JSON.parse(sessionStorage.getItem("SelectedItem"))
    receipts.map((item)=>{
      for(var j=0;j<data.itemlist.length;j++){
        if( data.itemlist[j].itemid.masteritemname == item.itemname){
          data.itemlist[j]['TotalRecQty'] = item.recevingqty;break
        }
      }
    })
    let poreceiptvalues = {}
    poreceiptvalues['ponumber'] = data['ponumber']
    poreceiptvalues['receiptnumber'] = data['receiptnumber']
    console.log(data.itemlist)
    poreceiptvalues['addeditemlist'] = data.itemlist.map(
      (itemlist, i) => {
        return {
          slno: (i + 1).toString(),
          itemname: itemlist.itemid.masteritemdescription ? itemlist.itemid.masteritemdescription : '',
          itemcode: itemlist.itemid.masteritemname ? itemlist.itemid.masteritemname : '',
          itemuom: itemlist.itemuom.uomname ? itemlist.itemuom.uomname : '',
          poqty: itemlist.itemquantity ? itemlist.itemquantity.toString() : '',
          receivedqty: itemlist.recevingqty ? itemlist.recevingqty.toString() : '',
          receiptDate:  itemlist.date ? new Date(itemlist.date).getDate() + "/" + (new Date(itemlist.date).getMonth() + 1) + "/" + new Date(itemlist.date).getFullYear() : '',
          availqty: itemlist.TotalRecQty ? itemlist.TotalRecQty : 0
        };
      }
    );

    setTimeout(() => {
      this.setState({
        orderlistvalues : poreceiptvalues,
        status: true
      },()=>{
        console.log(this.state.orderlistvalues)
      });
    },2000);
    this.dynamicClick();
  }
  dynamicClick() {
    if (document.getElementsByClassName('pdf-class')) {
      if (
        document.getElementsByClassName('pdf-class')[0].children.length <= 1
      ) {
        setTimeout(() => {
          this.dynamicClick();
        }, 6000);
      } else if (
        document.getElementsByClassName('pdf-class')[0].children.length > 1
      ) {
        this.setState({
          link: document.getElementsByClassName('pdf-class')[0].children[1].src
          // status : undefined,
        },()=>{
          setTimeout(() => {
            document.getElementById('D1').click();
            this.props.history.push('/receipt_screen');
          }, 300);
        });

      }
    } else {
    }
  }
  render() {
    const data_cond = [
      {
        slno: '1',
        title: 'Invoices',
        value: '2 copies Invoices (original & duplicate)'
      },
      {
        slno: '2',
        title: 'Tax & Duty',
        value: 'Included'
      },
      {
        slno: '3',
        title: 'Payment Schedule',
        value: '15 days from the date of delivery'
      },
      {
        slno: '4',
        title: 'Delivery Schedule',
        value: 'Immediately'
      },
      {
        slno: '5',
        title: 'Billing address & GST No',
        value:
          'M/s Pondhan Scaffolding Pvt Ltd \n Plot no. 40/41, Road no.5, Obedanahalli,'
      },
      {
        slno: '6',
        title: 'Delivery Address',
        value: 'M/s Pondhan Scaffolding Pvt Ltd'
      }
    ];
    const { status, orderlistvalues, link } = this.state;
    return (
      <div>
        <div className='pdf-class'>
          Downloading file.Please wait.
          {/* {!status && (
          <h1 style={{ 'position': 'absolute','left': '50%','top': '50%','transform': 'translate(-50%, -50%);'}}>Downloading file.Please wait.</h1>
        )} */}
          <a href={link} download id='D1'></a>
           {status && (
            <PDFViewer>
              <MyDocument
                orderlistvalues={this.state.orderlistvalues}
                data_cond={data_cond}
                logo={logo}
              />
            </PDFViewer>
          )} 
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    orderlistvalues: state.orderlistvalues
  };
}
export default connect(
  mapStateToProps
)(ViewReceiptPdf);
