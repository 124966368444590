import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Label, Alert } from "reactstrap";
import DatePicker from 'react-datepicker';
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

class LogsForm extends React.Component {
  state = {
    data: {
      date: "",
      type: "",
      invoicelist: [],
      quotationlist: [],
      proformainvoicelist: []
    },
    quotationlistvalues: [],
    invoicelistvalues: [],
    proformainvoicelistvalues: [],
    masteritemlistvalues: [],
    loading: false
  };
  componentDidMount() {
    const masteritemlistvalues = Object.values(this.props.masteritemlistvalues);
    let quotationlist = Object.values(this.props.quotationlistvalues);

    let invoicelist = Object.values(this.props.invoicelistvalues);

    let proformainvoicelist = Object.values(this.props.proformainvoicelistvalues);
    
    this.setState({
      ...this.state,
      masteritemlistvalues,
      quotationlistvalues: quotationlist,
      invoicelistvalues: invoicelist,
      proformainvoicelistvalues: proformainvoicelist
    });
  }
  componentWillReceiveProps(props) {
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    this.setState({
      ...this.state,
      masteritemlistvalues
    });
  }
  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  handledate = (date) => {
    this.setState({
        ...this.state,
        data: { ...this.state.data, ['date']: date }
      });
  }

  filterItems(itemarray, key) {
    return itemarray.sort((a, b) => { return a[key].toLowerCase().localeCompare(b[key].toLowerCase()) })
  }

  createSelectItems = () => {
    let items = [];
    items.push(
        <option key="0" value="Quotation Logs">
          Quotation Logs
        </option>
      );
      items.push(
        <option key="1" value="Invoice Logs">
          Invoice Logs
        </option>
      );      
      items.push(
        <option key="2" value="Proforma Invoice Logs">
          Proforma Invoice Logs
        </option>
      );      
    return items;
  };
  selectitemchange = () => { };
  
  onhandlerChange = e => {
    this.setState({
        ...this.state,
        data: { ...this.state.data, ['type']: e.target.value }
      });
  };
  handleValidSubmit = () => {
    const { data } = this.state;
    let newQuotation = this.state.quotationlistvalues.filter((quot, key) => {
        let selDate = new Date(data.date);
        let quotationDate = new Date(quot.date);
        quotationDate = new Date(quotationDate.getFullYear(), quotationDate.getMonth(), quotationDate.getDate());
        if(selDate.getTime() == quotationDate.getTime()){
            return quot;
        }
    });
    data.quotationlist = newQuotation;
    
    let newInvoiceList = this.state.invoicelistvalues.filter((quot, key) => {
        let selDate = new Date(data.date);
        let quotationDate = new Date(quot.date);
        quotationDate = new Date(quotationDate.getFullYear(), quotationDate.getMonth(), quotationDate.getDate());
        if(selDate.getTime() == quotationDate.getTime()){
            return quot;
        }
    });
    data.invoicelist = newInvoiceList;

    let newProformainvoice = this.state.proformainvoicelistvalues.filter((quot, key) => {
        let selDate = new Date(data.date);
        let quotationDate = new Date(quot.date);
        quotationDate = new Date(quotationDate.getFullYear(), quotationDate.getMonth(), quotationDate.getDate());
        if(selDate.getTime() == quotationDate.getTime()){
            return quot;
        }
    });
    data.proformainvoicelist = newProformainvoice;

    this.props
      .submit(data)
      this.setState({
        ...this.state,
        loading: false
      });  
  };
  render() {
    const { loading } = this.state;
    return (
      <div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader Label>Logs</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size'>Date</Label>
                    <DatePicker
                    autoComplete="new-password"
                    selected={this.state.data.date}
                    onChange={this.handledate}
                    className='form-control small-font-size'
                    dateFormat='yyyy-MM-dd'
                    id='date'
                  />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size'>Type</Label>
                    <AvField
                      Label
                      type="select"
                      name={`itemid`}
                      onChange={this.onhandlerChange}
                    >
                      <option value="">select Type</option>
                      {this.createSelectItems()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <Button
                    Label
                    color="primary"
                    disabled={loading}
                    type="submit"
                    onClick={this.submit}
                    style={{ marginTop: "30px" }}
                  >
                    Find
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    masteritemlistvalues: state.masteritemlistvalues,
    quotationlistvalues: state.quotationlistvalues,
    invoicelistvalues: state.invoicelistvalues,
    proformainvoicelistvalues: state.proformainvoicelistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(LogsForm);
