import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem, Button, Alert } from "reactstrap";
import { getSupplierDetails } from "../../../../actions/Supplier";
import DataTable from "../../../../Components/Table/DatatablePage";
import CustomLoader from "../../../../Components/loader/loader";

class Supplierlist extends React.Component {
  state = {
    supplierdetaillist: [],
    count: 0,
    loading: false
  };
  componentDidMount() {
    this.dataHandler(true);
    this.props.getSupplierDetails();
  }
  componentWillReceiveProps(props) {
    let supplierdetaillistvalue = Object.values(
      props.supplierdetaillistvalue
    );
    //supplierdetaillistvalue= supplierdetaillistvalue.sort((a, b) => b.suppliernumber.toString().toLowerCase().localeCompare(a.suppliernumber.toString().toLowerCase()));

    supplierdetaillistvalue.sort(function(a, b){
      return parseInt(b.suppliernumber) - parseInt(a.suppliernumber);
    });
    const count = supplierdetaillistvalue.length;
    const supplierdetaillist = supplierdetaillistvalue.map(
      (supplierdata, i) => {
        return {
          suppliernumber: supplierdata.suppliernumber,
          suppliername: supplierdata.suppliername,
          suppliergstnumber: supplierdata.suppliergstnumber,
          supplierAddress:
            supplierdata.supplieraddress1 +
            " " +
            supplierdata.supplieraddress2 +
            " " +
            supplierdata.city +
            " " +
            supplierdata.state +
            " " +
            supplierdata.country +
            " " +
            supplierdata.pincode,
          view: (
            <Button
              Label
              color="primary"
              onClick={() => {
                this.updateButton(supplierdata.id);
              }}
            >
              Edit
            </Button>
          )
        };
      }
    );
    this.setState({ supplierdetaillist, count });
    this.dataHandler(false);
  }
  updateButton = id => {
    let path = `/supplierdetail_update/${id}`;
    this.props.history.push(path);

  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { supplierdetaillist, count, loading } = this.state;
    const columnvalue = [
      {
        label: "Supplier Number",
        field: "suppliernumber",
        sort: "asc",
        width: 75
      },
      {
        label: "Supplier Name",
        field: "suppliername",
        sort: "asc",
        width: 75
      },
      {
        label: "Supplier GST",
        field: "suppliergstnumber",
        sort: "asc",
        width: 75
      },
      {
        label: "Supplier Address",
        field: "supplierAddress",
        sort: "asc",
        width: 75
      },
      {
        label: "Edit",
        field: "view",
        sort: "asc",
        width: 100
      }
    ];
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/admin_dashboard">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/supplier">Supplier</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Supplier list</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          {count > 0 ? (
            <div>
              <DataTable
                columnvalue={columnvalue}
                rowvalues={supplierdetaillist}
                className="supplierlist"
              />
            </div>
          ) : (
              <div>
                <Alert color="info">Please add Supplier details</Alert>
              </div>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierdetaillistvalue: state.supplierdetaillistvalue
  };
}

export default connect(
  mapStateToProps,
  { getSupplierDetails }
)(Supplierlist);
