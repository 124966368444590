import { ADD_CHALLAN, CHALLAN_FETCHED } from "../Services/types";

export default function(state = {}, action) {
  console.log(action)
  switch (action.type) {
    case CHALLAN_FETCHED:
    case ADD_CHALLAN:
      return {
        ...state,
        ...action.challanlist.entities.challanlistvalues
      };
    default:
      return state;
  }
}
