
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
class OnHandPage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/transaction_page'>Transaction</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/quantitypage'>Quantity</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>On Hand</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
                
                <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/addonhand'>
                    <i className='fas fa-plus-circle fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>Update On Hand Qty</span>
                    </div>
                    </Link>
                </div>
                </li>

                <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/totalitemquantity'>
                    <i className='fas fa-folder fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>View On hand Qty</span>
                    </div>
                    </Link>
                </div>
                </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default OnHandPage;

