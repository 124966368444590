import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class TransactionPage extends React.Component {
  render() {
    const adminMenu = (
      <React.Fragment>
     

        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/crmlist'>
              <i className='fas fa-users fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>CRM</span>
              </div>
            </Link>
          </div>
        </li>


        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/purchase_orderpage'>
              <i className='fas fa-gift fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Purchase Order</span>
              </div>
            </Link>
          </div>
        </li>
        {/*  */}
        
        {/*  */}

        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/quantitypage'>
              <i className='fas fa-cart-arrow-down fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Inventory</span>
              </div>
            </Link>
          </div>
        </li>

        
   
        
        {/* <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/addquotation'>
              <i className='fas fa-users fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Add Quotation</span>
              </div>
            </Link>
          </div>
        </li>
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/quotationlist'>
              <i className='fas fa-users fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>View Quotation</span>
              </div>
            </Link>
          </div>
        </li> */}
      

        
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/invoice'>
              <i className='fas fa-file-invoice fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Invoice</span>
              </div>
            </Link>
          </div>
        </li>
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/delivery-challan'>
              <i className='fas fa-truck fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Delivery Challan</span>
              </div>
            </Link>
          </div>
        </li>


          <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/manufacturing'>
              <i className='fas fa-industry fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Manufacturing</span>
              </div>
            </Link>
          </div>
        </li>

        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/move-order'>
              <i className='fas fa-forward fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Move Order</span>
              </div>
            </Link>
          </div>
        </li>


        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/logs'>
              <i className='fas fa-book fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Logs</span>
              </div>
            </Link>
          </div>
        </li>
      </React.Fragment>
    );
    const financeMenu = (
      <React.Fragment>
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/invoice'>
              <i className='fas fa-file-invoice fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Invoice</span>
              </div>
            </Link>
          </div>
        </li>
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/delivery-challan'>
              <i className='fas fa-truck fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Delivery Challan</span>
              </div>
            </Link>
          </div>
        </li>
      </React.Fragment>
    );
    const purchaseMenu = (
      <React.Fragment>
        {/* <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/purchase_order'>
              <i className='fas fa-kaaba fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Purchase Order</span>
              </div>
            </Link>
          </div>
        </li>
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/view_purchaseorderlist'>
              <i className='fas fa-kaaba fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>View Purchase Order</span>
              </div>
            </Link>
          </div>
        </li> */}
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/purchase_orderpage'>
              <i className='fas fa-gift fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Purchase Order</span>
              </div>
            </Link>
          </div>
        </li>
      </React.Fragment>
    );
    const salesMenu = (
      <React.Fragment>
        {/* <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/enquiry'>
              <i className='fas fa-envelope fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>New Enquiry</span>
              </div>
            </Link>
          </div>
        </li> */}

        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/crmlist'>
              <i className='fas fa-users fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>CRM</span>
              </div>
            </Link>
          </div>
        </li>
      </React.Fragment>
    );
    const storeMenu = (
      <React.Fragment>
        {/* <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/receipt_screen'>
              <i className='fas fa-dolly-flatbed fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Receive quantity</span>
              </div>
            </Link>
          </div>
        </li>
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/totalitemquantity'>
              <i className='fas fa-users fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>On hand quantity</span>
              </div>
            </Link>
          </div>
        </li> */}
         <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/quantitypage'>
              <i className='fas fa-folder fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Quantity</span>
              </div>
            </Link>
          </div>
        </li>

      </React.Fragment>
    );
    const productionMenu = (
      <React.Fragment>
        
        
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/manufacturing'>
              <i className='fas fa-industry fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Manufacturing</span>
              </div>
            </Link>
          </div>
        </li>

      
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/move-order'>
              <i className='fas fa-forward fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Move Order</span>
              </div>
            </Link>
          </div>
        </li>
      </React.Fragment>
    );
    return (
      <div className='container mg-20'>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Transactions</BreadcrumbItem>
        </Breadcrumb>
        <div className='admin-dashboard-icons' style={{'top': '55%','width': '55%'}}>
          <ul className='clearfix'>
            {this.props.role === 'management'
              ? adminMenu
              : this.props.role === 'finance'
              ? financeMenu
              : this.props.role === 'purchase'
              ? purchaseMenu
              : this.props.role === 'sales'
              ? salesMenu
              : this.props.role === 'stores'
              ? storeMenu
              : this.props.role === 'production'
              ? productionMenu
              : ''}
              {this.props.second_role === 'finance'
               ? financeMenu
               : this.props.second_role === 'purchase'
               ? purchaseMenu
               : this.props.second_role === 'sales'
               ? salesMenu
               : this.props.second_role === 'stores'
               ? storeMenu
               : this.props.second_role === 'production'
               ? productionMenu
               : ''
              }
          </ul>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    role: state.user.role,
    second_role: state.user.second_role
  };
}
export default connect(mapStateToProps)(TransactionPage);
