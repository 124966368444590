import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

class InventoryPage extends React.Component {
  render() {
    return (
      <div className="container mg-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Setup</BreadcrumbItem>
        </Breadcrumb>
        <div className="admin-dashboard-icons" style={{'top': '55%', 'width': '55%'}}>
          <ul className="clearfix">
            {/* <li className="bg-color-lg-blue">
              <div className="icon-eleme">
                <Link to="/mitemtype">
                  <i className="fas fa-dolly-flatbed fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Item Type</span>
                  </div>
                </Link>
              </div>
            </li> */}
            {/* <li className="bg-color-lg-blue">
              <div className="icon-eleme">
                <Link to="/masteritemtypelist">
                  <i className="fas fa-dolly-flatbed fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Item Type List</span>
                  </div>
                </Link>
              </div>
            </li> */}
            {/* 
             */}
            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/itemspage">
                  <i className="fas fa-cart-plus fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Items</span>
                  </div>
                </Link>
              </div>
            </li>

          {/* <li className='bg-colo-lg-red'>
            <div className='icon-eleme'>
              <Link to='/productbilling'>
                <i className='fas fa-kaaba fa-3x'></i>
                <div style={{ paddingTop: '5px' }}>
                  <span>Item Cost</span>
                </div>
              </Link>
            </div>
          </li> */}

      

             <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/bomproductlist">
                  <i className="fa fa-cog fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>BOM</span>
                  </div>
                </Link>
              </div>
            </li> 
            
            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/supplier">
                  <i className="fas fa-cubes fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Suppliers</span>
                  </div>
                </Link>
              </div>
            </li>
            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/customer">
                  <i className="fas fa-users fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Customers</span>
                  </div>
                </Link>
              </div>
            </li>
            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/financialYearAdd">
                  <i className="fas fa-calendar-plus fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Add/Update FinancialYear</span>
                  </div>
                </Link>
              </div>
            </li>            
          </ul>
        </div>
      </div>
    );
  }
}

export default InventoryPage;
