import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactPDF from "@react-pdf/renderer";
import moment from "moment";
import { PDFViewer } from "@react-pdf/renderer";
import { getquotation } from "../../../../actions/quotation";
import MyDocument from "./Component";
import logo from "../../../../Images/logo.png";
import axios from "axios";
import "./ViewQuotation.scss";

class ViewPurchaseOrderPdf extends React.Component {
  state = {
    quotationlistvalues: {},
    status: false,
    text: null
  };
  async componentDidMount() {
    this.props.getquotation();

    const quotationid = this.props.match.params.id;
    const quotationlistvalues = this.props.quotationlistvalues[quotationid];
    quotationlistvalues.requestdeliverydate = moment(
      quotationlistvalues.date
    ).format("DD MMM, YYYY");
    quotationlistvalues.addeditemlist = quotationlistvalues.addeditemlist.map(
      (itemlist, i) => {
        console.log(itemlist)
        itemlist.itemdiscountamount = parseFloat(itemlist.itemdiscountamount)
        itemlist.itemdiscountamount = itemlist.itemdiscountamount.toFixed(2)
        itemlist.quantity = parseInt(itemlist.quantity)
        if(itemlist.itemdiscountamount != '0.00' && itemlist.itemdiscountamount != 'NaN' && itemlist.itemdiscountamount != null) {
          itemlist.totalcost = parseFloat(itemlist.itemdiscountamount) * parseInt(itemlist.quantity);
        }
        else {
          itemlist.totalcost = parseFloat(itemlist.totalcost) * parseInt(itemlist.quantity);  
        }
  
        itemlist.totalcost = parseFloat(itemlist.totalcost)
        itemlist.totalcost = itemlist.totalcost.toFixed(2)

        if(itemlist.itemdiscountamount == '0.00' || itemlist.itemdiscountamount == 'NaN' || itemlist.itemdiscountamount == null) {
          itemlist.itemdiscountamount = "0";
        }

        let schedule = "8-10";
        if(itemlist.scheduledays) {
         schedule = itemlist.scheduledays;
        }

        if(itemlist.itemdescription.length > 200) {
          itemlist.itemdescription = itemlist.itemdescription.substring(0, 200);
        }

        if(itemlist.itemdescription.length < 180) {
          console.log("coming here");
          let totalSpacerequired = 200 - itemlist.itemdescription.length;
          let numSpaces = totalSpacerequired; // Change this to the number of spaces you want to add

          let spaces = " ".repeat(numSpaces); // Create a string of spaces
          itemlist.itemdescription = itemlist.itemdescription + spaces;

          console.log("after adding space", itemlist.itemdescription);
        }

        return {
          slno: (i + 1).toString(),
          itemname: itemlist.itemdescription ? itemlist.itemdescription : "",
          itemimage: itemlist.itemimage != '' && itemlist.itemimage != null ? itemlist.itemimage : "",
          scheduledays: schedule,
          weighteach: itemlist.id.masteritemunitwt && itemlist.id.masteritemunitwt != "undefined" && 
          itemlist.id.masteritemunitwt != "null"
            ? itemlist.id.masteritemunitwt
            : "-",
          itemimage: itemlist.id.masteritemimage
            ? itemlist.id.masteritemimage
            : "",
          quantity: itemlist.quantity ? itemlist.quantity.toString() : "",
          itemdiscount: itemlist.itemdiscount ? 
          itemlist.itemdiscount.toString()
            : "0",
          costperunit: itemlist.costperunit
            ? itemlist.costperunit.toString()
            : "",
            itemdiscountamount:itemlist.itemdiscountamount
            ? itemlist.itemdiscountamount
            : "0",
          totalcost: itemlist.totalcost
            ? itemlist.totalcost
            : ""
        };
      }
    );
    const customerid = quotationlistvalues.customerid;
    const customernumber = quotationlistvalues.customernumber;
    const customername = quotationlistvalues.customername;
    await axios.get(`${process.env.REACT_APP_BASE_URL}/year/getyear`).then(
      (res) => {
        if(res.data.year) {
          quotationlistvalues.financialyear = res.data.year.currentyear;
        }
        else {
          quotationlistvalues.financialyear = "2020-21";          
        }
      },
      (error) => {
        quotationlistvalues.financialyear = "2020-21";
      }
    )
    console.log("--COMPONENTDIDMOUNT--");
    console.log(quotationlistvalues);
    setTimeout(() => {
      this.setState({
        ...this.state.purchaseorderlistvalues,
        quotationlistvalues,
        status: true,
        text: quotationid.toString()+customername.toString()
      });
    }, 2000);
    this.dynamicClick();
  }
  dynamicClick() {
    if (document.getElementsByClassName("pdf-class")) {
      if (
        document.getElementsByClassName("pdf-class")[0].children.length <= 1
      ) {
        setTimeout(() => {
          this.dynamicClick();
        }, 7000);
      } else if (
        document.getElementsByClassName("pdf-class")[0].children.length > 1
      ) {
        this.setState({
          link: document.getElementsByClassName("pdf-class")[0].children[1].src
          // status : undefined,
        });

        setTimeout(() => {
          document.getElementById("D1").click();
          this.props.history.push("/quotationlist");
        }, 200);
    
      }
    } else {
    }
  }



  render() {
    const data_cond = [
      {
        slno: "1",
        title: "Invoices",
        value: "2 copies Invoices (original & duplicate)"
      },
      {
        slno: "2",
        title: "Tax & Duty",
        value: "Included"
      },
      {
        slno: "3",
        title: "Payment Schedule",
        value: "15 days from the date of delivery"
      },
      {
        slno: "4",
        title: "Delivery Schedule",
        value: "Immediately"
      },
      {
        slno: "5",
        title: "Billing address & GST No",
        value:
          "M/s Pondhan Scaffolding Pvt Ltd \n Plot no. 40/41, Road no.5, Obedanahalli,"
      },
      {
        slno: "6",
        title: "Delivery Address",
        value: "M/s Pondhan Scaffolding Pvt Ltd"
      }
    ];
    const { status, quotationlistvalues,link,text } = this.state;
    return (
      <div>
        <div className="pdf-class">
        Downloading file.Please wait.
          <a href={link} download={text} id="D1"></a>
          {status && (
            <PDFViewer>
              <MyDocument
                
                quotationlistvalues={this.state.quotationlistvalues}
                data_cond={data_cond}
                logo={logo}
              />
            </PDFViewer>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    quotationlistvalues: state.quotationlistvalues
  };
}
export default connect(
  mapStateToProps,
  { getquotation }
)(ViewPurchaseOrderPdf);
