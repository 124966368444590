import {
  ADD_CUSTOMER_CONTACT,
  CUSTOMER_CONTACT_FETCHED
} from "../Services/types";

export default function(state = {}, action) {
  console.log(state)
  switch (action.type) {
    case CUSTOMER_CONTACT_FETCHED:
    
    case ADD_CUSTOMER_CONTACT:
      return {
        ...state,
        ...action.customerlist.entities.customerlistvalues
      };
    default:
      return state;
  }
}
