import React from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Label, Alert } from "reactstrap";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class UpdateMaterialForm extends React.Component {
  state = {
    data: {
      materialtype: "",
      materialname: "",
      materialdescription: "",
      materialuom: "",
      costperunit: "",
      materialrate: "1"
    },

    errors: {},
    materialoptionlistvalues: [],
    loading: false,
    success: false
  };

  componentWillReceiveProps(props) {
    const data = props.materialvalues;
    data.materialtype = props.materialvalues.materialtype._id;

    const materialoptionlistvalues = Object.values(
      props.materialoptionlistvalues
    );
    console.log(materialoptionlistvalues);
    this.setState({ ...this.state, materialoptionlistvalues, data });
  }

  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  handleValidSubmit = () => {
    this.setState({ loading: true });
    this.props
      .submit(this.state.data)
      .then(() => {
        this.setState({ loading: false, errors: {}, success: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };

  createSelectItems = () => {
    let items = [];
    const materialoptionlistvalues = this.state.materialoptionlistvalues;
    materialoptionlistvalues.map((option, i) => {
      console.log(option);
      items.push(
        <option key={i} value={option.id}>
          {option.materialname}
        </option>
      );
    });
    return items;
  };

  handleInvalidSubmit = () => {
    console.log("invalid submit");
  };

  render() {
    const { data, errors, loading, success } = this.state;
    return (
      <div>
        <div>
          {!!success ? (
            <Alert color="info">Item is updated successfully.</Alert>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
        >
          <Card>
            <CardHeader>Add Material</CardHeader>
            <CardBody>
              <Row>
                <Col sm={6}>
                  <AvGroup>
                    <Label className='small-font-size' for="materialname">Item Name</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="materialname"
                      type="text"
                      id="materialname"
                      value={data.materialname}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter itemname"
                        },
                     
                        pattern: {
                          value: "^[a-zA-Z]",
                          errorMessage: "Please enter valid materialname"
                        }
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for="materialtype">Item Type</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name="materialtype"
                      onChange={this.onChange}
                      value={data.materialtype}
                    >
                      {/* <option value="5d10915e7ba4981da4e679f6">rawmaterial</option>
                                                <option value="5d10908a7ba4981da4e679f5">subassembly</option> */}
                      {this.createSelectItems()}
                    </AvField>
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for="materialuom">item unit of measure</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name="materialuom"
                      onChange={this.onChange}
                      value={data.materialuom}
                    >
                      <option value="kg">Kg</option>
                      <option value="liter">Liter</option>
                      <option value="ea">EA</option>
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={6}>
                  <AvGroup>
                    <Label className='small-font-size' for="materialdescription">Item Description</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="materialdescription"
                      type="text"
                      id="materialdescription"
                      value={data.materialdescription}
                      onChange={this.onChange}
                      validate={{
                      
                        pattern: {
                          value: "^[a-zA-Z]",
                          errorMessage: "Please enter valid itemdescription"
                        }
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for="materialrate">item Rate</Label>
                    <AvField
                      name="materialrate"
                      type="text"
                      id="materialrate"
                      value={data.materialrate}
                      onChange={this.onChange}
                      validate={{
                 
                        pattern: {
                          value: "^[0-9]*$",
                          errorMessage: "Please enter valid itemrate"
                        }
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for="costperunit">cost per unit</Label>
                    <AvField
                      name="costperunit"
                      type="text"
                      id="costperunit"
                      value={data.costperunit}
                      onChange={this.onChange}
                      validate={{
                    
                        pattern: {
                          value: "^[0-9]*$",
                          errorMessage: "Please enter valid costperunit"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="mg-20">
            <Button color="primary" disabled={loading} className="lgn-loader">
              update
              {/* {loading} */}
            </Button>
          </div>
        </AvForm>
      </div>
    );
  }
}

UpdateMaterialForm.propTypes = {
  submit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    materialoptionlistvalues: state.materialoption
  };
}

export default connect(
  mapStateToProps,
  null
)(UpdateMaterialForm);
