import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import PropTypes from "prop-types";
import PurchaseOrderForm from "./PurchaseOrderForm/PurchaseOrderForm";
import { getSupplierDetails } from "../../../actions/Supplier";
import {
  getMasterItemList,
  addPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrder,
  getMasterItemTypes
} from "../../../actions/Transaction";
import { getCurrencyList } from "../../../actions/Currency";
import { getUomList } from "../../../actions/uom";
import { getLocationList } from "../../../actions/Location";
import CustomLoader from "../../../Components/loader/loader";
import axios from "axios";
class purchaseOrder extends React.Component {
  state = {
    loading: false,
    ID:this.props.match.params.id
  };
  componentDidMount() {
    this.props.getPurchaseOrder();
    this.props.getCurrencyList();
    this.props.getSupplierDetails();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getLocationList();
    
    
  }
  submit = data => {

    this.dataHandler(true);
    if(this.props.match.params.id != undefined){
      
      data['_id'] = this.props.match.params.id;
      console.log(data)
      return this.props
      .updatePurchaseOrder(data)
      .then(res => {
        this.dataHandler(false);
        return res;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
      
      // axios
      // .post(`${process.env.REACT_APP_BASE_URL}/purchaseorder/updatepo`,{
      //   data
      // })
      // .then(res => {
  
      //   this.dataHandler(false)
      //   if(res.data.purchaseorderlist.purchaseorderlistdata.length > 0){
  
      //   }
      //   else{
      //     this.setState({count:0})
      //   }
      // },
      // error =>{
      //   this.dataHandler(false)
      //   this.setState({ success: false, message:'Server error.' });
      // });

      
    }
    else{
      return this.props
      .addPurchaseOrder(data)
      .then(res => {
        this.dataHandler(false);
        return res;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
    }
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading,ID } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/view_purchaseorderlist">Purchase Order</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Purchase Order</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <PurchaseOrderForm submit={this.submit} ID={ID} />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getSupplierDetails,
    getMasterItemList,
    getCurrencyList,
    getUomList,
    getLocationList,
    addPurchaseOrder,
    updatePurchaseOrder,
    getPurchaseOrder,
    getMasterItemTypes
  }
)(purchaseOrder);
