import React from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { getUser } from "../../actions/actions";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import DataTable from "../../Components/Table/DatatablePage";
import moment from "moment";
import { deleteUser } from "../../actions/actions";

class AdminRole extends React.Component {
  state = {
    count: "",
    userdata: []
  };

  updateButton = id => {
    let path = `/updateuser/${id}`;
    this.props.history.push(path);
  };

  deleteButton = id => {
    this.props.deleteUser(id)
    .then((data) => {
      window.location.reload(true);
    })
    .catch((err) => {console.log("could not delete")})
  }

  componentDidMount() {
    this.props.getUser().catch(err => {
      console.log(err);
    });
  }

  componentWillReceiveProps(props) {
    const userlist = Object.values(props.userlist);
    const userdata = userlist.map((userdata, i) => {
      return {
        title: userdata.title,
        name: `${userdata.firstname} ${userdata.lastname}`,
        email: userdata.email,
        phone: userdata.phone,
        startdat: moment(userdata.startdate).format("DD MMM, YYYY"),
        enddate: userdata.enddate ? moment(userdata.enddate).format("DD MMM, YYYY"):" ",
        persontype: userdata.persontype,
        department: userdata.department,
        job: userdata.job,
        location: userdata.location,
        view: (
          <Button
            color="primary"
            onClick={() => {
              this.updateButton(userdata.id);
            }}
          >
            Edit
          </Button>
        ),
        delete: (
          <Button
          color="danger"
          onClick={() => {
            this.deleteButton(userdata.id);
          }}
        >
          Delete
        </Button>
        )
      };
    });
    this.setState({ userdata });
  }
  render() {
    const columnvalue = [
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 75
      },
      {
        label: "Full Name",
        field: "name",
        sort: "asc",
        width: 75
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200
      },
      {
        label: "Start date",
        field: "startdate",
        sort: "asc",
        width: 100
      },
      {
        label: "End date",
        field: "enddate",
        sort: "asc",
        width: 150
      },
      {
        label: "Person type",
        field: "persontype",
        sort: "asc",
        width: 100
      },
      {
        label: "Department",
        field: "department",
        sort: "asc",
        width: 100
      },
      {
        label: "Job",
        field: "job",
        sort: "asc",
        width: 100
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 100
      },
      {
        label: "Edit",
        field: "view",
        width: 100
      },
      {
        label: "Delete",
        field: "delete",
        width: 100        
      }
    ];
    return (
      <div style={{ overflow: "hidden" }} className="mg-t-20">
          <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/admin_dashboard">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>View Users</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <DataTable columnvalue={columnvalue} rowvalues={this.state.userdata} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userlist: state.userlist
  };
}

export default connect(
  mapStateToProps,
  { getUser,deleteUser }
)(AdminRole);
