import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Modal,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { getproduction } from '../../../../actions/Production';
import { Button, Alert } from 'reactstrap';
import DataTable from '../../../../Components/Table/DatatablePage';
import CustomLoader from '../../../../Components/loader/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

class ViewProudction extends React.Component {
  state = {
    count: 0,
    loading: true,
    success: false,
    quantity : '',
    productiondate:'',
    description:'',
    id:'',
    qtyAvaillength:0,
    isViewList:false,
    productionTodayList: [],
    productionlistvalues: []
  };
  componentDidMount() {
    this.props.getproduction();
    console.log(this.props);

    if (this.props.productionlistvalues) {
      let quotationlist = Object.values(this.props.productionlistvalues);
      //quotationlist= quotationlist.sort((a, b) => b.productionorderno.toString().toLowerCase().localeCompare(a.productionorderno.toString().toLowerCase()));
      quotationlist.sort(function(a, b){
        return parseInt(b.productionorderno) - parseInt(a.productionorderno);
      });
      let obj = {}
      let productionlistvalues = quotationlist.map(quotationvalue => {
        obj = {'quantity' : 0,'items' : []}
        if(quotationvalue.products.length > 0){
          
          quotationvalue.products.filter((subitem) =>{
            obj['quantity'] = parseInt(subitem.quantity) > 0 ? obj['quantity'] + parseInt(subitem.quantity) : obj['quantity']
            obj['items'].push(subitem.item)
          })
        }
        quotationvalue['quantity'] = obj['quantity']
        quotationvalue['item'] = obj['items']
        let usedqty = 0;
        if(quotationvalue.transactionHistrory && quotationvalue.transactionHistrory.length > 0){
          quotationvalue.transactionHistrory.map((item) =>{
            usedqty = usedqty + parseInt(item.qty)
          })
        }
        console.log(usedqty)

        var time = '';
        if(quotationlist.productiondate) {
          var created_date = new Date(quotationlist.productiondate);

          var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          var year = created_date.getFullYear();
          var month = months[created_date.getMonth()];
          var date = created_date.getDate();
          var hour = created_date.getHours();
          var min = created_date.getMinutes();
          var sec = created_date.getSeconds();
          time = date + ',' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        }
        
        return {
          productionorderno: quotationvalue.productionorderno,
          item: quotationvalue.item,
          date: time,
          quantity: quotationvalue.quantity,
          customername: quotationvalue.customername,
          view2: quotationvalue.transactionHistrory && quotationvalue.transactionHistrory.length > 0 ? (
            <div className="actionbuttons">

            <a
              color="primary"
              style={{ 'fontSize': '0.8rem','color':'#379ccb' }}
              onClick={() => {
                this.viewTransaction(quotationvalue.transactionHistrory);
              }}
            >
              View Transaction
          </a>
          </div>
          ) : (
            <div className="actionbuttons">

              No Transaction

          </div>
          ),
          view: usedqty < quotationvalue.quantity ? (
            <div className="actionbuttons">

              <Button
                color="primary"
                style={{ 'fontSize': '0.8rem' }}
                onClick={() => {
                  this.addTransaction(quotationvalue,quotationvalue.quantity);
                }}
              >
                Daily Transaction
            </Button>
            </div>
          ) : (
            <div className="actionbuttons">

              You have finished production for all items.

          </div>
          )
          
        };
      });
      this.setState({ ...this.state, productionlistvalues });
      this.dataHandler(false);
    }
  }
  componentWillReceiveProps(props) {
    let quotationlist = Object.values(props.productionlistvalues);
    quotationlist= quotationlist.sort((a, b) => b.productionorderno.toString().toLowerCase().localeCompare(a.productionorderno.toString().toLowerCase()));
    let obj = {}
      let productionlistvalues = quotationlist.map(quotationvalue => {
        obj = {'quantity' : 0,'items' : []}
        if(quotationvalue.products.length > 0){
          
          quotationvalue.products.filter((subitem) =>{
            obj['quantity'] = parseInt(subitem.quantity) > 0 ? obj['quantity'] + parseInt(subitem.quantity) : obj['quantity']
            if(parseInt(subitem.quantity) > 0)
              obj['items'].push(subitem.item)

          })
        }
        if(obj['items'].length > 0){
          obj['items'] = obj['items'].join(", ")
        }
        quotationvalue['quantity'] = obj['quantity']
        quotationvalue['item'] = obj['items']
        let usedqty = 0;
        if(quotationvalue.transactionHistrory && quotationvalue.transactionHistrory.length > 0){
          quotationvalue.transactionHistrory.map((item) =>{
            usedqty = usedqty + parseInt(item.qty)
          })
        }
        console.log(usedqty)

        var time = '';
        if(quotationlist.productiondate) {
          var created_date = new Date(quotationlist.productiondate);

          var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          var year = created_date.getFullYear();
          var month = months[created_date.getMonth()];
          var date = created_date.getDate();
          var hour = created_date.getHours();
          var min = created_date.getMinutes();
          var sec = created_date.getSeconds();
          time = date + ',' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        }

        return {
          productionorderno: quotationvalue.productionorderno,
          item: quotationvalue.item,
          date: time,
          quantity: quotationvalue.quantity,
          customername: quotationvalue.customername,
          view2: quotationvalue.transactionHistrory && quotationvalue.transactionHistrory.length > 0 ? (
            <div className="actionbuttons">

            <a
              color="primary"
              style={{ 'fontSize': '0.8rem','color':'#379ccb' }}
              onClick={() => {
                this.viewTransaction(quotationvalue.transactionHistrory);
              }}
            >
              View Transaction
          </a>
          </div>
          ) : (
            <div className="actionbuttons">

              No Transaction

          </div>
          ),
          view: usedqty < quotationvalue.quantity ? (
            <div className="actionbuttons">

              <Button
                color="primary"
                style={{ 'fontSize': '0.8rem' }}
                onClick={() => {
                  this.addTransaction(quotationvalue,quotationvalue.quantity);
                }}
              >
                Daily Transaction
            </Button>
            </div>
          ) : (
            <div className="actionbuttons">

              You have finished production for all items.

          </div>
          )
        };
      });
    this.setState({ ...this.state, productionlistvalues });
    this.dataHandler(false);
  }

  viewTransaction(arr){
    this.setState({
      isViewList:true,
      productionTodayList : arr
    })
  }
  addTransaction(data,totalqty){
    console.log(data)
    let usedqty = 0
    if(data.transactionHistrory && data.transactionHistrory.length > 0){
      data.transactionHistrory.map((item) =>{
        usedqty = usedqty + parseInt(item.qty)
      })
    }
    this.setState({success:true,id:data._id,quantity:'',productiondate:'',description:'',qtyAvaillength: totalqty - usedqty},() =>{
      console.log(this.state.qtyAvaillength)
    })
  }

  updateTransaction = () => {
    // updateproductionqty
    if(this.state.quantity == '' || this.state.productiondate == ''){
      window.alert('All fields are manditory.')
      return
    }
    if(parseInt(this.state.quantity) > this.state.qtyAvaillength){
      window.alert('Quantity should be less than or equal to : ' + this.state.qtyAvaillength)
      return
    }
    console.log(this.state.id)
    let data = {}
    data['_id'] = this.state.id
    data['transactionHistrory'] = [{'qty' : this.state.quantity,
              'productiondate' : this.state.productiondate,
              'description' : this.state.description}]

    console.log(data)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/production/updateproductionqty`, {
      data
      })
      .then(res => {
          this.toggle();
          this.props.getproduction();
      });
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  toggle2 = () => {
    this.setState({ ...this.state, isViewList: false });
  };
  
  onhandlerChange = e => { 
    this.setState({
      quantity : e.target.value
    });
  };

  onhandlerChange2 = e => { 
    this.setState({
      description : e.target.value
    });
  };

  
  handleChangeDeliveryDate = date => {
    console.log(date)
    this.setState({
      productiondate : date
    });
  };

  render() {
    const { loading, count, isViewList,productionlistvalues,success,quantity,productiondate,description,qtyAvaillength,productionTodayList } = this.state;
    console.log("productionlistvalues is ",productionlistvalues);
    let headerBlock = '',contentBlock = ''
    if(isViewList){
      headerBlock = 
      (
      <div className="row meetingspace">
        <div className="col split1">Quantity</div>
        <div className="col split1">Date</div>
        <div className="col split1">Remarks</div>
      </div>
    )


    contentBlock = productionTodayList.map((value,index) => {
    value['description'] = value.description ? value.description : '-'
    value.productiondate = 
    (new Date(value.productiondate).getDate() < 10 ? ('0' + new Date(value.productiondate).getDate()) : new Date(value.productiondate).getDate()) + '/' +
    (new Date(value.productiondate).getMonth()+1 < 10 ? ('0' + (new Date(value.productiondate).getMonth()+1) ) : (new Date(value.productiondate).getMonth()+1)) + '/' + new Date(value.productiondate).getFullYear()
    return (
      <div key={index} className="row meetingspace">
        <div className="col split2">{value.qty}</div>
        <div className="col split2" >{value.productiondate}</div>
        <div className="col split2" >{value.description}</div>

      </div>
    )
  });

  }




    const columnvalue = [
      {
        label: 'Production no',
        field: 'productionorderno',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Items',
        field: 'item',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Quantity',
        field: 'quantity',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Customer',
        field: 'customername',
        sort: 'asc',
        width: 40
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 40
      },
      {
        label: "View Transaction",
        field: "view",
        sort: "asc",
        width: 30
      },
      {
        label: "Add Transaction",
        field: "view",
        sort: "asc",
        width: 30
      }
    ];
    return (
      <div className='container mg-t-20'>
        {loading ? <CustomLoader /> : null}

         {!!isViewList ? (
            // <Alert color="info">Purchase Order is generated successfully</Alert>
            <Modal
              isOpen={isViewList}
              toggle={this.toggle2}
              className={this.props.className}
            >
              <ModalHeader toggle2={this.toggle2}>View transaction <br/>
              
              </ModalHeader>
              <ModalBody style={{'margin': '10px'}}>
                {headerBlock}
                {contentBlock}
               
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggle2}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}

          {!!success ? (
            // <Alert color="info">Purchase Order is generated successfully</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Add transaction <br/>
              <span style={{ 'fontSize': '14px','fontWeight': 'bold'}}>Note: Available quantity is {qtyAvaillength}</span>
              </ModalHeader>
              <ModalBody>
                
                 <Label className='small-font-size' for="Quantity">Quantity(*)</Label>
                  <input type="text" className="form-control" placeholder="Quantity" name="Quantity" value={quantity} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange} />
                 
                  <Label className='small-font-size' for="productiondate">Production Date(*)</Label>
                  <DatePicker
                  autoComplete="new-password"
                    selected={productiondate}
                    autoComplete="new-password"
                    onChange={this.handleChangeDeliveryDate}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    id="productiondate"
                  />

                 <Label className='small-font-size' for="description">Remarks</Label>
                  <textarea className="form-control" placeholder="Remarks" name="description" value={description} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange2} ></textarea>
                 

              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.updateTransaction}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/transaction_page'>Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/production'>Production</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>List</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <DataTable
            rowvalues={productionlistvalues}
            columnvalue={columnvalue}
            className="productionlist"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productionlistvalues: state.productionlistvalues
  };
}

export default connect(
  mapStateToProps,
  { getproduction }
)(ViewProudction);
