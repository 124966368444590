import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import async from "async";
import {
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Button
} from "reactstrap";
import EditTable from "../../../../Components/EditTable/EditTable";
import {
  getPurchaseOrder,
  searchPoNumber,
  addorupdateitemsinlocation,
  getReceiptlist
} from "../../../../actions/Transaction";
import { getLocationList } from "../../../../actions/Location";
import { getUomList } from "../../../../actions/uom";
import ReceiptScreenForm from "./ReceiptScreenForm/ReceiptScreenForm";
import ReceiptTable from "./ReceiptScreenForm/ReceiptTable";
import CustomLoader from "../../../../Components/loader/loader";

class ReceiptScreen extends React.Component {
  state = {
    count: 0,
    poid: null,
    ponumber: null,
    selectedItem: [],
    success: false,
    receiptlistitems: [],
    loading: false
  };
  componentDidMount() {
    this.props.getPurchaseOrder();
    this.props.getLocationList();
    this.props.getUomList();
    const purchaseorderlist = Object.values(this.props.purchaseorderlistvalues);
    const count = purchaseorderlist.length;
    this.setState({ count });
  }
  componentWillReceiveProps(props) {
    const purchaseorderlist = Object.values(props.purchaseorderlistvalues);
    const count = purchaseorderlist.length;
    this.setState({ count });
  }
  submit = data => {
    this.dataHandler(true);
    this.props.getReceiptlist(data).then(receiptlist => {
      this.dataHandler(false);
      const receiptlistitems = receiptlist.receiptlist.map(receiptvalue => {
        return receiptvalue;
      });
      this.setState({
        ...this.state,
        receiptlistitems: receiptlistitems
      });
    });

    return this.props
      .searchPoNumber(data)
      .then(podata => {
        this.dataHandler(false);
        const selectedItem = podata.addeditemlist.map(poItem => {
          return {
            poitemid: poItem._id,
            itemid: poItem.id,
            costperunit: poItem.costperunit,
            itemdescription: poItem.itemdescription,
            itemlocation: poItem.itemlocation,
            itemname: poItem.itemname,
            itemtype: poItem.itemtype,
            itemuom: poItem.itemuom,
            receivedqty: poItem.receivedqty,
            recevingqty: parseFloat(poItem.receivedqty)
              ? parseFloat(poItem.quantity) - parseFloat(poItem.receivedqty)
              : parseFloat(poItem.quantity),
            quantity: poItem.quantity,
            totalcost: poItem.totalcost
          };
        });
        const ponumber = data.ponumber;
        const poid = podata._id;
        this.setState({
          ...this.state,
          selectedItem,
          ponumber,
          poid,
          success: true
        });
        return podata;
      })
      .catch(err => {
        this.dataHandler(false);
        this.setState({ ...this.state, success: false });
        throw err;
      });
  };

  itemlistsubmit = data => {
    const datavalue = {};
    datavalue.ponumber = data.ponumber;
    this.dataHandler(true);
    this.props.getReceiptlist(datavalue).then(receiptlist => {
      this.dataHandler(false);
      if (receiptlist) {
        const receiptlistitems = receiptlist.receiptlist.map(receiptvalue => {
          return receiptvalue;
        });
        this.setState({
          ...this.state,
          receiptlistitems: receiptlistitems
        });
      }
    });

    return this.props
      .addorupdateitemsinlocation(data)
      .then(async data => {
        this.dataHandler(false);
        const receiptlist = await this.props.getReceiptlist(datavalue);
        const searchporesult = await this.props.searchPoNumber(datavalue);
        data = {
          searchdata: data,
          receiptlist: receiptlist,
          searchporesult: searchporesult
        };
        return data;
      })
      .then(data => {
        console.log(data);
        const receiptlistitems = data.receiptlist.receiptlist.map(
          receiptvalue => {
            return receiptvalue;
          }
        );

        const selectedItem = data.searchporesult.addeditemlist.map(poItem => {
          return {
            poitemid: poItem._id,
            itemid: poItem.id,
            costperunit: poItem.costperunit,
            itemdescription: poItem.itemdescription,
            itemlocation: poItem.itemlocation,
            itemname: poItem.itemname,
            itemtype: poItem.itemtype,
            itemuom: poItem.itemuom,
            receivedqty: poItem.receivedqty,
            recevingqty: parseFloat(poItem.receivedqty)
              ? parseFloat(poItem.quantity) - parseFloat(poItem.receivedqty)
              : parseFloat(poItem.quantity),
            quantity: poItem.quantity,
            totalcost: poItem.totalcost
          };
        });

        this.setState({
          ...this.state,
          receiptlistitems,
          selectedItem
        });

        return data.searchdata;
      });
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };

  savereport = value => {
    sessionStorage.setItem("Receipt",JSON.stringify(value))
    let path = `/viewreceipt`;
    this.props.history.push(path);
  }

  render() {
    const {
      poid,
      ponumber,
      count,
      success,
      selectedItem,
      receiptlistitems,
      loading
    } = this.state;

    const receiptlisttable = receiptlistitems.map(receiptvalue => {
      return (
        <div className="container mg-t-20" key={receiptvalue.id}>
          {loading ? <CustomLoader /> : null}
          <Card>
            <CardHeader>
              <span> Receipt No : {receiptvalue.receiptnumber} </span>
              <span> || </span>
              <span> PO Number : {receiptvalue.ponumber} </span>
              <div style={{ 'float':'right','marginTop': '-8px','marginBottom':'-5px'}}>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.savereport(receiptvalue);
                      }}
                      className="lgn-loader small-font-size"
                      
                    >
                      Save Report
                     
                    </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReceiptTable itemlist={receiptvalue.itemlist} />
            </CardBody>
          </Card>
        </div>
      );
    });

    return (
      <div className="container mg-t-20">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/transaction_page">Transactions</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Receipt Screen</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          {count > 0 ? (
            <div>
              <ReceiptScreenForm submit={this.submit} />
              {success && (
                <EditTable
                  selectedItem={selectedItem}
                  ponumber={ponumber}
                  poid={poid}
                  itemlistsubmit={this.itemlistsubmit}
                />
              )}
              {receiptlistitems.length > 0 && receiptlisttable}
            </div>
          ) : (
              <Alert color="info" Label>Please Add purchase order </Alert>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    purchaseorderlistvalues: state.purchaseorderlistvalue
  };
}

export default connect(
  mapStateToProps,
  {
    getPurchaseOrder,
    searchPoNumber,
    addorupdateitemsinlocation,
    getReceiptlist,
    getLocationList,
    getUomList
  }
)(ReceiptScreen);
