import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import { getCustomerList } from "../../../actions/customerContact";
import ViewCustomerList from "./ViewCustomerList";

class CustomerInfo extends React.Component {
  componentDidMount = () => {
    this.props.getCustomerList();
  };
  render() {
    return (
      <div className="container mg-t-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/inventory_page">Setup</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/customer">Customer</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>customer Information</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <ViewCustomerList />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { getCustomerList }
)(CustomerInfo);
