import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  Label,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

class UpdateCustomerForm extends React.Component {
  state = {
    data: {
      id: "",
      customernumber: "",
      customername: "",
      customercontactnumber: "",
      customergstnumber: "",
      customeraddress1: "",
      customeraddress2: "",
      city: "",
      pincode: "",
      state: "",
      country: "",
      deliveryAddress: {
        address1: '',
        address2: '',
        city: '',
        pincode: '',
        state: '',
        country: ''
      }
    },
    errors: {},
    loading: false,
    success: false,
    visible: false,
    suppliercontactlistvalue: []
  };
  componentDidMount() {
    const id = this.props.id;
    const data = this.props.customerlistvalues[id];
    this.setState({ ...this.state, data });
  }
  componentWillReceiveProps(props) {
    const id = props.id;
    const data = props.customerlistvalues[id];
    this.setState({ ...this.state, data });
  }
  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  onDeliveryAddressChange = e => {
    this.setState({ ...this.state, data: {
      ...this.state.data,
      deliveryAddress:{
        ...this.state.data.deliveryAddress,
        [e.target.name] : e.target.value
      }
    } });
  };
  handleValidSubmit = () => {
    this.setState({ loading: true });
    const { data } = this.state;
    this.props
      .submit(data)
      .then(data => {
        this.setState({ success: true, loading: false, errors: {} });
        this.onShowAlert();
        // this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
        this.onShowAlert();
      });
  };

  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
    });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  render() {
    const { data, errors, loading, success, visible } = this.state;
    return (
      <div className="container mg-t-20">
        <div>
          {!!success ? (
            // <Alert color="info" isOpen={visible}>
            //   Customer is Updated successfully.
            // </Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Item Type</ModalHeader>
              <ModalBody>Customer is Updated successfully.</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
          {!!errors.customernumber ? (
            <Alert color="danger" isOpen={visible}>
              Customer Number should be unique value.
            </Alert>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Customer details</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customernumber">Customer Number</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="customernumber"
                      type="number"
                      id="customernumber"
                      value={data.customernumber}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Customer Number"
                        },
    
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Please enter valid Number"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customername">Customer Name</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="customername"
                      type="text"
                      id="customername"
                      value={data.customername}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Customer Name"
                        },
                    
                        pattern: {
                          value: "^[a-zA-Z ]+$",
                          errorMessage: "Please enter valid Supplier Name"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customercontactnumber">Contact Number </Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="customercontactnumber"
                      type="text"
                      id="customercontactnumber"
                      value={data.customercontactnumber}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Customer contact number"
                        },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage:
                            "Your phone number must be composed only with numbers"
                        },
                        minLength: {
                          value: 10,
                          errorMessage:
                            "Your phone number must be between 10 and 12 numbers"
                        },
                        maxLength: {
                          value: 12,
                          errorMessage:
                            "Your phone number be between 10 and 12 numbers"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customergstnumber">Customer GSTN </Label>
                    <AvField
                      name="customergstnumber"
                      type="text"
                      id="customergstnumber"
                      value={data.customergstnumber}
                      onChange={this.onChange}
                      validate={{
                        minLength: {
                          value: 15,
                          errorMessage:
                            "Customer GST Number should contain 15 characters"
                        },
                        maxLength: {
                          value: 15,
                          errorMessage:
                            "Customer GST Number should contain 15 characters"
                        },
                        pattern: {
                          value: "^[a-zA-z0-9]+$",
                          errorMessage: "Please enter valid GST Number"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Card>
                <CardHeader>Address</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for="customeraddress1">Address 1</Label>
                        <AvField
                          name="customeraddress1"
                          type="text"
                          id="customeraddress1"
                          value={data.customeraddress1}
                          onChange={this.onChange}
                       
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for="customeraddress2">Address 2</Label>
                        <AvField
                          name="customeraddress2"
                          type="text"
                          id="customeraddress2"
                          value={data.customeraddress2}
                          onChange={this.onChange}
                       
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for="city">City</Label>
                        <AvField
                          name="city"
                          type="text"
                          id="city"
                          value={data.city}
                          onChange={this.onChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                "city Name should contain between 1 to 50 characters"
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                "city Name should contain between 1 to 50 characters"
                            },
                            pattern: {
                              value: "^[a-zA-z ]+$",
                              errorMessage: "Please enter valid city Name"
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for="pincode">Pin Code</Label>
                        <AvField
                          name="pincode"
                          type="text"
                          id="pincode"
                          value={data.pincode}
                          onChange={this.onChange}
                          validate={{
                            minLength: {
                              value: 6,
                              errorMessage:
                                "Pincode should contain atleast 6 characters"
                            },
                            maxLength: {
                              value: 10,
                              errorMessage:
                                "Pincode should contain between 6 to 10 characters"
                            },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Please enter valid PinCode"
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for="state">State</Label>
                        <AvField
                          name="state"
                          type="text"
                          id="state"
                          value={data.state}
                          onChange={this.onChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                "State Name should contain between 1 to 50 characters"
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                "State Name should contain between 1 to 50 characters"
                            },
                            pattern: {
                              value: "^[a-zA-z ]+$",
                              errorMessage: "Please enter valid State Name"
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for="country">Country</Label>
                        <AvField
                          name="country"
                          type="text"
                          id="country"
                          value={data.country}
                          onChange={this.onChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                "Country Name should contain between 1 to 50 characters"
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                "Country Name should contain between 1 to 50 characters"
                            },
                            pattern: {
                              value: "^[a-zA-z ]+$",
                              errorMessage: "Please enter valid Country Name"
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                </CardBody>
                {/* Delivery Address */}
                {/* Delivery Address */}
              <Card>
                <CardHeader>Delivery Address</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='address1'>Address 1</Label>
                        <AvField
                          name='address1'
                          type='text'
                          id='customeraddress1'
                          value={data.deliveryAddress.address1}
                          onChange={this.onDeliveryAddressChange}
                        
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='address2'>Address 2</Label>
                        <AvField
                          name='address2'
                          type='text'
                          id='customeraddress2'
                          value={data.deliveryAddress.address2}
                          onChange={this.onDeliveryAddressChange}
                        
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='city'>City</Label>
                        <AvField
                          name='city'
                          type='text'
                          id='city'
                          value={data.deliveryAddress.city}
                          onChange={this.onDeliveryAddressChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'city Name should contain between 1 to 50 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'city Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid city Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='pincode'>Pin Code</Label>
                        <AvField
                          name='pincode'
                          type='text'
                          id='pincode'
                          value={data.deliveryAddress.pincode}
                          onChange={this.onDeliveryAddressChange}
                          validate={{
                            minLength: {
                              value: 6,
                              errorMessage:
                                'Pincode should contain atleast 6 characters'
                            },
                            maxLength: {
                              value: 10,
                              errorMessage:
                                'Pincode should contain between 6 to 10 characters'
                            },
                            pattern: {
                              value: '^[0-9]+$',
                              errorMessage: 'Please enter valid PinCode'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='state'>State</Label>
                        <AvField
                          name='state'
                          type='text'
                          id='state'
                          value={data.deliveryAddress.state}
                          onChange={this.onDeliveryAddressChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'State Name should contain between 1 to 50 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'State Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid State Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='country'>Country</Label>
                        <AvField
                          name='country'
                          type='text'
                          id='country'
                          value={data.deliveryAddress.country}
                          onChange={this.onDeliveryAddressChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'Country Name should contain between 1 to 50 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'Country Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid Country Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              </Card>
              <div className="mg-20">
                <Button
                  color="primary"
                  disabled={loading}
                  className="lgn-loader"
                >
                  Update
                  {/* {loading} */}
                </Button>
              </div>
            </CardBody>
          </Card>
        </AvForm>{" "}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerlistvalues: state.customerlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(UpdateCustomerForm);
