import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import React from 'react';
import { connect } from 'react-redux';
import {
  Alert, Button, Card,
  CardBody,
  CardHeader, Col, Label, Modal, ModalBody,
  ModalFooter, ModalHeader, Row
} from 'reactstrap';

class CustomerForm extends React.Component {
  state = {
    data: {
      customernumber: '',
      customername: '',
      customercontactnumber: '',
      customergstnumber: '',
      customeraddress1: '',
      customeraddress2: '',
      city: '',
      pincode: '',
      state: '',
      country: '',
      codate: new Date().getTime(),
      deliveryAddress: {
        address1: '',
        address2: '',
        city: '',
        pincode: '',
        state: '',
        country: ''
      }
    },
    errors: {},
    loading: false,
    success: false,
    visible: false,
    sameAsBilling: false,
    suppliercontactlistvalue: []
  };
  componentWillReceiveProps(props) {
    const customerlistvalues = Object.keys(props.customerlistvalues);
    const count = customerlistvalues.length;
    const customernumber = count ? 1001 + count : 1001;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customernumber
      }
    });
  }
  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  onDeliveryAddressChange = e => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        deliveryAddress: {
          ...this.state.data.deliveryAddress,
          [e.target.name]: e.target.value
        }
      }
    });
  };
  sameAsBilling = e => {
    let val = e.target.value === 'false' ? true : false;
    this.setState({ ...this.state, sameAsBilling: val });
    if (val) {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          deliveryAddress: {
            address1: this.state.data.customeraddress1,
            address2: this.state.data.customeraddress2,
            city: this.state.data.city,
            pincode: this.state.data.pincode,
            state: this.state.data.state,
            country: this.state.data.country
          }
        }
      });
    }
  };
  handleValidSubmit = () => {
    this.setState({ loading: true });
    const { data } = this.state;
    console.log(JSON.stringify(data));
    this.props
      .submit(data)
      .then(data => {
        this.setState({ success: true, loading: false, errors: {} });
        this.onShowAlert();
        this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
        this.onShowAlert();
      });
  };

  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
    });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  render() {
    const {
      data,
      errors,
      loading,
      success,
      visible,
      sameAsBilling
    } = this.state;
    return (
      <div>
        <div>
          {!!success ? (
            // <Alert color="info" isOpen={visible}>
            //   Customer is added successfully.
            // </Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Item Type</ModalHeader>
              <ModalBody>Customer is added successfully</ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
          {!!errors.customernumber ? (
            <Alert color='danger' isOpen={visible}>
              Customer Number should be unique value.
            </Alert>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Customer Details</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='customernumber'>Customer Number</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='customernumber'
                      type='number'
                      id='customernumber'
                      value={data.customernumber.toString()}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Customer Number'
                        },
                        pattern: {
                          value: '^[0-9]+$',
                          errorMessage: 'Please enter valid Number'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='customername'>Customer Name</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='customername'
                      type='text'
                      id='customername'
                      value={data.customername}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Customer Name'
                        }
                    
                        // pattern: {
                        //   value: '^[a-zA-Z ]+$',
                        //   errorMessage: 'Please enter valid Supplier Name'
                        // }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='customercontactnumber'>Contact Number </Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='customercontactnumber'
                      type='text'
                      id='customercontactnumber'
                      value={data.customercontactnumber}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Customer contact number'
                        },
                        pattern: {
                          value: '^[0-9]+$',
                          errorMessage:
                            'Your phone number must be composed only with numbers'
                        },
                        minLength: {
                          value: 10,
                          errorMessage:
                            'Your phone number must be between 10 and 12 numbers'
                        },
                        maxLength: {
                          value: 12,
                          errorMessage:
                            'Your phone number be between 10 and 12 numbers'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='customergstnumber'>Customer GSTN </Label>
                    <AvField
                      name='customergstnumber'
                      type='text'
                      id='customergstnumber'
                      value={data.customergstnumber}
                      onChange={this.onChange}
                      validate={{
                        minLength: {
                          value: 15,
                          errorMessage:
                            'Customer GST Number should contain 15 characters'
                        },
                        maxLength: {
                          value: 15,
                          errorMessage:
                            'Customer GST Number should contain 15 characters'
                        },
                        pattern: {
                          value: '^[a-zA-z0-9]+$',
                          errorMessage: 'Please enter valid GST Number'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Card>
                <CardHeader>Billing Address</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='customeraddress1'>Address 1</Label>
                        <AvField
                          name='customeraddress1'
                          type='text'
                          id='customeraddress1'
                          value={data.customeraddress1}
                          onChange={this.onChange}
                      
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='customeraddress2'>Address 2</Label>
                        <AvField
                          name='customeraddress2'
                          type='text'
                          id='customeraddress2'
                          value={data.customeraddress2}
                          onChange={this.onChange}
                        
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='city'>City</Label>
                        <AvField
                          name='city'
                          type='text'
                          id='city'
                          value={data.city}
                          onChange={this.onChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'city Name should contain between 1 to 50 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'city Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid city Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='pincode'>Pin Code</Label>
                        <AvField
                          name='pincode'
                          type='text'
                          id='pincode'
                          value={data.pincode}
                          onChange={this.onChange}
                          validate={{
                            minLength: {
                              value: 6,
                              errorMessage:
                                'Pincode should contain atleast 6 characters'
                            },
                            maxLength: {
                              value: 10,
                              errorMessage:
                                'Pincode should contain between 6 to 10 characters'
                            },
                            pattern: {
                              value: '^[0-9]+$',
                              errorMessage: 'Please enter valid PinCode'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='state'>State</Label>
                        <AvField
                          name='state'
                          type='text'
                          id='state'
                          value={data.state}
                          onChange={this.onChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'State Name should contain between 1 to 50 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'State Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid State Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='country'>Country</Label>
                        <AvField
                          name='country'
                          type='text'
                          id='country'
                          value={data.country}
                          onChange={this.onChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'Country Name should contain between 1 to 50 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'Country Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid Country Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {/* Delivery Address */}
              <Card>
                <CardHeader>
                  Delivery Address ({' '}
                  <input
                    type='checkbox'
                    className='same_checkbox'
                    value={sameAsBilling}
                    name='same'
                    onChange={this.sameAsBilling}
                  ></input>{' '}
                  Same as billing address)
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='address1'>Address 1</Label>
                        <AvField
                          name='address1'
                          type='text'
                          id='customeraddress1'
                          value={data.deliveryAddress.address1}
                          onChange={this.onDeliveryAddressChange}
                        
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='address2'>Address 2</Label>
                        <AvField
                          name='address2'
                          type='text'
                          id='customeraddress2'
                          value={data.deliveryAddress.address2}
                          onChange={this.onDeliveryAddressChange}
                         
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='city'>City</Label>
                        <AvField
                          name='city'
                          type='text'
                          id='city'
                          value={data.deliveryAddress.city}
                          onChange={this.onDeliveryAddressChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'city Name should contain atleast 1 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'city Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid city Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='pincode'>Pin Code</Label>
                        <AvField
                          name='pincode'
                          type='text'
                          id='pincode'
                          value={data.deliveryAddress.pincode}
                          onChange={this.onDeliveryAddressChange}
                          validate={{
                            minLength: {
                              value: 6,
                              errorMessage:
                                'Pincode should contain atleast 6 characters'
                            },
                            maxLength: {
                              value: 10,
                              errorMessage:
                                'Pincode should contain between 6 to 10 characters'
                            },
                            pattern: {
                              value: '^[0-9]+$',
                              errorMessage: 'Please enter valid PinCode'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='state'>State</Label>
                        <AvField
                          name='state'
                          type='text'
                          id='state'
                          value={data.deliveryAddress.state}
                          onChange={this.onDeliveryAddressChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'State Name should contain between 1 to 50 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'State Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid State Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='country'>Country</Label>
                        <AvField
                          name='country'
                          type='text'
                          id='country'
                          value={data.deliveryAddress.country}
                          onChange={this.onDeliveryAddressChange}
                          validate={{
                            minLength: {
                              value: 1,
                              errorMessage:
                                'Country Name should contain between 1 to 50 characters'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                'Country Name should contain between 1 to 50 characters'
                            },
                            pattern: {
                              value: '^[a-zA-z ]+$',
                              errorMessage: 'Please enter valid Country Name'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className='mg-20'>
                <Button
                  color='primary'
                  disabled={loading}
                  className='lgn-loader'
                >
                  Submit
                  {/* {loading} */}
                </Button>
              </div>
            </CardBody>
          </Card>
        </AvForm>{' '}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerlistvalues: state.customerlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(CustomerForm);
