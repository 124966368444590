import { schema } from 'normalizr';

export const userSchema = new schema.Entity('users', {}, { idAttribute: 'id' });

export const uomListSchema = new schema.Entity(
  'uomlistvalue',
  {},
  { idAttribute: 'id' }
);

export const materialOptionSchema = new schema.Entity(
  'materialoptions',
  {},
  { idAttribute: 'id' }
);

export const materialListSchema = new schema.Entity(
  'materialslistvalue',
  {},
  { idAttribute: 'id' }
);

export const productListSchema = new schema.Entity(
  'productlistvalues',
  {},
  { idAttribute: 'id' }
);

export const masterItemTypeNameSchema = new schema.Entity(
  'masteritemtypenamevalue',
  {},
  { idAttribute: 'id' }
);

export const masterItemTypeListSchema = new schema.Entity(
  'masteritemtypelistvalues',
  {},
  { idAttribute: 'id' }
);

export const supplierContactListSchema = new schema.Entity(
  'suppliercontactlistvalue',
  {},
  { idAttribute: 'id' }
);

export const supplierDetailListSchema = new schema.Entity(
  'supplierdetaillistvalue',
  {},
  { idAttribute: 'id' }
);

export const currencyListSchema = new schema.Entity(
  'currencylistvalue',
  {},
  { idAttribute: 'id' }
);
export const locationListSchema = new schema.Entity(
  'locationlistvalue',
  {},
  { idAttribute: 'id' }
);

export const purchaseOrderListSchema = new schema.Entity(
  'purchaseorderlistvalue',
  {},
  { idAttribute: 'id' }
);

export const receiptListSchema = new schema.Entity(
  'receiptlistvalues',
  {},
  { idAttribute: 'id' }
);

export const customerListSchema = new schema.Entity(
  'customerlistvalues',
  {},
  { idAttribute: 'id' }
);

export const customerTransactionSchema = new schema.Entity(
  'customertransationvalues',
  {},
  { idAttribute: 'customerid' }
);

export const quotationListSchema = new schema.Entity(
  'quotationlistvalues',
  {},
  { idAttribute: '_id' }
);

export const invoiceListSchema = new schema.Entity(
  'invoicelistvalues',
  {},
  { idAttribute: '_id' }
);

export const proformainvoiceListSchema = new schema.Entity(
  'proformainvoicelistvalues',
  {},
  { idAttribute: '_id' }
);

export const challanListSchema = new schema.Entity(
  'challanlistvalues',
  {},
  { idAttribute: '_id' }
);
export const orderListSchema = new schema.Entity(
  'orderlistvalues',
  {},
  { idAttribute: '_id' }
);
export const productionListSchema = new schema.Entity(
  'productionlistvalues',
  {},
  { idAttribute: '_id' }
);
export const moveorderListSchema = new schema.Entity(
  'moveorderlistvalues',
  {},
  { idAttribute: '_id' }
);
