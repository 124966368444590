import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import UpdateCustomerForm from "./UpdateCustomer/CustomerForm/UpdateCustomerForm";
import {
  updateCustomerContact,
  getCustomerList
} from "../../../actions/customerContact";
import CustomLoader from "../../../Components/loader/loader";
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";

class Customer extends React.Component {
  state = {
    id: "",
    loading: false
  };
  componentDidMount = () => {
    this.props.getCustomerList();
    const id = this.props.match.params.id;
    this.setState({ ...this.state, id });
  };
  submit = data => {
    this.dataHandler(true);
    return this.props
      .updateCustomerContact(data)
      .then(data => {
        this.dataHandler(false);
        this.props.getCustomerList();
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { id, loading } = this.state;
    return (
      <div className="container">
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/inventory_page">Setup</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/customerinfo">Customer List</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Update Customer</BreadcrumbItem>
        </Breadcrumb>
        <div>

          <UpdateCustomerForm submit={this.submit} id={id} />

           
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { updateCustomerContact, getCustomerList }
)(Customer);
