import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPDF from '@react-pdf/renderer';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import { getorder } from '../../../../actions/order';
import MyDocument from './Component';
import logo from '../../../../Images/logo.png';
import './ViewOrder';

class ViewOrderPdf extends React.Component {
  state = {
    orderlistvalues: {},
    status: false,
    link: ''
  };
  componentDidMount() {
    this.props.getorder();
    const quotationid = this.props.match.params.id;
    console.log(this.props.orderlistvalues);
    const orderlistvalues = this.props.orderlistvalues[quotationid];
    orderlistvalues.requestdeliverydate = moment(orderlistvalues.date).format(
      'DD MMM, YYYY'
    );
    orderlistvalues.deliveryschedule = moment(
      orderlistvalues.deliveryschedule
    ).format('DD MMM, YYYY');
    
    orderlistvalues.orderdate = moment(
      orderlistvalues.orderdate
    ).format('DD MMM, YYYY');

    orderlistvalues.addeditemlist = orderlistvalues.addeditemlist.map(
      (itemlist, i) => {

        if(itemlist.itemdescription.length < 340) {
          console.log("coming here");
          let totalSpacerequired = 340 - itemlist.itemdescription.length;
          let numSpaces = totalSpacerequired; // Change this to the number of spaces you want to add

          let spaces = " ".repeat(numSpaces); // Create a string of spaces
          itemlist.itemdescription = itemlist.itemdescription + spaces;

          console.log("after adding space", itemlist.itemdescription);
        }

        return {
          slno: (i + 1).toString(),
          itemname: itemlist.itemdescription ? itemlist.itemdescription : '',
          weighteach: itemlist.id.masteritemunitwt
            ? itemlist.id.masteritemunitwt
            : '',
          itemimage: itemlist.id.masteritemimage
            ? itemlist.id.masteritemimage
            : '',
          quantity: itemlist.quantity ? itemlist.quantity.toString() : '',
          // itemdiscount: itemlist.itemdiscount.toString()
          //   ? itemlist.itemdiscount
          //   : "0",
          remarks: itemlist.remarks ? itemlist.remarks.toString() : '',
          totalweight: itemlist.totalweight
            ? itemlist.totalweight.toString()
            : '',
          readystock: itemlist.readystock ? itemlist.readystock.toString() : '',
          firstround: itemlist.firstround ? itemlist.firstround.toString() : '',
          secondround: itemlist.secondround
            ? itemlist.secondround.toString()
            : '',
          thirdround: itemlist.thirdround ? itemlist.thirdround.toString() : '',
          unit: itemlist.itemuom ? itemlist.itemuom.uomname.toString() : ''
        };
      }
    );
    const customerid = orderlistvalues.customerid;
    const customernumber = orderlistvalues.customernumber;
    const customername = orderlistvalues.customername;
    console.log('--COMPONENTDIDMOUNT--');
    console.log(orderlistvalues);
    setTimeout(() => {
      this.setState({
        ...this.state.purchaseorderlistvalues,
        orderlistvalues,
        status: true
      });
    },5000);
    this.dynamicClick();
  }
  dynamicClick() {
    if (document.getElementsByClassName('pdf-class')) {
      if (
        document.getElementsByClassName('pdf-class')[0].children.length <= 1
      ) {
        setTimeout(() => {
          this.dynamicClick();
        }, 4000);
      } else if (
        document.getElementsByClassName('pdf-class')[0].children.length > 1
      ) {
        this.setState({
          link: document.getElementsByClassName('pdf-class')[0].children[1].src
          // status : undefined,
        },()=>{
          // setTimeout(() => {
          //   document.getElementById('D1').click();
          //   this.props.history.push('/orderlist');
          // }, 300);
        });

      }
    } else {
    }
  }
  render() {
    const data_cond = [
      {
        slno: '1',
        title: 'Invoices',
        value: '2 copies Invoices (original & duplicate)'
      },
      {
        slno: '2',
        title: 'Tax & Duty',
        value: 'Included'
      },
      {
        slno: '3',
        title: 'Payment Schedule',
        value: '15 days from the date of delivery'
      },
      {
        slno: '4',
        title: 'Delivery Schedule',
        value: 'Immediately'
      },
      {
        slno: '5',
        title: 'Billing address & GST No',
        value:
          'M/s Pondhan Scaffolding Pvt Ltd \n Plot no. 40/41, Road no.5, Obedanahalli,'
      },
      {
        slno: '6',
        title: 'Delivery Address',
        value: 'M/s Pondhan Scaffolding Pvt Ltd'
      }
    ];
    const { status, orderlistvalues, link } = this.state;
    console.log(this.state.orderlistvalues);
    return (
      <div>
        <div className='pdf-class'>
          Downloading file.Please wait.
          {/* {!status && (
          <h1 style={{ 'position': 'absolute','left': '50%','top': '50%','transform': 'translate(-50%, -50%);'}}>Downloading file.Please wait.</h1>
        )} */}
          <a href={link} download id='D1'></a>
          {status && (
            <PDFViewer>
              <MyDocument
                orderlistvalues={this.state.orderlistvalues}
                data_cond={data_cond}
                logo={logo}
              />
            </PDFViewer>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    orderlistvalues: state.orderlistvalues
  };
}
export default connect(
  mapStateToProps,
  { getorder }
)(ViewOrderPdf);
