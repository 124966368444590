import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
  getMasterItemList,
  getMasterItemTypes
} from "../../../../actions/Transaction";
import { connect } from "react-redux";
import { getUomList } from "../../../../actions/uom";
import { getCustomerList } from "../../../../actions/customerContact";
import { addinvoice } from "../../../../actions/Invoice";
import { getinvoice } from "../../../../actions/Invoice";
import { updateinvoice } from "../../../../actions/Invoice";

import { getChallan } from '../../../../actions/challan';
import { getquotation } from "../../../../actions/quotation";
import InvoiceForm from "./InvoiceForm";
import CustomLoader from "../../../../Components/loader/loader";
import axios from 'axios';

class AddInvoice extends React.Component {
  state = {
    loading: false,
    ID:this.props.match.params.id
  };
  componentDidMount() {
    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getinvoice();
    this.props.getChallan();
    this.props.getquotation();
  }
  valid = () =>{
    if( !localStorage.getItem('firstLoad') )
    {
      localStorage['firstLoad'] = true;
      window.location.reload();
    }  
    else
      localStorage.removeItem('firstLoad');
  }
  submit = data => {
    console.log(data)
    this.dataHandler(true);
      if(this.props.match.params.id != undefined){
        data['_id'] = this.props.match.params.id;
        return this.props
        .updateinvoice(data)
        .then(submitdata => {
          let obj = {};
          obj['_id'] = data.transactionid;
          obj['invoice_link'] = "/view_invoicepdf/"+submitdata.invoicelist.result;
          axios
          .put(`${process.env.REACT_APP_BASE_URL}/customerTransaction/updatecustomertransaction`,obj)
          .then(res => {
            return submitdata;
          },
          error =>{
          });
          this.dataHandler(false);
          return data;
        })
        .catch(err => {
          this.dataHandler(false);
          throw err;
        });
      }
      else{
        return this.props
        .addinvoice(data)
        .then(submitdata => {
          let obj = {};
          obj['_id'] = data.transactionid;
          obj['invoice_link'] = "/view_invoicepdf/"+submitdata.invoicelist.result;
          axios
          .put(`${process.env.REACT_APP_BASE_URL}/customerTransaction/updatecustomertransaction`,obj)
          .then(res => {
            return submitdata;
          },
          error =>{
          });
          this.dataHandler(false);
          return submitdata;
        })
        .catch(err => {
          this.dataHandler(false);
          throw err;
        });
      }
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading,ID } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/transaction_page">Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/invoice">Invoice</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Invoice</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <InvoiceForm submit={this.submit} ID={ID} valid={this.valid}/>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    addinvoice,
    updateinvoice,
    getCustomerList,
    getinvoice,
    getChallan,
    getquotation
  }
)(AddInvoice);
