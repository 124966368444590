import { normalize } from "normalizr";
import api from "../Services/customerapi";
import {
  ADD_CUSTOMER_CONTACT,
  CUSTOMER_CONTACT_FETCHED,
  CUSTOMERTRANSACTION_FETCHED
} from "../Services/types";
import { customerListSchema,customerTransactionSchema } from "../Schemas/schemas";

const customerCreated = customerlist => {
  return {
    type: ADD_CUSTOMER_CONTACT,
    customerlist
  };
};

const customerListFetched = customerlist => {

  return {
    type: CUSTOMER_CONTACT_FETCHED,
    customerlist
  };
};

const customerTransactionFetched = customerlist => {

  return {
    type: CUSTOMERTRANSACTION_FETCHED,
    customerlist
  };
};

export const addCustomerContact = data => {
  return dispatch => {
    return api.customer.addcustomer(data).then(customerlistvalues => {
      return dispatch(
        customerCreated(normalize(customerlistvalues, customerListSchema))
      );
    });
  };
};

export const getCustomerList = () => {
  return dispatch => {
    return api.customer.getcustomer().then(customerlist => {
      const customerlistvalue = customerlist.customerdata;
      return dispatch(
        customerListFetched(normalize(customerlistvalue, [customerListSchema]))
      );
    });
  };
};

export const getCustomerTransaction = (id) => {
  return dispatch => {
    return api.customerTransaction.getcustomerTransaction(id).then(customerlist => {
      const customertransationvalues = customerlist.customerdata;
      console.log(customertransationvalues)
      if(customertransationvalues.length != 0){
        return dispatch(
          customerTransactionFetched(normalize(customertransationvalues, [customerTransactionSchema]))
        );
      }
      // console.log(normalize(customertransationvalues, [customerTransactionSchema]))

    });
  };
};

export const updateCustomerContact = data => {
  return dispatch => {
    return api.customer.updatecustomer(data).then(customerlistvalues => {
      return customerlistvalues;
      // return dispatch(
      //   customerCreated(normalize(customerlistvalues, customerListSchema))
      // );
    });
  };
};
