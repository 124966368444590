import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import ViewCustomerList from "./ViewCustomerList";
import {
  addCustomerContact,
  getCustomerList
} from "../../../actions/customerContact";

class ViewCustomerTable extends React.Component {
  componentDidMount = () => {
    this.props.getCustomerList();
  };

  render() {
    return (
      <div className="container mg-t-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <Link to="/crmlist">CRM</Link>
          </BreadcrumbItem>

          <BreadcrumbItem active>Customer Transaction</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <ViewCustomerList />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { getCustomerList }
)(ViewCustomerTable);
