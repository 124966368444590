import React from "react";
import { Alert, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { getproducts, deleteProduct } from "../../actions/Product";
import DataTable from "../../Components/Table/DatatablePage";
import CustomLoader from "../../Components/loader/loader";

class ViewProductList extends React.Component {
  state = {
    loading: false,
    count: "",
    showModal: false,
    productlistvalues: "",
    setDeleteId: ""
  };
  componentDidMount() {
    this.props.getproducts();
    this.dataHandler(true);
  }
  updateButton = id => {
    let path = `/viewproduct/${id}`;
    this.props.history.push(path);
  };
  editButton = id => {
    let path = `/updateproduct/${id}`;
    this.props.history.push(path);
  };
  deleteItem = () => {
  return this.props.deleteProduct(this.state.setDeleteId)
    .then((data) => {
      setTimeout(() => {
        window.location.reload(true);
      }, 300);
    })
    .catch((err) => {console.log("could not delete")})
  };
  componentWillReceiveProps(props) {
    const productlist = Object.values(props.productlistvalues);
    const productlistvalues = productlist.map((productdata, i) => {
      console.log(productdata)
      return {
        materialname: productdata.productname,
        view: (
          <Button
            Label
            color="primary"
            onClick={() => {
              this.updateButton(productdata.id);
            }}
          >
            View Sub assembly
          </Button>
        ),
        edit: (
          <Button
            Label
            color="primary"
            onClick={() => {
              this.editButton(productdata.id);
            }}
          >
            Edit
          </Button>
        ),
        delete: (
          <Button
            Label
            color="danger"
            onClick={() => {
              this.setState({ ...this.state, showModal: true, setDeleteId: productdata.id });
            }}
          >
            Delete
          </Button>
        )
      };
    });
    const count = productlistvalues.length;
    this.setState({ ...this.state, count, productlistvalues });
    this.dataHandler(false);
  }
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading, count, productlistvalues, showModal } = this.state;
    const columnvalue = [
      {
        label: "Product Name",
        field: "materialname",
        sort: "asc",
        width: 10
      },
      {
        label: "View",
        field: "view",
        sort: "asc",
        width: 10
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 10
      },
      {
        label: "Delete",
        field: "delete",
        sort: "asc",
        width: 10
      }
    ];
    return (
      <div className="container mg-20">
        {loading ? <CustomLoader /> : null}
        {showModal ? (
                      <Modal
                      isOpen={showModal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle}>Delete</ModalHeader>
                      <ModalBody>Do you want to delete this product?</ModalBody>
                      <ModalFooter>
                        <Button color='primary' onClick={this.deleteItem}>
                          Yes
                        </Button>
                        <Button color='primary' onClick={this.toggle}>
                          No
                        </Button>                        
                      </ModalFooter>
                    </Modal>
        ) : null
        }
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>View Products</BreadcrumbItem>
          </Breadcrumb>
        </div>
        {count > 0 ? (
          <DataTable columnvalue={columnvalue} 
          rowvalues={productlistvalues}
          className="viewproductlist" />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productlistvalues: state.productlistvalues
  };
}

export default connect(
  mapStateToProps,
  { getproducts, deleteProduct }
)(ViewProductList);
