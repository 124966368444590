import { normalize } from "normalizr";
import api from "../Services/quotationapi";
import { ADD_QUOTATION, QUOTATION_FETCHED } from "../Services/types";
import { quotationListSchema } from "../Schemas/schemas";

const quotationCreated = quotationlist => {
  return {
    type: ADD_QUOTATION,
    quotationlist
  };
};

const quotationFetched = quotationlist => {
  return {
    type: QUOTATION_FETCHED,
    quotationlist
  };
};

export const addquotation = data => {
  return dispatch => {
    return api.quotation.addquotation(data).then(quotationlistvalues => {
      return dispatch(
        quotationCreated(normalize(quotationlistvalues, quotationListSchema))
      );
    });
  };
};

export const updatequotation = data => {
  return dispatch => {
    return api.quotation.updatequotation(data).then(quotationlistvalues => {
      return dispatch(
        quotationCreated(normalize(quotationlistvalues, quotationListSchema))
      );
    });
  };
};



export const getquotation = () => {
  return dispatch => {
    return api.quotation.getquotation().then(quotationlist => {
      const quotationlistvalues = quotationlist.quotationdata;
      return dispatch(
        quotationFetched(normalize(quotationlistvalues, [quotationListSchema]))
      );
    });
  };
};

export const searchquotation = customerid => {
  return dispatch => {
    return api.quotation.searchquotation(customerid);
  };
};
