export const Roles = {
  ADMIN: 'management',
  EMPLOYEE: 'employee',
  ITADMIN: 'itadmin',
  FINANCE: 'finance',
  PURCHASE: 'purchase',
  SALES: 'sales',
  STORE: 'stores',
  PRODUCTION:'production'
};
