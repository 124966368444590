import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import Select from 'react-select';
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import CustomLoader from "../../../../Components/loader/loader";
class ChallanForm extends React.Component {
  state = {
    data: {
      invoicenumber: null,
      transactionid: null,
      invoiceid: "",
      selectedItemlist: {
        id: [],
        itemname: [],
        itemdescription: [],
        itemtype: [null],
        itemrate: [null],
        itemuom: [],
        itemlocation: [null],
        costperunit: [],
        quantity: [],
        totalcost: [],
        remarks: []
      },
      customerdetail: {
        id: "",
        customername: "",
        customernumber: '',
        customercontactnumber: '',
        customergstnumber: '',
        customeraddress1: '',
        customeraddress2: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        deliveryAddress: {
          address1: '',
          address2: '',
          city: '',
          pincode: '',
          state: '',
          country: ''
        }
      },
      deliverychallanno: '',
      toaddress: '',
      mobilenumber: '',
      site: '',
      date: new Date(),
      ponumber: '',
      podate: new Date(),
      vehiclenumber: '',
      gstno: '',
      otherreference: '',
      values: ['']
    },
    errors: {},
    selectedCustomerID: null,
    selectedCustomerName: null,
    selectedInvoiceId: null,
    selectedInvoiceNumber: null,
    customerobjects: {},
    materialoptionlistvalues: [],
    supplierdetaillistvalue: [],
    supplierdetaillistvalueobject: {},
    masteritemlistvalues: [],
    invoicelistvalues: [],
    invoicelistselectedOptions: [],
    itemlistSelectedOption: [],
    masteritemlistvaluesobject: {},
    currencylistvalues: [],
    customerlistvalues: [],
    customerlistSelect: [],
    uom: [],
    locationlistvalue: [],
    loading: false,
    success: false,
    successmsg:'',
    counts:1
  };

  componentDidMount() {
  
      const materialoptionlistvalues = Object.values(
        this.props.materialoptionlistvalues
      );
      const masteritemlistvalues = Object.values(this.props.masteritemlistvalues);
      const masteritemlistvaluesobject = this.props.masteritemlistvalues;
      const currencylistvalues = Object.values(this.props.currencylistvalues);
      const invoicelistvalues = Object.values(this.props.invoicelistvalues);
      const uomlistvalues = Object.values(this.props.uomlistvalues);
      const locationlistvalue = Object.values(this.props.locationlistvalue);
      const purchaseorderlist = Object.keys(this.props.purchaseorderlistvalues);
      const customerlistvalues = Object.values(this.props.customerlistvalues);
      const customerobjects = this.props.customerlistvalues;


      let newArraySet = [];
      masteritemlistvalues.map((option, i) => {
        let name = "";
        if(option.masteritemshortdescription) {
          name = option.masteritemshortdescription;
        }
        else {
          name = option.masteritemdescription;
        }
        let objset = {
          value: option.id, label: name
        }
        newArraySet.push(objset);
      });
      
      let arrayInvoiceSet = [];
      invoicelistvalues.map((option, i) => {
        let objset = {
          value: option._id, label: option.invoicenumber
        }
        arrayInvoiceSet.push(objset);
      });

      this.setState({
        ...this.state,
        masteritemlistvalues,
        masteritemlistvaluesobject,
        materialoptionlistvalues,
        invoicelistvalues,
        invoicelistselectedOptions: arrayInvoiceSet,
        itemlistSelectedOption: newArraySet,
        currencylistvalues,
        locationlistvalue,
        customerlistvalues,
        customerobjects,
        uom: uomlistvalues,
        data: {
          ...this.state.data
        }
      },() =>{
        if (this.props.ID != undefined) {
          this.getDCDetails(this.props.ID)
        }
        else{
          //if(Object.keys(this.props.challanlistvalues).length == 0){
            this.getChallandata()
          //}
        }
      });
    
  }

  getChallandata(){
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/challan/getchallan`)
    .then(res => {
      // if (res.data.challanlist.challandata.length > 0) {
         
        let deliverychallanno =
        res.data.challanlist.challandata.length > 0 ? 1 + res.data.challanlist.challandata.length : 1;

        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            deliverychallanno
          }
        });
      //}
    },
      error => {
        this.setState({ success: false, message: 'Server error.' });
      });
  }

  getDCDetails(id) {
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/challan/getsinglechallan/${id}`)
      .then(res => {

        console.log(res.data)
         if (res.data.challanlist.challandata.length > 0) {
           let founddata = res.data.challanlist.challandata;
            console.log(founddata[0])
           if(founddata[0].invoicenumber) {
            this.handleInvoiceChange(founddata[0].invoiceid);
           }
           const newState = Object.assign({}, this.state);
           newState.data.deliverychallanno = founddata[0].deliverychallanno;
           newState.data.toaddress = founddata[0].toaddress;
           newState.data.site = founddata[0].site;
           newState.data.date = new Date(founddata[0].date);
           newState.data.ponumber = founddata[0].ponumber;
           newState.data.podate =  new Date(founddata[0].podate);
           newState.data.vehiclenumber = founddata[0].vehiclenumber;
           newState.data.gstno = founddata[0].gstno;
           newState.data.otherreference = founddata[0].otherreference;
           newState.data.values = founddata[0].addeditemlist;
           newState.data.mobilenumber = founddata[0].mobilenumber ? founddata[0].mobilenumber : ''
           newState.data.customerdetail.id = founddata[0].customerid ? founddata[0].customerid._id : '';
           if(founddata[0].customerid) 
            this.handleChange2(founddata[0].customerid._id)

          if (founddata[0].addeditemlist.length > 0) {

            for (var i = 0; i < founddata[0].addeditemlist.length; i++) {
         

              founddata[0].addeditemlist[i].quantity = founddata[0].addeditemlist[i].quantity == null || founddata[0].addeditemlist[i].quantity == '' ?
                founddata[0].addeditemlist[i].quantity = 0 : founddata[0].addeditemlist[i].quantity
              founddata[0].addeditemlist[i].quantity = (founddata[0].addeditemlist[i].quantity).toString()

              founddata[0].addeditemlist[i].costperunit = founddata[0].addeditemlist[i].costperunit == null || founddata[0].addeditemlist[i].costperunit == '' ?
                founddata[0].addeditemlist[i].costperunit = 0 : founddata[0].addeditemlist[i].costperunit
              founddata[0].addeditemlist[i].costperunit = (founddata[0].addeditemlist[i].costperunit).toString()

              founddata[0].addeditemlist[i].totalcost = founddata[0].addeditemlist[i].totalcost == null || founddata[0].addeditemlist[i].totalcost == '' ?
                founddata[0].addeditemlist[i].totalcost = 0 : founddata[0].addeditemlist[i].totalcost
              founddata[0].addeditemlist[i].totalcost = (founddata[0].addeditemlist[i].totalcost).toString()

              founddata[0].addeditemlist[i].remarks = founddata[0].addeditemlist[i].remarks == null || founddata[0].addeditemlist[i].remarks == '' ?
               "" : founddata[0].addeditemlist[i].remarks

              
          
              newState.data.selectedItemlist.itemdescription.push(founddata[0].addeditemlist[i].itemdescription)
              newState.data.selectedItemlist.itemtype.push(founddata[0].addeditemlist[i].itemtype._id)
              newState.data.selectedItemlist.remarks.push(founddata[0].addeditemlist[i].remarks)
              
            
              newState.data.selectedItemlist.itemuom.push(founddata[0].addeditemlist[i].itemuom._id)
              newState.data.selectedItemlist.quantity.push(founddata[0].addeditemlist[i].quantity)

              newState.data.selectedItemlist.costperunit.push(founddata[0].addeditemlist[i].costperunit)

              newState.data.selectedItemlist.totalcost.push(founddata[0].addeditemlist[i].totalcost)
             // setTimeout(()=>{
                newState.data.selectedItemlist.id.push(founddata[0].addeditemlist[i].id._id)
             // })
              
              
            }
          }

          
            this.setState(newState);

         
         
          if (founddata[0].addeditemlist.length > 0) {
            founddata[0].addeditemlist.filter((item, index) => {
              this.selectitemchange(item.id._id, index);
            })
          }
          this.setState({ loading: false });
        }
        else {
          this.setState({ count: 0,loading: false })
          
        }
      },
        error => {
          
          this.setState({ success: false, message: 'Server error.' });
        });
  }

  componentWillReceiveProps(props) {
    if (this.props.ID == undefined){
      const materialoptionlistvalues = Object.values(
        props.materialoptionlistvalues
      );
      const masteritemlistvalues = Object.values(props.masteritemlistvalues);
      const masteritemlistvaluesobject = props.masteritemlistvalues;
      const currencylistvalues = Object.values(props.currencylistvalues);
      const invoicelistvalues = Object.values(this.props.invoicelistvalues);
      const uomlistvalues = Object.values(props.uomlistvalues);
      const locationlistvalue = Object.values(props.locationlistvalue);
      const purchaseorderlist = Object.keys(this.props.purchaseorderlistvalues);
      const challanlistvalues = Object.keys(this.props.challanlistvalues);
      
      const customerlistvalues = Object.values(this.props.customerlistvalues);
      const customerobjects = this.props.customerlistvalues;

      let arraySet = [];    
      customerlistvalues.map((option, i) => {
        let objset = {
          value: option.id, label: option.customername
        }
        arraySet.push(objset);
      });

      let arrayInvoiceSet = [];
      invoicelistvalues.map((option, i) => {
        let objset = {
          value: option._id, label: option.invoicenumber
        }
        arrayInvoiceSet.push(objset);
      });
      
      let newArraySet = [];
      masteritemlistvalues.map((option, i) => {
        let name = "";
        if(option.masteritemshortdescription) {
          name = option.masteritemshortdescription;
        }
        else {
          name = option.masteritemdescription;
        }
        let objset = {
          value: option.id, label: name
        }
        newArraySet.push(objset);
      });
      

      let deliverychallanno =
        challanlistvalues.length > 0 ? 1 + challanlistvalues.length : 1;
  
        this.setState({
          ...this.state,
          masteritemlistvalues,
          masteritemlistvaluesobject,
          materialoptionlistvalues,
          currencylistvalues,
          invoicelistvalues,
          invoicelistselectedOptions: arrayInvoiceSet,
          locationlistvalue,
          customerlistvalues,
          itemlistSelectedOption: newArraySet,
          customerlistSelect: arraySet,
          customerobjects,
          uom: uomlistvalues,
          data: {
            ...this.state.data,
            deliverychallanno
          }
        },()=>{
          console.log(this.state.customerlistvalues)
        });
    }
 
    

      
 
  }


  handleInvoiceChange = async(e) => {
    console.log(e)
    let selectedinvoice = e.value ? e.value : e
    console.log(selectedinvoice)
    const invoicelistvalues = this.state.invoicelistvalues;
    let invoiceselected = invoicelistvalues.filter(
      invoice => invoice._id === selectedinvoice || invoice.invoicenumber === selectedinvoice
    );
    console.log("invoice selected is",invoiceselected)
    if(invoiceselected && invoiceselected.length > 0){
      invoiceselected = invoiceselected[0];

      const newState = Object.assign({}, this.state);
      newState.data.customerdetail.id = invoiceselected.customerid ? invoiceselected.customerid : '';
      

      let poDate = "";
      let purchaseordernumber = invoiceselected.quotationnumber;
      let transId = null;
      const invoicenumber = invoiceselected.invoicenumber;
      const invoiceid = selectedinvoice;
      this.setState({ loading: true });
      transId = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/customerTransaction/getcustomertransaction/` + invoiceselected.customerid)
      .then(res => {
        this.setState({ loading: false });
        if(res.data.customerlist.customerdata.length > 0){
          let customervalues = res.data.customerlist.customerdata;
          if(customervalues.length > 0){
            console.log("customer values is", customervalues);
            console.log("invoice selected is", invoiceselected);
            customervalues = customervalues.filter((item) =>{
              return item.Qno == invoiceselected.quotationnumber
            });
            if(customervalues.length > 0)
            {
              return customervalues[0]['_id'];
            }
            else {
              return "";
            }
          }
        }
      },
      error =>{
        this.setState({ loading: false });
      });
      let purchaseorderdate = new Date();
      if(invoiceselected.purchaseorderdate) {
        purchaseorderdate = new Date(invoiceselected.purchaseorderdate);
      }
  

      let selectedItemlist = {
        id: [],
        itemname: [],
        itemdescription: [],
        itemshortdescription: [],
        itemtype: [],
        itemrate: [],
        itemuom: [],
        itemdiscount: [],
        itemlocation: [],
        costperunit: [],
        quantity: [],
        totalcost: [],
        remarks: []
      }
      let values = invoiceselected.addeditemlist
      if (invoiceselected.addeditemlist.length > 0) {

        for (var i = 0; i < invoiceselected.addeditemlist.length; i++) {
          invoiceselected.addeditemlist[i].itemdiscount = invoiceselected.addeditemlist[i].itemdiscount == null || invoiceselected.addeditemlist[i].itemdiscount == '' ?
          invoiceselected.addeditemlist[i].itemdiscount = 0 : invoiceselected.addeditemlist[i].itemdiscount
          invoiceselected.addeditemlist[i].itemdiscount = (invoiceselected.addeditemlist[i].itemdiscount).toString()


          invoiceselected.addeditemlist[i].quantity = invoiceselected.addeditemlist[i].quantity == null || invoiceselected.addeditemlist[i].quantity == '' ?
          invoiceselected.addeditemlist[i].quantity = 0 : invoiceselected.addeditemlist[i].quantity
          invoiceselected.addeditemlist[i].quantity = (invoiceselected.addeditemlist[i].quantity).toString()

          invoiceselected.addeditemlist[i].costperunit = invoiceselected.addeditemlist[i].costperunit == null || invoiceselected.addeditemlist[i].costperunit == '' ?
          invoiceselected.addeditemlist[i].costperunit = 0 : invoiceselected.addeditemlist[i].costperunit
          invoiceselected.addeditemlist[i].costperunit = (invoiceselected.addeditemlist[i].costperunit).toString()

          invoiceselected.addeditemlist[i].totalcost = invoiceselected.addeditemlist[i].totalcost == null || invoiceselected.addeditemlist[i].totalcost == '' ?
          invoiceselected.addeditemlist[i].totalcost = 0 : invoiceselected.addeditemlist[i].totalcost
          invoiceselected.addeditemlist[i].totalcost = (invoiceselected.addeditemlist[i].totalcost).toString()

  


          let itemname = invoiceselected.addeditemlist[i].itemdescription;
          if(invoiceselected.addeditemlist[i].itemshortdescription && invoiceselected.addeditemlist[i].itemshortdescription !== '') {
            itemname = invoiceselected.addeditemlist[i].itemshortdescription;
          }

          selectedItemlist.itemname.push(itemname);
          selectedItemlist.itemrate.push(invoiceselected.addeditemlist[i].costperunit);
          selectedItemlist.itemdescription.push(invoiceselected.addeditemlist[i].itemdescription)
          selectedItemlist.itemshortdescription.push(invoiceselected.addeditemlist[i].itemshortdescription)
          selectedItemlist.itemtype.push(invoiceselected.addeditemlist[i].itemtype._id)
          selectedItemlist.itemdiscount.push(invoiceselected.addeditemlist[i].itemdiscount)
          selectedItemlist.remarks.push("");
          selectedItemlist.itemlocation.push("");


         
          selectedItemlist.itemuom.push(invoiceselected.addeditemlist[i].itemuom._id)
          selectedItemlist.quantity.push(invoiceselected.addeditemlist[i].quantity)

          selectedItemlist.costperunit.push(invoiceselected.addeditemlist[i].costperunit)

          selectedItemlist.totalcost.push(invoiceselected.addeditemlist[i].totalcost)
          selectedItemlist.id.push(invoiceselected.addeditemlist[i].id)

        }
      }

      const mobilenumber = invoiceselected.customercontactnumber;
      const toaddress = invoiceselected.billingaddress;
      let vehiclenumber = this.state.vehiclenumber;
      if(invoiceselected.vehiclenumber) {
        vehiclenumber = invoiceselected.vehiclenumber
      }
      let gstno = this.state.gstno;
      if(invoiceselected.customergstnumber) {
        gstno = invoiceselected.customergstnumber;
      }

      
 
      const { totalcost } = selectedItemlist;
      let totalvalue = 0;
      if (totalcost.length > 0) {
        totalvalue = totalcost
          .map((value, i) => {
            if (value) {
              return parseFloat(value);
            } else {
              return 0;
            }
          })
          .reduce((a, b) => {
            return a + b;
          }); 
        }

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
       //   values: values,
          invoicenumber,
          totalvalue,
          transactionid: transId,
          invoiceid,
          selectedCustomerID: invoiceselected.customerid,
          selectedCustomerName: invoiceselected.customername,
          selectedInvoiceId: selectedinvoice,
          selectedInvoiceNumber: invoiceselected.invoicenumber,
          ponumber: purchaseordernumber,
          podate: purchaseorderdate,
          selectedItemlist,
          mobilenumber,
          toaddress,
          gstno,
          vehiclenumber,
          values
        }
      }, () => {
        console.log(selectedItemlist)
        this.setState({
          ...this.state.data,
          data: {
            ...this.state.data,
            invoicenumber : invoicenumber,
            invoiceid : invoiceid,
          //  values: values
          }
        },() =>{
          console.log(this.state.data)
          //this.setState(newState);
          
          // if (invoiceselected.addeditemlist.length > 0) {
          //   invoiceselected.addeditemlist.filter((item, index) => {
          //     this.selectitemchange(item.id._id, index);
          //   })
          // }
          if(invoiceselected.customerid) {
            this.handleChange2(invoiceselected.customerid);
          }
        });
      });
    }
  }

  handleChallanDate = date => {
    this.setState({
      data: {
        ...this.state.data,
        date: date
      }
    });
  };

  handlePODate = date => {
    this.setState({
      data: {
        ...this.state.data,
        podate: date
      }
    });
  };

  handleChange = e => {
    console.log( e.target.value)
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  handleChange2 = e => {

    let selected_id = e.value ? e.value : e
    let customerdetail = this.state.customerobjects[selected_id];
    console.log(customerdetail)

    let toaddress = customerdetail ? customerdetail.customeraddress1 +
    " " +
    customerdetail.customeraddress2 +
    " " +
    customerdetail.city +
    " " +
    customerdetail.state +
    " " +
    customerdetail.country +
    " " +
    customerdetail.pincode : ''

  
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customerdetail,
        toaddress,
      },
      selectedCustomerID: selected_id, selectedCustomerName: customerdetail.customername
    },()=>{
      localStorage.setItem('custname',this.state.data.customerdetail.customername)
      console.log(this.state.data.customerdetail)
    });
  };

  totalValue = () => {
    const { totalcost } = this.state.data.selectedItemlist;
    if (totalcost.length > 0) {
      const totalvalue = totalcost
        .map((value, i) => {
          if (value) {
            return parseFloat(value);
          } else {
            return 0;
          }
        })
        .reduce((a, b) => {
          return a + b;
        });
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          totalvalue
        }
      });
    }
  };


  createSelectcustomer = () => {
    let items = [];
    let customerlistvalues = this.state.customerlistvalues;
    console.log(this.state.customerlistvalues)
    customerlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.customername}
        </option>
      );
    });
    return items;
  };


  selectitemchange = (e, index) => {
    let selected_id = e.value ? e.value : e;
    console.log(this.state.masteritemlistvaluesobject)
    console.log(selected_id)
    if (selected_id) {
      let selected_item = this.state.masteritemlistvaluesobject[selected_id];

      if(selected_item){
        const selectedItemlist = { ...this.state.data.selectedItemlist };
        selectedItemlist.id[index] = selected_item.id;
        selectedItemlist.itemname[index] = selected_item.masteritemtypename;
        selectedItemlist.itemdescription[index] =
          selected_item.masteritemdescription;

        // selectedItemlist.itemdescription[index] = selected_item.masteritemshortdescription == '' ? selected_item.masteritemdescription 
        //   : selected_item.masteritemshortdescription
        selectedItemlist.itemtype[index] = selected_item.masteritemtype._id;
        selectedItemlist.itemrate[index] = selected_item.masteritemrate;
        selectedItemlist.itemuom[index] = selected_item.masteritemuom._id;
        //selectedItemlist.itemlocation[index] = null;
        
        if (this.props.ID == undefined) {
          selectedItemlist.costperunit[index] = selected_item.masteritemcostperunit
          ? selected_item.masteritemcostperunit.toString()
          : '';
        selectedItemlist.quantity[index] = (1).toString();
        selectedItemlist.totalcost[index] = selected_item.masteritemcostperunit
          ? selected_item.masteritemcostperunit.toString()
          : '';
        }
  
        this.setState({
  
          ...this.state.data,
          data: {
            ...this.state.data,
            selectedItemlist
          }
        },()=>{
          this.totalValue();
        });
      }
      
      }

  };

  locationChange = (e, index) => {
    const selected_location = e.target.value;
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.itemlocation[index] = selected_location;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  onhandlerChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  onSubjectHandler = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  filterItems(itemarray, key) {
    return itemarray.sort((a, b) => { return a[key].toLowerCase().localeCompare(b[key].toLowerCase()) })
  }

  createSelectItems = () => {
    let items = [];
    let masteritemlistvalues = this.state.masteritemlistvalues;
    masteritemlistvalues = this.filterItems(masteritemlistvalues, 'masteritemtypename')
    masteritemlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectItemTypes = () => {
    let items = [];
    const materialoptionlistvalues = this.state.materialoptionlistvalues;
    materialoptionlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectCurrencyItems = () => {
    let items = [];
    const currencylistvalues = this.state.currencylistvalues;
    currencylistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.currencyname}
        </option>
      );
    });
    return items;
  };

  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uom;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };
  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  handleClick = e => {
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ''],
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id: [...this.state.data.selectedItemlist.id, ''],
          itemname: [...this.state.data.selectedItemlist.itemname, ''],
          itemdescription: [
            ...this.state.data.selectedItemlist.itemdescription,
            ''
          ],
          itemtype: [...this.state.data.selectedItemlist.itemtype, ''],
          itemrate: [...this.state.data.selectedItemlist.itemrate, ''],
          itemuom: [...this.state.data.selectedItemlist.itemuom, ''],
          itemlocation: [...this.state.data.selectedItemlist.itemlocation, ''],
          costperunit: [...this.state.data.selectedItemlist.costperunit, ''],
          quantity: [...this.state.data.selectedItemlist.quantity, ''],
          totalcost: [...this.state.data.selectedItemlist.totalcost, ''],
          remarks: [...this.state.data.selectedItemlist.remarks, '']
        }
      }
    });
  };
  removeClick = (e, index) => {
    const removeItem = { ...this.state.data };
    const values = removeItem.values;
    const id = removeItem.selectedItemlist.id;
    const itemname = removeItem.selectedItemlist.itemname;
    const itemdescription = removeItem.selectedItemlist.itemdescription;
    const itemtype = removeItem.selectedItemlist.itemtype;
    const itemrate = removeItem.selectedItemlist.itemrate;
    const itemuom = removeItem.selectedItemlist.itemuom;
    const itemlocation = removeItem.selectedItemlist.itemlocation;
    const costperunit = removeItem.selectedItemlist.costperunit;
    const quantity = removeItem.selectedItemlist.quantity;
    const totalcost = removeItem.selectedItemlist.totalcost;
    const remarks = removeItem.selectedItemlist.remarks;
    values.splice(index, 1);
    id.splice(index, 1);
    itemname.splice(index, 1);
    itemdescription.splice(index, 1);
    itemtype.splice(index, 1);
    itemrate.splice(index, 1);
    itemuom.splice(index, 1);
    itemlocation.splice(index, 1);
    costperunit.splice(index, 1);
    quantity.splice(index, 1);
    totalcost.splice(index, 1);
    remarks.splice(index, 1);
    
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id,
          itemname,
          itemdescription,
          itemtype,
          itemrate,
          itemuom,
          itemlocation,
          costperunit,
          quantity,
          totalcost,
          remarks
        }
      }
    },() =>{
      console.log(this.state.data.selectedItemlist)
    });
  };

  quantityChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const costperunit = selectedItemlist.costperunit[index] || null;
    selectedItemlist.quantity[index] = e.target.value;
    const totalValue = costperunit
      ? e.target.value * costperunit
      : null || undefined || '';
    selectedItemlist.totalcost[index] = totalValue
      ? parseFloat(totalValue).toFixed(2)
      : '';
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };
  remarkChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.remarks[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  unitChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.costperunit[index] = e.target.value;
    const totalcost =
      parseFloat(selectedItemlist.quantity[index]) *
      parseFloat(selectedItemlist.costperunit[index]);
    selectedItemlist.totalcost[index] = totalcost
      ? totalcost.toFixed(2)
      : null || '';
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  handleValidSubmit = () => {
    let AddedIteminfo = [];
    console.log(this.state.data.customerdetail)
    const { supplierdetail, selectedItemlist,customerdetail } = this.state.data;
    const { values } = this.state.data;
    const id = selectedItemlist.id;
    const itemname = selectedItemlist.itemname;
    const itemdescription = selectedItemlist.itemdescription;
    const itemtype = selectedItemlist.itemtype;
    const itemrate = selectedItemlist.itemrate;
    const itemuom = selectedItemlist.itemuom;
    const itemlocation = selectedItemlist.itemlocation;
    const costperunit = selectedItemlist.costperunit;
    const quantity = selectedItemlist.quantity;
    const totalcost = selectedItemlist.totalcost;
    const remarks = selectedItemlist.remarks;
    values.forEach(function (value, i) {
      let obj = {};
      obj.id = id[i] || null;
      obj.itemname = itemname[i] || null;
      obj.itemdescription = itemdescription[i] || null;
      obj.itemtype = itemtype[i] || null;
      obj.itemrate = itemrate[i] || null;
      obj.itemuom = itemuom[i] || null;
      obj.itemlocation = itemlocation[i] || null;
      obj.costperunit = costperunit[i] || null;
      obj.quantity = quantity[i] || null;
      obj.totalcost = totalcost[i] || null;
      obj.remarks = remarks[i] || null;
      AddedIteminfo.push(obj);
    });
    const { data } = this.state;
    data['AddedIteminfo'] = AddedIteminfo;
    data['customerdetail'].customername = localStorage.getItem('custname')
    
    this.setState({ loading: true });
    console.log(JSON.stringify(data));
    

    this.props
      .submit(data)
      .then(() => {

        if(this.props.ID == undefined){
          this.myFormRef.reset();
          this.setState({ loading: false, errors: {}, success: true,successmsg:'Delivery Challan is generated successfully' });
        }
        else{
          this.setState({ loading: false, errors: {}, success: true,successmsg:'Delivery Challan is updated successfully' });
        }

        this.setState({ loading: false, errors: {}, success: true });
        this.myFormRef.reset();
      })
      .catch(err => {

          if(this.props.ID == undefined){
            this.myFormRef.reset();
            this.setState({ loading: false, errors: {}, success: true,successmsg:'Delivery Challan is generated successfully' });
          }
          else{
            this.setState({ loading: false, errors: {}, success: true,successmsg:'Delivery Challan is updated successfully' });
          }
        
      });
  };

  
  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  handleInvalidSubmit = () => {
    console.log('invalid submit');
  };
  render() {
    const customStyles = {
      control: base => ({
        ...base,
        height: 35,
        minHeight: 35
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '35px',
        padding: '0 6px'
      }),
  
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '35px',
      }),
    };
    const { data, errors, loading, success,successmsg } = this.state;
    let SelVal;
    if(this.state.selectedCustomerID != undefined) {
      SelVal = {
        value: this.state.selectedCustomerID, label: this.state.selectedCustomerName
      }
    }
    let SelINV;
    if(this.state.selectedInvoiceId != undefined) {
      SelINV = {
        value: this.state.selectedInvoiceId, label: this.state.selectedInvoiceNumber
      }
    }
    const customerID = this.state.selectedCustomerID ? (
    //   <AvField
    //   Label
    //   type="select"
    //   name={`customername`}
    //   value={data.customerdetail.id}
    //   required={true}
    //   onChange={e => this.handleChange2(e)}
    // >
    //   <option value="">select customer name</option>
    //   {this.createSelectcustomer()}
    // </AvField>
    <Select name={`customername`} classNamePrefix="select" onChange={this.handleChange2.bind(this)} styles={customStyles} required value={SelVal} options={this.state.customerlistSelect} />    
    ) : (
    //   <AvField
    //   Label
    //   type="select"
    //   name={`customername`}
  
    //   required={true}
    //   onChange={e => this.handleChange2(e)}
    // >
    //   <option value="">select customer name</option>
    //   {this.createSelectcustomer()}
    // </AvField>
    <Select name={`customername`} classNamePrefix="select" onChange={this.handleChange2.bind(this)} styles={customStyles} required options={this.state.customerlistSelect} />        
    )

    let invoiceDisp = this.state.selectedInvoiceId ? (
      <Select name={`deliverychallannumber`} classNamePrefix="select" onChange={this.handleInvoiceChange.bind(this)} styles={customStyles} required value={SelINV} options={this.state.invoicelistselectedOptions} />
    ) : (
      <Select name={`deliverychallannumber`} classNamePrefix="select" onChange={this.handleInvoiceChange.bind(this)} styles={customStyles} required defaultValue={data.customerdetail.id} options={this.state.invoicelistselectedOptions} />
    )
    return (
      <div>

        <div>
        {loading ? <CustomLoader /> : null}
          {!!success ? (
            // <Alert color="info">Purchase Order is generated successfully</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader  toggle={this.toggle}>Delivery Challan</ModalHeader>
              <ModalBody >{successmsg}</ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader Label>Delivery Challan</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='deliverychallanno'>DC</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      Label
                      name='deliverychallanno'
                      type='text'
                      id='deliverychallanno'
                      value={data.deliverychallanno || null}
                      onChange={this.handleChange}
                 
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter DC'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customername">Invoice No</Label>
                    <span className="required-label">*</span>
                    {/* <AvField
                      Label
                      type="select"
                      name={`customername`}
                      value={data.customerdetail.id || null}
                      required={true}
                      onChange={e => this.handleChange(e)}
                    >
                      <option value="">select customer name</option>
                      {this.createSelectcustomer()}
                    </AvField> */}
                    {invoiceDisp}
                  </AvGroup>
                </Col>                
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customername">Customer Name</Label>
                    <span className="required-label">*</span>
                    {/* <AvField
                      Label
                      type="select"
                      name={`customername`}
                      value={data.customerdetail.id || null}
                      required={true}
                      onChange={e => this.handleChange(e)}
                    >
                      <option value="">select customer name</option>
                      {this.createSelectcustomer()}
                    </AvField> */}
                    {customerID}
                  </AvGroup>
                </Col>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='toaddress'>To address </Label>
                    <AvField
                      Label
                      name='toaddress'
                      type='text'
                      id='toaddress'
                      value={data.toaddress}
                      onChange={e => this.handleChange(e)}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='site'>Site</Label>
                  <AvField
                    Label
                    name='site'
                    type='text'
                    id='site'
                    value={data.site || ' ' || null}
                    onChange={e => this.handleChange(e)}
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='mobilenumber'>Mobile No</Label>
                  <AvField
                    Label
                    name='mobilenumber'
                    type='text'
                    id='mobilenumber'
                    value={data.mobilenumber || ' ' || null}
                    onChange={e => this.handleChange(e)}
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='quotationno'>Date</Label>
                  <DatePicker
                    selected={data.date}
                    autoComplete="new-password"
                    onChange={this.handleChallanDate}
                    className='form-control small-font-size'
                    dateFormat='dd/MM/yyyy'
                    id='date'
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='ponumber'>PO no</Label>
                    <AvField
                      Label
                      type='text'
                      name='ponumber'
                      id='ponumber'
                      value={data.ponumber || null}
                      onChange={e => this.handleChange(e)}
                    ></AvField>
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <Label className='small-font-size' for='podate'>PO Date</Label>
                  <DatePicker
                    selected={data.podate}
                    autoComplete="new-password"
                    onChange={this.handlePODate}
                    className='form-control small-font-size'
                    dateFormat='dd/MM/yyyy'
                    id='podate'
                  />
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='vehiclenumber'>Vehicle Number</Label>
                    <AvField
                      Label
                      name='vehiclenumber'
                      type='text'
                      id='vehiclenumber'
                      value={data.vehiclenumber}
                      onChange={this.handleChange}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='gstno'>GST No</Label>
                    <AvField
                      Label
                      name='gstno'
                      type='text'
                      id='gstno'
                      value={data.gstno}
                      onChange={this.handleChange}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='otherreference'>Other reference</Label>
                    <AvField
                      Label
                      name='otherreference'
                      type='text'
                      id='otherreference'
                      value={data.otherreference}
                      onChange={this.handleChange}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <div className='mg-t-20'>
                  <Button color='info' onClick={this.handleClick} type='button'>
                    Add Item
                  </Button>
                </div>
              </Row>
              {/* className='purchaseorder-item-row' */}
              <div >
                {data.values.map((values, index) => {
                            let optionsel = "";
                            let SelVal = {};
                            if(values.id != undefined) {
                              if(values.itemshortdescription) {
                                optionsel = values.itemshortdescription;
                              }
                              else {
                                optionsel = values.itemdescription;
                              }
                              SelVal = {
                                value: values.id._id, label: optionsel
                              }
                            }
                            const itemnameinfo = values.id != undefined ? (
                              // <AvField
                              //   type="select"
                              //   value={values.id._id}
                              //   name={`selectitem${index}`}
                              //   onChange={e => this.selectitemchange(e, index)}
                              //   required={true}
                              // >
                              //   <option value="">select Item</option>
                              //   {this.createSelectItems()}
                              // </AvField>
                              <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index)} styles={customStyles} required value={SelVal} options={this.state.itemlistSelectedOption} />            
                              ) :
                              (
                                // <AvField
                                //   type="select"
                                //   name={`selectitem${index}`}
                                //   value={
                                //     data.selectedItemlist.id[index]
                                //   }
                                //   onChange={e => this.selectitemchange(e, index)}
                                //   required={true}
                                // >
                                //   <option value="">select Item</option>
                                //   {this.createSelectItems()}
                                // </AvField>
                                <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index)} styles={customStyles} required defaultValue={data.selectedItemlist.id[index]} options={this.state.itemlistSelectedOption} />
                              )
                  return (
                    <div key={index}>
                      <div className='purchaseorder-table'>
                        <Row key={index}>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for='selectitem'>Item Code</Label>
                              <span className='required-label'>*</span>
                              {/* <AvField
                                Label
                                type='select'
                                name={`selectitem${index}`}
                                value={
                                  data.selectedItemlist.id[index]
                                }
                                onChange={e => this.selectitemchange(e, index)}
                                required={true}
                              >
                                <option value=''>select Item</option>
                                {this.createSelectItems()}
                              </AvField> */}
                              {itemnameinfo}
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemdescription${index}`}>
                                Description
                              </Label>
                              <span className='required-label'>*</span>
                              <AvField
                                Label
                                name={`masteritemdescription${index}`}
                                type='text'
                                id='masteritemdescription'
                                readOnly
                                value={
                                  data.selectedItemlist.itemdescription[index]
                                }
                                
                                onChange={this.onChange}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      'Please enter Item Description'
                                  }
                            
                                 
                                }}
                              />
                            </AvGroup>
                          </Col>
                          {/* <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemtype${index}`}>Type</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                style={{ width: '150px' }}
                                type='select'
                                name={`masteritemtype${index}`}
                                onChange={this.onChange}
                                required={true}
                                value={
                                  data.selectedItemlist.itemtype[index] || null
                                }
                              >
                                <option value=''>select</option>
                                {this.createSelectItemTypes()}
                              </AvField>
                            </AvGroup>
                          </Col> */}
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemuom${index}`}>UOM</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                Label
                                type='select'
                                name={`masteritemuom${index}`}
                                readOnly
                                onChange={this.onChange}
                                required={true}
                                value={
                                  data.selectedItemlist.itemuom[index] || null
                                }
                              >
                                <option value=''>uom</option>
                                {/* <option value="kg">Kg</option>
                            <option value="liter">Liter</option>
                            <option value="kg">EA</option> */}
                                {this.createSelectUomItems()}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`quantity${index}`}>Quantity</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                Label
                                name={`quantity${index}`}
                                type='number'
                                id={`quantity${index}`}
                                value={
                                  data.selectedItemlist.quantity[index] || null
                                }
                                onChange={e => this.quantityChange(e, index)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter Item Quantity'
                                  },
                                  minLength: {
                                    value: 1,
                                    errorMessage: 'quantity value is required'
                                  },
                                  pattern: {
                                    value: '^[0-9.]*$',
                                    errorMessage: 'Please enter valid quantity'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>

                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for='remarks'>Remarks</Label>
                              <AvField
                                Label
                                name='remarks'
                                type='text'
                                id='remarks'
                                value={
                                  data.selectedItemlist.remarks[index]
                                }
                                onChange={e => this.remarkChange(e, index)}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <Button
                              Label
                              type='button'
                              color='danger'
                              onClick={e => this.removeClick(e, index)}
                              style={{ marginTop: '30px' }}
                            >
                              -
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Row>
                <div className='mg-20'>
                  <Button
                    Label
                    color='primary'
                    disabled={loading}
                    className='lgn-loader'
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierdetaillistvalue: state.supplierdetaillistvalue,
    masteritemlistvalues: state.masteritemlistvalues,
    materialoptionlistvalues: state.masteritemtypenamevalue,
    invoicelistvalues: state.invoicelistvalues,
    currencylistvalues: state.currencylistvalue,
    uomlistvalues: state.uomlistvalue,
    locationlistvalue: state.locationlistvalue,
    purchaseorderlistvalues: state.purchaseorderlistvalue,
    challanlistvalues: state.challanlistvalues,
    customerlistvalues: state.customerlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(ChallanForm);
