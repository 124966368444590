import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Alert } from "reactstrap";
import DataTable from "../../../Components/Table/DatatablePage";
import { getLocationList } from "../../../actions/Location";
import { getquotation } from "../../../actions/quotation";
import { getproformainvoice } from "../../../actions/Invoice";
import { getinvoice } from "../../../actions/Invoice";
import {
  totalitemvaluesearch,
  getMasterItemList
} from "../../../actions/Transaction";
import { ar } from "date-fns/locale";
import LogsForm from "./LogsForm/LogsForm";

class Logs extends React.Component {
  state = {
    logslist: [],
    success: false,
    quotationlistvalues: [],
    invoicelistvalues: [],
    proformainvoicelistvalues: []
  };
  componentDidMount() {
    this.props.getquotation();
    this.props.getMasterItemList();
    this.props.getinvoice();
    this.props.getproformainvoice();
  }
  submit = data => {
    let logslist = [];
    if(data.type == "Quotation Logs") {
        logslist = data.quotationlist.map((value,index) => {
          var created_date = new Date(value.date);

          var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          var year = created_date.getFullYear();
          var month = months[created_date.getMonth()];
          var date = created_date.getDate();
          var hour = created_date.getHours();
          var min = created_date.getMinutes();
          var sec = created_date.getSeconds();
          var time = date + ',' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
            return {
                // locationname: item.locationname,
                number: value.quotationnumber,
                date: time,
                user_email: value.user_email
              };
        });
    }
    else if(data.type == "Invoice Logs") { 
        logslist = data.invoicelist.map((value,index) => {
          var created_date = new Date(value.date);

          var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          var year = created_date.getFullYear();
          var month = months[created_date.getMonth()];
          var date = created_date.getDate();
          var hour = created_date.getHours();
          var min = created_date.getMinutes();
          var sec = created_date.getSeconds();
          var time = date + ',' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
            return {
                // locationname: item.locationname,
                number: value.quotationnumber,
                date: time,
                user_email: value.user_email
              };
        });
     }
     else if(data.type == "Proforma Invoice Logs") { 
        logslist = data.proformainvoicelist.map((value,index) => {
          var created_date = new Date(value.date);

          var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          var year = created_date.getFullYear();
          var month = months[created_date.getMonth()];
          var date = created_date.getDate();
          var hour = created_date.getHours();
          var min = created_date.getMinutes();
          var sec = created_date.getSeconds();
          var time = date + ',' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
            return {
                // locationname: item.locationname,
                number: value.quotationnumber,
                date: time,
                user_email: value.user_email
              };
        });
      }
    //   const itemlist = data.itemlist.map(item => {
    //     return {
    //       // locationname: item.locationname,
    //       itemname: item.itemname,
    //       itemdescription: item.itemdescription,
    //       itemquantity: item.itemquantity
    //     };
    //   });
    //   this.setState({
    //     ...this.state,
    //     itemlist,
    //     success: true
    //   });
    this.setState({
        ...this.state,
        logslist,
        success: true
      });
    return logslist;
  };
  render() {
    const { success, logslist } = this.state;
    const columnvalue = [
      // {
      //   label: "location",
      //   field: "locationname",
      //   sort: "asc",
      //   width: 40
      // },
      {
        label: "Number",
        field: "number",
        sort: "asc",
        width: 40
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 40
      },
      {
        label: "Added By",
        field: "user_email",
        sort: "asc",
        width: 40
      }
    ];
    return (
      <div className="container mg-t-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/transaction_page">Transaction</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Logs</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <LogsForm submit={this.submit} />
        </div>
        <div className="mg-t-20">
          {logslist.length > 0 ? (
            <DataTable
              columnvalue={columnvalue}
              rowvalues={logslist}
              className="totalquantity"
            />
          ) : (
              <div>
                <Alert color="info" Label>Result Not Found.</Alert>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { getquotation, getinvoice, getproformainvoice, getMasterItemList }
)(Logs);
