import { USER_FETCHED } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case USER_FETCHED:
      return { ...state, ...action.userlist.entities.users };
    default:
      return state;
  }
}
