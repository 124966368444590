import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import SupplierDetailForm from "../SupplierForm/SupplierDetail";
import {
  getSupplierContact,
  addSupplierDetails,
  getSupplierDetails
} from "../../../../actions/Supplier";
import CustomLoader from "../../../../Components/loader/loader";

class SupplierDetail extends React.Component {
  state = {
    loading: false
  };
  componentDidMount() {
    this.props.getSupplierContact();
    this.props.getSupplierDetails();
  }
  submit = data => {
    this.dataHandler(true);
    return this.props
      .addSupplierDetails(data)
      .then(data => {
        this.dataHandler(false);
        this.props.getSupplierDetails();
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/admin_dashboard">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/supplier">Supplier</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Supplier Detail</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <SupplierDetailForm submit={this.submit} />
        </div>
      </div>
    );
  }
}

SupplierDetail.propTypes = {
  getSupplierContact: PropTypes.func.isRequired
};

export default connect(
  null,
  { getSupplierContact, addSupplierDetails, getSupplierDetails }
)(SupplierDetail);
