import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import {
    getCustomerTransaction
} from "../../../actions/customerContact";
import CustomLoader from "../../../Components/loader/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  Label,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import axios from "axios";
import DatePicker from "react-datepicker";


class Transaction extends React.Component {
  
  state = {
    data: {
      enquirydate:null,
      quotationdate:null,
      podate:null
    },
    enquirytimestamp:null,
    quotationtimestamp:null,
    podatetimestamp:null,
    userid : this.props.match.params.id,
    loading: false,
    count:0,
    success: false,
    message:'',
    ponum:'',
    podate:'',
    customerName:localStorage.getItem('customername') !=null ? localStorage.getItem('customername') : '',
    transactionID:'',
    customertransationvalues:[],
    backupcustomertransationvalues:[]
  };
  componentDidMount = () => {
    // this.props.match.params.id
    // 5d7245b928e146194b806e38
    
    this.getTransaction(this.props.match.params.id);
  };

  componentWillReceiveProps(props) {
    let customervalues = Object.values(props.customertransationvalues).map(customerdata => {
      return {
        customerid: customerdata.customerid,
        Transactionhistory: customerdata.Transactionhistory,
        id:new Date().getTime()
      };
    });
    if(customervalues.length > 0){
      customervalues = customervalues.filter((item) =>{
        return item.customerid == this.props.match.params.id
      })
      this.setState({ 
        customertransationvalues: customervalues,
        backupcustomertransationvalues:customervalues,
        count:customervalues.length
      }, () => {
        console.log(JSON.stringify(this.state.backupcustomertransationvalues))
      })
    }
  }

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  
  initializeTransactions = () => {
    this.setState({
      customertransationvalues:this.state.backupcustomertransationvalues
    },() =>{
      console.log(this.state.backupcustomertransationvalues)
    })
  }

  newEnquiry(){
    localStorage.setItem('ids',this.state.userid)
    this.setState({ success: true, message:'Are you sure want to add new enquiry' });
  }

  getTransaction(id){
    this.dataHandler(true)
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/customerTransaction/getcustomertransaction/` + id)
    .then(res => {
      this.dataHandler(false)
      if(res.data.customerlist.customerdata.length > 0){
        let customervalues = res.data.customerlist.customerdata;
        if(customervalues.length > 0){
          customervalues = customervalues.filter((item) =>{
            return item.customerid == this.props.match.params.id
          })
          this.setState({ customertransationvalues: customervalues,
            backupcustomertransationvalues:customervalues,
            count:customervalues.length}, () => {
            console.log(JSON.stringify(this.state.customertransationvalues))
          })
        }
      }
    },
    error =>{
      this.dataHandler(false)
      this.setState({ success: false, message:'Server error.' });
    });
  }

  addenquiry(){
    this.dataHandler(true)
    let date = (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()) + 
    "/" + ( new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) + "/" + new Date().getFullYear();

     axios
    .post(`${process.env.REACT_APP_BASE_URL}/customerTransaction/addcustomertransaction`,
    {
      "customerid":this.state.userid,
      "enq_Date" : date
    })
    .then(res => {
      this.getTransaction(this.state.userid)
    },
    error =>{
      this.dataHandler(false)
      this.setState({ success: false, message:'Server error.' });
    });
  }

  dataHandler = value => {
    this.setState({ loading: value });
  };

  poreceived = value =>{
    this.setState({
      transactionID:value._id,
      ponum:'',
      podate:''
    },() =>{
      this.setState({ success: true, message:'update transaction' });
    })
  }

  gotoPDF = value => {
    console.log("the pdf page is",  value)
    this.props.history.push(value);
  }

  updatePO = () => {
    if(this.state.ponum != '' && this.state.podate != ''){
      let podates = this.state.podate.split('-')[2] + '/' + this.state.podate.split('-')[1] + '/' + this.state.podate.split('-')[0]
      let obj = {}
      obj['_id'] = this.state.transactionID
      obj['po'] = podates
      obj['ponum'] = this.state.ponum
      this.dataHandler(true)
      axios
      .put(`${process.env.REACT_APP_BASE_URL}/customerTransaction/updatecustomertransaction`,obj)
      .then(res => {
        this.toggle()
        this.getTransaction(this.state.userid)
      },
      error =>{
        this.dataHandler(false)
        this.toggle()
        this.setState({ success: false, message:'Server error.' });
      });
    }
    else
      this.toggle()
  }

  onChange1 = e => {
    this.setState({
      'ponum' : e.target.value
    });
  }

  onChange2 = e => {
    console.log(e.target.value)
    this.setState({
      'podate' : e.target.value
    });
  }

  getDateByMMDDYYYY(date){
    let searchdate = date != '-' ? 

    (new Date(date).getDate() < 10 ? "0" + new Date(date).getDate() : new Date(date).getDate()) + "/" +
    ( new Date(date).getMonth() + 1 < 10 ? "0" + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1)
    + "/" + new Date(date).getFullYear() : '-'
    return searchdate
  }

    
  handleDateChanges(dateName, dateValue) {

    let { data,enquirytimestamp,quotationtimestamp,podatetimestamp } = this.state;
    if (dateName === 'enquirydate') {
      data.enquirydate = dateValue;
      enquirytimestamp = this.getDateByMMDDYYYY(dateValue)
    }
    this.setState({
      data,
      enquirytimestamp
    }, () => {
      if (this.state.data.enquirydate != null) {
        this.filterBydates()
      }
      else{
        this.initializeTransactions();
      }
    });
  };

  filterBydates(){
    this.initializeTransactions();
    setTimeout(() => {
        let filtereddata = this.state.customertransationvalues.filter((item) => {
            return (this.state.enquirytimestamp != null && item.enq_Date == this.state.enquirytimestamp)
            
        })
        this.setState({
          customertransationvalues:filtereddata
        })
    })
  }

  render() {
    let modaldata;

    const { customertransationvalues,loading,count,success,message,data,customerName } = this.state;
    if(message == 'update transaction' && success){
      modaldata =  (
        <Modal
        isOpen={success}
        toggle={this.toggle}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggle}>Add customer PO</ModalHeader>
        <ModalBody>
          <input type="text" className="form-control" placeholder="PO Number" value={this.state.ponum} style={{'marginBottom': '15px'}} onChange={this.onChange1}/>
          <input type="date" className="form-control" placeholder="PO Number" value={this.state.podate} onChange={this.onChange2}/>
        </ModalBody>
        <ModalFooter>
         <Button color="primary" onClick={this.toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={this.updatePO}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
      )
    }
    else if(message == 'Server error.' && !success){
      modaldata =  (
        <Modal
        isOpen={success}
        toggle={this.toggle}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggle}>Transaction</ModalHeader>
        <ModalBody>{this.state.message}</ModalBody>
        <ModalFooter>
         <Button color="primary" onClick={this.toggle}>
            Close
          </Button>

        </ModalFooter>
      </Modal>
      )
    }



    const customerTransactionBlock = customertransationvalues.map((customer,index) => {
      
      let invoice = customer.invoice_link ? (<td style={{textAlign:'center'}}> 
      Invoice PDF<br/>
      <Button color="primary" onClick={() => this.gotoPDF(customer.invoice_link)} style={{fontSize: '12px'}}>
       Print
     </Button>
 </td>) : (<td style={{textAlign:'center'}}>---</td>);
      let dc = customer.challan_link ? (
      <td style={{textAlign:'center'}}>{customer.challan_link}</td>
      ) : (
        <td style={{textAlign:'center'}}>---</td>)
      let enquiry = '',quote = '',PO=(<td style={{textAlign:'center'}}>---</td>)
      enquiry = customer.enq_Date !=undefined ? (<td style={{textAlign:'center'}}> {customer.enq_Date}</td>) : (<td style={{textAlign:'center'}}>---</td>)
      quote = customer.quotation !=undefined && customer.quotation != "" ? (<td style={{textAlign:'center'}}><b style={{ 'fontWeight' : 'bold'}}>{customer.Qno} </b> <br/>{customer.quotation}</td>) : (<td style={{textAlign:'center'}}>---</td>)
      PO = customer.po !=undefined && customer.po != "" ? (<td style={{textAlign:'center'}}><b style={{ 'fontWeight' : 'bold'}}>{customer.ponum} </b> <br/> {customer.po}</td>) 
      : (<td style={{textAlign:'center'}}> 
           Customer PO not reveived<br/>
           <Button color="primary" onClick={() => this.poreceived(customer)} style={{fontSize: '12px'}}>
            Add customer PO
          </Button>
      </td>)

      if(customer.quotation == ''){
        PO = (<td style={{textAlign:'center'}}>---</td>)
      }

      
      return (
        
        <tr>
          {/* <td></td>
          {enquiry} */}
          {quote}
          {PO}
          {invoice}
          {dc}

          </tr>
             
      )
 
    })



    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/customerlist">CRM</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{customerName}</BreadcrumbItem>
        </Breadcrumb>
     

            {modaldata}
 
        <h5 style={{ 'fontWeight': 'bold','margin': '15px'}}>{count} transaction found.</h5>
        {customertransationvalues.length > 0 ?
        (

        <div>
{/*           
          <Row style={{ 'width': '75%','marginLeft': '9%'}}>
                <Col sm={3} >
                    <Label for="enquirydate">Search enquiry date</Label>
                    

                      <DatePicker
                    selected={data.enquirydate}

                    onChange={date => this.handleDateChanges('enquirydate', date)}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    id="enquirydate"
                    isClearable={true}
                  />
                 
                </Col>

        

                

                </Row> */}

            <table className="table table-bordered table-sm table-striped dataTable" style={{'marginTop':'10px', 'width': '75%','marginLeft': '10%','background':'#fff'}}>

              <tr>
                {/* <th></th>
                <th>Enquiry</th> */}
                <th>Quotation</th>
                <th>PO</th>
                <th>Invoice</th>
                <th>Delivary challan</th>
                </tr>
                {customerTransactionBlock}
              </table>
          </div>
        ) : null
       }
      </div>
    );
  }
}



function mapStateToProps(state) {
  return {
    customertransationvalues: state.customertransationvalues
  };
}

export default connect(
  mapStateToProps,
  { getCustomerTransaction }
)(Transaction);
