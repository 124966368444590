import React from "react";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "./loader.css";
class CustomLoader extends React.Component {
    render() {
        return(
         <Loader className="loaderposition"
            type="ThreeDots"
            color="#ed272f"
            height="100"
            width="100"
         />
        );
       }
}

export default CustomLoader;