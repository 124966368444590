import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import SupplierDetailUpdateForm from "../SupplierForm/SupplierDetailUpdate";
import {
  getSupplierDetails,
  updateSupplierDetails
} from "../../../../actions/Supplier";
import CustomLoader from "../../../../Components/loader/loader";

class SupplierDetailUpdate extends React.Component {
  state = {
    supplierdetaillistvalue: "",
    loading: false
  };
  componentDidMount() {
    this.props.getSupplierDetails();
    const supplierdetailid = this.props.match.params.id;
    const supplierdetaillistvalue = this.props.supplierdetaillistvalue[
      supplierdetailid
    ];
    this.setState({ supplierdetaillistvalue });
  }

  submit = data => {
    this.dataHandler(true);
    return this.props
      .updateSupplierDetails(data)
      .then(data => {
        this.dataHandler(false);
        this.props.getSupplierDetails();
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  render() {
    const { loading, supplierdetaillistvalue } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/admin_dashboard">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/inventory_page">Set Up</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/supplier">Supplier</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Supplier Detail Update</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <SupplierDetailUpdateForm
            supplierdetaillistvalue={supplierdetaillistvalue}
            submit={this.submit}
          />
        </div>
      </div>
    );
  }
}

SupplierDetailUpdate.propTypes = {
  getSupplierContact: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    supplierdetaillistvalue: state.supplierdetaillistvalue
  };
}

export default connect(
  mapStateToProps,
  { getSupplierDetails, updateSupplierDetails }
)(SupplierDetailUpdate);
