import axios from "axios";

export default {
  suppliercontact: {
    addSupplierContact: data => {
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/supplier/suppliercontact/addsuppliercontact`,
          {
            data
          }
        )
        .then(res => {
          return res.data.supplierContactdata;
        });
    },
    getSupplierContact: () => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/supplier/suppliercontact/getsuppliercontact`
        )
        .then(res => {
          return res.data.suppliercontactlist;
        });
    }
  },
  supplierdetail: {
    addsupplierdetails: data => {
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/supplier/supplierdetail/addsupplierdetail`,
          {
            data
          }
        )
        .then(res => {
          return res.data.masterItemType;
        });
    },
    getSupplierDetails: data => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/supplier/supplierdetail/getSupplierdetail`
        )
        .then(res => {
          return res.data.supplierdetaillist;
        });
    },
    updateSupplierContact: data => {
      return axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/supplier/supplierdetail/updateSupplierdetail?id=${data.id}`,
          {
            data
          }
        )
        .then(res => {
          return res.data.success;
        });
    }
  }
};
