import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Alert } from "reactstrap";
import ItemSearch from "./TotalQuantityForm/ItemQuantitySearch";
import DataTable from "../../../Components/Table/DatatablePage";
import { getLocationList } from "../../../actions/Location";
import {
  totalitemvaluesearch,
  getMasterItemList
} from "../../../actions/Transaction";
import { ar } from "date-fns/locale";

class TotalQuantity extends React.Component {
  state = {
    itemlist: [],
    success: false
  };
  componentDidMount() {
    this.props.getLocationList();
    this.props.getMasterItemList();
  }
  submit = data => {
    return this.props.totalitemvaluesearch(data).then(data => {
   
       //console.log(itemsArray)
      // var i=0,j,arr = [],count=0,isAllDuplicate=false;
      // for(i=0;i<data.itemlist.length;i++){
      //   j=i;
      //   j = j + 1;
      //   console.log(data.itemlist[i].itemquantity)
      //   if(j < data.itemlist.length){
      //     if(data.itemlist[i].itemname == data.itemlist[j].itemname){
      //       count += parseInt(data.itemlist[i].itemquantity)
      //       isAllDuplicate = true;
      //     }
      //     else if(data.itemlist[i].itemname != data.itemlist[j].itemname){
      //       count += parseInt(data.itemlist[j].itemquantity)
      //       if(count > 0)
      //         data.itemlist[i].itemquantity = count
      //       arr.push(data.itemlist[i])
      //       count=0;
      //       isAllDuplicate=false;
      //     }
      //   }
      //   else if(j == data.itemlist.length){
      //     console.log(data.itemlist[i].itemquantity)
      //      if(count > 0)
      //          data.itemlist[i].itemquantity = data.itemlist[i].itemquantity + count
      //   }

        
      // }

      // //console.log(count)
      // //console.log(i)
      // if(isAllDuplicate){
      //   i > data.itemlist.length - 1 ?  arr.push(data.itemlist[i-1]) : arr.push(data.itemlist[i])
      // }
        

      // console.log(arr)

      // data.itemlist = arr;
      
      
      const itemlist = data.itemlist.map(item => {
        return {
          // locationname: item.locationname,
          itemname: item.itemname,
          itemdescription: item.itemdescription,
          itemquantity: item.itemquantity
        };
      });
      this.setState({
        ...this.state,
        itemlist,
        success: true
      });
   
    });
  };
  render() {
    const { success, itemlist } = this.state;
    const columnvalue = [
      // {
      //   label: "location",
      //   field: "locationname",
      //   sort: "asc",
      //   width: 40
      // },
      {
        label: "Item Code",
        field: "itemname",
        sort: "asc",
        width: 40
      },
      {
        label: "Item Description",
        field: "itemdescription",
        sort: "asc",
        width: 40
      },
      {
        label: "Quantity",
        field: "itemquantity",
        sort: "asc",
        width: 40
      }
    ];
    return (
      <div className="container mg-t-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/transaction_page">Transaction</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/onhand'>On Hand</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Total Item Quantity</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <ItemSearch submit={this.submit} />
        </div>
        <div className="mg-t-20">
          {itemlist.length > 0 ? (
            <DataTable
              columnvalue={columnvalue}
              rowvalues={itemlist}
              className="totalquantity"
            />
          ) : (
              <div>
                <Alert color="info" Label>Result Not Found.</Alert>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { getLocationList, totalitemvaluesearch, getMasterItemList }
)(TotalQuantity);
