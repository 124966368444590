import React from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Label, Alert } from "reactstrap";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

class SupplierContactForm extends React.Component {
  state = {
    data: {
      suppliercontactname: "",
      suppliercontactnumber: ""
    },
    errors: {},
    loading: false,
    success: false
  };
  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  handleValidSubmit = () => {
    console.log(this.state.data);
    this.setState({ loading: true });
    this.props
      .submit(this.state.data)
      .then(() => {
        this.setState({ loading: false, errors: {}, success: true });
        this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };

  render() {
    const { data, errors, loading, success } = this.state;
    return (
      <div>
        <div>
          {!!success ? (
            <Alert color="info">Supplier Contact is added Successfully</Alert>
          ) : null}
          {!!errors.suppliercontactnumber ? (
            <Alert color="danger">Supplier Contact Number already exist</Alert>
          ) : null}
          {!!errors.username ? (
            <Alert color="danger">{errors.username}</Alert>
          ) : null}
        </div>
        <div>
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
            ref={el => (this.myFormRef = el)}
          >
            <Card>
              <CardHeader>Supplier Contact</CardHeader>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label className='small-font-size' for="fullname">Full Name</Label>
                      <span className="required-label">*</span>
                      <AvField
                        name="suppliercontactname"
                        type="text"
                        id="fullname"
                        value={data.suppliercontactname}
                        onChange={this.onChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Your Name"
                          },
                      
                          pattern: {
                            value: "^[a-zA-Z ]+$",
                            errorMessage: "Please enter valid Name"
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label className='small-font-size' for="phone">Phone</Label>
                      <span className="required-label">*</span>
                      <AvField
                        type="text"
                        name="suppliercontactnumber"
                        id="phone"
                        value={data.suppliercontactnumber}
                        onChange={this.onChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter your phone number"
                          },
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage:
                              "Your phone number must be composed only with numbers"
                          },
                          minLength: {
                            value: 10,
                            errorMessage:
                              "Your phone number must be between 10 and 12 numbers"
                          },
                          maxLength: {
                            value: 12,
                            errorMessage:
                              "Your phone number be between 10 and 12 numbers"
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <div className="mg-20">
                  <Button
                    color="primary"
                    disabled={loading}
                    className="lgn-loader"
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </AvForm>
        </div>
      </div>
    );
  }
}

export default SupplierContactForm;
