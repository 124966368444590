import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import Select from 'react-select';
import moment from 'moment';
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import CustomLoader from "../../../../Components/loader/loader";

class ProformaInvoiceForm extends React.Component {
  state = {
    customerID: null,
    transaction: [],
    proformainvoicelist: [],
    data: {
      customerdetail: {
        id: null,
        customernumber: '',
        customergstnumber: '',
        customercontactnumber: '',
        customergstnumber: '',
        customeraddress1: '',
        customeraddress2: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        deliveryAddress: {
          address1: '',
          address2: '',
          city: '',
          pincode: '',
          state: '',
          country: ''
        }
      },
      selectedItemlist: {
        id: [null],
        itemname: [null],
        itemdescription: [null],
        itemshortdescription: [null],
        itemtype: [null],
        itemrate: [null],
        itemuom: [null],
        itemdiscount: [],
        itemdiscountcost: [],
        costperunit: [],
        quantity: [],
        totalcost: []
      },
      invoicenumber: '',
      user_email: '',
      deliverychallannumber: '',
      purchaseordernumber: '',
      vendorcode: '',
      quotationnumber: '',
      transactionid: null,
      quotationid: '',
      vehiclenumber: '',
      hsncode: '',
      saccode: '',
      billingaddress: '',
      remarks: '',
      deliveryaddress: '',
      taxableamount: '',
      freightcharges: '',
      cgst: '',
      sgst: '',
      igst: '',
      tcs: '',
      tcsamount: '',
      transit: '',
      transitamount: '',
      total: '',
      subtotal: '',
      total: '',
      grandtotal: '',
      date: new Date(),
      invoicedate: new Date(),
      purchaseorderdate: new Date(),
      goodsDescription: '',
      roundoffamount: '',
      deliverychallandata: '',
      workorderno: '',
      workorderdate: '',
      despathdate: '',
      removaltime: '',
      transportationcharge: '',
      transportationgst: '',
      advanceamountreceived: '',
      transportchargerequired: 'Yes',
      values: ['']
    },
    selectedCustomerID: null,
    user: {},
    selectedCustomerName: null,
    selectedQuotationId: null,
    selectedQuotationNumber: null,
    errors: {},
    customerobjects: {},
    customerlistvalues: [],
    quotationlistvalues: [],
    customerlistSelect: [],
    quotationlistSelect: [],
    challanlistvalues: [],
    uom: [],
    masteritemlistvalues: [],
    itemlistSelectedOption: [],
    masteritemlistvaluesobject: {},
    masteritemtypelistvalues: [],
    masteritemtypelistvaluesobject: {},
    loading: false,
    success: false,
    visible: false,
    successmsg: ''
  };

  componentDidMount() {
    const invoicelistvalues = Object.keys(this.props.invoicelistvalues);


    const customerlistvalues = Object.values(this.props.customerlistvalues);
    const customerobjects = this.props.customerlistvalues;
    const user = this.props.user;
    const masteritemlistvalues = Object.values(this.props.masteritemlistvalues);
    const masteritemlistvaluesobject = this.props.masteritemlistvalues;
    const quotationlistvalues = Object.values(this.props.quotationlistvalues);
    const challanlistvalues = Object.values(this.props.challanlistvalues);
    console.log(challanlistvalues)
    const masteritemtypelistvalues = Object.values(
      this.props.masteritemtypelistvalues
    );
    const masteritemtypelistvaluesobject = this.props.masteritemtypelistvalues;

    let arrayQuotationSet = [];
    quotationlistvalues.map((option, i) => {
      let objset = {
        value: option._id, label: option.quotationnumber
      }
      arrayQuotationSet.push(objset);
    });

    const uom = Object.values(this.props.uomlistvalues);
    this.setState({
      ...this.state,
      customerlistvalues,
      challanlistvalues,
      user,
      quotationlistvalues,
      quotationlistSelect: arrayQuotationSet,
      customerobjects,
      masteritemlistvalues,
      masteritemlistvaluesobject,
      masteritemtypelistvalues,
      masteritemtypelistvaluesobject,
      uom
    });

    if (this.props.ID != undefined) {

      this.getInvoiceDetails(this.props.ID)
    }
    else {
      this.getChallandata();
    }
  }



  getChallandata() {

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/challan/getchallan`)
      .then(res => {
        if (res.data.challanlist.challandata.length > 0) {
          const challanlistvalues = res.data.challanlist.challandata;
          this.setState({
            ...this.state,
            challanlistvalues
          })
        }
      },
        error => {
          this.setState({ success: false, message: 'Server error.' });
        });
  }


  dataHandler = value => {
    this.setState({ loading: value });
  };

  getInvoiceDetails(id) {
    this.dataHandler(true)
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/proformainvoice/getsingleproformainvoice/${id}`)
      .then(res => {

        if (res.data.invoicelist.invoicedata.length > 0) {
          let founddata = res.data.invoicelist.invoicedata;

          const newState = Object.assign({}, this.state);
          newState.data.customerdetail.id = founddata[0].customerid;
          newState.data.values = founddata[0].addeditemlist;

          newState.data.invoicenumber = founddata[0].invoicenumber;
          newState.data.date = new Date(founddata[0].date);
          newState.data.deliverychallannumber = founddata[0].deliverychallannumber;
          if (founddata[0].deliverychallannumber && founddata[0].deliverychallannumber != '')
            this.handleChallanChange(founddata[0].deliverychallannumber)

          if (founddata[0].purchaseordernumber != '' && founddata[0].purchaseordernumber != null) {
            if (founddata[0].purchaseordernumber.toString().lastIndexOf('$') > -1) {
              newState.data.purchaseordernumber = founddata[0].purchaseordernumber.toString().split('$')[0]
            }
            else
              newState.data.purchaseordernumber = founddata[0].purchaseordernumber
          }

          newState.data.vendorcode = founddata[0].vendorcode;

          newState.data.vehiclenumber = founddata[0].vehiclenumber;
          newState.data.hsncode = founddata[0].hsncode;
          newState.data.saccode = founddata[0].saccode;
          newState.data.billingaddress = founddata[0].billingaddress;
          newState.data.remarks = founddata[0].remarks ? founddata[0].remarks : '';
          newState.data.deliveryaddress = founddata[0].deliveryaddress;

          newState.data.taxableamount = founddata[0].taxableamount;
          newState.data.freightcharges = founddata[0].freightcharges;
          newState.data.cgst = founddata[0].cgst;
          newState.data.sgst = founddata[0].sgst;

          newState.data.igst = founddata[0].igst;
          newState.data.tcs = founddata[0].tcs;
          newState.data.tcsamount = founddata[0].tcsamount;
          newState.data.transit = founddata[0].transit ? founddata[0].transit : "";
          newState.data.transitamount = founddata[0].transitamount ? founddata[0].transitamount : "0";
          newState.data.total = founddata[0].total;
          newState.data.subtotal = founddata[0].subtotal;
          newState.data.grandtotal = founddata[0].grandtotal;
          newState.data.invoicedate = new Date(founddata[0].invoicedate);
          newState.data.purchaseorderdate = new Date(founddata[0].purchaseorderdate);
          newState.data.goodsDescription = founddata[0].goodsDescription;
          newState.data.roundoffamount = founddata[0].roundoffamount;

          newState.data.deliverychallandata = founddata[0].deliverychallandata ? founddata[0].deliverychallandata : '';
          newState.data.workorderno = founddata[0].workorderno ? founddata[0].workorderno : ''
          newState.data.workorderdate = founddata[0].workorderdate ? new Date(founddata[0].workorderdate) : ''
          newState.data.despathdate = founddata[0].despathdate ? new Date(founddata[0].despathdate) : ''
          newState.data.removaltime = founddata[0].removaltime ? founddata[0].removaltime : ''
          newState.data.transportationcharge = founddata[0].transportationcharge ? founddata[0].transportationcharge : ''
          newState.data.transportationgst = founddata[0].transportationgst ? founddata[0].transportationgst : ''

          newState.data.advanceamountreceived = founddata[0].advanceamountreceived ? founddata[0].advanceamountreceived : ''
          newState.data.transportchargerequired = founddata[0].transportchargerequired ? founddata[0].transportchargerequired : ''

          if (founddata[0].addeditemlist.length > 0) {

            for (var i = 0; i < founddata[0].addeditemlist.length; i++) {
              founddata[0].addeditemlist[i].itemdiscount = founddata[0].addeditemlist[i].itemdiscount == null || founddata[0].addeditemlist[i].itemdiscount == '' ?
                founddata[0].addeditemlist[i].itemdiscount = 0 : founddata[0].addeditemlist[i].itemdiscount
              founddata[0].addeditemlist[i].itemdiscount = (founddata[0].addeditemlist[i].itemdiscount).toString()

              founddata[0].addeditemlist[i].itemdiscountcost = founddata[0].addeditemlist[i].itemdiscountcost == null || founddata[0].addeditemlist[i].itemdiscountcost == '' ?
                founddata[0].addeditemlist[i].itemdiscountcost = 0 : founddata[0].addeditemlist[i].itemdiscountcost
              founddata[0].addeditemlist[i].itemdiscountcost = (founddata[0].addeditemlist[i].itemdiscountcost).toString()


              founddata[0].addeditemlist[i].quantity = founddata[0].addeditemlist[i].quantity == null || founddata[0].addeditemlist[i].quantity == '' ?
                founddata[0].addeditemlist[i].quantity = 0 : founddata[0].addeditemlist[i].quantity
              founddata[0].addeditemlist[i].quantity = (founddata[0].addeditemlist[i].quantity).toString()

              founddata[0].addeditemlist[i].costperunit = founddata[0].addeditemlist[i].costperunit == null || founddata[0].addeditemlist[i].costperunit == '' ?
                founddata[0].addeditemlist[i].costperunit = 0 : founddata[0].addeditemlist[i].costperunit
              founddata[0].addeditemlist[i].costperunit = (founddata[0].addeditemlist[i].costperunit).toString()

              founddata[0].addeditemlist[i].totalcost = founddata[0].addeditemlist[i].totalcost == null || founddata[0].addeditemlist[i].totalcost == '' ?
                founddata[0].addeditemlist[i].totalcost = 0 : founddata[0].addeditemlist[i].totalcost
              founddata[0].addeditemlist[i].totalcost = (founddata[0].addeditemlist[i].totalcost).toString()





              newState.data.selectedItemlist.itemdescription.push(founddata[0].addeditemlist[i].itemdescription)
              if (founddata[0].addeditemlist[i].itemshortdescription) {
                newState.data.selectedItemlist.itemshortdescription.push(founddata[0].addeditemlist[i].itemshortdescription)
              }
              newState.data.selectedItemlist.itemtype.push(founddata[0].addeditemlist[i].itemtype._id)
              newState.data.selectedItemlist.itemdiscount.push(founddata[0].addeditemlist[i].itemdiscount)
              if (founddata[0].addeditemlist[i].itemdiscount) {
                let discount = parseFloat(founddata[0].addeditemlist[i].costperunit) * parseFloat(founddata[0].addeditemlist[i].itemdiscount) / 100;
                discount = founddata[0].addeditemlist[i].costperunit - discount;
                newState.data.selectedItemlist.itemdiscountcost.push(discount.toFixed(1));
              }



              newState.data.selectedItemlist.itemuom.push(founddata[0].addeditemlist[i].itemuom._id)
              newState.data.selectedItemlist.quantity.push(founddata[0].addeditemlist[i].quantity)

              newState.data.selectedItemlist.costperunit.push(founddata[0].addeditemlist[i].costperunit)

              newState.data.selectedItemlist.totalcost.push(founddata[0].addeditemlist[i].totalcost)
              newState.data.selectedItemlist.id.push(founddata[0].addeditemlist[i].id)

            }
          }

          newState.selectedQuotationId = founddata[0].quotationid;
          newState.selectedQuotationNumber = founddata[0].quotationnumber;

          this.setState(newState);
          this.handleChange(founddata[0].customerid)
          if (founddata[0].addeditemlist.length > 0) {
            founddata[0].addeditemlist.filter((item, index) => {
              this.selectitemchange(item.id._id, index, false);
            })
          }

          this.dataHandler(false)
        }
        else {
          this.setState({ count: 0 })
          this.dataHandler(false)
        }
      },
        error => {
          this.dataHandler(false)
          this.setState({ success: false, message: 'Server error.' });
        });
  }

  async componentWillReceiveProps(props) {
    const invoicelistvalues = Object.keys(props.invoicelistvalues);
    const customerlistvalues = Object.values(props.customerlistvalues);
    const quotationlistvalues = Object.values(props.quotationlistvalues);
    const customerobjects = props.customerlistvalues;
    const user = props.user;
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    const masteritemlistvaluesobject = props.masteritemlistvalues;
    const masteritemtypelistvalues = Object.values(
      props.masteritemtypelistvalues
    );
    const masteritemtypelistvaluesobject = props.masteritemtypelistvalues;
    const uom = Object.values(props.uomlistvalues);

    console.log("quotation values is ", quotationlistvalues);

    let arraySet = [];
    customerlistvalues.map((option, i) => {
      let objset = {
        value: option.id, label: option.customername
      }
      arraySet.push(objset);
    });

    let arrayQuotationSet = [];
    quotationlistvalues.map((option, i) => {
      let objset = {
        value: option._id, label: option.quotationnumber
      }
      arrayQuotationSet.push(objset);
    });


    let newArraySet = [];
    masteritemlistvalues.map((option, i) => {
      let name = "";
      if (option.masteritemshortdescription) {
        name = option.masteritemshortdescription;
      }
      else {
        name = option.masteritemdescription;
      }
      let objset = {
        value: option.id, label: name
      }
      newArraySet.push(objset);
    });


    if (this.props.ID == undefined) {
      this.dataHandler(true)
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/proformainvoice/getproformainvoice`)
        .then(res => {

          this.dataHandler(false)
          if (res.data.invoicelist.invoicedata.length > 0) {
            let invoicelistlength =
              res.data.invoicelist.invoicedata.length > 0 ? 35 + res.data.invoicelist.invoicedata.length : 35;
            let invoicenumber = (1 + invoicelistlength)
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                invoicenumber
              }
            });
          }
          else {
            const invoicenumber = 35
            this.setState({
              data: {
                ...this.state.data,
                invoicenumber
              }
            })
          }
          this.getChallandata()
        },
          error => {
            this.dataHandler(false)

          });


      this.dataHandler(false)
    }


    this.setState({
      ...this.state,
      customerlistvalues,
      customerlistSelect: arraySet,
      customerobjects,
      user_email: user.email,
      quotationlistvalues,
      masteritemlistvalues,
      quotationlistSelect: arrayQuotationSet,
      quotationlistSelect: arrayQuotationSet,
      itemlistSelectedOption: newArraySet,
      masteritemlistvaluesobject,
      masteritemtypelistvalues,
      masteritemtypelistvaluesobject,
      uom
    });
  }

  onhandlerChange = e => {
    if (e.target.name === 'freightcharges') {
      const sub = parseFloat(e.target.value)
        ? parseFloat(e.target.value).toFixed(2)
        : 0;
      const taxable = parseFloat(this.state.data.taxableamount).toFixed(2);
      const subtotal = Number(sub) + Number(taxable);
      console.log(subtotal);
      this.setState(
        {
          ...this.state,
          data: {
            ...this.state.data,
            [e.target.name]: e.target.value,
            ['subtotal']: subtotal
          }
        },
        () => {
          this.calculatePercentageAmount();
        }
      );
    } else if (
      e.target.name === 'cgst' ||
      e.target.name === 'igst' ||
      e.target.name === 'sgst'
    ) {
      const ttl = parseFloat(this.state.data.total).toFixed(2);
      const gst = parseFloat(e.target.value).toFixed(2);
      const total = Number(ttl) + Number(ttl) * (Number(gst) / 100).toFixed(2);
      const gt = parseFloat(this.state.data.grandtotal).toFixed(2);
      const grandtotal =
        Number(gt) + Number(gt) * (Number(gst) / 100).toFixed(2);
      this.setState(
        {
          ...this.state,
          data: {
            ...this.state.data,
            [e.target.name]: e.target.value
          }
        },
        () => {
          this.calculatePercentageAmount();
        }
      );
    } else if (e.target.name === 'tcs') {
      console.log("tcs value is", e.target.value);
      if (e.target.value == "false") {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              [e.target.name]: '0.1'
            }
          });
      }
      else if (e.target.value == "true") {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              tcsamount: '',
              [e.target.name]: ''
            }
          },
          () => {
            this.calculatePercentageAmount();
          }
        );
      }
    } else if (e.target.name === 'tcsamount') {
      this.setState(
        {
          ...this.state,
          data: {
            ...this.state.data,
            [e.target.name]: e.target.value
          }
        },
        () => {
          this.calculatePercentageAmount();
        }
      );
    } else if (e.target.name === 'transit') {
      console.log("transit value is", e.target.value);
      if (e.target.value == "false") {
        // TCS
        let tcs = this.state.data.tcs;
        //tcs amount
        let tcsamount = this.state.data.tcsamount;
        // CGST
        let cgst = this.state.data.cgst;
        cgst = parseFloat(cgst) ? parseFloat(cgst).toFixed(2) : 0;
        // SGST
        let sgst = this.state.data.sgst;
        sgst = parseFloat(sgst) ? parseFloat(sgst).toFixed(2) : 0;
        // CGST
        let igst = this.state.data.igst;
        igst = parseFloat(igst) ? parseFloat(igst).toFixed(2) : 0;

        let subTotal = parseFloat(this.state.data.subtotal)
          ? Number(parseFloat(this.state.data.subtotal).toFixed(2))
          : 0;
        let roundoffamount = parseFloat(this.state.data.roundoffamount)
          ? parseFloat(this.state.data.roundoffamount).toFixed(2)
          : 0;
        // Convert it to percentage
        cgst = (subTotal * cgst) / 100;
        sgst = (subTotal * sgst) / 100;
        igst = (subTotal * igst) / 100;

        const total = (Number(subTotal) + Number(cgst) + Number(tcsamount) + Number(sgst) + Number(igst)).toFixed(2);
        let transitamount = (Number(total) * 0.1) / 100;
        transitamount = transitamount.toFixed(2);
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              [e.target.name]: '0.1',
              transitamount: transitamount
            }
          },
          () => {
            this.calculatePercentageAmount();
          }
        );
      }
      else if (e.target.value == "true") {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              transitamount: '',
              [e.target.name]: ''
            }
          },
          () => {
            this.calculatePercentageAmount();
          }
        );
      }
    } else if (e.target.name === 'transitamount') {
      this.setState(
        {
          ...this.state,
          data: {
            ...this.state.data,
            [e.target.name]: e.target.value
          }
        },
        () => {
          this.calculatePercentageAmount();
        }
      );
    }
    else if (e.target.name === 'roundoffamount') {
      const roa = parseFloat(e.target.value)
        ? parseFloat(e.target.value).toFixed(2)
        : 0;
      const gt = parseFloat(this.state.data.grandtotal).toFixed(2);

      let grandtotal = 0;
      if (roa > 0) grandtotal = Number(gt) + Number(roa);
      else {
        grandtotal = Number(gt) - Number(this.state.data.roundoffamount);
      }
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value
        }
      }, () => {
        this.calculateRoundoffTotal();
      });
    } else {
      this.setState({
        ...this.state,
        data: { ...this.state.data, [e.target.name]: e.target.value }
      });
    }
  };
  calculateRoundoffTotal = () => {
    let grandtotal = 0;
    const gt = parseFloat(this.state.data.grandtotal).toFixed(2);
    const total = parseFloat(this.state.data.total).toFixed(2);
    const roa = Number(this.state.data.roundoffamount);
    if (roa > 0) {
      grandtotal = Number(total) + Number(roa)
    } else if (roa < 0) {
      grandtotal = Number(total) + Number(roa)
    } else {
      grandtotal = total
    }
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        ['grandtotal']: grandtotal
      }
    });
  }
  calculatePercentageAmount = () => {
    // TCS
    let tcs = this.state.data.tcs;
    //tcs amount
    let tcsamount = this.state.data.tcsamount;
    // Transit
    let transit = this.state.data.transit;
    //transit amount
    let transitamount = this.state.data.transitamount;
    // CGST
    let cgst = this.state.data.cgst;
    cgst = parseFloat(cgst) ? parseFloat(cgst).toFixed(2) : 0;
    // SGST
    let sgst = this.state.data.sgst;
    sgst = parseFloat(sgst) ? parseFloat(sgst).toFixed(2) : 0;
    // CGST
    let igst = this.state.data.igst;
    igst = parseFloat(igst) ? parseFloat(igst).toFixed(2) : 0;

    let subTotal = parseFloat(this.state.data.subtotal)
      ? Number(parseFloat(this.state.data.subtotal).toFixed(2))
      : 0;
    let roundoffamount = parseFloat(this.state.data.roundoffamount)
      ? parseFloat(this.state.data.roundoffamount).toFixed(2)
      : 0;
    // Convert it to percentage
    cgst = (subTotal * cgst) / 100;
    sgst = (subTotal * sgst) / 100;
    igst = (subTotal * igst) / 100;

    const total = (Number(subTotal) + Number(cgst) + Number(tcsamount) + Number(sgst) + Number(igst)).toFixed(2);
    const grandtotal = (Number(total) + Number(roundoffamount) + Number(transitamount)).toFixed(2);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        ['total']: total,
        ['grandtotal']: grandtotal
      }
    });
  };

  onCustomerChange = e => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customerdetail: {
          ...this.state.data.customerdetail,
          [e.target.name]: e.target.value
        }
      }
    });
  };

  onRemarksChange = e => {

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        remarks: e.target.value
      }
    });
  };

  handleDate = date => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, ['date']: date }
    });
  };
  handleWorkorderDate = date => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, ['workorderdate']: date }
    });
  };
  handleDespathdate = date => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, ['despathdate']: date }
    });
  };

  purchaseDate = date => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, ['purchaseorderdate']: date }
    });
  };
  invocieDate = date => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, ['invoicedate']: date }
    });
  };
  onSubjectHandler = e => {
    console.log(e.target.value)
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    }, () => {
      console.log(this.state.data)
    });
  };

  createSelectcustomer = () => {
    let items = [];
    const customerlistvalues = this.state.customerlistvalues;
    customerlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.customername}
        </option>
      );
    });
    return items;
  };

  selectitemchange = (e, index, bool) => {
    let selected_id = e.value ? e.value : e;
    if (selected_id) {
      let selected_item = this.state.masteritemlistvaluesobject[selected_id];
      if (selected_item) {
        const selectedItemlist = { ...this.state.data.selectedItemlist };
        selectedItemlist.id[index] = selected_item.id;
        selectedItemlist.itemname[index] = selected_item.masteritemtypename;
        selectedItemlist.itemdescription[index] =
          selected_item.masteritemdescription;
        // selectedItemlist.itemdescription[index] = selected_item.masteritemshortdescription == '' ? selected_item.masteritemdescription 
        // : selected_item.masteritemshortdescription
        selectedItemlist.itemtype[index] = selected_item.masteritemtype._id;
        selectedItemlist.itemrate[index] = selected_item.masteritemrate;
        selectedItemlist.itemuom[index] = selected_item.masteritemuom._id;

        if (this.props.ID == undefined) {

          selectedItemlist.itemdiscount[index] = '0';
          selectedItemlist.itemdiscountcost[index] = '0';
          selectedItemlist.costperunit[index] = selected_item.masteritemcostperunit
            ? selected_item.masteritemcostperunit.toString()
            : '';
          selectedItemlist.quantity[index] = (1).toString();
          selectedItemlist.totalcost[index] = selected_item.masteritemcostperunit
            ? selected_item.masteritemcostperunit.toString()
            : '';

        }



        this.setState({
          ...this.state.data,
          data: {
            ...this.state.data,
            selectedItemlist
          }
        }, () => {
          if (bool)
            this.totalValue();
        });
      }


    }

  };

  quantityChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const costperunit = selectedItemlist.costperunit[index] || null;
    selectedItemlist.quantity[index] = e.target.value;
    const quantity = e.target.value;
    const discountpercentagevalue = selectedItemlist.itemdiscount[index];
    if (discountpercentagevalue && costperunit && quantity) {
      const totalcost =
        parseFloat(quantity) * parseFloat(selectedItemlist.costperunit[index]);
      const discountvalue = parseFloat(discountpercentagevalue / 100).toFixed(
        2
      );
      const totaldiscountcost = parseFloat(totalcost * discountvalue).toFixed(
        2
      );
      const totalvaluecost = totalcost - totaldiscountcost;
      selectedItemlist.itemdiscountcost[index] = totaldiscountcost ?
        totaldiscountcost : null || '';
      selectedItemlist.totalcost[index] = totalvaluecost
        ? totalvaluecost.toFixed(2)
        : null || '';
    } else {
      const totalValue = costperunit
        ? e.target.value * costperunit
        : null || undefined || '';
      selectedItemlist.totalcost[index] = totalValue
        ? parseFloat(totalValue).toFixed(2)
        : '';
    }
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  itemdiscountChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const discount = e.target.value;
    selectedItemlist.itemdiscount[index] = e.target.value;
    const unitcost = selectedItemlist.costperunit[index];
    const quantity = selectedItemlist.quantity[index];
    const particularindextotalvalue = parseFloat(unitcost * quantity).toFixed(
      2
    );
    if (particularindextotalvalue && discount) {
      const discountvalue = parseFloat(discount / 100).toFixed(2);
      const totaldiscountcost = parseFloat(
        particularindextotalvalue * discountvalue
      ).toFixed(2);
      const totalvaluecost = particularindextotalvalue - totaldiscountcost;
      selectedItemlist.itemdiscountcost = totaldiscountcost;
      selectedItemlist.totalcost[index] = parseFloat(totalvaluecost).toFixed(2);
    } else {
      selectedItemlist.totalcost[index] = particularindextotalvalue;
    }
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  unitChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.costperunit[index] = e.target.value;
    const discountpercentagevalue = selectedItemlist.itemdiscount[index];
    if (discountpercentagevalue) {
      const totalcost =
        parseFloat(selectedItemlist.quantity[index]) *
        parseFloat(selectedItemlist.costperunit[index]);
      const discountvalue = parseFloat(discountpercentagevalue / 100).toFixed(
        2
      );
      const totaldiscountcost = parseFloat(totalcost * discountvalue).toFixed(
        2
      );
      const totalvaluecost = totalcost - totaldiscountcost;
      selectedItemlist.itemdiscountcost[index] = totaldiscountcost
        ? totaldiscountcost
        : null || '';
      selectedItemlist.totalcost[index] = totalvaluecost
        ? totalvaluecost.toFixed(2)
        : null || '';
    } else {
      const totalcost =
        parseFloat(selectedItemlist.quantity[index]) *
        parseFloat(selectedItemlist.costperunit[index]);
      selectedItemlist.totalcost[index] = totalcost
        ? totalcost.toFixed(2)
        : null || '';
    }

    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  createSelectItemTypes = () => {
    let items = [];
    const masteritemtypelistvalues = this.state.masteritemtypelistvalues;
    masteritemtypelistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  totalValue = () => {
    const { totalcost } = this.state.data.selectedItemlist;
    if (totalcost.length > 0) {
      const taxableamount = totalcost
        .map((value, i) => {
          if (parseFloat(value)) {
            const floatvalue = parseFloat(value).toFixed(2);
            return floatvalue;
          } else {
            return parseFloat(0);
          }
        })
        .reduce((a, b) => {
          const parsedvalue = parseFloat(a) + parseFloat(b);
          return parsedvalue;
        });
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          taxableamount,
          ['subtotal']: taxableamount,
          ['total']: taxableamount,
          ['grandtotal']: taxableamount
        }
      });
    }
  };

  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uom;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };

  handleChange = e => {
    let selected_id = e.value ? e.value : e
    this.updateQuotation(selected_id);
    let customerdetail = this.state.customerobjects[selected_id];
    if (customerdetail) {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          billingaddress:
            customerdetail.customeraddress1 +
            ', ' +
            customerdetail.customeraddress2 +
            ', ' +
            customerdetail.city +
            ', ' +
            customerdetail.state +
            ', ' +
            customerdetail.country +
            ', ' +
            customerdetail.pincode,
          deliveryaddress:
            customerdetail.deliveryAddress.address1 +
            ', ' +
            customerdetail.deliveryAddress.address2 +
            ', ' +
            customerdetail.deliveryAddress.city +
            ', ' +
            customerdetail.deliveryAddress.state +
            ', ' +
            customerdetail.deliveryAddress.country +
            ', ' +
            customerdetail.deliveryAddress.pincode,
          customerdetail
        }, selectedCustomerID: selected_id, selectedCustomerName: customerdetail.customername
      });
    }

  };

  handleClick = e => {
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ''],
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id: [...this.state.data.selectedItemlist.id, ''],
          itemname: [...this.state.data.selectedItemlist.itemname, ''],
          itemdescription: [
            ...this.state.data.selectedItemlist.itemdescription,
            ''
          ],
          itemshortdescription: [
            ...this.state.data.selectedItemlist.itemshortdescription,
            ''
          ],
          itemtype: [...this.state.data.selectedItemlist.itemtype, ''],
          itemrate: [...this.state.data.selectedItemlist.itemrate, ''],
          itemuom: [...this.state.data.selectedItemlist.itemuom, ''],
          itemdiscount: [...this.state.data.selectedItemlist.itemdiscount, ''],
          costperunit: [...this.state.data.selectedItemlist.costperunit, ''],
          quantity: [...this.state.data.selectedItemlist.quantity, ''],
          totalcost: [...this.state.data.selectedItemlist.totalcost, '']
        }
      }
    });




  };

  filterItems(itemarray, key) {
    return itemarray.sort((a, b) => { return a[key].toLowerCase().localeCompare(b[key].toLowerCase()) })
  }

  createSelectItems = () => {
    let items = [];
    let masteritemlistvalues = this.state.masteritemlistvalues;
    masteritemlistvalues = this.filterItems(masteritemlistvalues, 'masteritemtypename')
    masteritemlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  removeClick = (e, index) => {
    const removeItem = { ...this.state.data };
    const values = removeItem.values;
    const id = removeItem.selectedItemlist.id;
    const itemname = removeItem.selectedItemlist.itemname;
    const itemdescription = removeItem.selectedItemlist.itemdescription;
    const itemshortdescription = removeItem.selectedItemlist.itemshortdescription;
    const itemtype = removeItem.selectedItemlist.itemtype;
    const itemrate = removeItem.selectedItemlist.itemrate;
    const itemuom = removeItem.selectedItemlist.itemuom;
    const itemdiscount = removeItem.selectedItemlist.itemdiscount;
    const costperunit = removeItem.selectedItemlist.costperunit;
    const quantity = removeItem.selectedItemlist.quantity;
    const totalcost = removeItem.selectedItemlist.totalcost;
    values.splice(index, 1);
    id.splice(index, 1);
    itemname.splice(index, 1);
    itemdescription.splice(index, 1);
    itemshortdescription.splice(index, 1);
    itemtype.splice(index, 1);
    itemrate.splice(index, 1);
    itemuom.splice(index, 1);
    itemdiscount.splice(index, 1);
    costperunit.splice(index, 1);
    quantity.splice(index, 1);
    totalcost.splice(index, 1);


    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id,
          itemname,
          itemdescription,
          itemshortdescription,
          itemtype,
          itemrate,
          itemuom,
          itemdiscount,
          costperunit,
          quantity,
          totalcost
        }
      }
    });
  };

  handleValidSubmit = () => {

    this.saveDynamicPonum();
    let AddedIteminfo = [];
    const { customerdetail, selectedItemlist } = this.state.data;
    const id = selectedItemlist.id;
    const itemname = selectedItemlist.itemname;
    const itemdescription = selectedItemlist.itemdescription;
    const itemshortdescription = selectedItemlist.itemshortdescription || null;
    const itemtype = selectedItemlist.itemtype;
    const itemrate = selectedItemlist.itemrate;
    const itemuom = selectedItemlist.itemuom;
    const itemdiscount = selectedItemlist.itemdiscount;
    const costperunit = selectedItemlist.costperunit;
    const quantity = selectedItemlist.quantity;
    const totalcost = selectedItemlist.totalcost;

    this.setState({ loading: true });
    const { data } = this.state;
    console.log(data);
    let values = data.values;
    values.forEach(function (value, i) {
      let obj = {};
      obj.id = id[i] || null;
      obj.itemname = itemname[i] || null;
      obj.itemdescription = itemdescription[i] || null;
      obj.itemshortdescription = itemshortdescription[i] || null;
      obj.itemtype = itemtype[i] || null;
      obj.itemrate = itemrate[i] || null;
      obj.itemuom = itemuom[i] || null;
      obj.itemdiscount = itemdiscount[i] || null;
      obj.costperunit = costperunit[i] || null;
      obj.quantity = quantity[i] || null;
      obj.totalcost = totalcost[i] || null;
      AddedIteminfo.push(obj);
    });
    data['AddedIteminfo'] = AddedIteminfo;
    console.log(JSON.stringify(data));

    if (this.props.ID != undefined) {
      data['_id'] = this.props.ID;
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/proformainvoice/updateproformainvoice`, {
          data
        })
        .then(res => {
          this.setState({ loading: false, errors: {}, success: true, successmsg: 'Proforma Invoice edited successfully' });

        }, (err) => {
          this.setState({ loading: false, errors: {}, success: true, successmsg: 'Proforma Invoice edited successfully' });

        });
    }

    else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/proformainvoice/addproformainvoice`, {
          data
        })
        .then(res => {

          this.myFormRef.reset();
          this.setState({ loading: false, errors: {}, success: true, successmsg: 'Proforma Invoice is generated successfully' });

        }, (err) => {

          this.myFormRef.reset();
          this.setState({ loading: false, errors: {}, success: true, successmsg: 'Proforma Invoice is generated successfully' });

        });
    }
  };

  updateQuotation(id) {
    if (id != '') {
      localStorage.setItem('customerId', id);
    }
  }

  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
    });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  handleInvalidSubmit = () => {
    console.log('invalid submit');
  };

  createChallanList = () => {
    let items = [];
    const challanlistvalues = this.state.challanlistvalues;
    challanlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.deliverychallanno}>
          {option.deliverychallanno}
        </option>
      );
    });
    return items;
  };

  saveDynamicPonum() {
    let purchaseordernumber = this.state.data.purchaseordernumber + "$" + new Date().getTime()

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        purchaseordernumber,
      }
    }, () => {
      console.log(this.state.data)
    })
  }


  handleQuotationChange = async (e) => {
    console.log(e)
    let selectedquotation = e.value ? e.value : e
    console.log(selectedquotation)
    const quotationlistvalues = this.state.quotationlistvalues;
    console.log("quotation list values ", quotationlistvalues);
    let quotationselected = quotationlistvalues.filter(
      quotation => quotation._id === selectedquotation || quotation.quotationnumber === selectedquotation
    );
    console.log(quotationselected)
    if (quotationselected && quotationselected.length > 0) {
      quotationselected = quotationselected[0];

      const newState = Object.assign({}, this.state);
      newState.data.customerdetail.id = quotationselected.customerid ? quotationselected.customerid : '';


      let poDate = "";
      let transId = null;
      const quotationnumber = quotationselected.quotationnumber;
      const quotationid = selectedquotation;
      this.dataHandler(true);
      const purchaseordernumber = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/customerTransaction/getcustomertransaction/` + quotationselected.customerid)
        .then(res => {
          this.dataHandler(false)
          if (res.data.customerlist.customerdata.length > 0) {
            let customervalues = res.data.customerlist.customerdata;
            if (customervalues.length > 0) {
              customervalues = customervalues.filter((item) => {
                return item.Qno == quotationselected.quotationnumber
              });
              if (customervalues.length > 0) {
                transId = customervalues[0]['_id'];
                poDate = customervalues[0]['po'];
                return customervalues[0]['ponum'];
              }
              else {
                transId = "";
                poDate = "";
                return "";
              }
            }
          }
        },
          error => {
            this.dataHandler(false);
          });
      const date = new Date(quotationselected.date);
      let purchaseorderdate = this.state.data.purchaseorderdate;
      if (poDate != "" && poDate !== null) {
        let arrDate = poDate.split("/");
        let newDate = arrDate[1] + "/" + arrDate[0] + "/" + arrDate[2];
        purchaseorderdate = new Date(newDate);
      }


      let selectedItemlist = {
        id: [],
        itemname: [],
        itemdescription: [],
        itemshortdescription: [],
        itemtype: [],
        itemrate: [],
        itemuom: [],
        itemdiscount: [],
        itemdiscountcost: [],
        costperunit: [],
        quantity: [],
        totalcost: []
      }
      let values = quotationselected.addeditemlist
      if (quotationselected.addeditemlist.length > 0) {

        for (var i = 0; i < quotationselected.addeditemlist.length; i++) {
          quotationselected.addeditemlist[i].itemdiscount = quotationselected.addeditemlist[i].itemdiscount == null || quotationselected.addeditemlist[i].itemdiscount == '' ?
            quotationselected.addeditemlist[i].itemdiscount = 0 : quotationselected.addeditemlist[i].itemdiscount
          quotationselected.addeditemlist[i].itemdiscount = (quotationselected.addeditemlist[i].itemdiscount).toString()

          quotationselected.addeditemlist[i].itemdiscountamount = quotationselected.addeditemlist[i].itemdiscountamount == null || quotationselected.addeditemlist[i].itemdiscountamount == '' ?
            quotationselected.addeditemlist[i].itemdiscountamount = 0 : quotationselected.addeditemlist[i].itemdiscountamount
          quotationselected.addeditemlist[i].itemdiscountamount = (quotationselected.addeditemlist[i].itemdiscountamount).toString()


          quotationselected.addeditemlist[i].quantity = quotationselected.addeditemlist[i].quantity == null || quotationselected.addeditemlist[i].quantity == '' ?
            quotationselected.addeditemlist[i].quantity = 0 : quotationselected.addeditemlist[i].quantity
          quotationselected.addeditemlist[i].quantity = (quotationselected.addeditemlist[i].quantity).toString()

          quotationselected.addeditemlist[i].costperunit = quotationselected.addeditemlist[i].costperunit == null || quotationselected.addeditemlist[i].costperunit == '' ?
            quotationselected.addeditemlist[i].costperunit = 0 : quotationselected.addeditemlist[i].costperunit
          quotationselected.addeditemlist[i].costperunit = (quotationselected.addeditemlist[i].costperunit).toString()

          quotationselected.addeditemlist[i].totalcost = quotationselected.addeditemlist[i].totalcost == null || quotationselected.addeditemlist[i].totalcost == '' ?
            quotationselected.addeditemlist[i].totalcost = 0 : quotationselected.addeditemlist[i].totalcost
          quotationselected.addeditemlist[i].totalcost = (quotationselected.addeditemlist[i].totalcost).toString()



          let itemname = quotationselected.addeditemlist[i].itemdescription;
          if (quotationselected.addeditemlist[i].itemshortdescription && quotationselected.addeditemlist[i].itemshortdescription !== '') {
            itemname = quotationselected.addeditemlist[i].itemshortdescription;
          }

          selectedItemlist.itemname.push(itemname);
          selectedItemlist.itemrate.push(quotationselected.addeditemlist[i].costperunit);
          selectedItemlist.itemdescription.push(quotationselected.addeditemlist[i].itemdescription)
          selectedItemlist.itemshortdescription.push(quotationselected.addeditemlist[i].itemshortdescription)
          selectedItemlist.itemtype.push(quotationselected.addeditemlist[i].itemtype._id)
          selectedItemlist.itemdiscount.push(quotationselected.addeditemlist[i].itemdiscount)
          selectedItemlist.itemdiscountcost.push(quotationselected.addeditemlist[i].itemdiscountamount)



          selectedItemlist.itemuom.push(quotationselected.addeditemlist[i].itemuom._id)
          selectedItemlist.quantity.push(quotationselected.addeditemlist[i].quantity)

          selectedItemlist.costperunit.push(quotationselected.addeditemlist[i].costperunit)

          selectedItemlist.totalcost.push(quotationselected.addeditemlist[i].totalcost)
          selectedItemlist.id.push(quotationselected.addeditemlist[i].id)

        }
      }

      let transportcharge = quotationselected.tcharge;
      let transportgst = parseFloat(transportcharge) * parseFloat(quotationselected.transportationgst) / 100;
      let ws = quotationselected.ws;
      let loadingcharges = quotationselected.loadingcharge;

      let totalFreight = parseFloat(transportcharge) + parseFloat(transportgst) + parseFloat(ws) + parseFloat(loadingcharges);



      let freightcharges = totalFreight.toFixed(2);


      const { totalcost } = selectedItemlist;
      let taxableamount = 0;
      if (totalcost.length > 0) {
        taxableamount = totalcost
          .map((value, i) => {
            if (parseFloat(value)) {
              const floatvalue = parseFloat(value).toFixed(2);
              return floatvalue;
            } else {
              return parseFloat(0);
            }
          })
          .reduce((a, b) => {
            const parsedvalue = parseFloat(a) + parseFloat(b);
            return parsedvalue;
          });
      }

      let cgst = Number(quotationselected.tax) / 2;
      let sgst = Number(quotationselected.tax) / 2;

      const taxable = parseFloat(taxableamount).toFixed(2);
      const subtotal = Number(freightcharges) + Number(taxable);

      let tcs = '';
      if (quotationselected.tcs) {
        tcs = Number(quotationselected.tcs);
      }

      let transit = '';
      if (quotationselected.transit) {
        transit = Number(quotationselected.transit);
      }

      let cgstamount;
      let sgstamount;
      let tcsamount = 0;

      if (quotationselected.tcsamount) {
        tcsamount = Number(quotationselected.tcsamount);
      }

      let transitamount = 0;

      if (quotationselected.transitamount) {
        transitamount = Number(quotationselected.transitamount);
      }

      cgstamount = (subtotal * cgst) / 100;
      sgstamount = (subtotal * sgst) / 100;


      const total = (Number(subtotal) + Number(cgstamount) + Number(tcsamount) + Number(sgstamount)).toFixed(2);
      const grandtotal = (Number(total) + Number(transitamount)).toFixed(2);

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          //   values: values,
          taxableamount,
          subtotal: subtotal,
          total: total,
          cgst: cgst,
          sgst: sgst,
          tcs: tcs,
          tcsamount: tcsamount,
          transit: transit,
          transitamount: transitamount,
          grandtotal: grandtotal,
          quotationnumber,
          transactionid: transId,
          quotationid,
          date,
          purchaseordernumber,
          purchaseorderdate,
          selectedItemlist,
          values
        },
        selectedCustomerID: quotationselected.customerid,
        selectedCustomerName: quotationselected.customername,
        selectedQuotationId: selectedquotation,
        selectedQuotationNumber: quotationselected.quotationnumber,
      }, () => {
        console.log(selectedItemlist)
        this.setState({
          ...this.state.data,
          data: {
            ...this.state.data,
            quotationnumber: quotationnumber,
            quotationid: quotationid,
            //  values: values
          }
        }, () => {
          console.log(this.state.data)
          //this.setState(newState);

          // if (quotationselected.addeditemlist.length > 0) {
          //   quotationselected.addeditemlist.filter((item, index) => {
          //     this.selectitemchange(item.id._id, index,true);
          //   })
          // }
          this.totalValue();
          if (quotationselected.customerid) {
            this.handleChange(quotationselected.customerid);
          }
        });
      });
    }
  }

  handleChallanChange = e => {
    console.log(e)
    let selecteddeliveryChallan = e.target ? e.target.value : e
    console.log(selecteddeliveryChallan)
    const challanlistvalues = this.state.challanlistvalues;
    let selectedChallan = challanlistvalues.filter(
      challan => challan._id === selecteddeliveryChallan || challan.deliverychallanno === selecteddeliveryChallan
    );
    console.log(selectedChallan)
    if (selectedChallan && selectedChallan.length > 0) {
      selectedChallan = selectedChallan[0];
      const newState = Object.assign({}, this.state);
      newState.data.customerdetail.id = selectedChallan.customerid ? selectedChallan.customerid._id : '';
      this.setState(newState);
      const deliverychallannumber = selectedChallan.deliverychallanno;
      const purchaseordernumber = selectedChallan.ponumber;
      const date = new Date(selectedChallan.date);
      const purchaseorderdate = new Date(selectedChallan.podate);
      const vehiclenumber = selectedChallan.vehiclenumber;
      console.log(JSON.stringify(selectedChallan));

      let addeditemlist = selectedChallan.addeditemlist;

      let selectedItemlist = {
        id: [],
        itemname: [],
        itemdescription: [],
        itemshortdescription: [],
        itemtype: [],
        itemrate: [],
        itemuom: [],
        itemdiscount: [],
        costperunit: [],
        quantity: [],
        totalcost: []
      }
      let values = selectedChallan.addeditemlist
      if (selectedChallan.addeditemlist.length > 0) {

        for (var i = 0; i < selectedChallan.addeditemlist.length; i++) {
          selectedChallan.addeditemlist[i].itemdiscount = selectedChallan.addeditemlist[i].itemdiscount == null || selectedChallan.addeditemlist[i].itemdiscount == '' ?
            selectedChallan.addeditemlist[i].itemdiscount = 0 : selectedChallan.addeditemlist[i].itemdiscount
          selectedChallan.addeditemlist[i].itemdiscount = (selectedChallan.addeditemlist[i].itemdiscount).toString()


          selectedChallan.addeditemlist[i].quantity = selectedChallan.addeditemlist[i].quantity == null || selectedChallan.addeditemlist[i].quantity == '' ?
            selectedChallan.addeditemlist[i].quantity = 0 : selectedChallan.addeditemlist[i].quantity
          selectedChallan.addeditemlist[i].quantity = (selectedChallan.addeditemlist[i].quantity).toString()

          selectedChallan.addeditemlist[i].costperunit = selectedChallan.addeditemlist[i].costperunit == null || selectedChallan.addeditemlist[i].costperunit == '' ?
            selectedChallan.addeditemlist[i].costperunit = 0 : selectedChallan.addeditemlist[i].costperunit
          selectedChallan.addeditemlist[i].costperunit = (selectedChallan.addeditemlist[i].costperunit).toString()

          selectedChallan.addeditemlist[i].totalcost = selectedChallan.addeditemlist[i].totalcost == null || selectedChallan.addeditemlist[i].totalcost == '' ?
            selectedChallan.addeditemlist[i].totalcost = 0 : selectedChallan.addeditemlist[i].totalcost
          selectedChallan.addeditemlist[i].totalcost = (selectedChallan.addeditemlist[i].totalcost).toString()





          selectedItemlist.itemdescription.push(selectedChallan.addeditemlist[i].itemdescription)
          selectedItemlist.itemshortdescription.push(selectedChallan.addeditemlist[i].itemshortdescription)
          selectedItemlist.itemtype.push(selectedChallan.addeditemlist[i].itemtype._id)
          selectedItemlist.itemdiscount.push(selectedChallan.addeditemlist[i].itemdiscount)



          selectedItemlist.itemuom.push(selectedChallan.addeditemlist[i].itemuom._id)
          selectedItemlist.quantity.push(selectedChallan.addeditemlist[i].quantity)

          selectedItemlist.costperunit.push(selectedChallan.addeditemlist[i].costperunit)

          selectedItemlist.totalcost.push(selectedChallan.addeditemlist[i].totalcost)
          selectedItemlist.id.push(selectedChallan.addeditemlist[i].id)

        }
      }

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          //   values: values,
          deliverychallannumber,
          purchaseordernumber,
          date,
          purchaseorderdate,
          vehiclenumber,
          selectedItemlist,
          values
        }
      }, () => {

        this.setState({
          ...this.state.data,
          data: {
            ...this.state.data,
            deliverychallannumber: deliverychallannumber
            //  values: values
          }
        }, () => {
          console.log(this.state.data)
          if (selectedChallan.addeditemlist.length > 0) {
            selectedChallan.addeditemlist.filter((item, index) => {
              this.selectitemchange(item.id._id, index, false);
            })
          }
          this.handleChange(selectedChallan._id)
        });
      });
    }

  };

  render() {
    const customStyles = {
      control: base => ({
        ...base,
        height: 35,
        minHeight: 35
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '35px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '35px',
      }),
    };
    const { data, errors, loading, success, visible, successmsg } = this.state;
    let SelVal;
    if (this.state.selectedCustomerID != undefined) {
      SelVal = {
        value: this.state.selectedCustomerID, label: this.state.selectedCustomerName
      }
    }
    let customerDisp = this.state.selectedCustomerID != undefined ? (
      <Select name={`customername`} classNamePrefix="select" onChange={this.handleChange.bind(this)} styles={customStyles} required value={SelVal} options={this.state.customerlistSelect} />
    ) : (
      <Select name={`customername`} classNamePrefix="select" onChange={this.handleChange.bind(this)} styles={customStyles} required defaultValue={data.customerdetail.id} options={this.state.customerlistSelect} />
    );
    let SelQuota;
    if (this.state.selectedQuotationId) {
      SelQuota = {
        value: this.state.selectedQuotationId, label: this.state.selectedQuotationNumber
      }
    }
    let quotationdisp = this.state.selectedQuotationId != undefined ? (
      <Select name={`deliverychallannumber`} classNamePrefix="select" onChange={this.handleQuotationChange.bind(this)} styles={customStyles} required value={SelQuota} options={this.state.quotationlistSelect} />
    ) : (
      <Select name={`deliverychallannumber`} classNamePrefix="select" onChange={this.handleQuotationChange.bind(this)} styles={customStyles} required defaultValue={data.deliverychallannumber} options={this.state.quotationlistSelect} />
    );
    return (
      <div>
        <div>
          {loading ? <CustomLoader /> : null}
          {!!success ? (
            // <Alert color="info" isOpen={visible}>
            //   Quotation is added successfully
            // </Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Invoice</ModalHeader>
              <ModalBody >{successmsg}</ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <div>
          {!!errors.quotationnumber ? (
            <Alert Label color='danger' isOpen={visible}>
              Proforma Invoice number should have unique value.
            </Alert>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          //  onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Proforma Invoice</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='ponumber'>Proforma Invoice Number</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      Label
                      name='invoicenumber'
                      type='number'
                      id='invoicenumber'
                      value={data.invoicenumber || null}
                      onChange={this.onhandlerChange}

                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter quotation Number'
                        },
                        pattern: {
                          value: '^[0-9]+$',
                          errorMessage: 'Please enter valid Number'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='vendorcode'>Quotation No</Label>
                  {/* <AvField
                    name='deliverychallannumber'
                    type='text'
                    id='deliverychallannumber'
                    value={data.deliverychallannumber || null}
                    onChange={e => this.onhandlerChange(e)}
                  /> */}
                  {/* <AvField
                    Label
                    type='select'
                    name={`deliverychallannumber`}
                    //required={true}          
                    value={data.deliverychallannumber}
                    onChange={e => this.handleChallanChange(e)}
                  >
                    <option value=''>select challan</option>
                    {this.createChallanList()}
                  </AvField> */}
                  {quotationdisp}
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='date'>Quotation date</Label>
                  <DatePicker
                    selected={data.date}
                    autoComplete="new-password"
                    onChange={this.handleDate}
                    className='form-control small-font-size'
                    dateFormat='dd/MM/yyyy'
                    id='date'
                  />
                </Col>


                <Col sm={2}>
                  <Label className='small-font-size' for='date'>Date of invoice</Label>
                  <DatePicker
                    autoComplete="new-password"
                    selected={data.invoicedate}
                    onChange={this.invocieDate}
                    className='form-control small-font-size'
                    dateFormat='dd/MM/yyyy'
                    id='invoicedate'
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='date'>Purchase order date</Label>
                  <DatePicker
                    autoComplete="new-password"
                    selected={data.purchaseorderdate}
                    onChange={this.purchaseDate}
                    className='form-control small-font-size'
                    dateFormat='dd/MM/yyyy'
                    id='purchaseorderdate'
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='vendorcode'>Vender code</Label>
                  <AvField
                    Label
                    name='vendorcode'
                    type='text'
                    id='vendorcode'
                    value={data.vendorcode || null}
                    onChange={e => this.onhandlerChange(e)}
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='vehiclenumber'>Vehicle number</Label>
                  <AvField
                    Label
                    name='vehiclenumber'
                    type='text'
                    id='vehiclenumber'
                    value={data.vehiclenumber || null}
                    onChange={e => this.onhandlerChange(e)}
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='hsncode'>HSN Code</Label>
                  <AvField
                    Label
                    name='hsncode'
                    type='text'
                    id='hsncode'
                    value={data.hsncode || null}
                    onChange={e => this.onhandlerChange(e)}
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='saccode'>SAC Code</Label>
                  <AvField
                    Label
                    name='saccode'
                    type='text'
                    id='saccode'
                    value={data.saccode || null}
                    onChange={e => this.onhandlerChange(e)}
                  />
                </Col>


                <Col sm={2}>
                  <Label className='small-font-size' for='purchaseordernumber'>Purchase order number</Label>
                  <span className='required-label'>*</span>
                  <AvField
                    Label
                    required
                    name='purchaseordernumber'
                    type='text'
                    id='purchaseordernumber'
                    value={data.purchaseordernumber || ' ' || null}
                    onChange={e => this.onhandlerChange(e)}
                  />
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for='goodsDescription'>
                    Description of Excisible Goods
                  </Label>
                  <AvField
                    Label
                    name='goodsDescription'
                    type='text'
                    id='goodsDescription'
                    value={data.goodsDescription || null}
                    onChange={e => this.onhandlerChange(e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='customername'>Customer Name</Label>
                    <span className='required-label'>*</span>
                    {/* <AvField
                      Label
                      type='select'
                      name={`customername`}
                      value={data.customerdetail.id || null}
                      required={true}
                      onChange={e => this.handleChange(e)}
                    >
                      <option value=''>select customer name</option>
                      {this.createSelectcustomer()}
                    </AvField> */}
                    {customerDisp}
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='customernumber'>Customer Number</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      Label
                      name='customernumber'
                      type='number'
                      id='customernumber'
                      readOnly
                      value={data.customerdetail.customernumber}
                      onChange={this.onCustomerChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter Customer Number'
                        },

                        pattern: {
                          value: '^[0-9]+$',
                          errorMessage: 'Please enter valid Number'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='customergstnumber'>Customer GST </Label>
                    <AvField
                      Label
                      name='customergstnumber'
                      type='text'
                      id='customergstnumber'
                      readOnly
                      value={data.customerdetail.customergstnumber}
                      onChange={e => this.onCustomerChange}
                      validate={{

                        pattern: {
                          value: '^[a-zA-z0-9]+$',
                          errorMessage: 'Please enter valid GST Number'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <AvGroup>
                    <Label className='small-font-size' for='billingaddress'>Billing Address</Label>
                    <AvField
                      Label
                      name='billingaddress'
                      type='text'
                      id='billingaddress'
                      readOnly
                      value={
                        data.customerdetail.customeraddress1 +
                        ', ' +
                        data.customerdetail.customeraddress2 +
                        ', ' +
                        data.customerdetail.city +
                        ', ' +
                        data.customerdetail.state +
                        ', ' +
                        data.customerdetail.country +
                        ', ' +
                        data.customerdetail.pincode
                      }
                      onChange={e => this.onhandlerChange(e)}
                    />
                  </AvGroup>
                </Col>
                <Col sm={4}>
                  <AvGroup>
                    <Label className='small-font-size' for='deliveryaddress'>Delivery Address</Label>
                    <AvField
                      Label
                      name='deliveryaddress'
                      type='text'
                      id='deliveryaddress'
                      readOnly
                      value={
                        data.customerdetail.deliveryAddress.address1 +
                        ', ' +
                        data.customerdetail.deliveryAddress.address2 +
                        ', ' +
                        data.customerdetail.deliveryAddress.city +
                        ', ' +
                        data.customerdetail.deliveryAddress.state +
                        ', ' +
                        data.customerdetail.deliveryAddress.country +
                        ', ' +
                        data.customerdetail.deliveryAddress.pincode
                      }
                      onChange={e => this.onhandlerChange(e)}
                    />
                  </AvGroup>
                </Col>

                <Col sm={4}>
                  <AvGroup>
                    <Label className='small-font-size' for='remarks'>Remarks </Label>
                    <AvField
                      Label
                      name='remarks'
                      type='text'
                      id='remarks'
                      value={data.remarks}
                      onChange={e => this.onRemarksChange(e)}

                    />
                  </AvGroup>
                </Col>

              </Row>


              <Row>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='deliverychallandata'>Delivary challan data </Label>
                    <AvField
                      Label
                      name='deliverychallandata'
                      type='text'
                      id='deliverychallandata'
                      value={data.deliverychallandata}
                      onChange={e => this.onSubjectHandler(e)}

                    />
                  </AvGroup>
                </Col>


                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='workorderno'>Work Order no </Label>
                    <AvField
                      Label
                      name='workorderno'
                      type='text'
                      id='workorderno'
                      value={data.workorderno}
                      onChange={e => this.onSubjectHandler(e)}

                    />
                  </AvGroup>
                </Col>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='workorderdate'>Work Order Date </Label>

                    <DatePicker
                      autoComplete="new-password"
                      selected={data.workorderdate}
                      onChange={this.handleWorkorderDate}
                      className='form-control small-font-size'
                      dateFormat='dd/MM/yyyy'
                      id='workorderdate'
                    />
                  </AvGroup>
                </Col>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='despathdate'> Date of Dispatch </Label>

                    <DatePicker
                      autoComplete="new-password"
                      selected={data.despathdate}
                      onChange={this.handleDespathdate}
                      className='form-control small-font-size'
                      dateFormat='dd/MM/yyyy'
                      id='despathdate'
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='removaltime'>Time of Removal </Label>

                    <AvField
                      Label
                      name='removaltime'
                      type='text'
                      id='removaltime'
                      value={data.removaltime}
                      onChange={e => this.onSubjectHandler(e)}

                    />
                  </AvGroup>
                </Col>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='transportationcharge'>Transportation Charges </Label>
                    <AvField
                      Label
                      name='transportationcharge'
                      type='text'
                      id='transportationcharge'
                      value={data.transportationcharge}
                      onChange={e => this.onSubjectHandler(e)}

                    />
                  </AvGroup>
                </Col>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='transportationgst'>Transportation GST </Label>
                    <AvField
                      Label
                      name='transportationgst'
                      type='text'
                      id='transportationgst'
                      value={data.transportationgst}
                      onChange={e => this.onSubjectHandler(e)}

                    />
                  </AvGroup>
                </Col>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='advanceamountreceived'>Advance Received </Label>
                    <AvField
                      Label
                      name='advanceamountreceived'
                      type='text'
                      id='advanceamountreceived'
                      value={data.advanceamountreceived}
                      onChange={e => this.onSubjectHandler(e)}

                    />
                  </AvGroup>
                </Col>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for='transportchargerequired'>GST required for Transport charge </Label>
                    <AvField
                      Label
                      name='transportchargerequired'
                      type='select'
                      id='transportchargerequired'
                      value={data.transportchargerequired}
                      onChange={e => this.onSubjectHandler(e)}

                    >
                      <option value='Yes'>Yes</option>
                      <option value='No'>No</option>
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>



              <Row>
                <div className='mg-t-20'>
                  <Button color='info' onClick={this.handleClick} type='button'>
                    Add Item
                  </Button>
                </div>
              </Row>
              <div className='purchaseorder-item-row'>
                {data.values.map((values, index) => {

                  let optionsel = "";
                  let SelVal = {};
                  if (values.id != undefined) {
                    if (values.itemshortdescription) {
                      optionsel = values.itemshortdescription;
                    }
                    else {
                      optionsel = values.itemdescription;
                    }
                    SelVal = {
                      value: values.id._id, label: optionsel
                    }
                  }

                  const itemnameinfo = values.id != undefined ? (
                    // <AvField
                    //   type="select"
                    //   value={values.id._id}
                    //   name={`selectitem${index}`}
                    //   onChange={e => this.selectitemchange(e, index)}
                    //   required={true}
                    // >
                    //   <option value="">select Item</option>
                    //   {this.createSelectItems()}
                    // </AvField>
                    <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index, true)} styles={customStyles} required value={SelVal} options={this.state.itemlistSelectedOption} />
                  ) :
                    (
                      // <AvField
                      //   type="select"
                      //   name={`selectitem${index}`}
                      //   value={
                      //     data.selectedItemlist.id[index]
                      //   }
                      //   onChange={e => this.selectitemchange(e, index)}
                      //   required={true}
                      // >
                      //   <option value="">select Item</option>
                      //   {this.createSelectItems()}
                      // </AvField>
                      <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index, true)} styles={customStyles} required defaultValue={data.selectedItemlist.id[index]} options={this.state.itemlistSelectedOption} />
                    )

                  return (
                    <div key={index}>
                      <div className='purchaseorder-table'>
                        <Row key={index}>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for='selectitem'>Item Code</Label>
                              <span className='required-label'>*</span>
                              {/* <AvField
                                Label
                                type='select'
                                name={`selectitem${index}`}
                                onChange={e => this.selectitemchange(e, index,true)}
                                required={true}
                                value={data.selectedItemlist.id[index]}
                              >
                                <option value=''>select Item</option>
                                {this.createSelectItems()}
                              </AvField> */}
                              {itemnameinfo}
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemdescription${index}`}>
                                Description
                              </Label>
                              <span className='required-label'>*</span>
                              <AvField
                                Label
                                readOnly
                                name={`masteritemdescription${index}`}
                                type='text'
                                id='masteritemdescription'
                                value={
                                  data.selectedItemlist.itemdescription[index]
                                }
                                onChange={this.onChange}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      'Please enter Item Description'
                                  }


                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemtype${index}`}>Type</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                Label
                                // style={{ width: '150px' }}
                                readOnly
                                type='select'
                                name={`masteritemtype${index}`}
                                onChange={this.onChange}
                                required={true}
                                value={
                                  data.selectedItemlist.itemtype[index]
                                }
                              >
                                <option value=''>select</option>
                                {this.createSelectItemTypes()}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemuom${index}`}>UOM</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                Label
                                readOnly
                                type='select'
                                name={`masteritemuom${index}`}
                                onChange={this.onChange}
                                required={true}
                                value={
                                  data.selectedItemlist.itemuom[index]
                                }
                              >
                                <option value=''>uom</option>
                                {this.createSelectUomItems()}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`discount${index}`}>Discount %</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                Label
                                name={`itemdiscount${index}`}
                                type='number'
                                id={`itemdiscount${index}`}
                                value={
                                  data.selectedItemlist.itemdiscount[index]
                                }
                                onChange={e =>
                                  this.itemdiscountChange(e, index)
                                }
                                validate={{
                                  minLength: {
                                    value: 1,
                                    errorMessage:
                                      'item Discount value is required'
                                  },
                                  maxLength: { value: 100 },
                                  pattern: {
                                    value: '^[0-9.]*$',
                                    errorMessage: 'Please enter valid value'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`costperunit${index}`}>
                                Unit Cost
                              </Label>
                              <AvField
                                Label
                                name={`costperunit${index}`}
                                type='number'
                                id={`costperunit${index}`}
                                value={
                                  data.selectedItemlist.costperunit[index]
                                }
                                onChange={e => this.unitChange(e, index)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter cost'
                                  },
                                  pattern: {
                                    value: '^[0-9.]*$',
                                    errorMessage:
                                      'Please enter valid costperunit'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`itemdiscountcost${index}`}>Discount Cost</Label>
                              <span className='required-label'></span>
                              <AvField
                                Label
                                readOnly
                                name={`itemdiscountcost${index}`}
                                type='number'
                                id={`itemdiscountcost${index}`}
                                value={
                                  data.selectedItemlist.itemdiscountcost[index]
                                }
                                validate={{
                                  minLength: {
                                    value: 1,
                                    errorMessage: 'quantity value is required'
                                  },
                                  pattern: {
                                    value: '^[0-9.]*$',
                                    errorMessage: 'Please enter valid quantity'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`quantity${index}`}>Quantity</Label>
                              <span className='required-label'>*</span>
                              <AvField
                                Label
                                name={`quantity${index}`}
                                type='number'
                                id={`quantity${index}`}
                                value={
                                  data.selectedItemlist.quantity[index]
                                }
                                onChange={e => this.quantityChange(e, index)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter Item Quantity'
                                  },
                                  minLength: {
                                    value: 1,
                                    errorMessage: 'quantity value is required'
                                  },
                                  pattern: {
                                    value: '^[0-9.]*$',
                                    errorMessage: 'Please enter valid quantity'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`totalcost${index}`}>
                                Total Cost
                              </Label>
                              <AvField
                                Label
                                name={`totalcost${index}`}
                                type='number'
                                id={`totalcost${index}`}
                                value={
                                  data.selectedItemlist.totalcost[index]
                                    ? data.selectedItemlist.totalcost[index]
                                    : ' '
                                }
                                onChange={e => this.onChange(e, index)}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <Button
                              Label
                              color='danger'
                              type='button'
                              onClick={e => this.removeClick(e, index)}
                              style={{ marginTop: '30px' }}
                            >
                              -
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div style={{ 'borderBottom': '1px solid #CCC', 'borderTop': '1px solid #CCC', 'paddingTop': '10px' }}>
                <Row>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className='small-font-size' for='taxableamount'>Taxable Amount</Label>
                      <span className='required-label'>*</span>
                      <AvField
                        Label
                        name='taxableamount'
                        type='number'
                        id='taxableamount'
                        value={data.taxableamount}
                        disabled='true'
                        onChange={e => this.onhandlerChange(e)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Please enter Taxable Amount'
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className='small-font-size' for='freightcharges'>Freight,w.s,loading and handling charges</Label>
                      <AvField
                        Label
                        name='freightcharges'
                        type='number'
                        id='freightcharges'
                        value={data.freightcharges}
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='subtotal'>Sub Total</Label>
                      <AvField
                        Label
                        name='subtotal'
                        type='number'
                        id='subtotal'
                        value={data.subtotal}
                        disabled='true'
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='tcs'>TCS</Label>
                      <AvField
                        Label
                        name='tcs'
                        type='checkbox'
                        id='tcs'
                        value={data.tcs}
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>

                  {data.tcs !== '' ? (
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='tcs'>TCS Amount</Label>
                        <AvField
                          Label
                          name='tcsamount'
                          type='number'
                          id='tcsamount'
                          value={data.tcsamount}
                          onChange={e => this.onhandlerChange(e)}
                        />
                      </AvGroup>
                    </Col>) : null}

                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='transit'>Transit</Label>
                      <AvField
                        Label
                        name='transit'
                        type='checkbox'
                        id='transit'
                        value={data.transit}
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>

                  {data.transit !== '' ? (
                    <Col sm={2}>
                      <AvGroup>
                        <Label className='small-font-size' for='transitamount'>Transit Amount</Label>
                        <AvField
                          Label
                          name='transitamount'
                          type='number'
                          id='transitamount'
                          disabled='true'
                          value={data.transitamount}
                          onChange={e => this.onhandlerChange(e)}
                        />
                      </AvGroup>
                    </Col>) : null}

                </Row>
                <Row>
                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='cgst'>CGST (%)</Label>
                      <AvField
                        Label
                        name='cgst'
                        type='number'
                        id='cgst'
                        value={data.cgst}
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='sgst'>SGST (%)</Label>
                      <AvField
                        Label
                        name='sgst'
                        type='number'
                        id='sgst'
                        value={data.sgst}
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='igst'>IGST (%)</Label>
                      <AvField
                        Label
                        name='igst'
                        type='number'
                        id='igst'
                        value={data.igst}
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='total'>Total</Label>
                      <AvField
                        Label
                        name='total'
                        type='number'
                        id='total'
                        disabled='true'
                        value={data.total}
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='roundoffamount'>Round Off</Label>
                      <AvField
                        Label
                        name='roundoffamount'
                        type='number'
                        id='roundoffamount'
                        value={data.roundoffamount}
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label className='small-font-size' for='grandtotal'>Grand total</Label>
                      <AvField
                        Label
                        name='grandtotal'
                        type='number'
                        id='grandtotal'
                        value={data.grandtotal}
                        disabled='true'
                        onChange={e => this.onhandlerChange(e)}
                      />
                    </AvGroup>
                  </Col>
                </Row>
              </div>
              <Row>
                <div className='mg-20'>
                  <Button
                    Label
                    color='primary'
                    //  / disabled={loading}
                    className='lgn-loader'
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerlistvalues: state.customerlistvalues,
    masteritemlistvalues: state.masteritemlistvalues,
    masteritemtypelistvalues: state.masteritemtypenamevalue,
    uomlistvalues: state.uomlistvalue,
    locationlistvalue: state.locationlistvalue,
    quotationlistvalues: state.quotationlistvalues,
    invoicelistvalues: state.invoicelistvalues,
    challanlistvalues: state.challanlistvalues,
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  null
)(ProformaInvoiceForm);
