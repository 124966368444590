import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPDF from '@react-pdf/renderer';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import { getChallan } from '../../../../actions/challan';
import MyDocument from './Component';
import logo from '../../../../Images/logo.png';
import axios from "axios";
import './ViewChallan';

class ViewChallanPdf extends React.Component {
  state = {
    challanlistvalues: {},
    status: false,
    link: ''
  };
  async componentDidMount() {
    this.props.getChallan();
    const quotationid = this.props.match.params.id;
    const challanlistvalues = this.props.challanlistvalues[quotationid];
    challanlistvalues.requestdeliverydate = moment(
      challanlistvalues.date
    ).format('DD MMM, YYYY');
    challanlistvalues.addeditemlist = challanlistvalues.addeditemlist.map(
      (itemlist, i) => {

        if(itemlist.itemdescription.length < 340) {
          console.log("coming here");
          let totalSpacerequired = 340 - itemlist.itemdescription.length;
          let numSpaces = totalSpacerequired; // Change this to the number of spaces you want to add

          let spaces = " ".repeat(numSpaces); // Create a string of spaces
          itemlist.itemdescription = itemlist.itemdescription + spaces;

          console.log("after adding space", itemlist.itemdescription);
        }
        return {
          slno: (i + 1).toString(),
          itemname: itemlist.itemdescription ? itemlist.itemdescription : '',
          weighteach: itemlist.id.masteritemunitwt
            ? itemlist.id.masteritemunitwt
            : '',
          itemimage: itemlist.id.masteritemimage
            ? itemlist.id.masteritemimage
            : '',
          quantity: itemlist.quantity ? itemlist.quantity.toString() : '',
          itemdiscount: 0,
          remarks: itemlist.remarks ? itemlist.remarks.toString() : '',
          unit: itemlist.itemuom ? itemlist.itemuom.uomname.toString() : ''
        };
      }
    );
    const customerid = challanlistvalues.customerid;
    const customernumber = challanlistvalues.customernumber;
    const customername = challanlistvalues.customername;
    await axios.get(`${process.env.REACT_APP_BASE_URL}/year/getyear`).then(
      (res) => {
        if(res.data.year) {
          challanlistvalues.financialyear = res.data.year.currentyear;
        }
        else {
          challanlistvalues.financialyear = "2020-21";          
        }
      },
      (error) => {
        challanlistvalues.financialyear = "2020-21";
      }
    )
    console.log('--COMPONENTDIDMOUNT--');
    console.log(challanlistvalues);
    setTimeout(() => {
      this.setState({
        ...this.state.purchaseorderlistvalues,
        challanlistvalues,
        status: true
      });
    },2000);
    this.dynamicClick();
  }
  dynamicClick() {
    if (document.getElementsByClassName('pdf-class')) {
      if (
        document.getElementsByClassName('pdf-class')[0].children.length <= 1
      ) {
        setTimeout(() => {
          this.dynamicClick();
        }, 7000);
      } else if (
        document.getElementsByClassName('pdf-class')[0].children.length > 1
      ) {
        this.setState({
          link: document.getElementsByClassName('pdf-class')[0].children[1].src
          // status : undefined,
        },()=>{
          setTimeout(() => {
            document.getElementById('D1').click();
            this.props.history.push('/challanlist');
          }, 300);
        });
   
      }
    } else {
    }
  }
  render() {
    const data_cond = [
      {
        slno: '1',
        title: 'Invoices',
        value: '2 copies Invoices (original & duplicate)'
      },
      {
        slno: '2',
        title: 'Tax & Duty',
        value: 'Included'
      },
      {
        slno: '3',
        title: 'Payment Schedule',
        value: '15 days from the date of delivery'
      },
      {
        slno: '4',
        title: 'Delivery Schedule',
        value: 'Immediately'
      },
      {
        slno: '5',
        title: 'Billing address & GST No',
        value:
          'M/s Pondhan Scaffolding Pvt Ltd \n Plot no. 40/41, Road no.5, Obedanahalli,'
      },
      {
        slno: '6',
        title: 'Delivery Address',
        value: 'M/s Pondhan Scaffolding Pvt Ltd'
      }
    ];
    const { status, challanlistvalues, link } = this.state;
    console.log(this.state);
    return (
      <div>
        <div className='pdf-class'>
          Downloading file.Please wait.
          {/* {!status && (
          <h1 style={{ 'position': 'absolute','left': '50%','top': '50%','transform': 'translate(-50%, -50%);'}}>Downloading file.Please wait.</h1>
        )} */}
          <a href={link} download id='D1'></a>
          {status && (
            <PDFViewer>
              <MyDocument
                challanlistvalues={this.state.challanlistvalues}
                data_cond={data_cond}
                logo={logo}
              />
            </PDFViewer>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    challanlistvalues: state.challanlistvalues
  };
}
export default connect(
  mapStateToProps,
  { getChallan }
)(ViewChallanPdf);
