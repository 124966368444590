import React from "react";

class BillingTable extends React.Component {
  render() {
    const {
      selectedProduct,
      productformvalues,
      materiallistvalues
    } = this.props;
    const materialTotalCost =
      selectedProduct.productMaterial
        .map((productMaterialData, i) => {
          return (
            parseFloat(productMaterialData.productqty) *
            // parseFloat(productMaterialData.product_id.materialrate) *
            parseFloat(productMaterialData.product_id.masteritemcostperunit)
          );
        })
        .reduce((a, b) => a + b, 0) * parseFloat(productformvalues.qty);
    const subTotal =
      materialTotalCost + parseFloat(productformvalues.labourcost);
    const profit = (parseFloat(productformvalues.profit) / 100) * subTotal;
    const grandTotal = subTotal + profit;
    return (
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Product Name</th>
              <th colSpan="4">
                {
                  materiallistvalues[selectedProduct.productname._id]
                    .masteritemdescription
                }
              </th>
            </tr>
            <tr>
              <th>Item Name</th>
              <th>UOM</th>
              <th>Unit per Cost</th>
              <th>Item Qty</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {selectedProduct.productMaterial.map((productMaterialData, i) => {
              return (
                <tr>
                  <td>{productMaterialData.product_id.masteritemname}</td>
                  <td>
                    {productMaterialData.product_id.masteritemuom.uomname}
                  </td>
                  <td>
                    {productMaterialData.product_id.masteritemcostperunit}
                  </td>
                  <td>{productMaterialData.productqty}</td>
                  <td>
                    {parseFloat(productMaterialData.productqty).toFixed(2) *
                      // parseFloat(productMaterialData.product_id.materialrate) *
                      parseFloat(
                        productMaterialData.product_id.masteritemcostperunit
                      ).toFixed(2)}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan="4">Product Quantity</td>
              <td>{parseFloat(productformvalues.qty)}</td>
            </tr>
            <tr>
              <td colSpan="4">Material Total Cost</td>
              <td>{materialTotalCost}</td>
            </tr>
            <tr>
              <td colSpan="4">Labour charge</td>
              <td>{parseInt(productformvalues.labourcost)}</td>
            </tr>
            <tr>
              <td colSpan="4">Sub Total</td>
              <td>{subTotal}</td>
            </tr>
            <tr>
              <td colSpan="4">Profit % </td>
              <td>{profit}</td>
            </tr>
            <tr>
              <td colSpan="4">Grand Total</td>

              <td>{grandTotal}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default BillingTable;
