import { ADD_MOVEORDER, MOVEORDER_FETCHED } from '../Services/types';

export default function(state = {}, action) {
  switch (action.type) {
    case MOVEORDER_FETCHED:
    case ADD_MOVEORDER:
      return {
        ...state,
        ...action.moverorderlist.entities.moveorderlistvalues
      };
    default:
      return state;
  }
}
