import React from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  Label,
  Alert
} from "reactstrap";

class ReceiptScreenForm extends React.Component {
  state = {
    data: {
      ponumber: null
    },
    errors: {},
    loading: false,
    success: false
  };

  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  onhandleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  handleValidSubmit = () => {
    console.log(this.state.data);
    this.setState({ loading: true });
    this.props
      .submit(this.state.data)
      .then(() => {
        this.setState({ loading: false, errors: {}, success: true });
        this.myFormRef.reset();
      })
      .catch(err => {
        // eslint-disable-next-line prettier/prettier
        this.setState({ errors: err.response.data.errors, loading: false, success: false });
      });
  };

  render() {
    const { data, errors, loading, success } = this.state;

    return (
      <div className="container mg-t-20">
        <div>
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
            ref={el => (this.myFormRef = el)}
          >
            <Card>
              <CardHeader Label>Receipt Contact</CardHeader>
              <CardBody>
                <Row>
                  <Col sm={2}>
                    {!!errors.global ? (
                      <Alert color="danger" Label>Result Not Found</Alert>
                    ) : null}
                    <AvGroup>
                      <Label className='small-font-size' for="fullname" Label>PO Number</Label>
                      <span className="required-label">*</span>
                      <AvField
                        Label
                        name="ponumber"
                        type="number"
                        id="ponumber"
                        value={data.ponumber}
                        onChange={this.onChange}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <Button
                      color="primary"
                      disabled={loading}
                      className="lgn-loader small-font-size"
                      style={{ marginTop: "30px" }}
                    >
                      Find
                      {/* {loading} */}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </AvForm>
        </div>
      </div>
    );
  }
}

export default ReceiptScreenForm;
