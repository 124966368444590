import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class InvoicePage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/transaction_page'>Transaction</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Invoice</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
              <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                  <Link to='/invoice1'>
                    <i class='fas fa-file-invoice fa-3x'></i>
                    <div style={{ 'padding-top': '5px' }}>
                      <span>Normal Invoice</span>
                    </div>
                  </Link>
                </div>
              </li>

               <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                  <Link to='/invoice2'>
                    <i class='fas fa-file-invoice fa-3x'></i>
                    <div style={{ 'padding-top': '5px' }}>
                      <span>Proforma Invoice</span>
                    </div>
                  </Link>
                </div>
              </li> 




            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default InvoicePage;
