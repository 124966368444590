import { normalize } from "normalizr";
import { challanListSchema } from "../Schemas/schemas";
import api from "../Services/challanapi";
import { ADD_CHALLAN, CHALLAN_FETCHED } from "../Services/types";

const challanCreated = challanlist => {
  return {
    type: ADD_CHALLAN,
    challanlist
  };
};

const challanFetched = challanlist => {
  return {
    type: CHALLAN_FETCHED,
    challanlist
  };
};

export const addChallan = data => {
  return dispatch => {
    return api.challan.addchallan(data).then(challanlistvalues => {
      return dispatch(
        challanCreated(normalize(challanlistvalues, challanListSchema))
      );
    });
  };
};

export const updateChallan = data => {
  return dispatch => {
    return api.challan.updatechallan(data).then(challanlistvalues => {
      return dispatch(
        challanCreated(normalize(challanlistvalues, challanListSchema))
      );
    });
  };
};



export const getChallan = () => {
  return dispatch => {
    return api.challan.getchallan().then(challanlist => {
      console.log(challanlist)
      const challanlistvalues = challanlist.challandata;
      console.log(challanlistvalues)
      return dispatch(
        challanFetched(normalize(challanlistvalues, [challanListSchema]))
      );
    });
  };
};

export const searchquotation = customerid => {
  return dispatch => {
    return api.quotation.searchquotation(customerid);
  };
};
