import {
    CUSTOMERTRANSACTION_FETCHED,
    ADD_CUSTOMER_TRANSACTION
  } from "../Services/types";
  
  export default function(state = {}, action) {
    console.log(action)
    switch (action.type) {
      case CUSTOMERTRANSACTION_FETCHED:
      return {
        ...state,
        ...action.customerlist.entities.customertransationvalues
      };
      case ADD_CUSTOMER_TRANSACTION:
        return {
          ...state,
          ...action.customerlist.entities.customertransationvalues
        };
      default:
        return state;
    }
  }
  