import React from 'react';
import logo from '../../../../Images/logo.png';
import justDial from '../../../../Images/js.jpg';
import makeInIdia from '../../../../Images/mk.jpg';
import proud from '../../../../Images/proud.jpg';
import scaff from '../../../../Images/scaff.jpg';
import seal from '../../../../Images/seal.jpg';
import sign from '../../../../Images/sign.jpg';
import TimesNewRoman from '../../../../fonts/Times New Roman.ttf'

import client from '../../../../Images/client.png';

import { connect } from 'react-redux';
import {
  Document,
  Page,
  Text,
  Font,
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import {
  TableCell,
  Table,
  TableHeader,
  TableBody,
  DataTableCell
} from '@david.kucsai/react-pdf-table';
import styled from '@react-pdf/styled-components';

Font.register({
  family: 'Times New Roman',
  src:  TimesNewRoman,
});

// Create styles
const styles = StyleSheet.create({
  textcenter: { textAlign: 'center' },
  textright: { textAlign: 'right' },
  textleft: { textAlign: 'left' },
  paddingleft: { paddingLeft: 5 },
  margin: {
    margin: 5
  },

  pagepadding: {
    padding: 35
  },
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerfont: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  headerfont5: {
    fontSize: 10,
    fontWeight: 'bold',
    padding: 5
  },
  tableHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    // backgroundColor: '#548DD4',
    // width: '100%',
    // height: 40,
    // color: 'white',
    // textAlign: 'center',
    // padding: 5
  },
  refno: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 10
  },
  cutoff: {
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // width:'150px',
    //wordBreak: 'break-all'

    'whiteSpace': 'normal',
    'wordBreak': 'breakAll',

  },

  bgblue: {
    backgroundColor: '#000'
  },
  padding5: {
    paddingTop: 5,
    paddingBottom: 5
  },
  fontbold: {
    fontWeight: 'bolder'
  },
  headerfont2: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'right',
    padding: 3
  },
  headerwidth: {
    width: 210,
    padding: 20
  },
  headerwidthdate: {
    width: 210,
    paddingTop: 23,
    fontSize: 10
  },
  smallicon: {
    width: 25
  },
  headerwidthlogo: {
    width: 350,
    fontSize: 10
  },
  logo: {
    width: 70
  },
  headerwidthAddress: {
    width: 300,
    fontSize: 10,
    padding: 2,
    textAlign: 'right'
  },
  refstyle: {
    paddingBottom: 20,
    fontSize: 10
  },
  headerpadding: {
    padding: 20
  },
  flexgrow: {
    flexGrow: 1
  },
  fontsize18: {
    fontSize: 10
  },
  brandname: {
    fontSize: 10,
    fontWeight: 600,
    paddingTop: 10,
    paddingBottom: 10
  },

  padding1: {
    paddingTop: 6
  },

  kindattend: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 10
  },
  yourref: {
    fontSize: 10,
    paddingBottom: 10
  },
  padding: {
    padding: 0
  },
  main: {
    fontSize: 10
  },
  flexview: {
    fontSize: 10,
    display: 'flex'
  },
  tableheaderbgcolor: {
    backgroundColor: '#000',
    color: 'red',
    background: '#000'
  },
  footerfixed: {
    // position: 'absolute',
    // bottom: 0,
    width: '100%'
  }
});

const Small = ({ children }) => {
  return (
    <Text style={{ fontSize: 10 }}>{children}</Text>
  )
}

const SmallBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, fontFamily: 'Times New Roman', fontWeight: 'bold', color: 'black' }}>{children}</Text>
  )
}

const BigBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 13, fontFamily: 'Times New Roman', fontWeight: 'extrabold', color: 'black' }}>{children}</Text>
  )
}

const Header = ({ children }) => {
  return (
    <Text style={{ fontSize: 14, fontFamily: 'Times New Roman', fontWeight: 'bold', color: 'black' }}>{children}</Text>
  )
}

const RedBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, fontFamily: 'Times New Roman', fontWeight: 'bold',color: 'red'}}>{children}</Text>
  )
}

const BlackBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, fontFamily: 'Times New Roman', fontWeight: 'bold',color: 'black'}}>{children}</Text>
  )
}

// Create Document Component

const MyDocument = ({ quotationlistvalues, data_cond }) => {
  console.log(quotationlistvalues)

  let amountinwords = ''
  const financialYear = quotationlistvalues.financialyear;
  let Rs = function (amount) {
    var words = new Array();
    words[0] = 'Zero'; words[1] = 'One'; words[2] = 'Two'; words[3] = 'Three'; words[4] = 'Four'; words[5] = 'Five'; words[6] = 'Six'; words[7] = 'Seven'; words[8] = 'Eight'; words[9] = 'Nine'; words[10] = 'Ten'; words[11] = 'Eleven'; words[12] = 'Twelve'; words[13] = 'Thirteen'; words[14] = 'Fourteen'; words[15] = 'Fifteen'; words[16] = 'Sixteen'; words[17] = 'Seventeen'; words[18] = 'Eighteen'; words[19] = 'Nineteen'; words[20] = 'Twenty'; words[30] = 'Thirty'; words[40] = 'Forty'; words[50] = 'Fifty'; words[60] = 'Sixty'; words[70] = 'Seventy'; words[80] = 'Eighty'; words[90] = 'Ninety'; var op;
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++ , j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++ , j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split(" ").join(" ");
    }
    return words_string;
  }

  let RsPaise = function (n) {
    var op;
    var nums = n.toString().split('.')
    var whole = Rs(nums[0])
    if (nums[1] == null) nums[1] = 0;
    if (nums[1].length == 1) nums[1] = nums[1] + '0';
    if (nums[1].length > 2) { nums[1] = nums[1].substring(2, nums[1].length - 1) }
    if (nums.length == 2) {
      if (nums[0] <= 9) { nums[0] = nums[0] * 10 } else { nums[0] = nums[0] };
      var fraction = Rs(nums[1])
      if (whole == '' && fraction == '') { op = 'Zero only'; }
      if (whole == '' && fraction != '') { op = 'paise ' + fraction + ' only'; }
      if (whole != '' && fraction == '') { op = 'INR: ' + whole + ' only'; }
      if (whole != '' && fraction != '') { op = 'INR: ' + whole + 'and ' + fraction + ' paise only'; }
      amountinwords = op
    }
  }




  const data = quotationlistvalues.addeditemlist;
  let totaleachitem = 0;
  console.log('data');
  console.log(data);
  if (data.length > 0) {
    data.map((item) => {
      totaleachitem = totaleachitem + parseFloat(item.totalcost)
    })
    totaleachitem = totaleachitem.toFixed(2)
  }
  // const data = [
  //   {
  //     slno: "1",
  //     itemname: "Pipe 40 NB X 2.6 mm thk ",
  //     quantity: "2",
  //     itemdiscount: 25,
  //     costperunit: "145.83",
  //     totalcost: "100"
  //   },
  //   {
  //     slno: "2",
  //     itemname: "Pipe 40 NB X 2.6 mm thk ",
  //     quantity: "2",
  //     itemdiscount: 25,
  //     costperunit: "145.83",
  //     totalcost: "100"
  //   },
  //   {
  //     slno: "1",
  //     itemname: "Pipe 40 NB X 2.6 mm thk ",
  //     quantity: "2",
  //     itemdiscount: 25,
  //     costperunit: "145.83",
  //     totalcost: "100"
  //   },
  //   {
  //     slno: "2",
  //     itemname: "Pipe 40 NB X 2.6 mm thk ",
  //     quantity: "2",
  //     itemdiscount: 25,
  //     costperunit: "145.83",
  //     totalcost: "100"
  //   },
  //   {
  //     slno: "1",
  //     itemname: "Pipe 40 NB X 2.6 mm thk ",
  //     quantity: "2",
  //     itemdiscount: 25,
  //     costperunit: "145.83",
  //     totalcost: "100"
  //   },
  //   {
  //     slno: "2",
  //     itemname: "Pipe 40 NB X 2.6 mm thk ",
  //     quantity: "2",
  //     itemdiscount: 25,
  //     costperunit: "145.83",
  //     totalcost: "100"
  //   }
  // ];
  console.log(quotationlistvalues)

  let customeraddress = quotationlistvalues.customeraddress ? quotationlistvalues.customeraddress : ''
  let loadingcharges = quotationlistvalues.loadingcharge != undefined
    && quotationlistvalues.loadingcharge != null ?
    parseFloat(quotationlistvalues.loadingcharge) : 0.00

  let ws = quotationlistvalues.ws != undefined
    && quotationlistvalues.ws != null ?
    parseFloat(quotationlistvalues.ws) : 0.00

  let transitinsurance = quotationlistvalues.transitinsurance != undefined
    && quotationlistvalues.transitinsurance != null ?
    parseFloat(quotationlistvalues.transitinsurance) : 0.00

    

  let tcharge = quotationlistvalues.tcharge != undefined
    && quotationlistvalues.tcharge != null ?
    parseFloat(quotationlistvalues.tcharge) : 0.00


  loadingcharges = loadingcharges.toFixed(2)
  let tchargegst = 0.00
  ws = ws.toFixed(2)
  transitinsurance = transitinsurance.toFixed(2)
  tcharge = tcharge.toFixed(2)
  let subtotal = parseFloat(totaleachitem) + parseFloat(loadingcharges) + parseFloat(ws) + parseFloat(transitinsurance)
  subtotal = subtotal.toFixed(2)
  let subtotalgst = parseFloat(subtotal * 0.18)
  subtotalgst = subtotalgst.toFixed(2)
  let totalincludegst = parseFloat(subtotal) + parseFloat(subtotalgst)
  totalincludegst = totalincludegst.toFixed(2)

  let transportationgstper=0,transportationgst;
  transportationgst = quotationlistvalues.transportationgst && quotationlistvalues.transportationgst != '' ? parseFloat(quotationlistvalues.transportationgst) : 0
  transportationgstper = transportationgst
  transportationgst = transportationgst / 100;

  tchargegst = tcharge * transportationgst
  tchargegst = tchargegst.toFixed(2)
  let tcsval = quotationlistvalues.tcs != '' && quotationlistvalues.tcs != null ? (parseFloat(subtotal) * parseFloat(quotationlistvalues.tcs)) / 100 : 0
  if (tcsval == 0)
  {
    quotationlistvalues.tcs = 0
  }
  tcsval = tcsval.toFixed(2)

  let grandtotal = parseFloat(tcharge) + parseFloat(totalincludegst) + parseFloat(tchargegst) + parseFloat(tcsval);
  grandtotal = grandtotal.toFixed(2)

  RsPaise(grandtotal)

  let scheduledays = quotationlistvalues.scheduledays ? quotationlistvalues.scheduledays + ' days' : ''

  const totalrs = quotationlistvalues.totalvalue
    ? quotationlistvalues.totalvalue
    : '';
  const tax = quotationlistvalues.tax ? quotationlistvalues.tax : '';
  const taxpercentage = quotationlistvalues.tax
    ? quotationlistvalues.tax / 100
    : '';
  //const grandtotal = taxpercentage * totalrs;
  //   const selectecontactlist = quotationlistvalues.selectedcontactlist[0]
  //     ? quotationlistvalues.selectedcontactlist[0].suppliercontactname
  //     : "";
  const cond_data = data_cond;
  const tableComponentTwo = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i] && data[i + 1]) {
      tableComponentTwo.push([data[i], data[i + 1]]);
    } else {
      tableComponentTwo.push([data[i]]);
    }
    ++i;
  }

  const refno = 'Quotation no:PSPL/Quote/' + quotationlistvalues.quotationnumber + '/'+financialYear;
  const dispDyn = data.map((datanew, i) => {
    if(i == 0 && i <= 1 && data.length < 3) {
      return (
        <>
        <Page size='A4' style={styles.pagepadding} wrap>

        <View style={styles.section}>
          <Image style={{ marginLeft: -15 }} src={logo} />
          <Text style={styles.headerwidthAddress}>
            <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>

        <Text style={{ fontSize: 10 }}>
          {'\n'}{'\n'}
          <BigBold>Dear sir / Madam,</BigBold>{'\n'}{'\n'}
          <SmallBold>Thank you for your valuable enquiry & giving us an opportunity to help you <RedBold>“BUILD BETTER”.</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We are the leading manufacturing company in the field of <RedBold>“SCAFFOLDING & FORMWORK SYSTEM”</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We’re honored to present ourselves to you, Please click www.pondhan.com{'\n'}{'\n'}
          Our Product catalog & detailed specifications will help you more to understand our Products.{'\n'}{'\n'}
          We trust you will find our offer in line with your requirements.{'\n'}{'\n'}
          Our goal is to “giving you pretty reasonable price with good / high quality & get reasonable profit”{'\n'}{'\n'}
          In case you need any further information / queries, please feel free to get in touch with us.{'\n'}{'\n'}
          Looking forward to your valuable support with an assurance of our best services at all times.{'\n'}{'\n'}
          We look forward to an opportunity to serve you & your quick reply will be highly appreciated!{'\n'}{'\n'}{'\n'}</SmallBold>
          <BigBold>Thanking You</BigBold>,{'\n'}{'\n'}
          With Regards,{'\n'}{'\n'}
          <SmallBold>For M/s Pondhan Scaffolding Pvt Ltd</SmallBold>
          {'\n'}
        </Text>


        <View style={{ flexDirection: 'row' }}>

          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'bottom' }}>
            <Image style={{ width: 70, height: 70 }} src={sign} />
            <Image style={{ width: 70, height: 70 }} src={seal} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 130, height: 120 }} src={proud} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 200, height: 120 }} src={makeInIdia} />
          </View>
        </View>

        <View>
          <SmallBold>A.B.Ponnappa.</SmallBold>
          <Small>Managing Director</Small>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={justDial} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={scaff} />
          </View>
        </View>
        </Page>

        <Page size='A4' style={styles.pagepadding} wrap>
        <View style={[styles.section]}>
          <Text style={styles.headerwidth}></Text>
          <Text style={styles.headerwidth}>
            <Header>QUOTATION</Header>
          </Text>
          <Text style={styles.headerwidthdate}>
            Date:{quotationlistvalues.requestdeliverydate}
          </Text>
        </View>
        <View>
          <Text style={styles.refno}>{refno}</Text>
        </View>
        <View style={styles.fontsize18}>
          <Text>To,</Text>
          <Text>Mr/Miss. {quotationlistvalues.customername}</Text>
          <Text
           style={[styles.fontsize18, {
            width: 180,
            wordWrap: 'break-word'
          }]}
          >{quotationlistvalues.customeraddress}</Text>
          
        </View>


        <View style={styles.kindattend}>
          <Text>Mob:{quotationlistvalues.customercontactnumber}</Text>
        </View>


        <View style={styles.main}>
          <Text>Dear Sir/Madam,</Text>
        </View>
        <View style={styles.main}>
          <Text style={styles.padding5}>
            As per your inquiry for MS Scaffolding material, we are pleased to inform you the rates as mentioned below
          </Text>
        </View>
        <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={data}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      </>
      );
    }
    else if(i == 0 && i <= 3 && data.length < 5) {
      let firstDat = [];
      let secondDat = [];
      data.forEach((element,i) => {
        if(i <= 1) {
          firstDat.push(data[i])
        }
        if(i > 1 && i <= 3) {
          secondDat.push(data[i])
        }
      });
      return (
        <>
        <Page size='A4' style={styles.pagepadding} wrap>

        <View style={styles.section}>
          <Image style={{ marginLeft: -15 }} src={logo} />
          <Text style={styles.headerwidthAddress}>
            <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>

        <Text style={{ fontSize: 10 }}>
          {'\n'}{'\n'}
          <BigBold>Dear sir / Madam,</BigBold>{'\n'}{'\n'}
          <SmallBold>Thank you for your valuable enquiry & giving us an opportunity to help you <RedBold>“BUILD BETTER”.</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We are the leading manufacturing company in the field of <RedBold>“SCAFFOLDING & FORMWORK SYSTEM”</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We’re honored to present ourselves to you, Please click www.pondhan.com{'\n'}{'\n'}
          Our Product catalog & detailed specifications will help you more to understand our Products.{'\n'}{'\n'}
          We trust you will find our offer in line with your requirements.{'\n'}{'\n'}
          Our goal is to “giving you pretty reasonable price with good / high quality & get reasonable profit”{'\n'}{'\n'}
          In case you need any further information / queries, please feel free to get in touch with us.{'\n'}{'\n'}
          Looking forward to your valuable support with an assurance of our best services at all times.{'\n'}{'\n'}
          We look forward to an opportunity to serve you & your quick reply will be highly appreciated!{'\n'}{'\n'}{'\n'}</SmallBold>
          <BigBold>Thanking You</BigBold>,{'\n'}{'\n'}
          With Regards,{'\n'}{'\n'}
          <SmallBold>For M/s Pondhan Scaffolding Pvt Ltd</SmallBold>
          {'\n'}
        </Text>


        <View style={{ flexDirection: 'row' }}>

          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'bottom' }}>
            <Image style={{ width: 70, height: 70 }} src={sign} />
            <Image style={{ width: 70, height: 70 }} src={seal} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 130, height: 120 }} src={proud} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 200, height: 120 }} src={makeInIdia} />
          </View>
        </View>

        <View>
          <SmallBold>A.B.Ponnappa.</SmallBold>
          <Small>Managing Director</Small>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={justDial} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={scaff} />
          </View>
        </View>
        </Page>

        <Page size='A4' style={styles.pagepadding} wrap>
        <View style={[styles.section]}>
          <Text style={styles.headerwidth}></Text>
          <Text style={styles.headerwidth}>
            <Header>QUOTATION</Header>
          </Text>
          <Text style={styles.headerwidthdate}>
            Date:{quotationlistvalues.requestdeliverydate}
          </Text>
        </View>
        <View>
          <Text style={styles.refno}>{refno}</Text>
        </View>
        <View style={styles.fontsize18}>
          <Text>To,</Text>
          <Text>Mr/Miss. {quotationlistvalues.customername}</Text>
          <Text
           style={[styles.fontsize18, {
            width: 180,
            wordWrap: 'break-word'
          }]}
          >{quotationlistvalues.customeraddress}</Text>
          
        </View>


        <View style={styles.kindattend}>
          <Text>Mob:{quotationlistvalues.customercontactnumber}</Text>
        </View>


        <View style={styles.main}>
          <Text>Dear Sir/Madam,</Text>
        </View>
        <View style={styles.main}>
          <Text style={styles.padding5}>
            As per your inquiry for MS Scaffolding material, we are pleased to inform you the rates as mentioned below
          </Text>
        </View>
        <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={firstDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={secondDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      </>
      );
    }
    else if(i == 0 && i <= 5 && data.length < 7) {
      let firstDat = [];
      let secondDat = [];
      let thirdDat = [];
      data.forEach((element,i) => {
        if(i <= 1) {
          firstDat.push(data[i]);
        }
        if(i > 1 && i <= 3) {
          secondDat.push(data[i]);
        }
        if(i > 3 && i <= 5) {
          thirdDat.push(data[i]);
        }
      });
      return (
        <>
        <Page size='A4' style={styles.pagepadding} wrap>

        <View style={styles.section}>
          <Image style={{ marginLeft: -15 }} src={logo} />
          <Text style={styles.headerwidthAddress}>
            <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>

        <Text style={{ fontSize: 10 }}>
          {'\n'}{'\n'}
          <BigBold>Dear sir / Madam,</BigBold>{'\n'}{'\n'}
          <SmallBold>Thank you for your valuable enquiry & giving us an opportunity to help you <RedBold>“BUILD BETTER”.</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We are the leading manufacturing company in the field of <RedBold>“SCAFFOLDING & FORMWORK SYSTEM”</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We’re honored to present ourselves to you, Please click www.pondhan.com{'\n'}{'\n'}
          Our Product catalog & detailed specifications will help you more to understand our Products.{'\n'}{'\n'}
          We trust you will find our offer in line with your requirements.{'\n'}{'\n'}
          Our goal is to “giving you pretty reasonable price with good / high quality & get reasonable profit”{'\n'}{'\n'}
          In case you need any further information / queries, please feel free to get in touch with us.{'\n'}{'\n'}
          Looking forward to your valuable support with an assurance of our best services at all times.{'\n'}{'\n'}
          We look forward to an opportunity to serve you & your quick reply will be highly appreciated!{'\n'}{'\n'}{'\n'}</SmallBold>
          <BigBold>Thanking You</BigBold>,{'\n'}{'\n'}
          With Regards,{'\n'}{'\n'}
          <SmallBold>For M/s Pondhan Scaffolding Pvt Ltd</SmallBold>
          {'\n'}
        </Text>


        <View style={{ flexDirection: 'row' }}>

          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'bottom' }}>
            <Image style={{ width: 70, height: 70 }} src={sign} />
            <Image style={{ width: 70, height: 70 }} src={seal} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 130, height: 120 }} src={proud} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 200, height: 120 }} src={makeInIdia} />
          </View>
        </View>

        <View>
          <SmallBold>A.B.Ponnappa.</SmallBold>
          <Small>Managing Director</Small>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={justDial} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={scaff} />
          </View>
        </View>
        </Page>

        <Page size='A4' style={styles.pagepadding} wrap>
        <View style={[styles.section]}>
          <Text style={styles.headerwidth}></Text>
          <Text style={styles.headerwidth}>
            <Header>QUOTATION</Header>
          </Text>
          <Text style={styles.headerwidthdate}>
            Date:{quotationlistvalues.requestdeliverydate}
          </Text>
        </View>
        <View>
          <Text style={styles.refno}>{refno}</Text>
        </View>
        <View style={styles.fontsize18}>
          <Text>To,</Text>
          <Text>Mr/Miss. {quotationlistvalues.customername}</Text>
          <Text
           style={[styles.fontsize18, {
            width: 180,
            wordWrap: 'break-word'
          }]}
          >{quotationlistvalues.customeraddress}</Text>
          
        </View>


        <View style={styles.kindattend}>
          <Text>Mob:{quotationlistvalues.customercontactnumber}</Text>
        </View>


        <View style={styles.main}>
          <Text>Dear Sir/Madam,</Text>
        </View>
        <View style={styles.main}>
          <Text style={styles.padding5}>
            As per your inquiry for MS Scaffolding material, we are pleased to inform you the rates as mentioned below
          </Text>
        </View>
        <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={firstDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={secondDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={thirdDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>          
      </>
      );
    }
    else if(i == 0 && i <= 7 && data.length < 9) {
      let firstDat = [];
      let secondDat = [];
      let thirdDat = [];
      let fourthDat = [];
      data.forEach((element,i) => {
        if(i <= 1) {
          firstDat.push(data[i]);
        }
        if(i > 1 && i <= 3) {
          secondDat.push(data[i]);
        }
        if(i > 3 && i <= 5) {
          thirdDat.push(data[i]);
        }
        if(i > 5 && i <= 7) {
          fourthDat.push(data[i]);
        }        
      });
      return (
        <>
        <Page size='A4' style={styles.pagepadding} wrap>

        <View style={styles.section}>
          <Image style={{ marginLeft: -15 }} src={logo} />
          <Text style={styles.headerwidthAddress}>
            <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>

        <Text style={{ fontSize: 10 }}>
          {'\n'}{'\n'}
          <BigBold>Dear sir / Madam,</BigBold>{'\n'}{'\n'}
          <SmallBold>Thank you for your valuable enquiry & giving us an opportunity to help you <RedBold>“BUILD BETTER”.</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We are the leading manufacturing company in the field of <RedBold>“SCAFFOLDING & FORMWORK SYSTEM”</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We’re honored to present ourselves to you, Please click www.pondhan.com{'\n'}{'\n'}
          Our Product catalog & detailed specifications will help you more to understand our Products.{'\n'}{'\n'}
          We trust you will find our offer in line with your requirements.{'\n'}{'\n'}
          Our goal is to “giving you pretty reasonable price with good / high quality & get reasonable profit”{'\n'}{'\n'}
          In case you need any further information / queries, please feel free to get in touch with us.{'\n'}{'\n'}
          Looking forward to your valuable support with an assurance of our best services at all times.{'\n'}{'\n'}
          We look forward to an opportunity to serve you & your quick reply will be highly appreciated!{'\n'}{'\n'}{'\n'}</SmallBold>
          <BigBold>Thanking You</BigBold>,{'\n'}{'\n'}
          With Regards,{'\n'}{'\n'}
          <SmallBold>For M/s Pondhan Scaffolding Pvt Ltd</SmallBold>
          {'\n'}
        </Text>


        <View style={{ flexDirection: 'row' }}>

          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'bottom' }}>
            <Image style={{ width: 70, height: 70 }} src={sign} />
            <Image style={{ width: 70, height: 70 }} src={seal} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 130, height: 120 }} src={proud} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 200, height: 120 }} src={makeInIdia} />
          </View>
        </View>

        <View>
          <SmallBold>A.B.Ponnappa.</SmallBold>
          <Small>Managing Director</Small>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={justDial} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={scaff} />
          </View>
        </View>
        </Page>

        <Page size='A4' style={styles.pagepadding} wrap>
        <View style={[styles.section]}>
          <Text style={styles.headerwidth}></Text>
          <Text style={styles.headerwidth}>
            <Header>QUOTATION</Header>
          </Text>
          <Text style={styles.headerwidthdate}>
            Date:{quotationlistvalues.requestdeliverydate}
          </Text>
        </View>
        <View>
          <Text style={styles.refno}>{refno}</Text>
        </View>
        <View style={styles.fontsize18}>
          <Text>To,</Text>
          <Text>Mr/Miss. {quotationlistvalues.customername}</Text>
          <Text
           style={[styles.fontsize18, {
            width: 180,
            wordWrap: 'break-word'
          }]}
          >{quotationlistvalues.customeraddress}</Text>
          
        </View>


        <View style={styles.kindattend}>
          <Text>Mob:{quotationlistvalues.customercontactnumber}</Text>
        </View>


        <View style={styles.main}>
          <Text>Dear Sir/Madam,</Text>
        </View>
        <View style={styles.main}>
          <Text style={styles.padding5}>
            As per your inquiry for MS Scaffolding material, we are pleased to inform you the rates as mentioned below
          </Text>
        </View>
        <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={firstDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={secondDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={thirdDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={fourthDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>                
      </>
      );
    }    
    else if(i == 0 && i <= 9 && data.length < 11) {
      let firstDat = [];
      let secondDat = [];
      let thirdDat = [];
      let fourthDat = [];
      let fifthDat = [];
      data.forEach((element,i) => {
        if(i <= 1) {
          firstDat.push(data[i]);
        }
        if(i > 1 && i <= 3) {
          secondDat.push(data[i]);
        }
        if(i > 3 && i <= 5) {
          thirdDat.push(data[i]);
        }
        if(i > 5 && i <= 7) {
          fourthDat.push(data[i]);
        }
        if(i > 7 && i <=9) {
          fifthDat.push(data[i]);
        }        
      });
      return (
        <>
        <Page size='A4' style={styles.pagepadding} wrap>

        <View style={styles.section}>
          <Image style={{ marginLeft: -15 }} src={logo} />
          <Text style={styles.headerwidthAddress}>
            <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>

        <Text style={{ fontSize: 10 }}>
          {'\n'}{'\n'}
          <BigBold>Dear sir / Madam,</BigBold>{'\n'}{'\n'}
          <SmallBold>Thank you for your valuable enquiry & giving us an opportunity to help you <RedBold>“BUILD BETTER”.</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We are the leading manufacturing company in the field of <RedBold>“SCAFFOLDING & FORMWORK SYSTEM”</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We’re honored to present ourselves to you, Please click www.pondhan.com{'\n'}{'\n'}
          Our Product catalog & detailed specifications will help you more to understand our Products.{'\n'}{'\n'}
          We trust you will find our offer in line with your requirements.{'\n'}{'\n'}
          Our goal is to “giving you pretty reasonable price with good / high quality & get reasonable profit”{'\n'}{'\n'}
          In case you need any further information / queries, please feel free to get in touch with us.{'\n'}{'\n'}
          Looking forward to your valuable support with an assurance of our best services at all times.{'\n'}{'\n'}
          We look forward to an opportunity to serve you & your quick reply will be highly appreciated!{'\n'}{'\n'}{'\n'}</SmallBold>
          <BigBold>Thanking You</BigBold>,{'\n'}{'\n'}
          With Regards,{'\n'}{'\n'}
          <SmallBold>For M/s Pondhan Scaffolding Pvt Ltd</SmallBold>
          {'\n'}
        </Text>


        <View style={{ flexDirection: 'row' }}>

          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'bottom' }}>
            <Image style={{ width: 70, height: 70 }} src={sign} />
            <Image style={{ width: 70, height: 70 }} src={seal} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 130, height: 120 }} src={proud} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 200, height: 120 }} src={makeInIdia} />
          </View>
        </View>

        <View>
          <SmallBold>A.B.Ponnappa.</SmallBold>
          <Small>Managing Director</Small>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={justDial} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={scaff} />
          </View>
        </View>
        </Page>

        <Page size='A4' style={styles.pagepadding} wrap>
        <View style={[styles.section]}>
          <Text style={styles.headerwidth}></Text>
          <Text style={styles.headerwidth}>
            <Header>QUOTATION</Header>
          </Text>
          <Text style={styles.headerwidthdate}>
            Date:{quotationlistvalues.requestdeliverydate}
          </Text>
        </View>
        <View>
          <Text style={styles.refno}>{refno}</Text>
        </View>
        <View style={styles.fontsize18}>
          <Text>To,</Text>
          <Text>Mr/Miss. {quotationlistvalues.customername}</Text>
          <Text
           style={[styles.fontsize18, {
            width: 180,
            wordWrap: 'break-word'
          }]}
          >{quotationlistvalues.customeraddress}</Text>
          
        </View>


        <View style={styles.kindattend}>
          <Text>Mob:{quotationlistvalues.customercontactnumber}</Text>
        </View>


        <View style={styles.main}>
          <Text>Dear Sir/Madam,</Text>
        </View>
        <View style={styles.main}>
          <Text style={styles.padding5}>
            As per your inquiry for MS Scaffolding material, we are pleased to inform you the rates as mentioned below
          </Text>
        </View>
        <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={firstDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={secondDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={thirdDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={fourthDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page> 
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={fifthDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>                     
      </>
      );
    }
    else if(i == 0 && i <= 11 && data.length < 13) {
      let firstDat = [];
      let secondDat = [];
      let thirdDat = [];
      let fourthDat = [];
      let fifthDat = [];
      let sixthDat = [];
      data.forEach((element,i) => {
        if(i <= 1) {
          firstDat.push(data[i]);
        }
        if(i > 1 && i <= 3) {
          secondDat.push(data[i]);
        }
        if(i > 3 && i <= 5) {
          thirdDat.push(data[i]);
        }
        if(i > 5 && i <= 7) {
          fourthDat.push(data[i]);
        }
        if(i > 7 && i <=9) {
          fifthDat.push(data[i]);
        }        
      });
      return (
        <>
        <Page size='A4' style={styles.pagepadding} wrap>

        <View style={styles.section}>
          <Image style={{ marginLeft: -15 }} src={logo} />
          <Text style={styles.headerwidthAddress}>
            <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>

        <Text style={{ fontSize: 10 }}>
          {'\n'}{'\n'}
          <BigBold>Dear sir / Madam,</BigBold>{'\n'}{'\n'}
          <SmallBold>Thank you for your valuable enquiry & giving us an opportunity to help you <RedBold>“BUILD BETTER”.</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We are the leading manufacturing company in the field of <RedBold>“SCAFFOLDING & FORMWORK SYSTEM”</RedBold></SmallBold>{'\n'}{'\n'}
          <SmallBold>We’re honored to present ourselves to you, Please click www.pondhan.com{'\n'}{'\n'}
          Our Product catalog & detailed specifications will help you more to understand our Products.{'\n'}{'\n'}
          We trust you will find our offer in line with your requirements.{'\n'}{'\n'}
          Our goal is to “giving you pretty reasonable price with good / high quality & get reasonable profit”{'\n'}{'\n'}
          In case you need any further information / queries, please feel free to get in touch with us.{'\n'}{'\n'}
          Looking forward to your valuable support with an assurance of our best services at all times.{'\n'}{'\n'}
          We look forward to an opportunity to serve you & your quick reply will be highly appreciated!{'\n'}{'\n'}{'\n'}</SmallBold>
          <BigBold>Thanking You</BigBold>,{'\n'}{'\n'}
          With Regards,{'\n'}{'\n'}
          <SmallBold>For M/s Pondhan Scaffolding Pvt Ltd</SmallBold>
          {'\n'}
        </Text>


        <View style={{ flexDirection: 'row' }}>

          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'bottom' }}>
            <Image style={{ width: 70, height: 70 }} src={sign} />
            <Image style={{ width: 70, height: 70 }} src={seal} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 130, height: 120 }} src={proud} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ width: 200, height: 120 }} src={makeInIdia} />
          </View>
        </View>

        <View>
          <SmallBold>A.B.Ponnappa.</SmallBold>
          <Small>Managing Director</Small>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={justDial} />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <Image style={{ flex: 1, height: 150, margin: 16 }} src={scaff} />
          </View>
        </View>
        </Page>

        <Page size='A4' style={styles.pagepadding} wrap>
        <View style={[styles.section]}>
          <Text style={styles.headerwidth}></Text>
          <Text style={styles.headerwidth}>
            <Header>QUOTATION</Header>
          </Text>
          <Text style={styles.headerwidthdate}>
            Date:{quotationlistvalues.requestdeliverydate}
          </Text>
        </View>
        <View>
          <Text style={styles.refno}>{refno}</Text>
        </View>
        <View style={styles.fontsize18}>
          <Text>To,</Text>
          <Text>Mr/Miss. {quotationlistvalues.customername}</Text>
          <Text
           style={[styles.fontsize18, {
            width: 180,
            wordWrap: 'break-word'
          }]}
          >{quotationlistvalues.customeraddress}</Text>
          
        </View>


        <View style={styles.kindattend}>
          <Text>Mob:{quotationlistvalues.customercontactnumber}</Text>
        </View>


        <View style={styles.main}>
          <Text>Dear Sir/Madam,</Text>
        </View>
        <View style={styles.main}>
          <Text style={styles.padding5}>
            As per your inquiry for MS Scaffolding material, we are pleased to inform you the rates as mentioned below
          </Text>
        </View>
        <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={firstDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={secondDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={thirdDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={fourthDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page> 
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={fifthDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>  
      <Page size='A4' style={styles.pagepadding} wrap>
      <Table
        style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
        data={sixthDat}
      >
        <TableHeader
          textAlign={'center'}
          style={{ background: '#000' }}
        >
          <TableCell weighting={0.08}>
            <Text style={[styles.headerfont, {}]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={styles.tableHeader}>Particular</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Weight Each kgs(+/-5%)</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Rate/{'\n'}Each</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Dist %</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>
              After Dist Rate/Each{'\n'}
            </Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Qty</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={styles.tableHeader}>Total</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => r.slno}
          />
          <DataTableCell
            style={[styles.padding, { width: 150, wordWrap: 'break-word' }]}
            weighting={0.6}
            getContent={r => {
              return (
                <View>
                  <Text style={{ fontSize: 11,height: 90, width: 162 }}><Small
                  >{r.itemname}</Small>{'\n'}</Text>
                  {r.itemimage && r.itemimage !== null ? (<View><Text>{'\n'}</Text>
                    <Image style={{height: "120px", width: "140px"}}
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                    />
                    <Text>{'\n'}</Text></View>) : (<View></View>)}

                </View>
              );
            }}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <BlackBold>{r.itemdiscountamount.toString()}</BlackBold>}
          />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
          <DataTableCell style={styles.padding} weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
        </TableBody>
      </Table>
      </Page>                           
      </>
      );
    }
  })
  const datalength = tableComponentTwo.length - 1;
  const tableComponent = tableComponentTwo.map((data, i) => {
    if (i >= 1 && i === datalength) {

      return (
        <Page size='A4' style={styles.pagepadding} wrap key={i}>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: '#000' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell
                weighting={0.2}
                style={styles.padding}
                getContent={r => {
                  return r.weighteach;
                }}
              />
              <DataTableCell style={styles.padding} weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                style={styles.padding}
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.2}
                getContent={r => r.itemdiscountamount.toString()}
              />
              <DataTableCell style={styles.padding} weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell style={styles.padding} weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Total</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{totalrs}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>GST %</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{tax}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Grand Total</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>
                  {grandtotal}
                </Text>
              </TableCell>
            </TableHeader>
          </Table>
        </Page>
      );
    } else if (i >= 1) {
      return (
        <Page size='A4' style={styles.pagepadding} wrap key={i}>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.weighteach} />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscountamount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
        </Page>
      );
    } else if (i < 1 && i === datalength) {
      return (
        <View>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
            key={i}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-3%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.weighteach} />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscountamount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Total</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{totalrs}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>GST %</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{tax}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Grand Total</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>
                  {grandtotal}
                </Text>
              </TableCell>
            </TableHeader>
          </Table>
        </View>
      );
    } else {
      return (
        <View>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
            key={i}
          >
            <TableHeader textAlign={'center'} style={styles.tableheaderbgcolor}>
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-3%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.weighteach} />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscountamount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
        </View>
      );
    }
  });

  return (
    <Document>
         {dispDyn}
        <Page size='A4' style={styles.pagepadding} wrap>
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>Total</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={{ textAlign: 'center', fontSize: 11 }}>{totaleachitem}</Text>
            </TableCell>
          </TableHeader>
        </Table>

        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>Loading and Handling Charges per ton Rs185.00 x (No of tons)</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={{ textAlign: 'center', fontSize: 11 }}>{loadingcharges}</Text>
            </TableCell>
          </TableHeader>
        </Table>

        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>W.S</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={[styles.headerfont, { textAlign: 'center' }]}>{ws}</Text>
            </TableCell>
          </TableHeader>
        </Table>

        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}> Transit Insurance</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={[styles.headerfont, { textAlign: 'center' }]}>{transitinsurance}</Text>
            </TableCell>
          </TableHeader>
        </Table>

        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>Sub Total</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={[styles.headerfont, { textAlign: 'center' }]}>{subtotal}</Text>
            </TableCell>
          </TableHeader>
        </Table>

        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>GST@18%</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={[styles.headerfont, { textAlign: 'center' }]}>{subtotalgst}</Text>
            </TableCell>
          </TableHeader>
        </Table>


        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>Total</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={[styles.headerfont, { textAlign: 'center' }]}>{totalincludegst}</Text>
            </TableCell>
          </TableHeader>
        </Table>


        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>Transportation Charges</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={[styles.headerfont, { textAlign: 'center' }]}>{tcharge}</Text>
            </TableCell>
          </TableHeader>
        </Table>


        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>GST@{transportationgstper}%</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={[styles.headerfont, { textAlign: 'center' }]}>{tchargegst}</Text>
            </TableCell>
          </TableHeader>
        </Table>

        <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>TCS@{quotationlistvalues.tcs}%</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={[styles.headerfont, { textAlign: 'center' }]}>{tcsval}</Text>
              </TableCell>
            </TableHeader>
        </Table>         



        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={1.087}>
              <Text style={styles.headerfont2}>Grand Total</Text>
            </TableCell>
            <TableCell weighting={0.13}>
              <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                {grandtotal}
              </Text>
            </TableCell>
          </TableHeader>
        </Table>

        <Table style={styles.section}>
          <TableHeader>
        
            <TableCell weighting={1}>
              <Text style={[styles.headerfont5, { textAlign: 'right' }]}>
              {amountinwords}
              </Text>
            </TableCell>
          </TableHeader>
        </Table>
{/* 
        <Text style={[styles.headerfont, { textAlign: 'right' }]}>
        {'\n'} 
            {amountinwords}
        </Text> */}

       

        {/* <View style={styles.footerfixed}>
          <Image src={client} />
        </View> */}
        {/* <View style={styles.section}>
          <Image style={{ marginLeft: -15 }} src={logo} />
          <Text style={styles.headerwidthAddress}>
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View> */}

        <View style={[styles.section]}>
          <Text style={{fontSize: '10'}}>We hope the rates quoted are reasonable and looking forward for your kind orders.</Text>
        </View>

        <View style={[styles.main]}>
          <Text style={styles.padding1} >Thanking you </Text>
          <Text style={styles.padding1}>Yours truly,</Text>
          <View style={[styles.section]}>
            <Text style={styles.brandname}>For PONDHAN SCAFFOLING PVT LTD</Text>
            <Text style={{fontSize: "12", fontWeight: "600", paddingTop: "10", paddingBottom: "10"}}>Sales Team Contact:</Text>
          </View>
        </View>



        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Image style={{ width: 70, height: 70 }} src={sign} />
          <Image style={{ width: 70, height: 70 }} src={seal} />
          <View style={{ paddingLeft: '300' }}>
          <Text style={{fontSize: "12", fontWeight: "600", paddingTop: "10", paddingBottom: "10"}}>9900069150</Text>
          </View>
        </View>

        <View>
          {/* <Image src={seal} style={styles.smallicon} />
          <Image src={sign} style={styles.smallicon} /> */}
          <Text style={styles.brandname}>A.B. Ponnappa {'\n'}
            Managing Director
          </Text>
          
        </View>

        <View style={[styles.main]}>
          <Text style={styles.padding1} > Note:</Text>
          <Text style={styles.padding1}>1,  Advance payment 60% against Purchase order  & Balance against Pro-forma invoice / deduction of advance on Pro Rata basis..</Text>
          <Text style={styles.padding1}>2, Validity: All prices are Current Ruling.</Text>
          <Text style={styles.padding1}>3, Delivery Schedule: {scheduledays} after confirmation.</Text>
          <Text style={styles.padding1}>4, Transportation Charges extra as actual.</Text>
          <Text style={styles.padding1}>5, In case of any Uncircumstances, The Advance Amount paid to materials will be returned within 7 working days.</Text>
          <Text style={styles.padding1}>6, Unloading should be done by party.</Text>
          <Text style={styles.padding1}>7, After Delivery if not satisfied you should return within 2 days from the supply date & collect payment within 5 working days but materials should not be used.</Text>
          <Text style={styles.padding1}>8, We are not responsible for Breakage & Damage while Transportaion or Unloading.</Text>          
        </View>

        <View style={[styles.main]}>
          <Text style={styles.padding1} > Bank Details:-</Text>
          <Text style={styles.padding1}>Name: Pondhan scaffolding Pvt ltd.</Text>
          <Text style={styles.padding1}>Bank: Bank Of Baroda Branch: Byatarayanapura</Text>
          <Text style={styles.padding1}>City: Bangalore-560092</Text>
          <Text style={styles.padding1}>A/C No: 73690500000003.</Text>
          <Text style={styles.padding1}>IFSC: Code: BARBOVJBYAT.</Text>
        </View>

        <View>
            <Text
                 style={[styles.main, { color: 'red' }]}
                >
                 Note: Any legal matter should be under Bengaluru Jurisdiction </Text>
        </View>

        <View style={[styles.footerfixed]}>
          <Image src={client} />
        </View>

      </Page>
    </Document>
  );
};

export default MyDocument;
