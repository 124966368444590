import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import UpdateMasterItemTypeForm from "../Form/UpdateMasterItemTypeForm";
import {
  getMasterItemTypes,
  updateMasterItemTypes
} from "../../../../actions/Transaction";
import CustomLoader from "../../../../Components/loader/loader";

class UpdateItemMasterType extends React.Component {
  state = {
    loading: false,
    masteritemtypenamevalue: {}
  };
  componentDidMount() {
    this.props.getMasterItemTypes();
    const masteritemid = this.props.match.params.id;
    const masteritemtypenamevalue = this.props.masteritemtypenamevalue[
      masteritemid
    ];
    this.setState({ masteritemtypenamevalue });
  }
  dataHandler = value => {
    this.setState({ loading: value });
  };
  submit = data => {
    this.dataHandler(true);
    return this.props.updateMasterItemTypes(data).then(data => {
      this.dataHandler(false);
      return data;
    });
  };
  render() {
    const { loading, masteritemtypenamevalue } = this.state;

    return (
      <div className="mg-t-20 container">
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/inventory_page">Setup</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/masteritemtypelist">Item Type</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Update Item </BreadcrumbItem>
        </Breadcrumb>
        <div>
          <UpdateMasterItemTypeForm
            submit={this.submit}
            masteritemtypenamevalue={masteritemtypenamevalue}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    masteritemtypenamevalue: state.masteritemtypenamevalue
  };
}

export default connect(
  mapStateToProps,
  { getMasterItemTypes, updateMasterItemTypes }
)(UpdateItemMasterType);
