import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTable from "../../Components/Table/DatatablePage";
import DatePicker from "react-datepicker";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Cols,
  Label,
  Button,
  Alert,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import CustomLoader from "../../Components/loader/loader";
import axios from "axios";


class EnquiryPage extends React.Component {
  state = {
    searchtodate: null,
    count: null,
    success: false,
    update: false,
    enquirylistvalues: [],
    backupenquirylistvalues: [],
    loading: false,
    errorMsg: '',
    data: {
      eno: '',
      company: '',
      name: '',
      phone: '',
      email: '',
      detail: '',
      enquiryFrom: '',
 //   quoteno: '',
      quotationCreated: false
    }
  };

  componentDidMount = () => {
    this.dataHandler(true);
    this.getEnquiries()
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  onhandlerChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  quotationCreated = e => {
    const val = this.state.data.quotationCreated;
    this.setState({
      ...this.state,
      data: { ...this.state.data, ["quotationCreated"]: !val }
    })
  }
  updateButton = e => {
    console.log(e);
    if (!e.quotationCreated) { e.quotationCreated = false; }
    this.setState({ ...this.state, success: true, update: true, data: e });
  }
  getEnquiries() {
    this.dataHandler(true)
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Enquiry/getenquiry`)
      .then(res => {
        this.dataHandler(false)
        if (res.data.enquirylist.enquirydata.length > 0) {
          let enquiryvalues = res.data.enquirylist.enquirydata;
          if (enquiryvalues.length > 0) {
            enquiryvalues = enquiryvalues.reverse()
            enquiryvalues = enquiryvalues.map(enquiry => {
              console.log(enquiry)
              let created = "No"
              if (enquiry.quotationCreated) {
                created = "Yes"
              }
              return {
                date: enquiry.date,
                name: enquiry.name,
                phone: enquiry.phone,
                company: enquiry.company,
                email: enquiry.email,
                detail: enquiry.detail,
                enquiryFrom: enquiry.enquiryFrom || null,
            //    quoteno: enquiry.quoteno || null,
                view: (
                  <Button
                    Label
                    color='primary'
                    onClick={() => {
                      this.updateButton(enquiry);
                    }}
                  >
                    Edit
                </Button>
                )

              };
            });

            this.setState({
              enquirylistvalues: enquiryvalues,
              count: enquiryvalues.length
            }, () => {
            })
          }
        }
        else {
          this.setState({ count: 0 })
        }
      },
        error => {
          this.dataHandler(false)
          this.setState({ success: false, message: 'Server error.' });
        });
  }

  addenquiry = () => {
    this.setState({
      success: true, update: false,
      data: {
        eno: '',
        company: '',
        name: '',
        phone: '',
        email: '',
        detail: '',
        enquiryFrom: '',
   //   quoteno: '',
        quotationCreated: false
      }
    });
  }

  addnewenquiry = () => {
    const { data } = this.state;
    if (data.company == '' || data.name == '' || data.phone == '' || data.email == '' || data.detail == '' || data.enquiryFrom == '') {
      this.setState({ 'errorMsg': 'Fill all fields' });
    }
    else {
      console.log(this.state.data)

      this.dataHandler(true)
      let date = (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()) +
        "/" + (new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) + "/" + new Date().getFullYear();

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/Enquiry/addenquiry`,
          {
            "eno": "",
            "company": data.company,
            "name": data.name,
            "phone": data.phone,
            "email": data.email,
            "detail": data.detail,
            "date": date,
            "enquiryFrom": data.enquiryFrom,
          //  "quoteno": data.quoteno,
            "quotationCreated": data.quotationCreated
          })
        .then(res => {
          this.getEnquiries()
          this.toggle();
        },
          error => {
            this.dataHandler(false)
            this.setState({ success: false, message: 'Server error.' });
          });
    }
  }

  updateenquiry = () => {
    const { data } = this.state;
    console.log(data)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Enquiry/updateenquiry`,
        {
          "_id": data._id,
          "eno": "",
          "company": data.company,
          "name": data.name,
          "phone": data.phone,
          "email": data.email,
          "detail": data.detail,
          "date": data.date,
          "enquiryFrom": data.enquiryFrom,
        //  "quoteno": data.quoteno,
          "quotationCreated": data.quotationCreated
        })
      .then(res => {
        this.getEnquiries()
        this.toggle();
      },
        error => {
          this.dataHandler(false)
          this.setState({ success: false, message: 'Server error.' });
        });
  }


  toggle = () => {
    this.setState({ ...this.state, success: false });
  }

  render() {
    const { count, enquirylistvalues, loading, success, data, errorMsg, update } = this.state;

    const columnvalue = [
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50
      },
      {
        label: "Customer name",
        field: "name",
        sort: "asc",
        width: 50
      },
      {
        label: "Phone number",
        field: "phone",
        sort: "asc",
        width: 50
      },
      {
        label: "Company name",
        field: "company",
        sort: "asc",
        width: 50
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 50
      },
      {
        label: "Item detail",
        field: "detail",
        sort: "asc",
        width: 50
      },
      {
        label: "Enquiry",
        field: "enquiryFrom",
        sort: "asc",
        width: 50
      },
      // {
      //   label: "Quotation created",
      //   field: "quotationCreated",
      //   sort: "asc",
      //   width: 50
      // },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 50
      }
    ];




    let modaldata, errormessage;
    errormessage = errorMsg != '' ? (<p style={{ 'textAlign': 'center', 'color': 'red' }}>{errorMsg}</p>) : null
    if (success) {
      modaldata = (
        <Modal
          isOpen={success}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Enquiry</ModalHeader>
          <ModalBody>

            <input type="text" className="form-control" placeholder="Company name" name="company" value={data.company} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange} />
            <input type="text" className="form-control" placeholder="Person name" name="name" value={data.name} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange} />
            <input type="text" className="form-control" placeholder="Mode of enquiry" name="enquiryFrom" value={data.enquiryFrom} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange} />
            <input type="number" className="form-control" placeholder="Phone number" name="phone" value={data.phone} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange} />
            <input type="email" className="form-control" placeholder="Email" name="email" value={data.email} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange}  />
            <textarea type="text" className="form-control" placeholder="Item details" name="detail" value={data.detail} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange}  ></textarea>
            {/* <input type="text" className="form-control" placeholder="Quotation Number" name="quoteno" value={data.quoteno} style={{ 'marginBottom': '12px' }} onChange={this.onhandlerChange} /> */}
            {/* <input type="checkbox" className="" value={data.quotationCreated} onChange={this.quotationCreated} checked={data.quotationCreated} /> <span style={{position:'relative',top:'-12px'}}> Quotation created </span> */}
            {errormessage}
          </ModalBody>
          <ModalFooter>
            {update ? (
              <Button
                Label
                style={{ float: 'right' }}
                color="primary"
                onClick={this.updateenquiry}
              >
                Update
          </Button>
            ) : (
                <div>
                  <Button color="primary" onClick={this.toggle} style={{ fontSize: '0.8rem' }}>
                    Cancel
          </Button>
                  <Button
                    Label
                    style={{ float: 'right' }}
                    color="primary"
                    onClick={this.addnewenquiry}
                  >
                    Submit
        </Button>
                </div>
              )}
          </ModalFooter>
        </Modal>
      )
    }


    return (
      <div className="mg-t-20">
        {loading ? <CustomLoader /> : null}
 
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin_dashboard">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/crmlist">CRM</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Enquiries</BreadcrumbItem>

            </Breadcrumb>


            <Button
              Label
              style={{ float: 'right' }}
              color="primary"
              onClick={this.addenquiry}
            >
              Add Enquiry
          </Button>

            {modaldata}

            <DataTable
              columnvalue={columnvalue}
              rowvalues={enquirylistvalues}
              className="customerlist"
            />

          </div>
         
      </div>
    );
  }
}



export default EnquiryPage
