import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Label, Alert } from "reactstrap";
import { Card,CardBody,CardHeader,Row, Col } from 'reactstrap';

class UpdateMaterialTypesForm extends React.Component{
    state={
        data:{
            materialname:this.props.materialoption.materialname,
            id:this.props.materialoption.id
        },
        errors:{},
        loading:false,
        success:false
    }
    onChange = e => {
        this.setState({
          ...this.state,
          data: { ...this.state.data, [e.target.name]: e.target.value }
        });
    };

    componentWillReceiveProps(props){
        const dataRecord = props.materialoption;
        this.setState({...this.state.data, data:dataRecord});
    }

    handleValidSubmit = () => {
        this.setState({ loading: true });
        this.props
          .submit(this.state.data)
          .then(() => {
            this.setState({ loading: false,errors:{},success:true});
            // this.myFormRef.reset();
          })
          .catch((err) =>{
            this.setState({ errors: err.response.data.errors, loading: false }) 
          });
    };

    render(){
        console.log(this.props);
        const { data, errors, loading, success } = this.state;
        return(
            <div>
                {!!success ? (
                    <Alert color="info">Item details is updated successfully</Alert>
                ) : null}
                {!!errors.materialname ? (
                    <Alert color="danger">Item data already exist.</Alert>
                ) : null}
                {!!errors.global ? (
                    <Alert color="danger">Something went wrong,Please try again later.</Alert>
                ) : null}
                <AvForm
                    onValidSubmit={this.handleValidSubmit}
                    onInvalidSubmit={this.handleInvalidSubmit}
                    ref={el => (this.myFormRef = el)}
                >
                    <Card>
                        <CardHeader>Update Item</CardHeader>
                        <CardBody>
                        <Row>
                            <Col sm={6}>
                                <AvGroup>
                                    <Label className='small-font-size' for="material">Item</Label><span className="required-label">*</span>
                                    <AvField
                                        name="materialname"
                                        type="text"
                                        id="material"
                                        value={data.materialname}
                                        onChange={this.onChange}
                                        validate={{
                                            required: {
                                            value: true,
                                            errorMessage: "Please enter Item name"
                                            },
                                      
                                            pattern: {
                                            value:
                                                "^[a-zA-Z]",
                                            errorMessage:
                                                "Please enter valid item name"
                                            }
                                        }}
                                    />   
                                </AvGroup>
                            </Col>
                        </Row>
                        </CardBody>
                    </Card>
                    <div className="mg-20">
                        <Button color="primary" disabled={loading} className="lgn-loader">
                            update
                            {/* {loading} */}
                        </Button>
                    </div>
                </AvForm>                        
            </div>
        );
    }
}

UpdateMaterialTypesForm.propTypes = {
    submit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
      materialoptionobjectlist: state.materialoption
    };
}

export default connect(mapStateToProps,{})(UpdateMaterialTypesForm);