import React from 'react';
import logo from '../../../../Images/logo.png';
import client from '../../../../Images/client.png';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import {
  TableCell,
  Table,
  TableHeader,
  TableBody,
  DataTableCell
} from '@david.kucsai/react-pdf-table';
import styled from '@react-pdf/styled-components';

// Create styles
const styles = StyleSheet.create({
  textcenter: { textAlign: 'center' },
  textright: { textAlign: 'right' },
  textleft: { textAlign: 'left' },
  paddingleft: { paddingLeft: 5 },
  margin: {
    margin: 5
  },
  pagepadding: {
    padding: 20
  },
  section: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  section_column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  myHeight: {
    height: '100px'
  },
  headerfont: {
    fontSize: 8,
    fontWeight: 'bold',
    padding: 3
  },
  descriptionText: {
    fontSize: 10,
    fontWeight: 'normal'
  },
  headerfont2: {
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'right',
    padding: 3
  },
  headerfontleft: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left'
  },
  billingaddress: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
    height: 70
  },
  shippingaddress: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
    height: 130
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dcaddress: {
    fontSize: 8,
    color: ' black'
  },
  dcaddress_left: {
    fontSize: 8
  },
  view_border: {
    border: '2pt solid #000',
    flexGrow: 1,
    height: 100,
    padding: 8
  },
  view_border_left: {
    borderLeft: '2pt solid #000',
    borderTop: '2pt solid #000',
    borderBottom: '2pt solid #000',
    flexGrow: 1,
    height: 100,
    padding: 8
  },
  view_border_right: {
    borderRight: '2pt solid #000',
    borderTop: '2pt solid #000',
    borderBottom: '2pt solid #000',
    flexGrow: 1,
    height: 100,
    padding: 8
  },
  column: {
    width: 190
  },
  headerwidth: {
    width: 210,
    padding: 20
  },
  headerwidthdate: {
    width: 210,
    paddingTop: 23,
    fontSize: 12
  },
  headerwidthlogo: {
    width: 350,
    fontSize: 12
  },
  logo: {
    width: 225,
    height: 90
  },
  header_name: {
    width: 350,
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'left',
    paddingLeft: 40
  },
  header_sub_name: {
    width: '100%',
    fontSize: 8,
    textAlign: 'center',
    paddingHorizontal: 25
  },
  header_sub_name_phone: {
    width: '100%',
    fontSize: 8,
    fontWeight: 'bolder',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  header_tintext: {
    fontSize: 8,
    fontWeight: 'bold',
    paddingLeft: 10
  },
  header_sub_name3: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  header_sub_nameB: {
    flexDirection: 'column',
    paddingTop: 10,
    flex: 1
  },
  header_sub_nameA: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  headerwidthAddress: {
    width: 260,
    fontSize: 10,
    padding: 2,
    textAlign: 'right'
  },
  refstyle: {
    paddingBottom: 20,
    fontSize: 12
  },
  headerpadding: {
    padding: 20
  },
  flexgrow: {
    flexGrow: 1
  },
  fontsize18: {
    fontSize: 13
  },
  kindattend: {
    paddingTop: 15,
    fontSize: 13
  },
  yourref: {
    fontSize: 13,
    paddingBottom: 10
  },
  main: {
    fontSize: 12
  },
  tableheaderbgcolor: {
    backgroundColor: 'red',
    color: 'red',
    background: 'red'
  },
  footerfixed: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  dc_header: {
    flexDirection: 'row'
  },
  dc_gst: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  padding: {
    padding: 16,
    fontSize: 8
  }
});

const Small = ({ children }) => {
  return (
    <Text style={{ fontSize: 8 }}>{children}</Text>
  )
}

const SmallBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 8, fontWeight: 'bold' }}>{children}</Text>
  )
}

const Medium = ({ children }) => {
  return (
    <Text style={{
      fontSize: 10,
      marginLeft: 25,
      textDecorationLine: 'underline'
    }}>{children}</Text>
  )
}

const MediumBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>{children}</Text>
  )
}

const Bold = ({ children }) => {
  return (
    <Text style={{ fontWeight: 'bold' }}>{children}</Text>
  )
}

const Header = ({ children }) => {
  return (
    <Text style={{
      // width: 350,
      fontWeight: 'bold',
      fontSize: 24,
      textAlign: 'left',
      paddingLeft: 10
    }}>{children}</Text>
  )
}

// Create Document Component

const MyDocument = ({ challanlistvalues, data_cond }) => {
  const financialYear = challanlistvalues.financialyear;
  console.log(JSON.stringify(challanlistvalues));
  const data = challanlistvalues.addeditemlist;
  console.log('data');
  console.log(data);
  const totalrs = challanlistvalues.totalvalue
    ? challanlistvalues.totalvalue
    : '';
  const taxableAmount = challanlistvalues.taxableamount;
  const date = moment(challanlistvalues.date).format('DD MMM, YYYY');
  const podate = moment(challanlistvalues.podate).format('DD MMM, YYYY');
  const purchaseorderdate = moment(challanlistvalues.purchaseorderdate).format(
    'DD MMM, YYYY'
  );
  const billingaddress = challanlistvalues.billingaddress;
  const deliveryaddress = challanlistvalues.deliveryaddress;
  const vendorcode = challanlistvalues.vendorcode;
  const {
    freightcharges,
    subtotal,
    cgst,
    sgst,
    igst,
    total,
    grandtotal,
    deliverychallannumber,
    goodsDescription,
    invoicenumber,
    roundoffamount
  } = challanlistvalues;

  let {
    deliverychallanno,
    toaddress,
    site,
    ponumber,
    vehiclenumber,
    otherreference,
    gstno,
    customername
  } = challanlistvalues;
  let arr = [],toAD='',arr1 = [],siteAD='',customername1 = ''
  let mobileno = challanlistvalues.mobilenumber ? challanlistvalues.mobilenumber : ''
  // if(toaddress.indexOf(',') > -1){
  //   arr = toaddress.split(',');
  //   customername = arr[0]
  //   if(arr.length > 2){
  //     for(var i=1;i<arr.length;i++){
  //       toAD = i > 1 ? toAD + ',' + arr[i] : toAD + arr[i]
  //     }
  //   }
  // }
  // else
  //   toAD = toaddress


    toAD = toaddress
  
    // if(site.indexOf(',') > -1){
    //   arr1 = site.split(',');
    //   customername1 = arr1[0]
    //   if(arr1.length > 2){
    //     for(var i=1;i<arr1.length;i++){
    //       siteAD = i > 1 ? siteAD + ',' + arr1[i] : siteAD + arr1[i]
    //     }
    //   }
    // }
    // else
    //   siteAD = site

    


  const purchaseordernumber = challanlistvalues.purchaseordernumber;
  const tax = challanlistvalues.tax ? challanlistvalues.tax : '';
  const taxpercentage = challanlistvalues.tax
    ? challanlistvalues.tax / 100
    : '';


  let pageone = [];
  let pageTwo = [];
  let pageThree = [];
  let pageoneislast = false;
  let pagetwoislast = false;
  let pagethreeislast = false;

  data.forEach((ele, i) => {
    if (i == 0 && data.length > 1) {
        if(ele.itemname.length < 2043) {
            pageone.push(ele);
            if (ele.itemname.length + data[i + 1].itemname.length < 2043) {
                pageone.push(data[i + 1]);
                if (data.length > 2) {
                    if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length < 2043) {
                        pageone.push(data[i + 2]);
                        if (data.length > 3) {
                            if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length < 2043) {
                                pageone.push(data[i + 3]);
                                if (data.length > 4) {
                                    if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length + data[i + 4].itemname.length < 2043) {
                                        pageone.push(data[i + 4]);
                                        if (data.length > 5) {
                                          if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length + data[i + 4].itemname.length + data[i + 5].itemname.length < 2043) {
                                            pageone.push(data[i + 5]);
                                          }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (pageone.length > 0) {
            if (data.length > pageone.length) {
                if (data[pageone.length].itemname.length < 2723) {
                    pageTwo.push(data[pageone.length])
                }
                if(data.length > (pageone.length + 1) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length < 2723) {
                    pageTwo.push(data[pageone.length + 1])
                }
                if(data.length > (pageone.length + 2) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length < 2723) {
                    pageTwo.push(data[pageone.length + 2])
                }
                if(data.length > (pageone.length + 3) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length < 2723) {
                    pageTwo.push(data[pageone.length + 3])
                }
                if(data.length > (pageone.length + 3) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length + data[pageone.length + 4].itemname.length < 2723) {
                    pageTwo.push(data[pageone.length + 4])
                }
                if(data.length > (pageone.length + 3) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length + data[pageone.length + 4].itemname.length + data[pageone.length + 5].itemname.length < 2723) {
                    pageTwo.push(data[pageone.length + 5])
                }
                if(data.length > (pageone.length + 3) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length + data[pageone.length + 4].itemname.length + data[pageone.length + 5].itemname.length + data[pageone.length + 6].itemname.length < 2723) {
                    pageTwo.push(data[pageone.length + 6])
                }                
            }
            else {
              pageoneislast = true;
            }
        }
        if(pageTwo.length > 0) {
            if (data.length > (pageone.length + pageTwo.length)) {
                if (data[pageone.length + pageTwo.length].itemname.length < 1361) {
                    pageThree.push(data[pageone.length  + pageTwo.length])
                }
                if(data.length > (pageone.length  + pageTwo.length + 1) && data[pageone.length  + pageTwo.length].itemname.length + data[pageone.length  + pageTwo.length + 1].itemname.length < 1361) {
                    pageThree.push(data[pageone.length  + pageTwo.length + 1])
                }
                if(data.length > (pageone.length  + pageTwo.length + 2) && data[pageone.length  + pageTwo.length].itemname.length + data[pageone.length  + pageTwo.length + 1].itemname.length + data[pageone.length  + pageTwo.length + 2].itemname.length < 1361) {
                    pageThree.push(data[pageone.length  + pageTwo.length + 2])
                }
                if(data.length > (pageone.length  + pageTwo.length + 3) && data[pageone.length  + pageTwo.length].itemname.length + data[pageone.length  + pageTwo.length + 1].itemname.length + data[pageone.length  + pageTwo.length + 2].itemname.length + data[pageone.length  + pageTwo.length + 3].itemname.length < 1361) {
                    pageThree.push(data[pageone.length  + pageTwo.length + 3])
                }
            }
            else {
              pagetwoislast = true;
            }
        }
    }
    else if((i == 0 && data.length == 1)) {
        pageone.push(data[i]);
        pageoneislast = true;
    }
});
let pageoneTable;
let pagetwoTable;
let pagethreeTable;

if (pageone.length > 0) {
  pageoneTable = pageone.map((pageonedata, index) => {
    if(index == 0) {
      return (
      <Table
        border={2}
        style={[styles.section, styles.padding, styles.tableheaderbgcolor, { fontSize: 8 }]}
        data={pageone}
      >
        <TableHeader
          textAlign={'center'}
          style={{ backgroundColor: 'tomato' }}
        >
          <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
            <Text style={[styles.headerfont]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={[styles.headerfont]}>PARTICULAR</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={[styles.headerfont]}>Unit</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={[styles.headerfont]}>Quantity</Text>
          </TableCell>
          <TableCell weighting={0.4}>
            <Text style={[styles.headerfont]}>REMARKS</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => <Text style={[styles.headerfont]}>{r.slno}</Text>}
          />
          <DataTableCell styles={{ height: 60 }}
            style={styles.padding}
            weighting={0.6}
            getContent={r => <Text style={[styles.headerfont, { textAlign: 'left' }]}>{r.itemname}</Text>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Text style={[styles.headerfont]}>{r.unit}</Text>} />
          <DataTableCell
            style={styles.padding}
            weighting={0.2}
            getContent={r => <Text style={[styles.headerfont]}>{r.quantity}</Text>} />
          <DataTableCell
            style={styles.padding}
            weighting={0.4}
            getContent={r => <Text style={[styles.headerfont]}>{r.remarks}</Text>} />
        </TableBody>
      </Table>
      )
    }
  });
}

if(pageTwo.length > 0) {
  pagetwoTable = pageTwo.map((pagetwodata, index) => {
    if(index == 0) {
      return (
      <Page size='A4' style={styles.pagepadding} wrap>
        <View style={{ marginTop: 8 }}>
          <View style={styles.dc_header}>
            <Image style={styles.logo} src={logo} />
            <View stye={styles.header_sub_nameB}>

              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 10 }}>
                <Small><Bold>CIN:</Bold> U28110KA2012PTC062181</Small><Medium>GOODS DELIVERY CHALLAN</Medium>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 4, marginBottom: 4, paddingRight: 10 }}>
                <Small>Original/Duplicate/Office Copy</Small>
              </View>
              <Header>Pondhan Scaffolding Pvt. Ltd.</Header>
              {/* <Text>{'\n'}</Text> */}
              <Text style={[styles.header_tintext, { fontWeight: 'bold' }]}>
                {' '}
                MANF: Scaffolding Props, Cup-Lock, Jack, Span, Centering Sheet,
                'H' Frame & Swivel Coupler
            </Text>
              <Text>{'\n'}</Text>
            </View>
          </View>




          {/* <Text style={styles.logo}></Text>
          <Text style={styles.logo}></Text>
          <Text style={styles.headerwidthAddress}>
            Email: info@pondhan.com, Website: www.pondhan.com{'\n'}
            TIN No: 29710660725{'\n'}
            GSTIN:29AAGCP4018E1ZJ RCC No: U28110KA2012PTC062181{'\n'}
          </Text> */}
        <View style={styles.dc_gst}>
          <Text style={styles.header_tintext}><Bold>TIN:</Bold> 29710660725</Text>
          <Text style={styles.header_tintext}><Bold>GSTIN:</Bold> 29AAGCP4018E1ZJ</Text>
          <View>
            <Text style={styles.header_tintext}><Bold>HSN Code:</Bold> 7308</Text>
            <Text style={[styles.header_tintext, { marginTop: 4 }]}><Bold>SAC Code:</Bold> 996793</Text>
          </View>
        </View>

        <Text style={styles.header_sub_name}>
          {'\n'}
          Office & Works : Plot no.40 & 41 (corner) Road,no.5, KIADB Obadenahalli Industrial
          Area,3rd phase Doddaballapura, Bangalore Rural District-561203.
        </Text>
        <Text style={[styles.header_sub_name_phone, { fontWeight: 'bold', color: 'black' }]}>
          <Bold>GST No. 29AAGCP4018E1ZJ, Mob: +91 9900069150</Bold>
        </Text> 
      <Table
        border={2}
        style={[styles.section, styles.padding, styles.tableheaderbgcolor, { fontSize: 8 }]}
        data={pageTwo}
      >
        <TableHeader
          textAlign={'center'}
          style={{ backgroundColor: 'tomato' }}
        >
          <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
            <Text style={[styles.headerfont]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={[styles.headerfont]}>PARTICULAR</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={[styles.headerfont]}>Unit</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={[styles.headerfont]}>Quantity</Text>
          </TableCell>
          <TableCell weighting={0.4}>
            <Text style={[styles.headerfont]}>REMARKS</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => <Text style={[styles.headerfont]}>{r.slno}</Text>}
          />
          <DataTableCell styles={{ height: 60 }}
            style={styles.padding}
            weighting={0.6}
            getContent={r => <Text style={[styles.headerfont, { textAlign: 'left' }]}>{r.itemname}</Text>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Text style={[styles.headerfont]}>{r.unit}</Text>} />
          <DataTableCell
            style={styles.padding}
            weighting={0.2}
            getContent={r => <Text style={[styles.headerfont]}>{r.quantity}</Text>} />
          <DataTableCell
            style={styles.padding}
            weighting={0.4}
            getContent={r => <Text style={[styles.headerfont]}>{r.remarks}</Text>} />
        </TableBody>
      </Table>        
       <Text> {'\n'}</Text>
       {/* Three divs for remarks, tax details and amount */}
       
       {/* Two */}
       {/* <Table style={styles.section}>
         <TableHeader>
           <TableCell weighting={0.5}>
             <Text style={styles.headerfont3}>
               {'\n'}
               {'\n'}

             </Text>
           </TableCell>
           <TableCell weighting={0.5}>
   
           </TableCell>
         </TableHeader>
       </Table> */}
       {/* Three */}
       {/* <Table style={styles.section}>
         <TableHeader>
           <TableCell weighting={0.5}>
             <Text style={styles.headerfont3}>
             {amountinwords}
             </Text>
           </TableCell>

         </TableHeader>
       </Table> */}
       {/* ends here */}
       <Text> {'\n'}</Text>
                 {/* <Text>{'\n'}</Text> */}
          {/* ends here */}
          <View style={styles.header_sub_name3}>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              Received the above goods in good order & condition {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              Receiver's Signature with Seal
            </Text>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              For PONDHAN SCAFFOLDING PVT LTD {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              Authorised Signatories
            </Text>
          </View>

          <View>
            <Text
                 style={[styles.headerfont, { color: 'red' }]}
                >
                 {'\n'} {'\n'} 
                 Note: Any legal matter should be under Bengaluru Jurisdiction </Text>
        </View>
        </View>
      </Page>
      );
    }
  })
}


if(pageThree.length > 0) {
  pagethreeTable = pageThree.map((pagethreedata, index) => {
    if(index == 0) {
      return (
      <Page style={styles.pagepadding} wrap>
        <View style={{ marginTop: 8 }}>
          <View style={styles.dc_header}>
            <Image style={styles.logo} src={logo} />
            <View stye={styles.header_sub_nameB}>

              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 10 }}>
                <Small><Bold>CIN:</Bold> U28110KA2012PTC062181</Small><Medium>GOODS DELIVERY CHALLAN</Medium>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 4, marginBottom: 4, paddingRight: 10 }}>
                <Small>Original/Duplicate/Office Copy</Small>
              </View>
              <Header>Pondhan Scaffolding Pvt. Ltd.</Header>
              {/* <Text>{'\n'}</Text> */}
              <Text style={[styles.header_tintext, { fontWeight: 'bold' }]}>
                {' '}
                MANF: Scaffolding Props, Cup-Lock, Jack, Span, Centering Sheet,
                'H' Frame & Swivel Coupler
            </Text>
              <Text>{'\n'}</Text>
            </View>
          </View>




          {/* <Text style={styles.logo}></Text>
          <Text style={styles.logo}></Text>
          <Text style={styles.headerwidthAddress}>
            Email: info@pondhan.com, Website: www.pondhan.com{'\n'}
            TIN No: 29710660725{'\n'}
            GSTIN:29AAGCP4018E1ZJ RCC No: U28110KA2012PTC062181{'\n'}
          </Text> */}
        <View style={styles.dc_gst}>
          <Text style={styles.header_tintext}><Bold>TIN:</Bold> 29710660725</Text>
          <Text style={styles.header_tintext}><Bold>GSTIN:</Bold> 29AAGCP4018E1ZJ</Text>
          <View>
            <Text style={styles.header_tintext}><Bold>HSN Code:</Bold> 7308</Text>
            <Text style={[styles.header_tintext, { marginTop: 4 }]}><Bold>SAC Code:</Bold> 996793</Text>
          </View>
        </View>

        <Text style={styles.header_sub_name}>
          {'\n'}
          Office & Works : Plot no.40 & 41 (corner) Road,no.5, KIADB Obadenahalli Industrial
          Area,3rd phase Doddaballapura, Bangalore Rural District-561203.
        </Text>
        <Text style={[styles.header_sub_name_phone, { fontWeight: 'bold', color: 'black' }]}>
          <Bold>GST No. 29AAGCP4018E1ZJ, Mob: +91 9900069150</Bold>
        </Text>
      <Table
        border={2}
        style={[styles.section, styles.padding, styles.tableheaderbgcolor, { fontSize: 8 }]}
        data={pageThree}
      >
        <TableHeader
          textAlign={'center'}
          style={{ backgroundColor: 'tomato' }}
        >
          <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
            <Text style={[styles.headerfont]}>SL No</Text>
          </TableCell>
          <TableCell weighting={0.6}>
            <Text style={[styles.headerfont]}>PARTICULAR</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={[styles.headerfont]}>Unit</Text>
          </TableCell>
          <TableCell weighting={0.2}>
            <Text style={[styles.headerfont]}>Quantity</Text>
          </TableCell>
          <TableCell weighting={0.4}>
            <Text style={[styles.headerfont]}>REMARKS</Text>
          </TableCell>
        </TableHeader>
        <TableBody textAlign={'center'}>
          <DataTableCell
            weighting={0.08}
            style={styles.padding}
            getContent={r => <Text style={[styles.headerfont]}>{r.slno}</Text>}
          />
          <DataTableCell styles={{ height: 60 }}
            style={styles.padding}
            weighting={0.6}
            getContent={r => <Text style={[styles.headerfont, { textAlign: 'left' }]}>{r.itemname}</Text>}
          />
          <DataTableCell
            weighting={0.2}
            style={styles.padding}
            getContent={r => <Text style={[styles.headerfont]}>{r.unit}</Text>} />
          <DataTableCell
            style={styles.padding}
            weighting={0.2}
            getContent={r => <Text style={[styles.headerfont]}>{r.quantity}</Text>} />
          <DataTableCell
            style={styles.padding}
            weighting={0.4}
            getContent={r => <Text style={[styles.headerfont]}>{r.remarks}</Text>} />
        </TableBody>
      </Table>        
       <Text> {'\n'}</Text>
       
       {/* Two */}
       {/* <Table style={styles.section}>
         <TableHeader>
           <TableCell weighting={0.5}>
             <Text style={styles.headerfont3}>
               {'\n'}
               {'\n'}

             </Text>
           </TableCell>
           <TableCell weighting={0.5}>
   
           </TableCell>
         </TableHeader>
       </Table> */}
       {/* Three */}
       {/* <Table style={styles.section}>
         <TableHeader>
           <TableCell weighting={0.5}>
             <Text style={styles.headerfont3}>
             {amountinwords}
             </Text>
           </TableCell>

         </TableHeader>
       </Table> */}
       {/* ends here */}
       <Text> {'\n'}</Text>
                 {/* <Text>{'\n'}</Text> */}
          {/* ends here */}
          <View style={styles.header_sub_name3}>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              Received the above goods in good order & condition {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              Receiver's Signature with Seal
            </Text>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              For PONDHAN SCAFFOLDING PVT LTD {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              Authorised Signatories
            </Text>
          </View>

          <View>
            <Text
                 style={[styles.headerfont, { color: 'red' }]}
                >
                 {'\n'} {'\n'} 
                 Note: Any legal matter should be under Bengaluru Jurisdiction </Text>
        </View>
        </View>
      </Page> 
      );
    }
  })
}



  // const grandtotal = taxpercentage * totalrs;
  //   const selectecontactlist = challanlistvalues.selectedcontactlist[0]
  //     ? challanlistvalues.selectedcontactlist[0].suppliercontactname
  //     : "";
  const cond_data = data_cond;
  const tableComponentTwo = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i] && data[i + 1]) {
      tableComponentTwo.push([data[i], data[i + 1]]);
    } else {
      tableComponentTwo.push([data[i]]);
    }
    ++i;
  }
  const datalength = tableComponentTwo.length - 1;
  const tableComponent = tableComponentTwo.map((data, i) => {
    if (i >= 1 && i === datalength) {
      return (
        <Page size='A4' style={styles.pagepadding} wrap key={i}>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => {
                  return r.weighteach;
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
          <Text>{'\n'}</Text>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Taxable Amount</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{taxableAmount}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>GST %</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{tax}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Grand Total Rs</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>
                  {(grandtotal + +totalrs).toFixed(2)}
                </Text>
              </TableCell>
            </TableHeader>
          </Table>
        </Page>
      );
    } else if (i >= 1) {
      return (
        <Page size='A4' style={styles.pagepadding} wrap key={i}>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.weighteach} />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
        </Page>
      );
    } else if (i < 1 && i === datalength) {
      return (
        <View>
          <View style={styles.row}>
            <View style={styles.view_border_left}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.dcaddress, { fontWeight: 'bold' }]}>D.C. </Text>
                <Text style={[styles.dcaddress, { color: 'red', paddingLeft: 16 }]}>{deliverychallanno}</Text>
              </View>
              <Text style={[styles.dcaddress, { marginTop: 10 }]}>To,</Text>
              <Text style={[styles.dcaddress, { paddingLeft: 16 }]}>{customername}</Text>
              <Text style={[styles.dcaddress, { paddingLeft: 16 }]}>{toAD}</Text>
            </View>
            <View style={styles.view_border}>
              <Text style={styles.dcaddress}>Site</Text>
              <Text style={styles.dcaddress}>{site}</Text>
            </View>
            <View style={styles.view_border_right}>
              <View style={styles.header_sub_name3}>
                <Text></Text>
                <Text style={styles.dcaddress_left}>
                  Date: {date}
                  {'\n'}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>PO No:</Bold></Text>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>Date:</Bold></Text>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>vehiclenumber:</Bold></Text>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>GST No:</Bold></Text>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>Other Ref:</Bold></Text>
                </View>
                <View style={{ paddingLeft: 16 }}>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{ponumber}</Text>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{podate}</Text>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{vehiclenumber}</Text>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{gstno}</Text>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{otherreference}</Text>
                </View>
              </View>
            </View>
          </View>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
            key={i}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Unit</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Quantity</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont}>Remarks</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => <Text style={[styles.headerfont]}>{r.slno}</Text>}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => <Text style={[styles.headerfont]}>{r.itemname}</Text>}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Text style={[styles.headerfont]}>{r.unit}</Text>} />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Text style={[styles.headerfont]}>{r.quantity}</Text>} />
              <DataTableCell
                weighting={0.4}
                getContent={r => <Text style={[styles.headerfont]}>{r.remarks}</Text>} />
            </TableBody>
          </Table>
          {/* ends here */}
          <View style={styles.header_sub_name3}>
            <Text style={styles.header_tintext}>
              Received the above goods in good order & condition {'\n'}
              {'\n'}
              {'\n'}
              Receiver's Signature with Seal
            </Text>
            <Text style={styles.header_tintext}>
              For PONDHAN SCAFFOLDING PVT LTD {'\n'}
              {'\n'}
              {'\n'}
              Authorised Signatories
            </Text>
          </View>
        </View>
      );
    } else {
      return (
        <View>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
            key={i}
          >
            <TableHeader textAlign={'center'} style={styles.tableheaderbgcolor}>
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Text style={[styles.headerfont]}>{r.weighteach}</Text>} />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Text style={[styles.headerfont]}>{r.costperunit}</Text>} />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Text style={[styles.headerfont]}>{r.itemdiscount.toString()}</Text>}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Text style={[styles.headerfont]}>{r.itemdiscount.toString()}</Text>}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Text style={[styles.headerfont]}>{r.quantity}</Text>} />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Text style={[styles.headerfont]}>{r.totalcost}</Text>} />
            </TableBody>
          </Table>
        </View>
      );
    }
  });

  return (
    <Document>
      <Page size='A4' style={styles.pagepadding} wrap>
        <View>
          <View style={styles.dc_header}>
            <Image style={styles.logo} src={logo} />
            <View stye={styles.header_sub_nameB}>

              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 10 }}>
                <Small><Bold>CIN:</Bold> U28110KA2012PTC062181</Small><Medium>GOODS DELIVERY CHALLAN</Medium>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 4, marginBottom: 4, paddingRight: 10 }}>
                <Small>Original/Duplicate/Office Copy</Small>
              </View>
              <Header>Pondhan Scaffolding Pvt. Ltd.</Header>
              {/* <Text>{'\n'}</Text> */}
              <Text style={[styles.header_tintext, { fontWeight: 'bold' }]}>
                {' '}
                MANF: Scaffolding Props, Cup-Lock, Jack, Span, Centering Sheet,
                'H' Frame & Swivel Coupler
            </Text>
              <Text>{'\n'}</Text>
            </View>
          </View>




          {/* <Text style={styles.logo}></Text>
          <Text style={styles.logo}></Text>
          <Text style={styles.headerwidthAddress}>
            Email: info@pondhan.com, Website: www.pondhan.com{'\n'}
            TIN No: 29710660725{'\n'}
            GSTIN:29AAGCP4018E1ZJ RCC No: U28110KA2012PTC062181{'\n'}
          </Text> */}
        </View>
        <View style={styles.dc_gst}>
          <Text style={styles.header_tintext}><Bold>TIN:</Bold> 29710660725</Text>
          <Text style={styles.header_tintext}><Bold>GSTIN:</Bold> 29AAGCP4018E1ZJ</Text>
          <View>
            <Text style={styles.header_tintext}><Bold>HSN Code:</Bold> 7308</Text>
            <Text style={[styles.header_tintext, { marginTop: 4 }]}><Bold>SAC Code:</Bold> 996793</Text>
          </View>
        </View>

        <Text style={styles.header_sub_name}>
          {'\n'}
          Office & Works : Plot no.40 & 41 (corner) Road,no.5, KIADB Obadenahalli Industrial
          Area,3rd phase Doddaballapura, Bangalore Rural District-561203.
        </Text>
        <Text style={[styles.header_sub_name_phone, { fontWeight: 'bold', color: 'black' }]}>
          <Bold>GST No. 29AAGCP4018E1ZJ, Mob: +91 9900069150</Bold>
        </Text>

    

        {/* <Text>{'\n'}</Text> */}
        {/* {tableComponent} */}
        <View style={{ marginTop: 8 }}>
          <View style={styles.row}>
            <View style={styles.view_border_left}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.dcaddress, { fontWeight: 'bold' }]}>D.C. </Text>
                <Text style={[styles.dcaddress, {
                  color: 'red',
                  paddingLeft: 16,
                }]}>{deliverychallanno}/{financialYear}</Text>
              </View>
              <Text style={[styles.dcaddress, { marginTop: 10 }]}>To,</Text>
              <Text style={[styles.dcaddress, {
                paddingLeft: 16, width: 180,
                wordWrap: 'break-word'
              }]}>{customername}</Text>
              <Text style={[styles.dcaddress, {
                paddingLeft: 16, width: 150,
                wordWrap: 'break-word'
              }]}>{toAD}</Text>
              <Text style={[styles.dcaddress, {
                paddingLeft: 16, width: 180,
                wordWrap: 'break-word'
              }]}> Phone no : {mobileno} </Text>
            </View>
            <View style={styles.view_border}>
              <Text style={[styles.dcaddress, { marginTop: 10 }]}>Site</Text>
              {/* <Text style={styles.dcaddress}>{site}</Text> */}
              {/* <Text style={[styles.dcaddress, {
                paddingLeft: 16, width: 180,
                wordWrap: 'break-word'
              }]}>{customername1}</Text> */}

              <Text style={[styles.dcaddress, {
                paddingLeft: 16, width: 150,
                wordWrap: 'break-word'
              }]}>{site}</Text>

            </View>
            <View style={styles.view_border_right}>
              <View style={styles.header_sub_name3}>
                <Text></Text>
                <Text style={styles.dcaddress_left}>
                  Date: {date}
                  {'\n'}
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>PO No:</Bold></Text>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>Date:</Bold></Text>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>vehiclenumber:</Bold></Text>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>GST No:</Bold></Text>
                  <Text style={[styles.dcaddress, { fontWeight: 'bold', marginTop: 4 }]}><Bold>Other Ref:</Bold></Text>
                </View>
                <View style={{ paddingLeft: 16 }}>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{ponumber}</Text>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{podate}</Text>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{vehiclenumber}</Text>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{gstno}</Text>
                  <Text style={[styles.dcaddress, { marginTop: 4 }]}>{otherreference}</Text>
                </View>
              </View>
            </View>
          </View>
          <Text>{'\n'}</Text>

          {pageoneTable}
                    {/* <Text>{'\n'}</Text> */}
          {/* ends here */}
          <View style={styles.header_sub_name3}>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              Received the above goods in good order & condition {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              Receiver's Signature with Seal
            </Text>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              For PONDHAN SCAFFOLDING PVT LTD {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              Authorised Signatories
            </Text>
          </View>

          <View>
            <Text
                 style={[styles.headerfont, { color: 'red' }]}
                >
                 {'\n'} {'\n'} 
                 Note: Any legal matter should be under Bengaluru Jurisdiction </Text>
        </View>
        </View>
      </Page>
          {pagetwoTable}
          {pagethreeTable}
    </Document>
  );
};

export default MyDocument;
