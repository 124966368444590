import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

class CustomerPage extends React.Component {
  render() {
    return (
      <div className="container mg-t-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/inventory_page">Setup</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>customer</BreadcrumbItem>
        </Breadcrumb>
        <div class="admin-dashboard-icons">
          <ul className="clearfix">
            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/AddCustomer">
                  <i class="fas fa-plus-circle fa-3x"></i>
                  <div style={{ "padding-top": "5px" }}>
                    <span>Add Customer</span>
                  </div>
                </Link>
              </div>
            </li>
    
            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/customerinfo">
                  <i class="fas fa-list fa-3x"></i>
                  <div style={{ "padding-top": "5px" }}>
                    <span>Customer List</span>
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default CustomerPage;
