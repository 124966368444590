import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { connect } from 'react-redux';
import { getorder } from '../../../../actions/order';
import { updateaddorder } from '../../../../actions/order';
import { Button, Alert } from 'reactstrap';
import DataTable from '../../../../Components/Table/DatatablePage';
import CustomLoader from '../../../../Components/loader/loader';

class ViewOrder extends React.Component {
  state = {
    count: 0,
    loading: true,
    orderlistvalues: []
  };
  componentDidMount() {
    this.props.getorder();
    console.log(this.props);
    if (this.props.orderlistvalues) {
      let orderlist = Object.values(this.props.orderlistvalues);
      orderlist.sort(function(a, b){
        return parseInt(b.workorderno) - parseInt(a.workorderno);
      });
      const orderlistvalues = orderlist.map(quotationvalue => {
        if(!quotationvalue.completed || quotationvalue.completed != "yes") {
          const DateNow = new Date();
          const deliveryDate = new Date(quotationvalue.deliveryschedule);
          return {
            workorderno: quotationvalue.workorderno,
            companyname: quotationvalue.companydetails,
            contactperson: quotationvalue.contactperson,
            officeno: quotationvalue.officeno,
            view: (
              <div className="actionbuttons">
                <Button
                  Label
                  color='primary'
                  onClick={() => {
                    this.updateButton(quotationvalue._id);
                  }}
                >
                  Print
                </Button>
                <Button
                  Label
                  color='primary'
                  onClick={() => {
                    this.updateworkorder(quotationvalue._id);
                  }}
                >
                  Edit
                </Button>
                </div>
            ),
            complete: deliveryDate.getTime() < DateNow.getTime() ? (
              <Button
              Label
              color='primary'
              onClick={() => {
                this.updateworkorder(quotationvalue._id);
              }}
            >
              Complete
            </Button>
            ) : null
          };
        }
      });  
      this.setState({ ...this.state, orderlistvalues });
      this.dataHandler(false);
    }
  }
  componentWillReceiveProps(props) {
    let orderlist = Object.values(props.orderlistvalues);
    //orderlist= orderlist.sort((a, b) => b.workorderno.toLowerCase().localeCompare(a.workorderno.toLowerCase()));
    orderlist.sort(function(a, b){
      return parseInt(b.workorderno) - parseInt(a.workorderno);
    });
    console.log(props);
    const orderlistvalues = orderlist.map(quotationvalue => {
      if(!quotationvalue.completed || quotationvalue.completed != "yes") {
      const DateNow = new Date();
      const deliveryDate = new Date(quotationvalue.deliveryschedule);
      return {
        workorderno: quotationvalue.workorderno,
        companyname: quotationvalue.companydetails,
        contactperson: quotationvalue.contactperson,
        officeno: quotationvalue.officeno,
        view: (
          <div className="actionbuttons">
            <Button
              Label
              color='primary'
              onClick={() => {
                this.updateButton(quotationvalue._id);
              }}
            >
              Print
            </Button>
            <Button
              Label
              color='primary'
              onClick={() => {
                this.updateworkorder(quotationvalue._id);
              }}
            >
              Edit
            </Button>
            </div>
        ),
        complete: deliveryDate.getTime() < DateNow.getTime() ? (
          <Button
          Label
          color='primary'
          onClick={() => {
            this.updateCompleteOrder(quotationvalue._id);
          }}
        >
          Complete
        </Button>
        ) : null        
      };
    }
    });
    this.setState({ ...this.state, orderlistvalues });
    this.dataHandler(false);
  }

  updateButton = id => {
    // let path = `/view_invoicepdf/${id}`;
    // window.open(path, '_blank');
    let path = `/view_orderpdf/${id}`;
    //window.open(path, "_blank");
    this.props.history.push(path);
  };


  updateCompleteOrder = id => {
    let data = this.props.orderlistvalues[id];
    console.log("order is", data);
    data['_id'] = id;
    data['AddedIteminfo'] = data.addeditemlist;
    data['completed'] = "yes";
    return this.props
    .updateaddorder(data)
    .then(data => {
      this.dataHandler(false);
      this.props.getorder();
      window.location.reload(true);
    })
    .catch(err => {
      this.dataHandler(false);
      throw err;
    });
  };


  updateworkorder= id => {
    // let path = `/view_invoicepdf/${id}`;
    // window.open(path, '_blank');
    let path = `/editworkorder/${id}`;
    //window.open(path, "_blank");
    this.props.history.push(path);
  };


  dataHandler = value => {
    this.setState({ loading: value });
  };

  render() {
    const { loading, count, orderlistvalues } = this.state;
    console.log(orderlistvalues);
    const columnvalue = [
      {
        label: 'Order ID',
        field: 'workorderno',
        sort: 'asc',
        width: 20
      },
      {
        label: 'Company Name',
        field: 'companyname',
        sort: 'asc',
        width: 30
      },
      {
        label: 'Contact Person',
        field: 'contactperson',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Office Number',
        field: 'officeno',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Print/Edit',
        field: 'view',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Complete',
        field: 'complete',
        sort: 'asc',
        width: 20
      }
    ];
    return (
      <div className='container mg-t-20'>
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/transaction_page'>Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/work-order'>Work order</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>List</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <DataTable 
          scrollx="true"
          rowvalues={orderlistvalues} 
          columnvalue={columnvalue}
          className="orderlist" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orderlistvalues: state.orderlistvalues
  };
}

export default connect(
  mapStateToProps,
  { getorder, updateaddorder }
)(ViewOrder);
