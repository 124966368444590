import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import UpdateForm from "./ProductForm/UpdateForm";
import { updateproduct, getproducts } from "../../actions/Product";
import { getMasterItemList } from "../../actions/Transaction";
import { getUomList } from "../../actions/uom";
import CustomLoader from "../../Components/loader/loader";

class UpdateProduct extends React.Component {
    state = {
        id: "",
        loading: false,
        productvalues: "",
        materiallistvalues: []
        };
  componentDidMount() {
    //this.props.getUomList();
    this.props.getMasterItemList();
    this.props.getproducts();
    const productid = this.props.match.params.id;
    const productvalues = this.props.productlistvalues[productid];
    this.setState({ productvalues, materiallistvalues: this.props.materiallistvalues, id: this.props.match.params.id });

  }
  submit = data => {
    return this.props.updateproduct(data).then(data => {
        this.props.getproducts();
        return data;
      });
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading, id, productvalues, materiallistvalues } = this.state;
    return (
      <div className="container mg-20">
        <Breadcrumb>
          <BreadcrumbItem  Label>
            <Link to="/inventory_page">Setup</Link>
          </BreadcrumbItem>
          <BreadcrumbItem  Label active>Add Subassembly</BreadcrumbItem>
        </Breadcrumb>
        <UpdateForm submit={this.submit} materiallistvalues={materiallistvalues} id={id} productlistvalues={productvalues} />
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        productlistvalues: state.productlistvalues
    };
  }

export default connect(
    mapStateToProps,
  { updateproduct, getproducts, getMasterItemList,getUomList }
)(UpdateProduct);
