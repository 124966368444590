import { normalize } from "normalizr";
import api from "../Services/currency";
import { CURRENCY_LIST } from "../Services/types";
import { currencyListSchema } from "../Schemas/schemas";

function currencyListFetched(currencylist) {
  return {
    type: CURRENCY_LIST,
    currencylist
  };
}

export const getCurrencyList = () => {
  return dispatch => {
    return api.currency.getCurrencyList().then(currencylist => {
      const currencylistvalue = currencylist.currencydata;
      return dispatch(
        currencyListFetched(normalize(currencylistvalue, [currencyListSchema]))
      );
    });
  };
};
