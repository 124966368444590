import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
  getMasterItemList,
  getMasterItemTypes
} from '../../../../actions/Transaction';
import { connect } from 'react-redux';
import { getUomList } from '../../../../actions/uom';
import { getCustomerList } from '../../../../actions/customerContact';
import { addorder } from '../../../../actions/order';
import { getquotation } from '../../../../actions/quotation';
import { updateaddorder } from '../../../../actions/order';
import { getorder } from '../../../../actions/order';
import OrderForm from './OrderForm';
import CustomLoader from '../../../../Components/loader/loader';

class AddOrder extends React.Component {
  state = {
    loading: false,
    ID:this.props.match.params.id
  };
  componentDidMount() {
    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getorder();
    this.props.getquotation();
  }
  
  componentWillReceiveProps() {
    
  }

  submit = data => {
    console.log(data);
    this.dataHandler(true);

    if(this.props.match.params.id != undefined){
      data['_id'] = this.props.match.params.id;
      return this.props
      .updateaddorder(data)
      .then(data => {
        this.dataHandler(false);
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
    }
    else{
      return this.props
      .addorder(data)
      .then(data => {
        this.dataHandler(false);
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
    }

  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading,ID } = this.state;
    return (
      <div className='container mg-t-20'>
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/transaction_page'>Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/work-order'>Work Order</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Work order</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <OrderForm submit={this.submit} ID={ID}/>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    getorder,
    getCustomerList,
    addorder,
    getquotation,
    updateaddorder
  }
)(AddOrder);
