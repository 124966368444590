import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DataTable from "../../Components/Table/DatatablePage";
import axios from "axios";

class ViewProduct extends React.Component {
  state = {
    productlistvalue: "",
    productMaterialslist: [],
    productname: ""
  };
  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/product/getproduct/${this.props.match.params.id}`)
      .then(res => {

        console.log(res)
        if (res.data.productlist.productdata.length > 0) {
          let product = res.data.productlist.productdata[0]
          let obj = {}, arr = []
          const productname = product.productname;

          for (var i = 0; i < product.productMaterial.length; i++) {
            obj = {}
            obj['materialitemtype'] = product.productname;
            obj['materialname'] = product.productMaterial[i].productqty;
            obj['materialuom'] = product.productsuom[i];
            obj['productqty'] = product.productsqtyinmeter[i]
            arr.push(obj)
          }

          this.setState({ 'productname': productname, 'productMaterialslist': arr });
        }

      },
        error => {

          alert("Server error")
        });

    /* const productid = this.props.match.params.id;
     const productlistvalue = this.props.productlistvalues[productid];
     const productname = productlistvalue.productname.masteritemdescription;
     const productMaterialslist = productlistvalue.productMaterial.map(
       productMaterialData => {
         let totalcost =
           parseFloat(productMaterialData.productqty) *
           parseFloat(productMaterialData.product_id.masteritemcostperunit);
         let materialcostperunit =
           productMaterialData.product_id.masteritemcostperunit;
         return {
           materialitemtype:
             productMaterialData.product_id.masteritemtype.masteritemtypename,
           materialname: productMaterialData.product_id.masteritemname,
 
           materialdescription:
             productMaterialData.product_id.masteritemdescription,
 
           materialuom: productMaterialData.product_id.masteritemuom.uomname,
           materialcostperunit:
             materialcostperunit !== "null" ? materialcostperunit : " ",
           materialqty: productMaterialData.productqty,
           totalcost: isNaN(totalcost) ? " " : totalcost
         };
       }
     );
     this.setState({ ...this.state, productMaterialslist, productname });*/
  }
  render() {
    const { productMaterialslist, productname } = this.state;
    let product;
    if (productMaterialslist.length) {
      product = productMaterialslist[0].materialitemtype;
    }

    const tablerows = productMaterialslist.map((itemlist, i) => {
      return (
        <tr key={i}>
          {/* <td>{itemlist.materialitemtype}</td> */}
          <td style={{textAlign:'left'}}>{itemlist.materialname}</td>
          <td style={{textAlign:'left'}}>{itemlist.materialuom}</td>
          <td style={{textAlign:'left'}}>{itemlist.productqty}</td>
          
          {/* <td>{itemlist.materialcostperunit}</td>
          <td>{itemlist.materialqty}</td>
          <td>{itemlist.totalcost}</td> */}
        </tr>
      );
    });
    return (
      <div className="container mg-20">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/viewproductlist">View subassembly</Link>
            </BreadcrumbItem>

          </Breadcrumb>
        </div>
        {/* <h5>Product Name: {productname}</h5> */}
        {/* <DataTable columnvalue={columnvalue} rowvalues={productMaterialslist} /> */}
        <div style={{
              marginBottom: 16}}>
          <span
            style={{
              fontSize: 12,
              fontWeight: 900,
              marginRight: 16
            }}>Product</span>
          <span
            style={{
              fontSize: 12
            }}>{product ? product : ''}</span>
        </div>
        <Table bordered style={{'width':'40%'}}>
          <thead>
            <tr>
              {/* <th>Product Type</th> */}
              <th>Subassembly</th>

              <th>UOM</th>
              <th>Quantity</th>
              {/* (Meter) */}
              {/* <th>Unit Cost</th>
              <th>Item QTY</th>
              <th>Total cost</th> */}
            </tr>
          </thead>
          <tbody>{tablerows}</tbody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productlistvalues: state.productlistvalues,
    materiallistvalues: state.materiallistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(ViewProduct);
