import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/actions';
import logo from '../../Images/logo.png';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      isAuthenticated: this.props.isAuthenticated
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  logout = () => {
    this.props.logout();
    window.location.reload();
    this.props.history.push('/login');
  };
  render() {
    return (
      <div>
        <Navbar  expand='md'>
          <div className='container'>
            <NavbarBrand tag={Link} to='/'>
              <div>
                <img src={logo} alt='scaffolding' style={{ width: '90px' }} />
              </div>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className='ml-auto' navbar>
                {!!(
                  this.props.isAuthenticated && this.props.role == 'admin'
                ) && (
                  <NavItem>
                    <NavLink tag={Link} to='/admin_dashboard'>
                      <i className='fas fa-home fa-2x'></i>
                    </NavLink>
                  </NavItem>
                )}
                {!!(
                  this.props.isAuthenticated && this.props.role == 'management' || this.props.role == 'itadmin' || this.props.second_role == 'management' || this.props.second_role == 'itadmin'
                ) && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Users
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag={Link} to='/adduser'>
                        Add User
                      </DropdownItem>
                      <DropdownItem tag={Link} to='/adminlist'>
                        Registered user details
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {/* {!!(this.props.isAuthenticated && this.props.role == "admin") &&
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Material & product
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/addmaterialtype">
                    Add MaterialTypes 
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/addmaterial">
                    Add Material 
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/addproduct">
                    Add Product 
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/viewproductlist">
                    View Product 
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/productbilling">
                    Product Billing 
                  </DropdownItem>  
                </DropdownMenu>
              </UncontrolledDropdown>
              } */}
                {this.props.isAuthenticated ? (
                  <NavItem>
                    <NavLink tag={Link} onClick={this.logout}>
                      Logout
                    </NavLink>
                  </NavItem>
                ) : (
                  <NavItem>
                    <NavLink tag={Link} to='/login'>
                      Login
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  if (state && state.user) {
    return {
      isAuthenticated: !!state.user.token,
      role: state.user.role,
      second_role: state.user.second_role
    };
  } else {
    return {
      isAuthenticated: false,
      role: 'admin'
    };
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    { logout }
  )(Header)
);
