import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTable from "../../../Components/Table/DatatablePage";
import DatePicker from "react-datepicker";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Cols,
  Label,
  Button,
  Alert,
  Col
} from "reactstrap";
import CustomLoader from "../../../Components/loader/loader";

class ViewCustomerList extends React.Component {
  state = {
    data: {
      searchcustomername: '',
      searchfromdate: null,
      searchtodate: null
    },
    count: 0,
    customerlistvalues: [],
    backupcustomerlistvalues: [],
    fromdatetimestamp: '',
    todatetimestamp: '',
    loading: false
  };

  componentDidMount = () => {
    this.dataHandler(true);
  };

  componentWillReceiveProps(props) {
    const customerlistvalues = Object.values(props.customerlistvalues);
    const count = customerlistvalues.length;
    const customervalues = customerlistvalues.map(customerdata => {
      return {
        // id: customerdata.id,
        // customernumber: customerdata.customernumber,
        customername: customerdata.customername,
        customercontactnumber: customerdata.customercontactnumber != '' ? customerdata.customercontactnumber : '-',
        codate: customerdata.codate != undefined ? customerdata.codate : '-',

        Transaction: (
          <Button
            // style={{ fontSize: '0.8rem' }}
            Label
            color="primary"
            onClick={() => {
              localStorage.setItem('customername', customerdata.customername)
              this.getTransaction(customerdata.id);
            }}
          >
            Transaction
          </Button>
        )
      };
    });
    this.setState({ customerlistvalues: customervalues, backupcustomerlistvalues: customervalues, count });
    this.setState({ customerlistvalues: customervalues, count });
    this.dataHandler(false);
  }

  updateButton = id => {
    let path = `/updatecustomer/${id}`;
    this.props.history.push(path);
  };

  getTransaction = id => {
    this.props.history.push(`/transaction/${id}`);
  }

  dataHandler = value => {
    this.setState({ loading: value });
  };


  onChange = e => {
    console.log(e)
    this.setState({
      //data: { ...this.state.data, [e.target.name]: e.target.value }
      data: {
        searchcustomername: e.target.value,
        searchfromdate: null,
        searchtodate: null
      },
      fromdatetimestamp: '',
      todatetimestamp: ''
    }, () => {
      this.filterCustomer(this.state.data.searchcustomername)
    });

  };

  getDateByMMDDYYYY(date) {
    let searchdate = date != '-' ?
      (new Date(date).getMonth() + 1 < 10 ? "0" + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1)
      + "/" +
      (new Date(date).getDate() < 10 ? "0" + new Date(date).getDate() : new Date(date).getDate()) + "/" + new Date(date).getFullYear() : '-'
    return searchdate
  }

  handleDateChanges(dateName, dateValue) {

    let { data, fromdatetimestamp, todatetimestamp } = this.state;
    if (dateName === 'startDateTime') {
      data.searchfromdate = dateValue;
      fromdatetimestamp = new Date(this.getDateByMMDDYYYY(dateValue)).getTime()
    } else {
      data.searchtodate = dateValue;
      todatetimestamp = new Date(this.getDateByMMDDYYYY(dateValue)).getTime()
    }
    data.searchcustomername = ''
    this.setState({
      data,
      fromdatetimestamp,
      todatetimestamp
    }, () => {
      if (this.state.data.searchfromdate != null && this.state.data.searchtodate != null) {
        this.filterBydates(this.state.fromdatetimestamp, this.state.todatetimestamp)
      }
      else {
        this.initializeCustomers();
      }
    });
  };

  filterCustomer(ev) {
    this.initializeCustomers();
    setTimeout(() => {
      const val = ev
      if (val && val.trim() != '') {
        let filtereddata = this.state.customerlistvalues.filter((item) => {
          return (item.customername.toLowerCase().indexOf(val.toLowerCase()) > -1
          )
        })
        this.setState({
          customerlistvalues: filtereddata
        })
      }

    })
  }

  filterBydates(date1, date2) {
    this.initializeCustomers();
    setTimeout(() => {

      if (this.state.data.searchfromdate != null && this.state.data.searchtodate != null) {
        let filtereddata = this.state.customerlistvalues.filter((customer) => {
          let comparedate =
            (new Date(parseInt(customer.codate)).getMonth() + 1 < 10 ? "0" + (new Date(parseInt(customer.codate)).getMonth() + 1) : new Date(parseInt(customer.codate)).getMonth() + 1)
            + "/" + (new Date(parseInt(customer.codate)).getDate() < 10 ? "0" + new Date(parseInt(customer.codate)).getDate() : new Date(parseInt(customer.codate)).getDate())
            + "/" + new Date(parseInt(customer.codate)).getFullYear()

          return (new Date(this.getDateByMMDDYYYY(comparedate)).getTime() >= date1 && new Date(this.getDateByMMDDYYYY(comparedate)).getTime() <= date2)

        })
        this.setState({
          customerlistvalues: filtereddata
        })
      }

    })
  }

  initializeCustomers() {
    this.setState({
      customerlistvalues: this.state.backupcustomerlistvalues
    })
  }


  render() {
    const { count, customerlistvalues, data, loading } = this.state;

    const customerBlock = customerlistvalues.map(customer => {
      customer['codate2'] = customer.codate != '-' ? (new Date(parseInt(customer.codate)).getDate() < 10 ? "0" + new Date(parseInt(customer.codate)).getDate() : new Date(parseInt(customer.codate)).getDate()) +
        "/" + (new Date(parseInt(customer.codate)).getMonth() + 1 < 10 ? "0" + (new Date(parseInt(customer.codate)).getMonth() + 1) : new Date(parseInt(customer.codate)).getMonth() + 1) + "/" + new Date(parseInt(customer.codate)).getFullYear() : '-'
      return (
        <tr key={customer.customernumber}>

          <td style={{'width' : '15%'}}>{customer.customername}</td>
          {/* <td style={{ textAlign: 'center' }}>{customer.customernumber}</td> */}
          <td style={{ textAlign: 'center','width' : '12%' }}>{customer.customercontactnumber}</td>

          <td style={{ textAlign: 'center','width' : '8%' }}>{customer.Transaction}</td>
        </tr>
      )
    });

    return (
      <div className="mg-t-20">
        {loading ? <CustomLoader /> : null}
        {count > 0 ? (
          <div>
            {/* <DataTable
              columnvalue={columnvalue}
              rowvalues={customerlistvalues}
              className="customerlist"
            /> */}

            {/* <Card>
           
            <CardBody> */}
            <Row>
              <Col sm={3}>
                <Label className='small-font-size' for="searchcustomername">Search Customer Name</Label>


                <input
                  name="searchcustomername"
                  type="text"
                  id="searchcustomername"
                  className="form-control small-font-size"
                  value={data.searchcustomername}

                  onChange={this.onChange}
                />

              </Col>



              {/* <Col sm={3}>
                  <Label for="searchfromdate">Transaction from date</Label>
                

                     <DatePicker
                    selected={data.searchfromdate}
     
                    onChange={date => this.handleDateChanges('startDateTime', date)}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    id="searchfromdate"
                    isClearable={true}
                  />
                 
                </Col>

                 <Col sm={3}>
                  <Label for="searchtodate">Transaction to date</Label>
                 
                  
                  <DatePicker
                    selected={data.searchtodate}
                    onChange={date => this.handleDateChanges('endDateTime', date)}
                    className="form-control"
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                    id="searchtodate"
                  />
                 
                </Col> */}

            </Row>
            {/* </CardBody>
                </Card> */}

            <table className="table table-bordered table-sm table-striped dataTable" style={{ 'marginTop': '10px','width': '40%' }}>
              <thead>
                <tr>

                  <th style={{'width': '15%'}}>Customer Name</th>
                  {/* <th>Customer Number</th> */}
                  <th style={{'width': '12%'}}>Contact</th>
                  <th style={{'width': '8%'}}>Transaction</th>
                </tr>
              </thead>
              <tbody>
                {customerBlock}
              </tbody>
            </table>




          </div>
        ) : (
            <div>
              <Alert color="info" Label>Loading Customer data</Alert>
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerlistvalues: state.customerlistvalues
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ViewCustomerList)
);
