import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./purchaseorder.css";
import axios from "axios";
import Select from "react-select";
import CustomLoader from "../../../../Components/loader/loader";
class PurchaseOrderForm extends React.Component {
  
  state = {
    data: {
      isEditdone:false,
      supplierdetail: {
        id: null,
        suppliernumber: "",
        suppliername: "",
        supplieraddress1: "",
        supplieraddress2: "",
        suppliercontactlistvalue: [],
        city: "",
        pincode: "",
        state: "",
        country: "",
        suppliergstnumber: ""
      },
      selectedItemlist: {
        id: [],
        itemname: [],
        itemdescription: [],
        itemtype: [],
        itemrate: [],
        itemuom: [],
        itemlocation: [],
        costperunit: [],
        quantity: [],
        totalcost: []
      },
      ponumber: "",
      refno: "",
      subject: "",
      requestdeliverydate: "",
      suppliercontactid: "",
      values: [""],
      quotationno: null,
      currency: "5d3437e21c9d4400008624b7",
      totalvalue: null,
      gst: null,
      cgst:'',
      sgst:'',
      igst:'',
      loadingcharge: "",
      weighttons: "",
      transportationcharge: '',
      scheduledays:'',
      schedulepayment:''
    },
    errors: {},
    materialoptionlistvalues: [],
    supplierdetaillistvalue: [],
    supplierdetaillistvalueobject: {},
    itemlistSelectedOption: [],
    masteritemlistvalues: [],
    masteritemlistvaluesobject: {},
    currencylistvalues: [],
    uom: [],
    locationlistvalue: [],
    loading: false,
    success: false,
    successmsg:''
   
  };
  
  componentDidMount(){
    console.log( Object.keys(this.props.purchaseorderlistvalues))
    if(this.props.ID != undefined){
      const materialoptionlistvalues = Object.values(
        this.props.materialoptionlistvalues
      );
      const supplierdetaillistvalue = Object.values(
        this.props.supplierdetaillistvalue
      );
      const supplierdetaillistvalueobject = this.props.supplierdetaillistvalue;
      const masteritemlistvalues = Object.values(this.props.masteritemlistvalues);
      const masteritemlistvaluesobject = this.props.masteritemlistvalues;
      const currencylistvalues = Object.values(this.props.currencylistvalues);
      const uomlistvalues = Object.values(this.props.uomlistvalues);
      const locationlistvalue = Object.values(this.props.locationlistvalue);

      let newArraySet = [];
      masteritemlistvalues.map((option, i) => {
        let name = "";
        if(option.masteritemshortdescription) {
          name = option.masteritemshortdescription;
        }
        else {
          name = option.masteritemdescription;
        }
        let objset = {
          value: option.id, label: name
        }
        newArraySet.push(objset);
      });
  

      this.setState({
        ...this.state,
        supplierdetaillistvalue,
        supplierdetaillistvalueobject,
        masteritemlistvalues,
        itemlistSelectedOption: newArraySet,
        masteritemlistvaluesobject,
        materialoptionlistvalues,
        currencylistvalues,
        locationlistvalue,
        uom: uomlistvalues
      },()=>{
        this.getPODetails(this.props.ID)
      });
    }
    else{
      

      if(Object.keys(this.props.purchaseorderlistvalues).length == 0){

        this.dataHandler(true)
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/purchaseorder//getpurchaseorderrlist`)
        .then(res => {
    
          this.dataHandler(false)
          if(res.data.purchaseorderlist.purchaseorderlistdata.length > 0){
     
            const purchaseorderlistlength =

            res.data.purchaseorderlist.purchaseorderlistdata.length > 0 ? res.data.purchaseorderlist.purchaseorderlistdata.length + 53 : 74;
      
            let ponumber = (1 + purchaseorderlistlength).toString();
       
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                ponumber
              }
            });
          
            
          }
        
        },
        error =>{
          this.dataHandler(false)
         
        });

      }
    }

  }

  getPODetails(id){
    this.dataHandler(true)
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/purchaseorder/getpurchaseorder/${id}`)
    .then(res => {

      this.dataHandler(false)
      if(res.data.purchaseorderlist.purchaseorderlistdata.length > 0){
        let founddata = res.data.purchaseorderlist.purchaseorderlistdata;
        let podetail = {...this.state.data}
 
         podetail.supplierdetail.suppliername = founddata[0].suppliername;
         podetail.supplierdetail.supplierid = founddata[0].supplierid;
        
        const newState = Object.assign({}, this.state);
        newState.data.ponumber = founddata[0].ponumber;
        newState.data.refno = founddata[0].referenceno;
        newState.data.values = founddata[0].addeditemlist;
        newState.data.subject = founddata[0].subject;
        newState.data.requestdeliverydate = new Date(founddata[0].requestdeliverydate);
        newState.data.currency = founddata[0].currencyid;
        newState.data.totalvalue =  founddata[0].totalvalue;
        newState.data.gst = '0';
        newState.data.cgst = founddata[0].cgst;
        newState.data.sgst = founddata[0].sgst;
        newState.data.igst = founddata[0].igst;
        newState.data.scheduledays = founddata[0].scheduledays ? founddata[0].scheduledays : "";
        newState.data.transportationcharge = founddata[0].transportationcharge ? founddata[0].transportationcharge : "";
        newState.data.loadingcharge = founddata[0].loadingcharge != undefined && founddata[0].loadingcharge != null ? founddata[0].loadingcharge : '';
        newState.data.weighttons = founddata[0].weighttons != undefined && founddata[0].weighttons != null ? founddata[0].weighttons : '';
        newState.data.schedulepayment = founddata[0].schedulepayment ? founddata[0].schedulepayment : "";
        
        newState.data.suppliercontactid = founddata[0].suppliercontactid;
        if(founddata[0].addeditemlist.length > 0){
          
          for(var i=0;i<founddata[0].addeditemlist.length;i++){
            newState.data.selectedItemlist.itemdescription.push(founddata[0].addeditemlist[i].itemdescription)
            newState.data.selectedItemlist.itemtype.push(founddata[0].addeditemlist[i].itemtype)
            newState.data.selectedItemlist.itemtype.push(founddata[0].addeditemlist[i].itemtype)
            newState.data.selectedItemlist.itemname.push(founddata[0].addeditemlist[i].itemname)
            newState.data.selectedItemlist.itemuom.push(founddata[0].addeditemlist[i].itemuom)
            newState.data.selectedItemlist.quantity.push(founddata[0].addeditemlist[i].quantity)
            newState.data.selectedItemlist.costperunit.push(founddata[0].addeditemlist[i].costperunit)
            newState.data.selectedItemlist.totalcost.push(founddata[0].addeditemlist[i].totalcost)
            newState.data.selectedItemlist.id.push(founddata[0].addeditemlist[i].id)
            newState.data.selectedItemlist.itemlocation.push(founddata[0].addeditemlist[i].itemlocation)
          }
        }


        this.setState(newState);
      
        console.log(this.state)
      }
      else{
        this.setState({count:0})
      }
    },
    error =>{
      this.dataHandler(false)
      this.setState({ success: false, message:'Server error.' });
    });
  }

  componentWillReceiveProps(props) {
    
    const materialoptionlistvalues = Object.values(
      props.materialoptionlistvalues
    );
    const supplierdetaillistvalue = Object.values(
      props.supplierdetaillistvalue
    );
    const supplierdetaillistvalueobject = props.supplierdetaillistvalue;
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    const masteritemlistvaluesobject = props.masteritemlistvalues;
    const currencylistvalues = Object.values(props.currencylistvalues);
    const uomlistvalues = Object.values(props.uomlistvalues);
    const locationlistvalue = Object.values(props.locationlistvalue);
    const purchaseorderlist = Object.keys(this.props.purchaseorderlistvalues);
    const purchaseorderlistlength =
      purchaseorderlist.length > 0 ? purchaseorderlist.length + 53 : 74;

    let ponumber = (1 + purchaseorderlistlength).toString();

    let newArraySet = [];
    masteritemlistvalues.map((option, i) => {
      let name = "";
      if(option.masteritemshortdescription) {
        name = option.masteritemshortdescription;
      }
      else {
        name = option.masteritemdescription;
      }
      let objset = {
        value: option.id, label: name
      }
      newArraySet.push(objset);
    });
    if(this.props.ID != undefined){
      ponumber = this.state.data.ponumber
    }
    this.setState({
      ...this.state,
      supplierdetaillistvalue,
      supplierdetaillistvalueobject,
      masteritemlistvalues,
      itemlistSelectedOption: newArraySet,
      masteritemlistvaluesobject,
      materialoptionlistvalues,
      currencylistvalues,
      locationlistvalue,
      uom: uomlistvalues,
      data: {
        ...this.state.data,
        ponumber
      }
    });

    if(this.state.data.supplierdetail.supplierid && this.state.data.supplierdetail.supplierid != '' && this.props.ID != undefined && !this.state.isEditdone){
        this.setState({isEditdone:true},()=>{
          this.handleChange2(this.state.data.supplierdetail.supplierid)
        })
    }
    //console.log(props.supplierdetaillistvalue)
    
  }

  dataHandler = value => {
    this.setState({ loading: value });
  };

  handleChangeDeliveryDate = date => {
    console.log(date)
    this.setState({
      data: {
        ...this.state.data,
        requestdeliverydate: date
      }
    });
  };
  handleChange = e => {
    
    const selected_id = e.target.value;
    const supplierdetail = this.state.supplierdetaillistvalueobject[
      selected_id
    ];
    
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        supplierdetail
      }
    });

  };

  handleChange2 = e => {
    const selected_id = e
    const supplierdetail = this.state.supplierdetaillistvalueobject[
      selected_id
    ];
    console.log(supplierdetail)
    
    if(supplierdetail != undefined){
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          supplierdetail
        }
      });
    }
 
    

  };

  totalValue = () => {
    // const { totalcost } = this.state.data.selectedItemlist;
    // if (totalcost.length > 0) {
    //   let totalvalue = totalcost
    //     .map((value, i) => {
    //       if (value) {
    //         return parseFloat(value);
    //       } else {
    //         return 0;
    //       }
    //     })
    //     .reduce((a, b) => {
    //       return a + b;
    //     });
    //   // Check if tax exists and handle tax  
    //   let {gst} = this.state.data;
    //   if(gst !== null) {
    //     gst = parseFloat(gst).toFixed(2);
    //   let per = (gst * totalvalue) / 100;
    //   totalvalue = parseFloat(Number(per)+Number(totalvalue)).toFixed(2);
    //   }
    //   this.setState({
    //     ...this.state.data,
    //     data: {
    //       ...this.state.data,
    //       totalvalue
    //     }
    //   });
    // }
    this.calculatePercentageAmount()
  };



  calculatePercentageAmount = () => {

    const { totalcost } = this.state.data.selectedItemlist;
    if (totalcost.length > 0) {
      let totalvalue = totalcost
        .map((value, i) => {
          if (value) {
            return parseFloat(value);
          } else {
            return 0;
          }
        })
        .reduce((a, b) => {
          return a + b;
        });

            // CGST
    let cgst = this.state.data.cgst;
    cgst = parseFloat(cgst) ? parseFloat(cgst).toFixed(2) : 0;
    // SGST
    let sgst = this.state.data.sgst;
    sgst = parseFloat(sgst) ? parseFloat(sgst).toFixed(2) : 0;
    // CGST
    let igst = this.state.data.igst;
    igst = parseFloat(igst) ? parseFloat(igst).toFixed(2) : 0;

    let subTotal = parseFloat(totalvalue)
      ? Number(parseFloat(totalvalue).toFixed(2))
      : 0;
    // Convert it to percentage
    cgst = (subTotal * cgst) / 100;
    sgst = (subTotal * sgst) / 100;
    igst = (subTotal * igst) / 100;
      console.log(cgst)
    const total = (Number(subTotal) + Number(cgst) + Number(sgst) + Number(igst)).toFixed(2);
    const grandtotal = (Number(total)).toFixed(2);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        ['totalvalue']: grandtotal
      }
    });
    }
  };

  selectitemchange = (e, index) => {
    const selected_id = e.value ? e.value : e;
    if (selected_id) {
      let selected_item = this.state.masteritemlistvaluesobject[selected_id];
      if(selected_item){
        console.log(selected_item)
        const selectedItemlist = { ...this.state.data.selectedItemlist };
        selectedItemlist.id[index] = selected_item.id;
        selectedItemlist.itemname[index] = selected_item.masteritemtypename;
        //  selectedItemlist.itemdescription[index] = selected_item.masteritemshortdescription == '' ? selected_item.masteritemdescription 
        //                                                                                           : selected_item.masteritemshortdescription
        selectedItemlist.itemdescription[index] = selected_item.masteritemdescription;
        selectedItemlist.itemtype[index] = selected_item.masteritemtype._id;
        selectedItemlist.itemrate[index] = selected_item.masteritemrate;
        selectedItemlist.itemuom[index] = selected_item.masteritemuom._id;
        //selectedItemlist.itemlocation[index] = null;
        selectedItemlist.costperunit[index] = selected_item.masteritemcostperunit
          ? selected_item.masteritemcostperunit.toString()
          : "";
        selectedItemlist.quantity[index] = (1).toString();
        selectedItemlist.totalcost[index] = selected_item.masteritemcostperunit
          ? selected_item.masteritemcostperunit.toString()
          : "";

        this.setState({
          ...this.state.data,
          data: {
            ...this.state.data,
            selectedItemlist
          }
        },() =>{
          this.totalValue();
        });

      }
    
      }
  };

  locationChange = (e, index) => {
    const selected_location = e.target.value;
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.itemlocation[index] = selected_location;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  createSelectSupplier = () => {
    let items = [];
    const supplierdetaillistvalue = this.state.supplierdetaillistvalue;
    supplierdetaillistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.suppliername}
        </option>
      );
    });
    return items;
  };

  onhandlerChange = e => {
    let name = e.target.name;
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    }, () => {
      this.totalValue()
    });
  };

  onSubjectHandler = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  filterItems(itemarray,key) {
    // masteritemlistvalues = this.filterItems(masteritemlistvalues,'masteritemtypename')
    return itemarray.sort((a,b)=>{return a[key].toLowerCase().localeCompare(b[key].toLowerCase())})
  }

  createSelectItems = () => {
    let items = [];
    let masteritemlistvalues = this.state.masteritemlistvalues;
    masteritemlistvalues = this.filterItems(masteritemlistvalues,'masteritemtypename')
    console.log(masteritemlistvalues)

    masteritemlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectItemTypes = () => {
    let items = [];
    const materialoptionlistvalues = this.state.materialoptionlistvalues;
    materialoptionlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectSupplierContact = () => {
    let items = [];
    const suppliercontactlistvalues = this.state.data.supplierdetail
      .suppliercontactlistvalue;
    suppliercontactlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option._id}>
          {option.suppliercontactname}
        </option>
      );
    });
    return items;
  };

  createSelectCurrencyItems = () => {
    let items = [];
    const currencylistvalues = this.state.currencylistvalues;
    currencylistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.currencyname}
        </option>
      );
    });
    return items;
  };

  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uom;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };
  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  handleClick = e => {
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ""],
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id: [...this.state.data.selectedItemlist.id, ""],
          itemname: [...this.state.data.selectedItemlist.itemname, ""],
          itemdescription: [
            ...this.state.data.selectedItemlist.itemdescription,
            ""
          ],
          itemtype: [...this.state.data.selectedItemlist.itemtype, ""],
          itemrate: [...this.state.data.selectedItemlist.itemrate, ""],
          itemuom: [...this.state.data.selectedItemlist.itemuom, ""],
          itemlocation: [...this.state.data.selectedItemlist.itemlocation, ""],
          costperunit: [...this.state.data.selectedItemlist.costperunit, ""],
          quantity: [...this.state.data.selectedItemlist.quantity, ""],
          totalcost: [...this.state.data.selectedItemlist.totalcost, ""]
        }
      }
    },()=>{
      console.log(this.state.data.values)
    });
  };
  removeClick = (e, index) => {
    const removeItem = { ...this.state.data };
    const values = removeItem.values;
    const id = removeItem.selectedItemlist.id;
    const itemname = removeItem.selectedItemlist.itemname;
    const itemdescription = removeItem.selectedItemlist.itemdescription;
    const itemtype = removeItem.selectedItemlist.itemtype;
    const itemrate = removeItem.selectedItemlist.itemrate;
    const itemuom = removeItem.selectedItemlist.itemuom;
    const itemlocation = removeItem.selectedItemlist.itemlocation;
    const costperunit = removeItem.selectedItemlist.costperunit;
    const quantity = removeItem.selectedItemlist.quantity;
    const totalcost = removeItem.selectedItemlist.totalcost;
    console.log(values)
    values.splice(index, 1);
    id.splice(index, 1);
    itemname.splice(index, 1);
    itemdescription.splice(index, 1);
    itemtype.splice(index, 1);
    itemrate.splice(index, 1);
    itemuom.splice(index, 1);
    itemlocation.splice(index, 1);
    costperunit.splice(index, 1);
    quantity.splice(index, 1);
    totalcost.splice(index, 1);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id,
          itemname,
          itemdescription,
          itemtype,
          itemrate,
          itemuom,
          itemlocation,
          costperunit,
          quantity,
          totalcost
        }
      }
    },()=>{
      this.totalValue()
    });
  };

  quantityChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const costperunit = selectedItemlist.costperunit[index] || null;
    selectedItemlist.quantity[index] = e.target.value;
    const totalValue = costperunit
      ? e.target.value * costperunit
      : null || undefined || "";
    selectedItemlist.totalcost[index] = totalValue
      ? parseFloat(totalValue).toFixed(2)
      : "";
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  onChangePO = e => {
    let name = e.target.name;
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  unitChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.costperunit[index] = e.target.value;
    const totalcost =
      parseFloat(selectedItemlist.quantity[index]) *
      parseFloat(selectedItemlist.costperunit[index]);
    selectedItemlist.totalcost[index] = totalcost
      ? totalcost.toFixed(2)
      : null || "";
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  handleValidSubmit = () => {
    let AddedIteminfo = [];
    const { supplierdetail, selectedItemlist } = this.state.data;
    const {
      values,
      refno,
      subject,
      requestdeliverydate,
      quotationno,
      currency,
      suppliercontactid,
      totalvalue,
      ponumber,
      loadingcharge,
      weighttons,
      transportationcharge,
      gst,
      cgst,
      sgst,
      igst,
      scheduledays,
      schedulepayment
    } = this.state.data;
    const id = selectedItemlist.id;
    const itemname = selectedItemlist.itemname;
    const itemdescription = selectedItemlist.itemdescription;
    const itemtype = selectedItemlist.itemtype;
    const itemrate = selectedItemlist.itemrate;
    const itemuom = selectedItemlist.itemuom;
    const itemlocation = selectedItemlist.itemlocation;
    const costperunit = selectedItemlist.costperunit;
    const quantity = selectedItemlist.quantity;
    const totalcost = selectedItemlist.totalcost;
    values.forEach(function(value, i) {
      let obj = {};
      obj.id = id[i] || null;
      obj.itemname = itemname[i] || null;
      obj.itemdescription = itemdescription[i] || null;
      obj.itemtype = itemtype[i] || null;
      obj.itemrate = itemrate[i] || null;
      obj.itemuom = itemuom[i] || null;
      obj.itemlocation = itemlocation[i] || null;
      obj.costperunit = costperunit[i] || null;
      obj.quantity = quantity[i] || null;
      obj.totalcost = totalcost[i] || null;
      AddedIteminfo.push(obj);
    });
    const data = {
      supplierdetail,
      AddedIteminfo,
      refno,
      subject,
      requestdeliverydate,
      quotationno,
      currency,
      suppliercontactid,
      ponumber,
      totalvalue,
      loadingcharge,
      weighttons,
      transportationcharge,
      gst,
      cgst,
      sgst,
      igst,
      scheduledays,
      schedulepayment
    };
    this.setState({ loading: true });
    this.props
      .submit(data)
      .then(() => {
        
          if(this.props.ID == undefined){
            this.myFormRef.reset();
            this.setState({ loading: false, errors: {}, success: true,successmsg:'Purchase Order is generated successfully' });
          }
          else{
            this.setState({ loading: false, errors: {}, success: true,successmsg:'Purchase Order edited successfully' });
          }
         
      
        
   
      })
      .catch(err => {
        
          this.setState({ errors: err.response.data.errors, loading: false });
        
        
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };
  
  handleInvalidSubmit = () => {
    console.log("invalid submit");
  };
  render() {
    const { data, errors, loading, success,successmsg } = this.state;
    const customStyles = {
      control: base => ({
        ...base,
        height: 35,
        minHeight: 35
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '35px',
        padding: '0 6px'
      }),
  
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '35px',
      }),
    };
    return (
      <div>
        <div>
        {loading ? <CustomLoader /> : null}
          {!!success ? (
            // <Alert color="info">Purchase Order is generated successfully</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Purchase Order</ModalHeader>
              <ModalBody>{successmsg}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Purchase Order</CardHeader>
            <CardBody>
              <Row>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for="ponumber">PO Number</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="ponumber"
                      type="number"
                      id="ponumber"
  
                      value={data.ponumber || null}
                      onChange={this.onChangePO}
                      
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter PO Number"
                        },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Please enter valid Number"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="refno">Reference No </Label>
                   
                    <AvField
                      name="refno"
                      type="text"
                      id="refno"
                      value={data.refno}
                      onChange={e => this.onhandlerChange(e)}
                    
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for="totalcost">Total Value</Label>
                  <span className="required-label">*</span>
                  <AvField
                    name="totalvalue"
                    type="Number"
                    id="totalcost"
                    value={data.totalvalue || " " || null}
                    onChange={e => this.onhandlerChange(e)}
                    readOnly
                  />
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for="currency">Currency</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name={`currency`}
                      required={true}
                      value={data.currency || null}
                      onChange={e => this.onhandlerChange(e)}
                    >
                      <option value="">select currency</option>
                      {this.createSelectCurrencyItems()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <Label className='small-font-size' for="quotationno">Request Delivery Date</Label>
                  <span className="required-label">*</span>
                  <DatePicker
                  autoComplete="new-password"
                    selected={data.requestdeliverydate}
                    required={true}
                    onChange={this.handleChangeDeliveryDate}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    id="startdate"
                  />
                </Col>
                <Col sm={2}>
                  <AvGroup>
                  <Label className='small-font-size' for="scheduledays">Delivery Schedule</Label>
                  <AvField
                      name="scheduledays"
                      type="text"
                      id="scheduledays"
                      value={data.scheduledays || ""}
                      onChange={e => this.onhandlerChange(e)}
                   
                    />
                    </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                  <Label className='small-font-size' for="schedulepayment">Payment Schedule</Label>
                  <AvField
                      name="schedulepayment"
                      type="text"
                      id="schedulepayment"
                      value={data.schedulepayment || ""}
                      onChange={e => this.onhandlerChange(e)}
                   
                    />
                    </AvGroup>
                </Col>

                
              </Row>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="suppliername">Supplier Name</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name={`suppliername`}
                      value={data.supplierdetail.supplierid}
                      //required={true}
                      onChange={e => this.handleChange(e)}
                    >
                      <option value="">select supplier name</option>
                      {this.createSelectSupplier()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="suppliernumber">Supplier Number</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="suppliernumber"
                      type="number"
                      readOnly
                      id="suppliernumber"
                      value={data.supplierdetail.suppliernumber}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Supplier Number"
                        },
                     
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Please enter valid Number"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="suppliergstnumber">Supplier GST </Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="suppliergstnumber"
                      type="text"
                      readOnly
                      id="suppliergstnumber"
                      value={data.supplierdetail.suppliergstnumber}
                      onChange={e => this.onhandlerChange(e)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Supplier GST Number"
                        },
                        minLength: {
                          value: 15,
                          errorMessage:
                            "Supplier GST Number should contain 15 characters"
                        },
                        maxLength: {
                          value: 15,
                          errorMessage:
                            "Supplier GST Number should contain 15 characters"
                        },
                        pattern: {
                          value: "^[a-zA-z0-9]+$",
                          errorMessage: "Please enter valid GST Number"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="suppliercontactinfo">
                      Supplier Contact Name
                    </Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      
                      name={`suppliercontactid`}
                      value={data.suppliercontactid}
                      required={true}
                      onChange={e => this.onhandlerChange(e)}
                    >
                      <option value="">select contact</option>
                      {this.createSelectSupplierContact()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="supplieraddress">Supplier Address</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="supplieraddress"
                      type="text"
                      id="supplieraddress"
                      readOnly
                      value={
                        data.supplierdetail.supplieraddress1 ||
                        "" + " " + data.supplierdetail.supplieraddress2 ||
                        "" + " " + data.supplierdetail.city ||
                        "" + " " + data.supplierdetail.state ||
                        "" + " " + data.supplierdetail.country ||
                        "" + " " + data.supplierdetail.pincode ||
                        ""
                      }
                      onChange={this.onChange}
                    />
                  </AvGroup>
                </Col>
                  <Col sm={1}>
                  <Label className='small-font-size' for="gst">Tax %</Label>
                  <span className="required-label">*</span>
                  <AvField
                    name="gst"
                    type="Number"
                    id="gst"
                    value={data.gst || null}
                    onChange={e => this.onhandlerChange(e)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter Tax Value in Percentage"
                      },
                      pattern: {
                        value: "^[0-9.]+$",
                        errorMessage: "Please enter valid value"
                      }
                    }}
                  />
                </Col>  
              </Row>
              <Row>
                <Col sm={3}>
                  <AvGroup>
                    <Label className='small-font-size' for="subject">Subject</Label>
                
                    <AvField
                      name="subject"
                      type="text"
                      id="subject"
                      value={data.subject || ""}
                      onChange={this.onSubjectHandler}
                 
                    />
                  </AvGroup>
                </Col>
                
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for="cgst">CGST(%)</Label>
                    
                    <AvField
                      name="cgst"
                      type="text"
                      id="cgst"
                      value={data.cgst || ""}
                      onChange={e => this.onhandlerChange(e)}
                   
                    />
                  </AvGroup>
                </Col>

                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for="sgst">SGST(%)</Label>
                    
                    <AvField
                      name="sgst"
                      type="text"
                      id="sgst"
                      value={data.sgst || ""}
                      onChange={e => this.onhandlerChange(e)}
                   
                    />
                  </AvGroup>
                </Col>

                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for="igst">IGST(%)</Label>
                    
                    <AvField
                      name="igst"
                      type="text"
                      id="igst"
                      value={data.igst || ""}
                      onChange={e => this.onhandlerChange(e)}
                   
                    />
                  </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="transportationcharge">Transportation charges</Label>
                    
                    <AvField
                      name="transportationcharge"
                      type="number"
                      id="transportationcharge"
                      value={data.transportationcharge}
                      onChange={e => this.onhandlerChange(e)}
                   
                    />
                  </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="loadingcharge">Loading and Handling charges</Label>
                    
                    <AvField
                      name="loadingcharge"
                      type="number"
                      id="loadingcharge"
                      value={data.loadingcharge}
                      onChange={e => this.onhandlerChange(e)}
                   
                    />
                  </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="weighttons">Total Weight(in tons)</Label>
                    
                    <AvField
                      name="weighttons"
                      type="number"
                      id="weighttons"
                      value={data.weighttons}
                      onChange={e => this.onhandlerChange(e)}
                   
                    />
                  </AvGroup>
                </Col>

              </Row>
              <Row>
                <div className="mg-t-20">
                  <Button color="info" onClick={this.handleClick} type="button">
                    Add Item
                  </Button>
                </div>
              </Row>
              <div className="purchaseorder-item-row">
                {data.values.map((values, index) => {
                  
                  let optionsel = "";
                  let SelVal = {};
                  if(values.id != undefined) {
                    if(values.itemshortdescription) {
                      optionsel = values.itemshortdescription;
                    }
                    else {
                      optionsel = values.itemdescription;
                    }
                    SelVal = {
                      value: values.id._id, label: optionsel
                    }
                  }
                  const itemnameinfo = values.id != undefined ? (
                    // <AvField
                    //   type="select"
                    //   value={values.id._id}
                    //   name={`selectitem${index}`}
                    //   onChange={e => this.selectitemchange(e, index)}
                    //   required={true}
                    // >
                    //   <option value="">select Item</option>
                    //   {this.createSelectItems()}
                    // </AvField>
                    <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index)} styles={customStyles} required value={SelVal} options={this.state.itemlistSelectedOption} />            
                    ) :
                    (
                      // <AvField
                      //   type="select"
                      //   name={`selectitem${index}`}
                      //   value={
                      //     data.selectedItemlist.id[index]
                      //   }
                      //   onChange={e => this.selectitemchange(e, index)}
                      //   required={true}
                      // >
                      //   <option value="">select Item</option>
                      //   {this.createSelectItems()}
                      // </AvField>
                      <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index)} styles={customStyles} required defaultValue={values.id} options={this.state.itemlistSelectedOption} />
                    )
                  
        // const itemnameinfo = values.id != undefined ? (   
        //   <AvField
        //   type="select"
        //   value={values.id}
        //   name={`selectitem${index}`}
        //   onChange={e => this.selectitemchange(e, index)}
        //   required={true}
        // >
        //   <option value="">select Item</option>
        //   {this.createSelectItems()}
        // </AvField>
  
        // ) : 
        //   (
        //        <AvField
        //        type="select"
        //        name={`selectitem${index}`}
        //        value={
        //         data.selectedItemlist.id[index]
        //       }
        //        onChange={e => this.selectitemchange(e, index)}
        //        required={true}
        //      >
        //        <option value="">select Item</option>
        //        {this.createSelectItems()}
        //      </AvField>
             
        //   )



                  return (
                    <div key={index}>
                      <div className="purchaseorder-table">
                      
                        <Row key={index}>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for="selectitem">Item Code</Label>
                              <span className="required-label">*</span>
                              {itemnameinfo}
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemdescription${index}`}>
                                Description
                              </Label>
                              <span className="required-label">*</span>
                              <AvField
                                name={`masteritemdescription${index}`}
                                type="text"
                                readOnly
                                id="masteritemdescription"
                                value={
                                  data.selectedItemlist.itemdescription[index]
                                }
                                onChange={this.onChange}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      "Please enter Item Description"
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemtype${index}`}>Type</Label>
                              <span className="required-label">*</span>
                              <AvField
                                style={{ width: "150px" }}
                                type="select"
                                readOnly
                                name={`masteritemtype${index}`}
                                onChange={this.onChange}
                                required={true}
                                value={
                                  data.selectedItemlist.itemtype[index] 
                                }
                              >
                                <option value="">select</option>
                                {/* <option value="5d10915e7ba4981da4e679f6">rawmaterial</option>
                                                <option value="5d10908a7ba4981da4e679f5">subassembly</option> */}
                                {this.createSelectItemTypes()}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`masteritemuom${index}`}>UOM</Label>
                              <span className="required-label">*</span>
                              <AvField
                                type="select"
                                readOnly
                                name={`masteritemuom${index}`}
                                onChange={this.onChange}
                                required={true}
                                value={
                                  data.selectedItemlist.itemuom[index] 
                                }
                              >
                                <option value="">uom</option>
                                {/* <option value="kg">Kg</option>
                            <option value="liter">Liter</option>
                            <option value="kg">EA</option> */}
                                {this.createSelectUomItems()}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col sm={2}>
                            <AvGroup>
                              <Label className='small-font-size' for={`location${index}`}>
                                Delivery Location
                              </Label>
                              <span className="required-label">*</span>
                              <AvField
                                type="select"
                                name={`location${index}`}
                                onChange={e => this.locationChange(e, index)}
                                
                                 value={data.selectedItemlist.itemlocation[index]}
                              >
                                <option value="">select location</option>
                                {this.createSelectLocationItems()}
                              </AvField>
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`costperunit${index}`}>
                                Unit Cost
                              </Label>
                              <AvField
                                name={`costperunit${index}`}
                                type="number"
                                id={`costperunit${index}`}
                                value={
                                  data.selectedItemlist.costperunit[index] 
                                }
                                onChange={e => this.unitChange(e, index)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter cost'
                                  },
                                  pattern: {
                                    value: "^[0-9.]*$",
                                    errorMessage:
                                      "Please enter valid costperunit"
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`quantity${index}`}>Quantity</Label>
                              <span className="required-label">*</span>
                              <AvField
                                name={`quantity${index}`}
                                type="number"
                                id={`quantity${index}`}
                                value={
                                  data.selectedItemlist.quantity[index] 
                                }
                                onChange={e => this.quantityChange(e, index)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter Item Quantity"
                                  },
                                  pattern: {
                                    value: "^[0-9.]*$",
                                    errorMessage: "Please enter valid quantity"
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <AvGroup>
                              <Label className='small-font-size' for={`totalcost${index}`}>
                                Total Cost
                              </Label>
                              <AvField
                                name={`totalcost${index}`}
                                type="number"
                                id={`totalcost${index}`}
                                value={
                                  data.selectedItemlist.totalcost[index]
                                    ? data.selectedItemlist.totalcost[index]
                                    : " "
                                }
                                onChange={e => this.onChange(e, index)}
                                // validate={{
                                //   minLength: {
                                //     value: 1,
                                //     errorMessage: "Total Cost value is required"
                                //   }
                                // }}
                              />
                            </AvGroup>
                          </Col>
                          <Col sm={1}>
                            <Button
                              type="button"
                              color='danger'
                              onClick={e => this.removeClick(e, index)}
                              style={{ marginTop: "30px" }}
                            >
                              -
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Row>
                <div className="mg-20">
                  <Button
                    color="primary"
                    disabled={loading}
                    className="lgn-loader"
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierdetaillistvalue: state.supplierdetaillistvalue,
    masteritemlistvalues: state.masteritemlistvalues,
    materialoptionlistvalues: state.masteritemtypenamevalue,
    currencylistvalues: state.currencylistvalue,
    uomlistvalues: state.uomlistvalue,
    locationlistvalue: state.locationlistvalue,
    purchaseorderlistvalues: state.purchaseorderlistvalue
  };
}

export default connect(
  mapStateToProps,
  null
)(PurchaseOrderForm);
