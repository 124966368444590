import React from "react";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";

class ProductForm extends React.Component {
  state = this.initialState;

  get initialState() {
    return {
      data: {
        productname: "",
        values: [""],
        products: [""],
        productsqty: [""],
        productsuom: [""],
        productsqtyinmeter: [""],
        productsweight: [""]
      },
      materiallistvalues: [],
      uomlistvalues:[],
      materiallistvaluesObject: {},
      loading: false,
      errors: {},
      success: false
    };
  }
  componentDidMount() {
    const materiallistvaluesObject = this.props.materiallistvalues;
    const materiallistvalues = Object.values(this.props.materiallistvalues);
    this.setState({
      ...this.state,
      materiallistvalues,
      materiallistvaluesObject
    });
    this.getUnitmeasures()
  }

  componentWillReceiveProps(props) {
    const materiallistvaluesObject = this.props.materiallistvalues;
    const materiallistvalues = Object.values(props.materiallistvalues);
    //const uomlistvalues = Object.values(props.uomlistvalues);
    // console.log(uomlistvalues);
    this.setState({
      ...this.state,
      materiallistvalues,
      materiallistvaluesObject
    });
  }



  getUnitmeasures(){
    
    this.setState({ loading: true });
 
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/unitofmeasure/getuom`)
    .then(res => {
      console.log(res.data)
      if(res.data.uomlist.uomdata.length > 0){
        this.setState({
          uomlistvalues:res.data.uomlist.uomdata
        });
      }
      this.setState({ loading: false });
    },
    error =>{
      this.setState({ loading: false });
      this.setState({ success: false, message:'Server error.' });
    });
  }


  createSelectItems3 = optionvalue => {

    let items = [];
    const uomlistvalues = this.state.uomlistvalues
    uomlistvalues.filter((option, i) => {
      items.push(
        <option key={i} value={option.uomname}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };

  createSelectItems = optionvalue => {

    let items = [];
    // const materiallistvalues = [
    //   {
    //     'id': '1',
    //     'itemname': 'Props'
    //   },
    //   {
    //     'id': '2',
    //     'itemname': 'MS Span'
    //   },
    //   {
    //     'id': '3',
    //     'itemname': 'MS Centering sheet'
    //   },
    //   {
    //     'id': '4',
    //     'itemname': 'Cuplock Vertical'
    //   },
    //   {
    //     'id': '5',
    //     'itemname': 'Cuoplock horizontal'
    //   },
    //   {
    //     'id': '6',
    //     'itemname': 'H Frame Scaffolding'
    //   },
    //   {
    //     'id': '7',
    //     'itemname': 'U Jack/ Base Jack'
    //   },
    //   {
    //     'id': '8',
    //     'itemname': 'Spigot pins'
    //   },
    //   {
    //     'id': '9',
    //     'itemname': 'Pressed swivel cuplers'
    //   },
    //   {
    //     'id': '10',
    //     'itemname': 'pressed right cuplers'
    //   },
    //   {
    //     'id': '11',
    //     'itemname': 'Pressed swivel cuplers'
    //   },
    //   {
    //     'id': '12',
    //     'itemname': 'Shttering clamps'
    //   }
    // ]
    const materiallistvalues = this.state.materiallistvalues;
    console.log("__MATERIAL_LIST_VALUES___");
    console.log(materiallistvalues);
    materiallistvalues.filter((option, i) => {
      if(option.masteritemtypename != ""){
        if(option.masteritemtypename.split('')[0] == "3"){
          items.push(
            <option key={i} value={option.masteritemdescription}>
              {option.masteritemdescription}
            </option>
          );
        }
      }
   
    });
    return items;
  };


  createSelectItems2 = optionvalue => {

    let items = [];
    // const materiallistvalues = [
    //   {
    //     'itemname': '50 NB H.R. Tubes'
    //   },
    //   {
    //     'itemname': '40 NB H.R. Tubes'
    //   },
    //   {
    //     'itemname': '6 mm MS Plate'
    //   },
    //   {
    //     'itemname': 'Prop nut'
    //   },
    //   {
    //     'itemname': 'H.R sheet'
    //   },
    //   {
    //     'itemname': 'Dia10 mm MS rod'
    //   },
    //   {
    //     'itemname': '65mm*6mm MS Flat'
    //   },
    //   {
    //     'itemname': '32mm*5mm MS Flat'
    //   },
    //   {
    //     'itemname': '75mm*75mm*8mm L Angle'
    //   },
    //   {
    //     'itemname': '3/4" MS span Bolt'
    //   },
    //   {
    //     'itemname': '3/4" MS Hexagonal nut'
    //   },
    //   {
    //     'itemname': 'H.R. Sheet'
    //   },
    //   {
    //     'itemname': '25mm*25mm*5mm L angle.'
    //   },
    //   {
    //     'itemname': '40 NB H.R. Tubes'
    //   },
    //   {
    //     'itemname': 'Top cup'
    //   },
    //   {
    //     'itemname': 'Bottom cup'
    //   },
    //   {
    //     'itemname': 'Dia 8 mm Rod'
    //   },
    //   {
    //     'itemname': '20 NB H.R Tubes'
    //   },
    //   {
    //     'itemname': 'Ledger blade'
    //   },
    //   {
    //     'itemname': 'locking pins'
    //   },
    //   {
    //     'itemname': 'Dia 32 mm MS rod'
    //   },
    //   {
    //     'itemname': 'MS pLate'
    //   },
    //   {
    //     'itemname': 'Cup nuts'
    //   },
    //   {
    //     'itemname': 'dia 38 OD pipe'
    //   },
    //   {
    //     'itemname': '40mm*40mm'
    //   },
    //   {
    //     'itemname': '40mm*50mm'
    //   },
    //   {
    //     'itemname': '32mm*5mm Ms flat'
    //   }

    // ]
    const materiallistvalues = this.state.materiallistvalues;
    materiallistvalues.filter((option, i) => {
      items.push(
        <option key={i} value={option.masteritemdescription}>
          {option.masteritemdescription}
        </option>
      );
    });
    return items;
  };

  createSelectunfinishedItems = optionvalue => {
    let items = [];
    const materiallistvalues = this.state.materiallistvalues;
    materiallistvalues.filter((option, i) => {
      if (option.masteritemtype.masteritemtypename !== optionvalue) {
        items.push(
          <option key={i} value={option.id}>
            {option.masteritemtypename}
          </option>
        );
      }
    });
    return items;
  };

  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  handleClick = e => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ""],
        products: [...this.state.data.products, ""],
        productsqty: [...this.state.data.productsqty, ""],
        productsuom: [...this.state.data.productsuom, ""],
        productsqtyinmeter: [...this.state.data.productsqtyinmeter, ""]
      }
    });
  };
  handleChange = (e, index) => {
    this.state.data.products[index] = e.target.value;
    const materialuom = this.state.materiallistvaluesObject[
      this.state.data.products[index]
    ];
    this.state.data.productsqty[index] = "1";
    this.state.data.productsuom[index] = materialuom.masteritemuom.uomname;
    this.state.data.productsqtyinmeter[index] = ""
    
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        products: this.state.data.products,
        productsuom: this.state.data.productsuom,
        productsqty: this.state.data.productsqty,
        productsqtyinmeter: this.state.data.productsqtyinmeter
      }
    });
  };

  ProductqtyhandleChange = (e, index) => {
    console.log(index);
    let arr = this.state.materiallistvalues.filter((item) =>{
      return item.masteritemdescription == e.target.value
    })
    console.log(arr)
    this.state.data.productsqty[index] = e.target.value;
    this.state.data.productsweight[index] = 
    arr.length > 0 ? (arr[0].masteritemunitwt != 'undefined' && arr[0].masteritemunitwt != 'null' && arr[0].masteritemunitwt != '' ? arr[0].masteritemunitwt : 0) : 0
    
    this.setState({
      ...this.state,
      data: { ...this.state.data, 
        productsqty: this.state.data.productsqty,
        productsweight: this.state.data.productsweight
      }
    },() =>{
      console.log(this.state.data)
    });
  };

  ProductuomhandleChange = (e, index) => {
    console.log(index);
    this.state.data.productsuom[index] = e.target.value;
    this.setState({
      ...this.state,
      data: { ...this.state.data, productsuom: this.state.data.productsuom }
    });
  };
  
  ProductqtyinmeterhandleChange = (e, index) => {
    console.log(e.target.value);
    this.state.data.productsqtyinmeter[index] = e.target.value;
    this.setState({
      ...this.state,
      data: { ...this.state.data, productsqtyinmeter: this.state.data.productsqtyinmeter }
    });
  };
  
  removeClick = (e, index) => {
    const removeProduct = { ...this.state.data };
    const products = removeProduct.products;
    const productsqty = removeProduct.productsqty;
    const productsuom = removeProduct.productsuom;
    const productsqtyinmeter = removeProduct.productsqtyinmeter;
    const values = removeProduct.values;
    products.splice(index, 1);
    productsqty.splice(index, 1);
    productsuom.splice(index, 1);
    productsqtyinmeter.splice(index, 1);
    values.splice(index, 1);
    this.setState({
      ...this.state,
      data: { ...this.state.data, products, productsqty, productsuom,productsqtyinmeter, values }
    });
  };
  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  handleValidSubmit = () => {
    console.log("product form data");
    console.log(this.state.data);
    const products = this.state.data.products;
    const productsqty = this.state.data.productsqty;
    console.log(productsqty)
    let productMaterials = [];
    products.forEach(function (v, i) {
      var obj = {};
      obj.product_id = v;
      obj.productqty = productsqty[i];
      productMaterials.push(obj);
    });

    const data = { ...this.state.data, productMaterials };
    delete data.products;
    delete data.productsqty;
    this.setState({ loading: true });
    this.props
      .submit(data)
      .then(() => {
        this.setState({
          loading: false,
          errors: {},
          success: true,
          data: {
            productname: "",
            values: [""],
            products: [""],
            productsqty: [""],
            productsuom: [""],
            productsqtyinmeter: [""]
          },
          materiallistvalues: this.initialState.materiallistvalues,
          materiallistvaluesObject: this.initialState.materiallistvaluesObject
        });
        this.myFormRef.reset();

      })
      .catch(err => {
        alert("Same product sub assembly is already added.")
        window.location.reload()
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };
  toggle = () => {
    this.setState({ ...this.state, success: false });
  };
  toggelbutton = () => {
    this.setState({ ...this.state, success: false });
    window.location.reload()
  }
  handleInvalidSubmit = () => {
    console.log("invalid submit");
  };
  render() {
    const { data, errors, loading, success,uomlistvalues } = this.state;
    console.log("__STATE_VALUE___DATA_");
    console.log(data);
    return (
      <div>
        {!!success ? (
          // <Alert color="info">Product detail is added successfully.</Alert>
          <Modal
            isOpen={success}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle} className='small-font-size-bold'>Subassembly</ModalHeader>
            <ModalBody >Subassembly is added successfully.</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggelbutton}>
                Ok
              </Button>{" "}

            </ModalFooter>
          </Modal>
        ) : null}
        {!!errors.productname ? (
          <Alert Label color="danger">Product Name must be unique id.</Alert>
        ) : null}
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader className='small-font-size-bold'>Product Details</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="productname" Label>Product Name</Label>
                    <span className="required-label">*</span>
                    <AvField
                      Label
                      type="select"
                      name="productname"
                      id="productname"
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="">select</option>
          
                      {this.createSelectItems("FinishedGoods")}
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div>
                    <div className="mg-20">
                      <Button color="info" onClick={this.handleClick}>
                        Add Component
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>

              {data.values.map((value, index) => {
                return (<Row key={index}>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className='small-font-size' for={`Material-qty+${index}`}>
                        Sub assembly
                  </Label>
                      <span className="required-label">*</span>
          

                      <AvField
                        Label
                        type="select"
                        name="material_qty"
                        id={`Material-qty+${index}`}
                        value={data.productsqty[index] || null}
                        onChange={e =>
                          this.ProductqtyhandleChange(e, index)
                        }
                      >
                        <option value="">select</option>
             
                        {this.createSelectItems2("")}
                      </AvField>
                    </AvGroup>


                  </Col>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className='small-font-size' for={`Material-qtymeter+${index}`}>
                        Quantity
                  </Label>
                      <span className="required-label">*</span>
          
                    <AvField
                      Label
                      type="text"
                      name="Material-qtymeter"
                      id={`Material-qtymeter+${index}`}
                      onChange={this.onChange}
                      value={data.productsqtyinmeter[index]}
                      onChange={e =>
                        this.ProductqtyinmeterhandleChange(e, index)
                      }
                    >
                     
                    </AvField>
                 
                    </AvGroup>
                  </Col>

                  <Col sm={2}>
                    <AvGroup>
                      <Label className='small-font-size' for={`Material-uom+${index}`}>
                        UOM
                  </Label>
                      <span className="required-label">*</span>
          
                    <AvField
                      Label
                      type="select"
                      name="Material-uom"
                      id={`Material-uom+${index}`}
                      onChange={this.onChange}
                      value={data.productsuom[index]}
                      onChange={e =>
                        this.ProductuomhandleChange(e, index)
                      }
                    >
                      <option value="">Select unit</option>
          
                      {this.createSelectItems3("FinishedGoods")}
                    </AvField>
                 
                    </AvGroup>
                  </Col>

                  
                  <Col sm={2}>
                    <AvGroup style={{ marginTop: "28px" }}>
                      <Button
                        Label
                        Label
                        color='danger'
                        type="button"
                        onClick={e => this.removeClick(e, index)}
                      >
                        -
                  </Button>

                    </AvGroup>

                  </Col>
                </Row>)
              })}
              {/* <div>
                                    <button onClick={this.handleSubmit}>submit</button>
                                </div> */}

            </CardBody>
          </Card>
          <div className="mg-20" style={{paddingLeft: 15, paddingTop: 10}}>
            <Button color="primary" disabled={loading} className="lgn-loader small-font-size">
              Submit
              {/* {loading} */}
            </Button>
          </div>
        </AvForm>
      </div>
    );
  }
}

ProductForm.propTypes = {
  submit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    materiallistvalues: state.masteritemlistvalues
    // masteritemlistvalues: state.masteritemlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(ProductForm);
