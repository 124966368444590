import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { getinvoice } from '../../../../actions/Invoice';
import { Button, Alert } from 'reactstrap';
import DataTable from '../../../../Components/Table/DatatablePage';
import CustomLoader from '../../../../Components/loader/loader';


import {
  getMasterItemList,
  getMasterItemTypes
} from "../../../../actions/Transaction";

import { getUomList } from "../../../../actions/uom";
import { getCustomerList } from "../../../../actions/customerContact";


import { getChallan } from '../../../../actions/challan';
import { tr } from 'date-fns/esm/locale';

class ViewInvoice extends React.Component {
  state = {
    count: 0,
    loading: true,
    success:false,
    invoiceid:'',
    invoicelistvalues: []
  };
  componentDidMount() {


    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();

    this.props.getUomList();
    this.props.getinvoice();
    this.props.getChallan();

    console.log(this.props);
    if (this.props.invoicelistvalues) {
      let quotationlist = Object.values(this.props.invoicelistvalues);
      quotationlist.sort(function(a, b){
        return parseInt(b.invoicenumber) - parseInt(a.invoicenumber);
      });
      const invoicelistvalues = quotationlist.map(quotationvalue => {
        return {
          invoicenumber: quotationvalue.invoicenumber,
          customername: quotationvalue.customername,
          customernumber: quotationvalue.customernumber,
          customercontactnumber: quotationvalue.customercontactnumber,

          view: (
            <div className="actionbuttons">
            <Button
              Label
              color='primary'
              onClick={() => {
                this.updateButton(quotationvalue._id);
              }}
            >
              Print
            </Button>
            <Button
              Label
              color='primary'
              onClick={() => {
                this.updateinvoice(quotationvalue._id);
              }}
            >
              Edit
            </Button>
            </div>
          )
        };
      });
      this.setState({ ...this.state, invoicelistvalues });
      this.dataHandler(false);
    }
  }
  componentWillReceiveProps(props) {
    let quotationlist = Object.values(props.invoicelistvalues);
    console.log(quotationlist)
    //quotationlist= quotationlist.sort((a, b) => b.invoicenumber.toString().toLowerCase().localeCompare(a.invoicenumber.toString().toLowerCase()));
    quotationlist.sort(function(a, b){
      return parseInt(b.invoicenumber) - parseInt(a.invoicenumber);
    });
    const invoicelistvalues = quotationlist.map(quotationvalue => {
      return {
        invoicenumber: quotationvalue.invoicenumber,
        customername: quotationvalue.customername,
        customernumber: quotationvalue.customernumber,
        customercontactnumber: quotationvalue.customercontactnumber,

        view: (
          <div className="actionbuttons">
            <Button
              Label
              color='primary'
              onClick={() => {
                this.updateButton(quotationvalue._id);
              }}
            >
              Print
            </Button>
            <Button
              Label
              color='primary'
              onClick={() => {
                this.updateinvoice(quotationvalue._id);
              }}
            >
              Edit
            </Button>
            </div>
        )
      };
    });
    this.setState({ ...this.state, invoicelistvalues });
    this.dataHandler(false);
  }

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };


  updateButton = id => {
    let path = `/view_invoicepdf/${id}`;
    this.props.history.push(path);
  };

  originalInvoice = () =>{
    let path = `/view_invoicepdf/${this.state.invoiceid}`;
    this.props.history.push(path);
  }

  profomaInvoice = () =>{
    let path = `/view_proformainvoicepdf/${this.state.invoiceid}`;
    this.props.history.push(path);
  }

  updateinvoice = id => {
    // let path = `/view_invoicepdf/${id}`;
    // window.open(path, '_blank');
    let path = `/editinvoice/${id}`;
    //window.open(path, "_blank");
    this.props.history.push(path);
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  render() {
    const { loading, count, invoicelistvalues,success } = this.state;
    console.log(invoicelistvalues)
    const columnvalue = [
      {
        label: 'Invoice Number',
        field: 'invoicenumber',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Customer Name',
        field: 'customername',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Customer Number',
        field: 'customernumber',
        sort: 'asc',
        width: 40
      },

      {
        label: 'Contact Number',
        field: 'customercontactnumber',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Print/Edit',
        field: 'view',
        sort: 'asc',
        width: 40
      }
    ];
    return (
      <div className='container mg-t-20'>

          {!!success ? (
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Invoice</ModalHeader>
              <ModalBody style={{'textAlign': 'center'}}>Please select type of invoice</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.originalInvoice}>
                  Original Invoice
                </Button>
                <Button color="primary" onClick={this.profomaInvoice}>
                  Proforma Invoice
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}

        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/transaction_page">Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/invoice">Invoice</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>List</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <DataTable
            rowvalues={invoicelistvalues}
            columnvalue={columnvalue}
            className="invoicelist" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoicelistvalues: state.invoicelistvalues
  };
}

export default connect(
  mapStateToProps,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    getCustomerList,
    getinvoice,
    getChallan
  }
)(ViewInvoice);
