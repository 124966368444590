import React from "react";
import PropTypes from "prop-types";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Label, Alert } from "reactstrap";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

class MasterItemTypeForm extends React.Component {
  state = {
    data: {
      itemtype: ""
    },
    errors: {},
    loading: false,
    success: false
  };
  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  handleValidSubmit = () => {
    console.log(this.state.data);
    this.setState({ loading: true });
    this.props
      .submit(this.state.data)
      .then(() => {
        this.setState({ loading: false, errors: {}, success: true });
        this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  render() {
    const { data, errors, loading, success } = this.state;
    return (
      <div>
        {!!success ? (
          // <Alert color="info">Item Type data is Added successfully</Alert>
          <Modal
            isOpen={success}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>Item Type</ModalHeader>
            <ModalBody>Item Type data is Added successfully</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
        {!!errors.MasterItemTypename ? (
          <Alert color="danger">Item Type data already exist.</Alert>
        ) : null}
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Add Item Type</CardHeader>
            <CardBody>
              <Row>
                <Col sm={6}>
                  <AvGroup>
                    <Label className='small-font-size' for="masteritemtype">Item Type</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="itemtype"
                      type="text"
                      id="masteritemtype"
                      value={data.option}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Item Type"
                        },
                  
                        pattern: {
                          value: "^[a-zA-Z]+$",
                          errorMessage: "Please enter valid Item Type name"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={6}>
                  <AvGroup className="mg-20">
                    <Button
                      color="primary"
                      disabled={loading}
                      className="lgn-loader"
                    >
                      Submit
                      {/* {loading} */}
                    </Button>
                  </AvGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

MasterItemTypeForm.propTypes = {
  submit: PropTypes.func.isRequired
};

export default MasterItemTypeForm;
