import React from 'react';
import logo from '../../../../Images/logo.png';
import client from '../../../../Images/client.png';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import {
  TableCell,
  Table,
  TableHeader,
  TableBody,
  DataTableCell
} from '@david.kucsai/react-pdf-table';
import styled from '@react-pdf/styled-components';

// Create styles
const styles = StyleSheet.create({
  textcenter: { textAlign: 'center' },
  textright: { textAlign: 'right' },
  textleft: { textAlign: 'left' },
  paddingleft: { paddingLeft: 5 },
  margin: {
    margin: 5
  },
  pagepadding: {
    padding: 20
  },
  section: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  section_column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  myHeight: {
    height: '100px'
  },
  headerfont: {
    fontSize: 8,
    fontWeight: 'bold',
    padding: 3
  },
  descriptionText: {
    fontSize: 10,
    fontWeight: 'normal'
  },
  headerfont2: {
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'right',
    padding: 3
  },
  headerfontleft: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left'
  },
  billingaddress: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
    height: 70
  },
  shippingaddress: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
    height: 130
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dcaddress: {
    fontSize: 8,
    color: ' black'
  },
  dcaddress_left: {
    fontSize: 8
  },
  view_border: {
    border: '2pt solid #000',
    flexGrow: 1,
    height: 100,
    padding: 8
  },
  view_border_left: {
    borderLeft: '2pt solid #000',
    borderTop: '2pt solid #000',
    borderBottom: '2pt solid #000',
    flexGrow: 1,
    height: 50,
    padding: 8
  },
  view_border_right: {
    borderRight: '2pt solid #000',
    borderTop: '2pt solid #000',
    borderBottom: '2pt solid #000',
    flexGrow: 1,
    height:50,
    padding: 8
  },
  column: {
    width: 190
  },
  headerwidth: {
    width: 210,
    padding: 20
  },
  headerwidthdate: {
    width: 210,
    paddingTop: 23,
    fontSize: 12
  },
  headerwidthlogo: {
    width: 350,
    fontSize: 12
  },
  logo: {
    width: 225,
    height: 90
  },
  header_name: {
    width: 350,
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'left',
    paddingLeft: 40
  },
  header_sub_name: {
    width: '100%',
    fontSize: 8,
    textAlign: 'center',
    paddingHorizontal: 25
  },
  header_sub_name_phone: {
    width: '100%',
    fontSize: 8,
    fontWeight: 'bolder',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  header_tintext: {
    fontSize: 8,
    fontWeight: 'bold',
    paddingLeft: 10
  },
  header_sub_name3: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  header_sub_nameB: {
    flexDirection: 'column',
    paddingTop: 10,
    flex: 1
  },
  header_sub_nameA: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  headerwidthAddress: {
    width: 260,
    fontSize: 10,
    padding: 2,
    textAlign: 'right'
  },
  refstyle: {
    paddingBottom: 20,
    fontSize: 12
  },
  headerpadding: {
    padding: 20
  },
  flexgrow: {
    flexGrow: 1
  },
  fontsize18: {
    fontSize: 13
  },
  kindattend: {
    paddingTop: 15,
    fontSize: 13
  },
  yourref: {
    fontSize: 13,
    paddingBottom: 10
  },
  main: {
    fontSize: 12
  },
  tableheaderbgcolor: {
    backgroundColor: 'red',
    color: 'red',
    background: 'red'
  },
  footerfixed: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  dc_header: {
    flexDirection: 'row'
  },
  dc_gst: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  padding: {
    padding: 16,
    fontSize: 8
  }
});

const Small = ({ children }) => {
  return (
    <Text style={{ fontSize: 8 }}>{children}</Text>
  )
}

const SmallBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 8, fontWeight: 'bold' }}>{children}</Text>
  )
}

const Medium = ({ children }) => {
  return (
    <Text style={{
      fontSize: 10,
      marginLeft: 25,
      textDecorationLine: 'underline'
    }}>{children}</Text>
  )
}

const MediumBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>{children}</Text>
  )
}

const Bold = ({ children }) => {
  return (
    <Text style={{ fontWeight: 'bold' }}>{children}</Text>
  )
}

const Header = ({ children }) => {
  return (
    <Text style={{
      // width: 350,
      fontWeight: 'bold',
      fontSize: 24,
      textAlign: 'left',
      paddingLeft: 10
    }}>{children}</Text>
  )
}

// Create Document Component

const MyDocument = ({ orderlistvalues, data_cond }) => {

const today = new Date().getDate() + '/' +  (new Date().getMonth()+1) + '/' + new Date().getFullYear()
const data = orderlistvalues.addeditemlist;
  const {
    ponumber,
    receiptnumber
  } = orderlistvalues;

  return (
    <Document>
      <Page size='A4' style={styles.pagepadding} wrap>
        <View>
          <View style={styles.dc_header}>
            <Image style={styles.logo} src={logo} />
            <View stye={styles.header_sub_nameB}>

              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 10 }}>
                <Small><Bold>CIN:</Bold> U28110KA2012PTC062181</Small>
              </View>
       
              <Header>Pondhan Scaffolding Pvt. Ltd.</Header>
              {/* <Text>{'\n'}</Text> */}
              <Text style={[styles.header_tintext, { fontWeight: 'bold' }]}>
                {' '}
                MANF: Scaffolding Props, Cup-Lock, Jack, Span, Centering Sheet,
                'H' Frame & Swivel Coupler
            </Text>
              <Text>{'\n'}</Text>
            </View>
          </View>




          {/* <Text style={styles.logo}></Text>
          <Text style={styles.logo}></Text>
          <Text style={styles.headerwidthAddress}>
            Email: info@pondhan.com, Website: www.pondhan.com{'\n'}
            TIN No: 29710660725{'\n'}
            GSTIN:29AAGCP4018E1ZJ RCC No: U28110KA2012PTC062181{'\n'}
          </Text> */}
        </View>
        <View style={styles.dc_gst}>
          <Text style={styles.header_tintext}><Bold>TIN:</Bold> 29710660725</Text>
          <Text style={styles.header_tintext}><Bold>GSTIN:</Bold> 29AAGCP4018E1ZJ</Text>
          <View>
            <Text style={styles.header_tintext}><Bold>HSN Code:</Bold> 7308</Text>
            <Text style={[styles.header_tintext, { marginTop: 4 }]}><Bold>SAC Code:</Bold> 996793</Text>
          </View>
        </View>

        <Text style={styles.header_sub_name}>
          {'\n'}
          Office & Works : 40 & 41 (corner) Road,no.5, Obadenahalli Industrial
          Area,3rd phase Doddaballapura, Bangalore Rural District-561203.
        </Text>
        <Text style={[styles.header_sub_name_phone, { fontWeight: 'bold', color: 'black' }]}>
          <Bold>Off: +91 080 23339150, Mob: +91 9900069150</Bold>
    
        </Text>
        <Text> {'\n'}</Text>
        <Text style={[styles.header_sub_name_phone, { fontWeight: 'bold', color: 'black',textAlign:'center' }]}>
          <Bold>Goods Received Note</Bold>
        </Text>

        
        {/* {tableComponent} */}
        <View style={{ marginTop: 8 }}>
          <View style={styles.row}>
            <View style={styles.view_border_left}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.dcaddress, { fontWeight: 'bold' }]}>GRN No. </Text>
                <Text style={[styles.dcaddress, {
                
                  paddingLeft: 16,
                }]}>{receiptnumber}/2019-20 
                {'\n'}
                </Text>
              </View>
            
              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.dcaddress, { fontWeight: 'bold' }]}>PO No.</Text>
                <Text style={[styles.dcaddress, {
                    paddingLeft: 16,
                    wordWrap: 'break-word'
                }]}> PSPL/2019-20/{ponumber}
                {'\n'}
                </Text>
             </View>
            </View>
           
            <View style={styles.view_border_right}>
              <View style={styles.header_sub_name3}>
                <Text></Text>
                <Text style={styles.dcaddress_left}>
                  Date: {today}
                  {'\n'}
                </Text>
              </View>
            </View>
            <Text>{'\n'}</Text> 
          </View>
          <Text>{'\n'}</Text> 
          <Table
            border={2}
            style={[styles.section, styles.padding, styles.tableheaderbgcolor, { fontSize: 8 }]}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={[styles.headerfont]}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={[styles.headerfont]}>PARTICULAR</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={[styles.headerfont]}>UOM</Text>
              </TableCell>
              <TableCell weighting={0.3}>
                <Text style={[styles.headerfont]}>PO Qty</Text>
              </TableCell>
              <TableCell weighting={0.3}>
                <Text style={[styles.headerfont]}>Received Qty</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={[styles.headerfont]}>Received Date</Text>
              </TableCell>
              
              <TableCell weighting={0.3}>
                <Text style={[styles.headerfont]}>Remaining Qty</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => <Text style={[styles.headerfont]}>{r.slno}</Text>}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => <Text style={[styles.headerfont, { textAlign: 'left' }]}>{r.itemname}</Text>}
              />
              <DataTableCell
                weighting={0.2}
                style={styles.padding}
                getContent={r => <Text style={[styles.headerfont]}>{r.itemuom}</Text>} />
              <DataTableCell
                style={styles.padding}
                weighting={0.3}
                getContent={r => <Text style={[styles.headerfont]}>{r.poqty}</Text>} />
              <DataTableCell
                style={styles.padding}
                weighting={0.3}
                getContent={r => <Text style={[styles.headerfont]}>{r.receivedqty}</Text>} />
            <DataTableCell
                style={styles.padding}
                weighting={0.4}
                getContent={r => <Text style={[styles.headerfont]}>{r.receiptDate}</Text>} />
                
                <DataTableCell
                style={styles.padding}
                weighting={0.3}
                getContent={r => <Text style={[styles.headerfont]}>{r.availqty}</Text>} />
            </TableBody>
          </Table>
          {/* <Text>{'\n'}</Text> */}
          {/* ends here */}
          <View style={styles.header_sub_name3}>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
             Authorised : Yes {'\n'}
            
            </Text>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              Inspection Required : Yes {'\n'}
             
            </Text>
          </View>
          <Text>{'\n'}</Text> 
          <View style={styles.header_sub_name3}>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
             EDP Prepared by {'\n'}
            
            </Text>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              Store incharge {'\n'}
             
            </Text>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              Inspected By {'\n'}
            </Text>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              Manager {'\n'}
            </Text>
            <Text style={[styles.header_tintext, { paddingLeft: 0, marginTop: 4 }]}>
              Accounts Department {'\n'}
            </Text>
          </View>

          <View>
            <Text
                 style={[styles.headerfont, { color: 'red' }]}
                >
                 {'\n'} {'\n'} 
                 Note: Any legal matter should be under Bengaluru Jurisdiction </Text>
        </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
