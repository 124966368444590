import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { connect } from 'react-redux';
import { getMoveOrder } from '../../../../actions/moveorder';
import { Button, Alert } from 'reactstrap';
import DataTable from '../../../../Components/Table/DatatablePage';
import CustomLoader from '../../../../Components/loader/loader';

class ViewProduction extends React.Component {
  state = {
    count: 0,
    loading: true,
    moveorderlistvalues: []
  };
  componentDidMount() {
    this.props.getMoveOrder();
    // console.log(this.props);
    // if (this.props.moveorderlistvalues) {
    //   const quotationlist = Object.values(this.props.moveorderlistvalues);
    //   const moveorderlistvalues = quotationlist.map(quotationvalue => {
    //     return {
    //       moveorderno: quotationvalue.moveorderno,
    //       fromaddress: quotationvalue.fromaddress,
    //       toaddress: quotationvalue.toaddress
    //     };
    //   });
    //   this.setState({ ...this.state, moveorderlistvalues });
    //   this.dataHandler(false);
    // }
  }
  componentWillReceiveProps(props) {
    let quotationlist = Object.values(props.moveorderlistvalues);
    //quotationlist= quotationlist.sort((a, b) => b.moveorderno.toLowerCase().localeCompare(a.moveorderno.toLowerCase()));
    quotationlist.sort(function(a, b){
      return parseInt(b.moveorderno) - parseInt(a.moveorderno);
    });
    const moveorderlistvalues = quotationlist.map(quotationvalue => {
      return {
        moveorderno: quotationvalue.moveorderno,
        fromaddress: quotationvalue.fromaddress,
        toaddress: quotationvalue.toaddress,
        view: (
          <Button
          color="primary"
          onClick={() => {
            let path = `/view_ItemsOrder/${quotationvalue._id}`;
            this.props.history.push(path);
          }}
        >
          View Items
        </Button>
        )
      };
    });
    this.setState({ ...this.state, moveorderlistvalues });
    this.dataHandler(false);
  }

  updateButton = id => {
    // let path = `/view_invoicepdf/${id}`;
    // window.open(path, '_blank');
    let path = `/view_invoicepdf/${id}`;
    //window.open(path, "_blank");
    this.props.history.push(path);
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  render() {
    const { loading, count, moveorderlistvalues } = this.state;
    console.log(moveorderlistvalues);
    const columnvalue = [
      {
        label: 'Move Order No',
        field: 'moveorderno',
        sort: 'asc',
        width: 40
      },
      {
        label: 'From Address',
        field: 'fromaddress',
        sort: 'asc',
        width: 40
      },
      {
        label: 'To Address',
        field: 'toaddress',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Items',
        field: 'view',
        width: 100,
      }
    ];
    return (
      <div className='container mg-t-20'>
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/transaction_page'>Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/move-order'>Move Order</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>List</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <DataTable
            rowvalues={moveorderlistvalues}
            columnvalue={columnvalue}
            className="moveorderlist"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    moveorderlistvalues: state.moveorderlistvalues
  };
}

export default connect(
  mapStateToProps,
  { getMoveOrder }
)(ViewProduction);
