import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import { connect } from 'react-redux';
import { getquotation } from '../../../../actions/quotation';
import { Button, Alert } from 'reactstrap';
import DataTable from '../../../../Components/Table/DatatablePage';
import CustomLoader from '../../../../Components/loader/loader';

import {
  getMasterItemList,
  getMasterItemTypes
} from "../../../../actions/Transaction";
import { getUomList } from "../../../../actions/uom";
import { getCustomerList } from "../../../../actions/customerContact";

class viewQuotationListTest extends React.Component {
  state = {
    count: 0,
    loading: true,
    quotationlistvalues: []
  };
  componentDidMount() {

    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getquotation();

    let quotationlist = Object.values(this.props.quotationlistvalues);
    quotationlist.sort(function(a, b){
      return parseInt(b.quotationnumber) - parseInt(a.quotationnumber);
    });
  
    const quotationlistvalues = quotationlist.map(quotationvalue => {
      return {
        quotationnumber: quotationvalue.quotationnumber,
        customername: quotationvalue.customername,
        customernumber: quotationvalue.customernumber,
        customercontactnumber: quotationvalue.customercontactnumber,
       
        view: (

          <div className="actionbuttons">
            <Button
              color="primary"
              // style={{ 'fontSize': '0.8rem' }}
              Label
              onClick={() => {
                this.updateButton(quotationvalue._id);
              }}
            >
              Print
          </Button>
            <Button
              color="primary"
              Label
              // style={{ 'fontSize': '0.8rem' }}
              onClick={() => {
                this.updateQuotation(quotationvalue._id);
              }}
            >
              Edit
          </Button>
          </div>
        )
      };
    });
    this.setState({ ...this.state, quotationlistvalues });
    this.dataHandler(false);
  }
  componentWillReceiveProps(props) {
    let quotationlist = Object.values(props.quotationlistvalues);
    //quotationlist= quotationlist.sort((a, b) => b.quotationnumber.toLowerCase().localeCompare(a.quotationnumber.toLowerCase()));
    quotationlist.sort(function(a, b){
      return parseInt(b.quotationnumber) - parseInt(a.quotationnumber);
    });
    const quotationlistvalues = quotationlist.map(quotationvalue => {
      return {
        quotationnumber: quotationvalue.quotationnumber,
        customername: quotationvalue.customername,
        customernumber: quotationvalue.customernumber,
        customercontactnumber: quotationvalue.customercontactnumber,
       
        view: (
          <div className="actionbuttons">
            <Button
              color="primary"
              style={{ 'fontSize': '0.8rem' }}
              onClick={() => {
                this.updateButton(quotationvalue._id);
              }}
            >
              Print
          </Button>
            <Button
              color="primary"
              style={{ 'fontSize': '0.8rem' }}
              onClick={() => {
                this.updateQuotation(quotationvalue._id);
              }}
            >
              Edit
          </Button>
          </div>
        )
      };
    });
    this.setState({ ...this.state, quotationlistvalues });
    this.dataHandler(false);
  }

  updateQuotation(id) {
    let path = `/editquotation/${id}`;
    this.props.history.push(path);
  }

  updateButton = id => {
    let path = `/viewnewpdf/${id}`;
    this.props.history.push(path);
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  render() {
    const { loading, count, quotationlistvalues } = this.state;
    const columnvalue = [
      {
        label: 'Quotation Number',
        field: 'quotationnumber',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Customer Name',
        field: 'customername',
        sort: 'asc',
        width: 40
      },

      {
        label: 'Customer Number',
        field: 'customernumber',
        sort: 'asc',
        width: 40
      },
    
      {
        label: 'Contact Number',
        field: 'customercontactnumber',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Action',
        field: 'view',
        sort: 'asc',
        width: 40
      }
    ];
    return (
      <div className='container mg-t-20'>
       
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/crmlist'>CRM</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Quotation</BreadcrumbItem>
        </Breadcrumb>
        {loading ? <CustomLoader /> : null}
        <div>
          <DataTable
            rowvalues={quotationlistvalues}
            columnvalue={columnvalue}
            className="quotationlist"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    quotationlistvalues: state.quotationlistvalues
  };
}

export default connect(
  mapStateToProps,
  { 
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    getCustomerList,
    getquotation 
  }
)(viewQuotationListTest);
