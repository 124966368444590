import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import MasterItemListForm from "../Form/ItemList/AddItemListForm";
import ViewMasterItemList from "./ViewMasterItemList";
import {
  addMasterItemList,
  getMasterItemList
} from "../../../../actions/Transaction";
import { getUomList } from "../../../../actions/uom";

class MasterItemListValues extends React.Component {
  render() {
    return (
      <div class="container mg-t-20">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Item List</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <ViewMasterItemList />
      </div>
    );
  }
}

export default MasterItemListValues;
