import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Roles } from '../Services/Roles';
import { connect } from 'react-redux';

const UserRoute = ({
  isAuthenticated,
  roles,
  role,
  second_role,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // return isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      if (!isAuthenticated) {
        return <Redirect to='/login' />;
      }
      if (typeof roles === 'string') {
        if (!(role === roles || second_role === roles)) {
          if(role === Roles.FINANCE ||
            role === Roles.PURCHASE ||
            role === Roles.SALES ||
            role === Roles.STORE ||
            role === Roles.PRODUCTION || second_role === Roles.FINANCE ||
            second_role === Roles.PURCHASE ||
            second_role === Roles.SALES ||
            second_role === Roles.STORE ||
            second_role === Roles.PRODUCTION) {
              return <Redirect to='/transaction_page' />;
            }
            else {
              return <Redirect to='/' />;
            }
        }
      } else if (!(roles.includes(role) || roles.includes(second_role))) {
        if(role === Roles.FINANCE ||
          role === Roles.PURCHASE ||
          role === Roles.SALES ||
          role === Roles.STORE ||
          role === Roles.PRODUCTION || second_role === Roles.FINANCE ||
          second_role === Roles.PURCHASE ||
          second_role === Roles.SALES ||
          second_role === Roles.STORE ||
          second_role === Roles.PRODUCTION) {
            return <Redirect to='/transaction_page' />;
          }
          else {
            return <Redirect to='/' />;
          }
      }
      return <Component {...props} />;
    }}
  />
);

UserRoute.propTypes = {
  // component: PropTypes.func.isRequired,
  // role: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    role: state.user.role,
    second_role: state.user.second_role
  };
}

export default connect(
  mapStateToProps,
  null
)(UserRoute);
