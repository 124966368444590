import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  Label,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

class SupplierDetail extends React.Component {
  state = {
    data: {
      suppliernumber: "",
      suppliername: "",
      supplieraddress1: "",
      supplieraddress2: "",
      city: "",
      pincode: "",
      state: "",
      country: "",
      suppliergstnumber: "",
      status: null,
      values: [""],
      suppliercontactnamelist: [""],
      suppliercontactdesignation: [""],
      suppliercontactnumber: [""],
      suppliercontactemail: [""]
    },
    errors: {},
    loading: false,
    success: false,
    suppliercontactlistvalue: []
  };

  componentWillReceiveProps(props) {
    const suppliercontactlistvalue = Object.values(
      props.suppliercontactlistvalue
    );
    const supplierdetaillistvalue = Object.values(
      props.supplierdetaillistvalue
    );
    const count = supplierdetaillistvalue.length;
    const suppliernumber = count > 0 ? 10001 + count : 10001;
    this.setState({
      ...this.state,
      suppliercontactlistvalue,
      data: { ...this.state.data, suppliernumber }
    });
  }

  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  handleClick = e => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ""],
        suppliercontactnamelist: [
          ...this.state.data.suppliercontactnamelist,
          ""
        ],
        suppliercontactdesignation: [
          ...this.state.data.suppliercontactdesignation,
          ""
        ],
        suppliercontactnumber: [...this.state.data.suppliercontactnumber, ""],
        suppliercontactemail: [...this.state.data.suppliercontactemail, ""]
      }
    });
  };

  supplierContacthandleChange = (e, index) => {
    this.state.data.suppliercontactlistvalue[index] = e.target.value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        suppliercontactlistvalue: this.state.data.suppliercontactlistvalue
      }
    });
  };

  removeClick = (e, index) => {
    const removeContact = { ...this.state.data };
    const values = removeContact.values;
    const suppliercontactnamelist = removeContact.suppliercontactnamelist;
    const suppliercontactdesignation = removeContact.suppliercontactdesignation;
    const suppliercontactnumber = removeContact.suppliercontactnumber;
    const suppliercontactemail = removeContact.suppliercontactemail;
    values.splice(index, 1);
    suppliercontactnamelist.splice(index, 1);
    suppliercontactdesignation.splice(index, 1);
    suppliercontactnumber.splice(index, 1);
    suppliercontactemail.splice(index, 1);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        suppliercontactnamelist,
        suppliercontactdesignation,
        suppliercontactnumber,
        suppliercontactemail
      }
    });
  };

  contactnameChange = (e, index) => {
    this.state.data.suppliercontactnamelist[index] = e.target.value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        suppliercontactnamelist: this.state.data.suppliercontactnamelist
      }
    });
  };

  contactdesignationChange = (e, index) => {
    this.state.data.suppliercontactdesignation[index] = e.target.value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        suppliercontactdesignation: this.state.data.suppliercontactdesignation
      }
    });
  };

  contactnumberChange = (e, index) => {
    this.state.data.suppliercontactnumber[index] = e.target.value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        suppliercontactnumber: this.state.data.suppliercontactnumber
      }
    });
  };

  contactemailChange = (e, index) => {
    this.state.data.suppliercontactemail[index] = e.target.value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        suppliercontactemail: this.state.data.suppliercontactemail
      }
    });
  };

  handleValidSubmit = () => {
    let supplierContactinfo = [];
    const values = this.state.data.values;
    const suppliercontactarray = this.state.data.suppliercontactnamelist;
    const suppliercontactdesignationarray = this.state.data
      .suppliercontactdesignation;
    const suppliercontactnumberarray = this.state.data.suppliercontactnumber;
    const suppliercontactemailarray = this.state.data.suppliercontactemail;
    values.forEach(function (value, i) {
      var obj = {};
      obj.suppliercontactname = suppliercontactarray[i] || null;
      obj.suppliercontactdesignation =
        suppliercontactdesignationarray[i] || null;
      obj.suppliercontactnumber = suppliercontactnumberarray[i] || null;
      obj.suppliercontactemail = suppliercontactemailarray[i] || null;
      supplierContactinfo.push(obj);
    });
    const data = { ...this.state.data, supplierContactinfo };
    this.setState({ loading: true });
    this.props
      .submit(data)
      .then(() => {
        this.setState({ loading: false, errors: {}, success: true });
        this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  render() {
    const { data, errors, loading, success } = this.state;

    return (
      <div className="container mg-t-20">
        <div>
          {!!success ? (
            // <Alert color="info">Supplier data is added successfully</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader  toggle={this.toggle}>Supplier</ModalHeader>
              <ModalBody >Supplier data is added successfully</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggle}>
                  Ok
                </Button>{" "}
               
              </ModalFooter>
            </Modal>
          ) : null}
          {!!errors.suppliernumber ? (
            <Alert Label color="danger">Supplier Number alreadt exist</Alert>
          ) : null}
          {!!errors.username ? (
            <Alert Label color="danger">{errors.username}</Alert>
          ) : null}
        </div>
        <div>
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
            ref={el => (this.myFormRef = el)}
          >
            <Card>
              <CardHeader Label>Supplier Details</CardHeader>
              <CardBody>
                <Row>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className='small-font-size' for="suppliernumber">Supplier Number</Label>
                      <span className="required-label">*</span>
                      <AvField
                        Label
                        name="suppliernumber"
                        type="number"
                        id="suppliernumber"
                        value={data.suppliernumber.toString()}
                        onChange={this.onChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Supplier Number"
                          },
                        
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Please enter valid Number"
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className='small-font-size' for="suppliername">Supplier Name</Label>
                      <span className="required-label">*</span>
                      <AvField
                        Label
                        name="suppliername"
                        type="text"
                        id="suppliername"
                        value={data.suppliername}
                        onChange={this.onChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Supplier Name"
                          },
                          minLength: {
                            value: 3,
                            errorMessage:
                              "Supplier Name must be between 3 and 300 characters"
                          },
                          maxLength: {
                            value: 300,
                            errorMessage:
                              "Supplier Name must be between 3 and 300 characters"
                          },
                          pattern: {
                            value: "^[a-zA-Z ]+$",
                            errorMessage: "Please enter valid Supplier Name"
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={2}>
                    <AvGroup>
                      <Label className='small-font-size' for="suppliergstnumber">Supplier GSTN </Label>
                      <span className="required-label">*</span>
                      <AvField
                        Label
                        name="suppliergstnumber"
                        type="text"
                        id="suppliergstnumber"
                        value={data.suppliergstnumber}
                        onChange={this.onChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Supplier GST Number"
                          },
                          minLength: {
                            value: 15,
                            errorMessage:
                              "Supplier GST Number should contain 15 characters"
                          },
                          maxLength: {
                            value: 15,
                            errorMessage:
                              "Supplier GST Number should contain 15 characters"
                          },
                          pattern: {
                            value: "^[a-zA-z0-9]+$",
                            errorMessage: "Please enter valid GST Number"
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Card>
                  <CardHeader Label>Address</CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm={3}>
                        <AvGroup>
                          <Label className='small-font-size' for="supplieraddress1">Address 1</Label>
                          <span className="required-label">*</span>
                          <AvField
                            Label
                            name="supplieraddress1"
                            type="text"
                            id="supplieraddress1"
                            value={data.supplieraddress1}
                            onChange={this.onChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter Supplier Address"
                              },
                              minLength: {
                                value: 3,
                                errorMessage:
                                  "Supplier Address must be between 3 and 300 characters"
                              },
                              maxLength: {
                                value: 300,
                                errorMessage:
                                  "SupplierAddress must be between 3 and 300 characters"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={3}>
                        <AvGroup>
                          <Label className='small-font-size' for="supplieraddress2">Address 2</Label>
                          <span className="required-label">*</span>
                          <AvField
                            Label
                            name="supplieraddress2"
                            type="text"
                            id="supplieraddress2"
                            value={data.supplieraddress2}
                            onChange={this.onChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter Supplier Address"
                              },
                              minLength: {
                                value: 3,
                                errorMessage:
                                  "Supplier Address must be between 3 and 300 characters"
                              },
                              maxLength: {
                                value: 300,
                                errorMessage:
                                  "SupplierAddress must be between 3 and 300 characters"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={2}>
                        <AvGroup>
                          <Label className='small-font-size' for="city">City</Label>
                          <span className="required-label">*</span>
                          <AvField
                            Label
                            name="city"
                            type="text"
                            id="city"
                            value={data.city}
                            onChange={this.onChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter city Name"
                              },
                              minLength: {
                                value: 1,
                                errorMessage:
                                  "city Name should contain between 1 to 50 characters"
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "city Name should contain between 1 to 50 characters"
                              },
                              pattern: {
                                value: "^[a-zA-z ]+$",
                                errorMessage: "Please enter valid city Name"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <AvGroup>
                          <Label className='small-font-size' for="pincode">Pin Code</Label>
                          <span className="required-label">*</span>
                          <AvField
                            Label
                            name="pincode"
                            type="text"
                            id="pincode"
                            value={data.pincode}
                            onChange={this.onChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter Pincode"
                              },
                              minLength: {
                                value: 6,
                                errorMessage:
                                  "Pincode should contain atleast 6 characters"
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "Pincode should contain between 6 to 10 characters"
                              },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Please enter valid PinCode"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={2}>
                        <AvGroup>
                          <Label className='small-font-size' for="state">State</Label>
                          <span className="required-label">*</span>
                          <AvField
                            Label
                            name="state"
                            type="text"
                            id="state"
                            value={data.state}
                            onChange={this.onChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter State Name"
                              },
                              minLength: {
                                value: 1,
                                errorMessage:
                                  "State Name should contain between 1 to 50 characters"
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "State Name should contain between 1 to 50 characters"
                              },
                              pattern: {
                                value: "^[a-zA-z ]+$",
                                errorMessage: "Please enter valid State Name"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={2}>
                        <AvGroup>
                          <Label className='small-font-size' for="country">Country</Label>
                          <span className="required-label">*</span>
                          <AvField
                            Label
                            name="country"
                            type="text"
                            id="country"
                            value={data.country}
                            onChange={this.onChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter Country Name"
                              },
                              minLength: {
                                value: 1,
                                errorMessage:
                                  "Country Name should contain between 1 to 50 characters"
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "Country Name should contain between 1 to 50 characters"
                              },
                              pattern: {
                                value: "^[a-zA-z ]+$",
                                errorMessage: "Please enter valid Country Name"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardBody>
              <CardHeader Label>Add supplier contact</CardHeader>
              <CardBody>
                <div className="mg-t-20">
                  <Button color="info" onClick={this.handleClick}>
                    Add Supplier Contacts
                  </Button>
                </div>
                {data.values.map((value, index) => {
                  return (
                    <Row key={index}>
                      <Col sm={2}>
                        <AvGroup>
                          <Label className='small-font-size' for={`contactname + ${index}`}>
                            Contact Name{" "}
                          </Label>
                          <AvField
                            Label
                            name={`contactname + ${index}`}
                            type="text"
                            id={`contactname + ${index}`}
                            value={data.suppliercontactnamelist[index] || null}
                            onChange={e => this.contactnameChange(e, index)}
                            validate={{
                              minLength: {
                                value: 3,
                                errorMessage:
                                  "contact name should contain only between 3 to 300 characters"
                              },
                              maxLength: {
                                value: 300,
                                errorMessage:
                                  "contact name should contain only between 3 to 300 characters"
                              },
                              pattern: {
                                value: "^[a-zA-z ]+$",
                                errorMessage: "Please enter valid contact name"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={2}>
                        <AvGroup>
                          <Label className='small-font-size' for={`suppliercontactdesignation + ${index}`}>
                            designation{" "}
                          </Label>
                          <AvField
                            Label
                            name={`suppliercontactdesignation + ${index}`}
                            type="text"
                            id={`suppliercontactdesignation + ${index}`}
                            value={
                              data.suppliercontactdesignation[index] || null
                            }
                            onChange={e =>
                              this.contactdesignationChange(e, index)
                            }
                            validate={{
                              minLength: {
                                value: 3,
                                errorMessage:
                                  "contact designation name should contain only between 3 to 100 characters"
                              },
                              maxLength: {
                                value: 100,
                                errorMessage:
                                  "contact designation name should contain only between 3 to 100 characters"
                              },
                              pattern: {
                                value: "^[a-zA-z ]+$",
                                errorMessage: "Please enter valid contact name"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={2}>
                        <AvGroup>
                          <Label className='small-font-size' for={`suppliercontactnumber + ${index}`}>
                            Contact Number{" "}
                          </Label>
                          <AvField
                            Label
                            name={`suppliercontactnumber + ${index}`}
                            type="text"
                            id={`suppliercontactnumber + ${index}`}
                            value={data.suppliercontactnumber[index]}
                            onChange={e => this.contactnumberChange(e, index)}
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage:
                                  "Your phone number must be composed only with numbers"
                              },
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "Your phone number must be between 10 and 12 numbers"
                              },
                              maxLength: {
                                value: 12,
                                errorMessage:
                                  "Your phone number be between 10 and 12 numbers"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={3}>
                        <AvGroup>
                          <Label className='small-font-size' for={`suppliercontactemail + ${index}`}>
                            Contact Email{" "}
                          </Label>
                          <AvField
                            Label
                            name={`suppliercontactemail + ${index}`}
                            type="text"
                            id={`suppliercontactemail + ${index}`}
                            value={data.suppliercontactemail[index] || null}
                            onChange={e => {
                              this.contactemailChange(e, index);
                            }}
                            validate={{
                              pattern: {
                                value:
                                  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
                                errorMessage: "Please enter valid email"
                              }
                            }}
                          />
                        </AvGroup>
                      </Col>
                      <Col sm={2}>
                        <Button
                          Label
                          type="button"
                          color='danger'
                          onClick={e => this.removeClick(e, index)}
                          style={{ marginTop: "30px" }}
                        >
                          -
                        </Button>
                      </Col>
                      {/* <Col sm={6}>
                                  <AvGroup>
                                    <Label className='small-font-size' for="contact Number">Contact Number</Label>
                                    <span className="required-label">*</span>
                                    <AvField
                                      type="select"
                                      name="contactnumber"
                                      onChange={this.onChange}
                                      required={true}
                                      disabled={true}
                                    >
                                      <option value="">123</option>
                                      <option value="">345</option>
                                      <option value=""></option>
                                      {this.createSelectNumbers()}
                                    </AvField>
                                  </AvGroup>
                                </Col> */}
                    </Row>
                  );
                })}
                <div className="mg-20">
                  <Button
                    Label
                    color="primary"
                    disabled={loading}
                    className="lgn-loader"
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </AvForm>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    suppliercontactlistvalue: state.suppliercontactlistvalue,
    supplierdetaillistvalue: state.supplierdetaillistvalue
  };
}
export default connect(
  mapStateToProps,
  null
)(SupplierDetail);
