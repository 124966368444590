import React from "react";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import CustomLoader from "../loader/loader";

class EditTable extends React.Component {
  state = {
    data: {
      poid: null,
      ponumber: null,
      poitemid: [],
      itemid: [],
      itemuom: [],
      itemquantity: [],
      receivedqty: [],
      recevingqty: [],
      itemlocation: [],
      totalqty: [],
      date: []
    },
    receiptnumber: null,
    selectedItem: [],
    locationlistvalue: [],
    sucess: false,
    errors: {},
    uomlistvalues: [],
    loading: false,
    loadingvalue: false,
    visible: false
  };
  componentDidMount() {
    const locationlistvalue = Object.values(this.props.locationlistvalue);
    const uomlistvalues = Object.values(this.props.uomlistvalues);
    const ponumber = this.props.ponumber;
    const poid = this.props.poid;
    const selectedItemlistvalues = this.props.selectedItem;
    const poitemid = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.poitemid;
    });
    const itemuom = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.itemuom;
    });
    const itemid = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.itemid;
    });
    const itemlocation = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.itemlocation;
    });
    const receivedqty = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.receivedqty.toString();
    });
    const itemquantity = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.quantity.toString();
    });
    const totalqty = selectedItemlistvalues.map(selectedItem => {
      const recevingqtyvalue =
        parseFloat(selectedItem.quantity) -
        parseFloat(selectedItem.receivedqty);
      return recevingqtyvalue + selectedItem.receivedqty;
    });
    const recevingqty = selectedItemlistvalues.map(selectedItem => {
      const recevingqtyvalue =
        parseFloat(selectedItem.quantity) -
        parseFloat(selectedItem.receivedqty);
      return recevingqtyvalue.toString();
    });
    this.setState({
      ...this.state,
      locationlistvalue,
      uomlistvalues,
      data: {
        poid,
        ponumber,
        poitemid,
        itemid,
        itemuom,
        itemlocation,
        itemquantity,
        receivedqty,
        recevingqty,
        totalqty
      },
      selectedItem: selectedItemlistvalues
    },()=>{
      sessionStorage.setItem('SelectedItem',JSON.stringify(this.state.selectedItem))
    });
  }
  componentWillReceiveProps(props) {
    const locationlistvalue = Object.values(props.locationlistvalue);
    const uomlistvalues = Object.values(props.uomlistvalues);
    const poid = props.poid;
    const ponumber = props.ponumber;
    const selectedItemlistvalues = props.selectedItem;
    const poitemid = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.poitemid;
    });
    const itemid = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.itemid;
    });
    const itemuom = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.itemuom;
    });
    const itemlocation = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.itemlocation;
    });
    const itemquantity = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.quantity.toString();
    });
    const receivedqty = selectedItemlistvalues.map(selectedItem => {
      return selectedItem.receivedqty.toString();
    });
    const recevingqty = selectedItemlistvalues.map(selectedItem => {
      const recevingqtyvalue =
        parseFloat(selectedItem.quantity) -
        parseFloat(selectedItem.receivedqty);
      return recevingqtyvalue.toString();
    });
    const totalqty = selectedItemlistvalues.map(selectedItem => {
      const recevingqtyvalue =
        parseFloat(selectedItem.quantity) -
        parseFloat(selectedItem.receivedqty);
      return recevingqtyvalue + selectedItem.receivedqty;
    });
    this.setState({
      ...this.state,
      locationlistvalue,
      uomlistvalues,
      data: {
        poid,
        ponumber,
        poitemid,
        itemid,
        itemuom,
        itemlocation,
        itemquantity,
        receivedqty,
        recevingqty,
        totalqty
      },
      selectedItem: selectedItemlistvalues
    },()=>{
      sessionStorage.setItem('SelectedItem',JSON.stringify(this.state.selectedItem))
    });
  }
  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uomlistvalues;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };
  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };
  locationChange = (e, index) => {
    const selected_location = e.target.value;
    const itemlocation = [...this.state.data.itemlocation];
    itemlocation[index] = selected_location;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        itemlocation
      }
    });
  };
  quantityChange = (e, index) => {
    const selected_qty = e.target.value;
    const recevingqty = [...this.state.data.recevingqty];
    const totalqty = [...this.state.data.totalqty];
    const receivedqty = [...this.state.data.receivedqty];
    recevingqty[index] = selected_qty;
    totalqty[index] =
      parseFloat(receivedqty[index]) + parseFloat(recevingqty[index]);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        recevingqty,
        totalqty
      }
    });
  };
  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
    });
  };
  handleValidSubmit = () => {
    this.dataHandler(true);
    let data = {};
    let AddedIteminfo = [];
    const {
      poid,
      ponumber,
      poitemid,
      itemid,
      itemuom,
      itemquantity,
      receivedqty,
      recevingqty,
      itemlocation,
      totalqty,
      date
    } = this.state.data;
    data.ponumber = ponumber;
    data.poid = poid;
    itemid.forEach(function(id, i) {
      let obj = {};
      obj.poitemid = poitemid[i];
      obj.itemid = itemid[i];
      obj.itemuom = itemuom[i];
      obj.itemquantity = itemquantity[i];
      obj.receivedqty = receivedqty[i];
      obj.recevingqty = recevingqty[i];
      obj.itemlocation = itemlocation[i];
      obj.totalqty = totalqty[i];
      AddedIteminfo.push(obj);
    });
    data.iteminfo = AddedIteminfo;
    this.props
      .itemlistsubmit(data)
      .then(data => {
        this.onShowAlert();
        this.dataHandler(false);
        this.setState({
          success: true,
          loading: false,
          receiptnumber: data.receiptnumber
        });
      })
      .catch(err => {
        this.dataHandler(false);
        this.setState({ errors: err.response.data.error, loading: false });
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  dataHandler = value => {
    this.setState({ loadingvalue: value });
  };

  render() {
    // const { selectedItem } = this.props;
    const {
      loading,
      data,
      success,
      errors,
      selectedItem,
      receiptnumber,
      visible,
      loadingvalue
    } = this.state;
    const tableBody = selectedItem.map((itemlist, i) => {
      let nowDate = new Date();
      let date =
        nowDate.getDate() +
        "/" +
        (nowDate.getMonth() + 1) +
        "/" +
        nowDate.getFullYear();
      return (
        <tr key={itemlist.poitemid}>
          <td>{i + 1}</td>
          <td>{itemlist.itemname}</td>
          <td>{itemlist.itemdescription}</td>
          <td>
            <AvField
              type="select"
              name={`uom${i}`}
              onChange={this.onChange}
              required={true}
              value={itemlist.itemuom || " "}
            >
              <option value="">uom</option>
              {this.createSelectUomItems()}
            </AvField>
          </td>
          <td>{itemlist.quantity}</td>
          <td>{itemlist.receivedqty}</td>
          <td>
            <AvField
              name={`quantity${i}`}
              type="number"
              id={`quantity${i}`}
              value={itemlist.recevingqty.toString() || null}
              min={0}
              max={itemlist.recevingqty}
              onChange={e => this.quantityChange(e, i)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter Item Quantity"
                },
                minLength: {
                  value: 1,
                  errorMessage: "quantity value is required"
                },
                pattern: {
                  value: "^[0-9.]*$",
                  errorMessage: "Please enter valid quantity"
                }
              }}
            />
          </td>
          <td>
            <AvField
              type="select"
              name={`location${i}`}
              onChange={e => this.locationChange(e, i)}
              required={true}
              value={itemlist.itemlocation}
            >
              {this.createSelectLocationItems()}
            </AvField>
          </td>
          <td>{date}</td>
        </tr>
      );
    });

    return (
      <div className="container mg-t-20">
        {loadingvalue ? <CustomLoader /> : null}
        {success && (
          // <Alert color="info" isOpen={visible}>
          //   Receipt {receiptnumber} created Successfully
          // </Alert>
          <Modal
            isOpen={success}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>Item Type</ModalHeader>
            <ModalBody>Receipt {receiptnumber} created Successfully</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        )}
        {!!errors.global ? <Alert color="danger">{errors.global}</Alert> : null}
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
        >
          <Card>
            <CardBody>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>SI No</th>
                    <th>Item Name</th>
                    <th>Item Description</th>
                    <th>UOM</th>
                    <th>PO Qty</th>
                    <th>Received Qty</th>
                    <th>Receiving Qty</th>
                    <th>location</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>{tableBody}</tbody>
              </table>
              <div className="mg-20">
                <Button
                  color="primary"
                  disabled={loading}
                  className="lgn-loader"
                >
                  Submit
                  {/* {loading} */}
                </Button>
              </div>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationlistvalue: state.locationlistvalue,
    uomlistvalues: state.uomlistvalue
  };
}

export default connect(
  mapStateToProps,
  null
)(EditTable);
