import axios from 'axios';

export default {
  production: {
    addproduction: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/production/addproduction`, {
          data
        })
        .then(res => {
          return res.data.productiondata;
        });
    },
    getproduction: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/production/getproduction`)
        .then(res => {
          return res.data.productionlist;
        });
    },
    searchquotation: customerid => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/quotation/searchquotation?q=${customerid}`
        )
        .then(res => {
          return res.data.customerquotationlist;
        });
    }
  }
};
