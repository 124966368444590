import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import UpdateMaterialForm from "./MaterialForms/UpdateMaterialForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatematerialdetails, getMaterial } from "../../actions/Product";

class updateMaterial extends React.Component {
  state = {
    materialvalues: ""
  };

  componentDidMount() {
    this.props.getMaterial();
    const materialid = this.props.match.params.id;
    const materialvalues = this.props.materiallistvalues[materialid];
    this.setState({ materialvalues });
  }

  submit = data => {
    return this.props.updatematerialdetails(data).then(data => {
      this.props.getMaterial();
      return data;
    });
  };
  render() {
    const { materialvalues } = this.state;
    return (
      <div className="container mg-20">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/inventory_page">Inventory</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/additem">Add Item</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Update Item </BreadcrumbItem>
        </Breadcrumb>
        <UpdateMaterialForm
          submit={this.submit}
          materialvalues={materialvalues}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    materiallistvalues: state.materiallistvalues
  };
}

export default connect(
  mapStateToProps,
  { updatematerialdetails, getMaterial }
)(updateMaterial);
