import axios from "axios";

export default {
  uom: {
    getUomDetails: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/unitofmeasure/getuom`)
        .then(res => {
          return res.data.uomlist;
        });
    }
  },
  transaction: {
    addMasterItemType: data => {
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/transaction/addmasteritemtype`,
          {
            data
          }
        )
        .then(res => {
          return res.data.masterItemType;
        });
    },
    getMasterItemType: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/transaction/getmasteritemtype`)
        .then(res => {
          return res.data.masteritemtypes;
        });
    },
    updateMasterItemType: data => {
      return axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/transaction/updatemasteritemtype?id=${data.id}`,
          {
            data
          }
        )
        .then(res => {
          return res.data.success;
        });
    }
  },
  transactionMasterItemList: {
    addMasterItemList: data => {
      return axios({
        url: `${process.env.REACT_APP_BASE_URL}/transaction/masterItemList/addmasteritemlist`,
        method: "POST",
        data: data,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {
        return res.data.masterItemList;
      });
    },
    getMasterItemList: () => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/transaction/masterItemList/getmasteritemlist`
        )
        .then(res => {
          return res.data.masteritemlist;
        });
    },
    updatemasteritemlist: data => {
      return axios({
        url: `${process.env.REACT_APP_BASE_URL}/transaction/masterItemList/updatemasteritemlist?id=${data.id}`,
        method: "PATCH",
        data,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {
        return res.data.masterItemList;
      });
    },
    deletemasteritemlist: id => {
      return axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/transaction/masterItemList/deletemasteritemlist?id=${id}`
      )
    }
  },
  purchaseorder: {
    addPurchaseOrder: data => {
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/purchaseorder/addpurchaseorder`,
          {
            data
          }
        )
        .then(res => {
          return res.data.purchaseorderlist;
        });
    },
    updatePurchaseOrder: data =>{
      console.log(data)
      return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/purchaseorder/updatepo`,
        {
          data
        }
      )
      .then(res => {
        return res;
      });
    },
    getPurchaseOrderList: () => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/purchaseorder/getpurchaseorderrlist`
        )
        .then(res => {
          return res.data.purchaseorderlist;
        });
    },
    searchponumber: data => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/purchaseorder/searchpurchaseorderrlist/?q=${data.ponumber}`
        )
        .then(res => {
          return res.data.purchaseordervalue;
        });
    }
  },
  addorupdateitemsinlocation: {
    addorupdateitemsinlocation: data => {
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/addorupdateitemsinlocation/addorupdateitems`,
          {
            data
          }
        )
        .then(res => {
          return res.data.success;
        });
    },
    additemsinlocation: data => {
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/addorupdateitemsinlocation/additems`,
          {
            data
          }
        )
        .then(res => {
          return res.data.success;
        });
    },
    getitemlistavaliable: data => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/addorupdateitemsinlocation/getitemlistavaliable/?locationid=${data.locationid}&itemid=${data.itemid}`
        )
        .then(res => {
          return res.data.totalitemlistvalues;
        });
    }
  },
  receipt: {
    getReceiptList: data => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/receiptinfo/getreceiptinfolist/?q=${data.ponumber}`
        )
        .then(res => {
          return res.data.receiptlist;
        });
    }
  }
};
