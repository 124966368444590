import { normalize } from "normalizr";
import api from "../Services/api";
import {
  MATERIAL_OPTIONS,
  MATERIAL_VALUES_FETCHED,
  PRODUCT_VALUES_FETCHED,
  PRODUCT_VALUES_DELETED
} from "../Services/types";
import {
  materialOptionSchema,
  materialListSchema,
  productListSchema
} from "../Schemas/schemas";

export const productvalueFetched = productlistvalues => {
  return {
    type: PRODUCT_VALUES_FETCHED,
    productlistvalues
  };
};
export const materialoptionFetched = materialoption => {
  return {
    type: MATERIAL_OPTIONS,
    materialoption
  };
};

export const materialsFetched = materialsvalues => {
  return {
    type: MATERIAL_VALUES_FETCHED,
    materialsvalues
  };
};

export const addproduct = data => {
  return dispatch => {
    return api.product.addproduct(data);
  };
};

export const getproducts = data => {
  return dispatch => {
    return api.product.getproducts(data).then(data => {
      const productlistvalues = data.productdata;
      return dispatch(
        productvalueFetched(normalize(productlistvalues, [productListSchema]))
      );
    });
  };
};

export const deleteProduct = id => {
  return dispatch => {
    return api.product.deleteproduct(id).then(deletedData => {
      return dispatch({
        type: PRODUCT_VALUES_DELETED,
        payload: id
     })
    })
  };
}


export const updateproduct = data => {
  return dispatch => {
    return api.product.updateproduct(data);
  };
};

export const addMaterialTypes = data => {
  return dispatch => {
    return api.material.addmaterialtype(data);
  };
};

export const updateMaterialType = data => {
  return dispatch => {
    return api.material.updatematerialtype(data);
  };
};

export const getMaterialsTypes = () => {
  return dispatch => {
    return api.material.getmaterialstype().then(materiallist => {
      const materialsvalue = materiallist.materialsdata;
      return dispatch(
        materialoptionFetched(normalize(materialsvalue, [materialOptionSchema]))
      );
    });
  };
};

export const addMaterial = data => {
  return dispatch => {
    return api.material.addmaterial(data);
  };
};

export const getMaterial = () => {
  return dispatch => {
    return api.material.getmaterial().then(materiallist => {
      const materialslistvalue = materiallist.materialsdata;
      return dispatch(
        materialsFetched(normalize(materialslistvalue, [materialListSchema]))
      );
    });
  };
};

export const updatematerialdetails = data => {
  return dispatch => {
    return api.material.updatematerial(data);
  };
};
