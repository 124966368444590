import { ADD_QUOTATION, QUOTATION_FETCHED } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case QUOTATION_FETCHED:
    case ADD_QUOTATION:
      return {
        ...state,
        ...action.quotationlist.entities.quotationlistvalues
      };
    default:
      return state;
  }
}
