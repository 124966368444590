import { normalize } from "normalizr";
import api from "../Services/locationapi";
import { Location_LIST } from "../Services/types";
import { locationListSchema } from "../Schemas/schemas";

function locationListFetched(locationlist) {
  return {
    type: Location_LIST,
    locationlist
  };
}

export const getLocationList = () => {
  return dispatch => {
    return api.location.getLocationList().then(locationlist => {
      const locationlistvalue = locationlist.locationdata;
      return dispatch(
        locationListFetched(normalize(locationlistvalue, [locationListSchema]))
      );
    });
  };
};
