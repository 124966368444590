import React from 'react';
import logo from '../../../../Images/logo.png';
import client from '../../../../Images/client.png';

import { connect } from 'react-redux';
import moment from 'moment';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import {
  TableCell,
  Table,
  TableHeader,
  TableBody,
  DataTableCell
} from '@david.kucsai/react-pdf-table';
import styled from '@react-pdf/styled-components';

// Create styles
const styles = StyleSheet.create({
  textcenter: { textAlign: 'center' },
  textright: { textAlign: 'right' },
  textleft: { textAlign: 'left' },
  paddingleft: { paddingLeft: 5 },
  margin: {
    margin: 5
  },
  pagepadding: {
    padding: 20
  },
  section: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  headerfont3: {
    textAlign: 'left',
    padding: 3,
    fontSize: 10
  },
  headerfont4: {
    textAlign: 'left',
    padding: 3,
    fontSize: 10
  },
  section_column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  myHeight: {
    height: '100px'
  },
  headerfont: {
    fontSize: 10,
    fontWeight: 'bold'
  },
  descriptionText: {
    fontSize: 10,
    fontWeight: 'normal'
  },
  headerfont2: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  headerfontleft: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left'
  },
  billingaddress: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    height: 70
  },
  shippingaddress: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    height: 130
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dcaddress: {
    fontSize: 10
  },
  dcaddress_left: {
    fontSize: 10
  },
  view_border: {
    border: '1pt solid #000',
    flexGrow: 1,
    height: 100
  },
  column: {
    width: 190
  },
  headerwidth: {
    width: 210,
    padding: 20
  },
  headerwidthdate: {
    width: 210,
    paddingTop: 23,
    fontSize: 10
  },
  headerwidthlogo: {
    width: 350,
    fontSize: 10
  },
  logo: {
    width: 70
  },
  header_name: {
    width: 350,
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    paddingLeft: 100
  },
  header_sub_name: {
    width: '100%',
    fontSize: 10,
    textAlign: 'center'
  },
  header_sub_name_phone: {
    width: '100%',
    fontSize: 10,
    fontWeight: 'bolder',
    textAlign: 'center'
  },
  header_tintext: {
    fontSize: 10,
    fontWeight: 900
  },
  header_sub_name3: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerwidthAddress: {
    width: 260,
    fontSize: 10,
    padding: 2,
    textAlign: 'right'
  },
  refstyle: {
    paddingBottom: 20,
    fontSize: 10
  },
  headerpadding: {
    padding: 20
  },
  flexgrow: {
    flexGrow: 1
  },
  fontsize18: {
    fontSize: 10
  },
  kindattend: {
    paddingTop: 15,
    fontSize: 10
  },
  yourref: {
    fontSize: 13,
    paddingBottom: 10
  },
  main: {
    fontSize: 10
  },
  tableheaderbgcolor: {
    backgroundColor: 'red',
    color: 'red',
    background: 'red'
  },
  footerfixed: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
});

const Small = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, padding: 5 }}>{children}</Text>
  )
}
const Description = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, padding: 5, textAlign: 'left', wordWrap: 'break-word' }}>{children}</Text>
  )
}

const SmallBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>{children}</Text>
  )
}

// Create Document Component

const MyDocument = ({ orderlistvalues, data_cond }) => {
  console.log(JSON.stringify(orderlistvalues));
  let data = orderlistvalues.addeditemlist;
  let totalwt = 0
  if(data.length > 0){
   
    for(var i=0;i<data.length;i++){
      totalwt += parseFloat(data[i].totalweight)
    }
  }
  console.log('data');
  console.log(totalwt);
  totalwt = parseFloat(totalwt)
  totalwt = totalwt.toFixed(2)
  const totalrs = orderlistvalues.totalvalue ? orderlistvalues.totalvalue : '';
  const taxableAmount = orderlistvalues.taxableamount;
  const date = moment(orderlistvalues.date).format('DD MMM, YYYY');
  const podate = moment(orderlistvalues.podate).format('DD MMM, YYYY');
  const purchaseorderdate = moment(orderlistvalues.purchaseorderdate).format(
    'DD MMM, YYYY'
  );
  const billingaddress = orderlistvalues.billingaddress;
  const vendorcode = orderlistvalues.vendorcode;
  const {
    freightcharges,
    subtotal,
    cgst,
    sgst,
    igst,
    total,
    grandtotal,
    deliverychallannumber,
    goodsDescription,
    invoicenumber,
    roundoffamount
  } = orderlistvalues;

  let {
    workorderno,
    companydetails,
    contactperson,
    officeno,
    deliveryschedule,
    orderdate,
    gstno,
    deliveryaddress,
    distance,
    sitecontactperson,
    pono
  } = orderlistvalues;
  //orderdate = new Date(orderdate)
  const purchaseordernumber = orderlistvalues.purchaseordernumber;

  const tax = orderlistvalues.tax ? orderlistvalues.tax : '';
  const taxpercentage = orderlistvalues.tax ? orderlistvalues.tax / 100 : '';
  // const grandtotal = taxpercentage * totalrs;
  //   const selectecontactlist = orderlistvalues.selectedcontactlist[0]
  //     ? orderlistvalues.selectedcontactlist[0].suppliercontactname
  //     : "";
  const cond_data = data_cond;
  const tableComponentTwo = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i] && data[i + 1]) {
      tableComponentTwo.push([data[i], data[i + 1]]);
    } else {
      tableComponentTwo.push([data[i]]);
    }
    ++i;
  }
let pageone = [];
let pageTwo = [];
let pageoneislast = false;
let pagetwoislast = false;


data.forEach((ele, i) => {
  if (i == 0 && data.length > 1) {
      if(ele.itemname.length < 2383) {
          pageone.push(ele);
          if (ele.itemname.length + data[i + 1].itemname.length < 2383) {
              pageone.push(data[i + 1]);
              if (data.length > 2) {
                  if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length < 2383) {
                      pageone.push(data[i + 2]);
                      if (data.length > 3) {
                          if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length < 2383) {
                              pageone.push(data[i + 3]);
                              if (data.length > 4) {
                                  if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length + data[i + 4].itemname.length < 2383) {
                                      pageone.push(data[i + 4]);
                                      if (data.length > 5) {
                                        if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length + data[i + 4].itemname.length + data[i + 5].itemname.length < 2383) {
                                          pageone.push(data[i + 5]);
                                          if (data.length > 6) {
                                            if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length + data[i + 4].itemname.length + data[i + 5].itemname.length + data[i + 6].itemname.length < 2383) {
                                              pageone.push(data[i + 6]);
                                            }
                                          }
                                        }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      if (pageone.length > 0) {
          if (data.length > pageone.length) {
              pagetwoislast = true;
              if (data[pageone.length].itemname.length < 2723) {
                  pageTwo.push(data[pageone.length])
              }
              if(data.length > (pageone.length + 1) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length < 2723) {
                  pageTwo.push(data[pageone.length + 1])
              }
              if(data.length > (pageone.length + 2) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length < 2723) {
                  pageTwo.push(data[pageone.length + 2])
              }
              if(data.length > (pageone.length + 3) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length < 2723) {
                  pageTwo.push(data[pageone.length + 3])
              }
              if(data.length > (pageone.length + 4) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length + data[pageone.length + 4].itemname.length < 2723) {
                  pageTwo.push(data[pageone.length + 4])
              }
              if(data.length > (pageone.length + 5) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length + data[pageone.length + 4].itemname.length + data[pageone.length + 5].itemname.length < 2723) {
                  pageTwo.push(data[pageone.length + 5])
              }
              if(data.length > (pageone.length + 6) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length + data[pageone.length + 4].itemname.length + data[pageone.length + 5].itemname.length + data[pageone.length + 6].itemname.length < 2723) {
                  pageTwo.push(data[pageone.length + 6])
              }                
          }
          else {
            pageoneislast = true;
          }
      }
  }
  else if((i == 0 && data.length == 1)) {
      pageone.push(data[i]);
      pageoneislast = true;
  }
});


let pageoneTable;
let pagetwoTable;
let pagethreeTable;


if (pageone.length > 0) {
  pageoneTable = pageone.map((pageonedata, index) => {
    if(index == 0) {
      return (
          <Table
          style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
          data={pageone}
          >
          <TableHeader
            textAlign={'center'}
            style={{ backgroundColor: 'tomato' }}
          >
            <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
              <Text style={styles.headerfont3}>SL</Text>
            </TableCell>
            <TableCell weighting={0.7}>
              <Text style={styles.headerfont3}>Material</Text>
            </TableCell>
            <TableCell weighting={0.3}>
              <Text style={styles.headerfont3}>Total weight</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>qty</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>Ready stock</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>1st trip</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>2nd trip</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>3rd trip</Text>
            </TableCell>
            <TableCell weighting={0.3}>
              <Text style={styles.headerfont3}>Remarks</Text>
            </TableCell>
          </TableHeader>
          <TableBody textAlign={'center'}>
            <DataTableCell
              weighting={0.08}
            
              getContent={r => <Text style={styles.headerfont4}>{r.slno}</Text>}
            />
            <DataTableCell
              
              weighting={0.7}
              getContent={r => <Text style={styles.headerfont4}>{r.itemname}</Text>}
            />
            <DataTableCell  weighting={0.3} getContent={r => <Text style={styles.headerfont4}>{r.totalweight}</Text>} />
            <DataTableCell weighting={0.2} getContent={r => <Text style={styles.headerfont4} >{r.quantity}</Text>} />
            <DataTableCell weighting={0.2} getContent={r => <Text style={styles.headerfont4} >{r.readystock}</Text>} />
            <DataTableCell weighting={0.2} getContent={r => <Text style={styles.headerfont4} >{r.firstround}</Text>} />
            <DataTableCell  weighting={0.2} getContent={r => <Text style={styles.headerfont4}>{r.secondround}</Text>} />
            <DataTableCell  weighting={0.2} getContent={r => <Text style={styles.headerfont4}>{r.thirdround}</Text>} />
            <DataTableCell  weighting={0.3} getContent={r => <Text style={styles.headerfont4}>{r.remarks}</Text>} />
          </TableBody>
          </Table>
      )
    }
  })
}


if (pageTwo.length > 0) {
  pagetwoTable = pageTwo.map((pagetwodata, index) => {
    if(index == 0) {
      return (
      <Page size='A4' style={styles.pagepadding} wrap>
        <View style={styles.section}>
          <Image src={logo} />
          <Text style={styles.logo}></Text>
          <Text style={styles.headerwidthAddress}>
          <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>
        <Text style={{ fontSize: 14, fontWeight: 900, textAlign: 'center', margin: 8 }}>WORK ORDER</Text>
        <Text style={{ fontSize: 12, fontWeight: 500, textAlign: 'right', margin: 8 }}>Workorder Date : {orderdate}</Text>
        <View>
          <Table
          style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
          data={pageTwo}
          >
          <TableHeader
            textAlign={'center'}
            style={{ backgroundColor: 'tomato' }}
          >
            <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
              <Text style={styles.headerfont3}>SL</Text>
            </TableCell>
            <TableCell weighting={0.7}>
              <Text style={styles.headerfont3}>Material</Text>
            </TableCell>
            <TableCell weighting={0.3}>
              <Text style={styles.headerfont3}>Total weight</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>qty</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>Ready stock</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>1st trip</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>2nd trip</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont3}>3rd trip</Text>
            </TableCell>
            <TableCell weighting={0.3}>
              <Text style={styles.headerfont3}>Remarks</Text>
            </TableCell>
          </TableHeader>
          <TableBody textAlign={'center'}>
            <DataTableCell
              weighting={0.08}
            
              getContent={r => <Text style={styles.headerfont4}>{r.slno}</Text>}
            />
            <DataTableCell
              
              weighting={0.7}
              getContent={r => <Text style={styles.headerfont4}>{r.itemname}</Text>}
            />
            <DataTableCell  weighting={0.3} getContent={r => <Text style={styles.headerfont4}>{r.totalweight}</Text>} />
            <DataTableCell weighting={0.2} getContent={r => <Text style={styles.headerfont4} >{r.quantity}</Text>} />
            <DataTableCell weighting={0.2} getContent={r => <Text style={styles.headerfont4} >{r.readystock}</Text>} />
            <DataTableCell weighting={0.2} getContent={r => <Text style={styles.headerfont4} >{r.firstround}</Text>} />
            <DataTableCell  weighting={0.2} getContent={r => <Text style={styles.headerfont4}>{r.secondround}</Text>} />
            <DataTableCell  weighting={0.2} getContent={r => <Text style={styles.headerfont4}>{r.thirdround}</Text>} />
            <DataTableCell  weighting={0.3} getContent={r => <Text style={styles.headerfont4}>{r.remarks}</Text>} />
          </TableBody>
          </Table>
          <Text>{'\n'}</Text>
          <Text style={styles.headerfont4}>Total Weight : {totalwt}</Text>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>

          <View style={styles.header_sub_name3}>
            <Text style={styles.header_tintext}>Managing Director</Text>
            <Text style={styles.header_tintext}>Manger</Text>
            <Text style={styles.header_tintext}>Prepared by</Text>
          </View>
          {/* ends here */}
        </View>
        </Page>
      )
    }
  })
}


  const datalength = tableComponentTwo.length - 1;
  const tableComponent = tableComponentTwo.map((data, i) => {
    if (i >= 1 && i === datalength) {
      return (
        <Page size='A4' style={styles.pagepadding} wrap key={i}>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => {
                  return r.weighteach;
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Taxable Amount</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{taxableAmount}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>GST %</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{tax}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Grand Total Rs</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>
                  {(grandtotal + +totalrs).toFixed(2)}
                </Text>
              </TableCell>
            </TableHeader>
          </Table>
        </Page>
      );
    } else if (i >= 1) {
      return (
        <Page size='A4' style={styles.pagepadding} wrap key={i}>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.weighteach} />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
        </Page>
      );
    } else if (i < 1 && i === datalength) {
      return (
        <View>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>Slno</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>Particulars</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>Remarks</Text>
              </TableCell>
            </TableHeader>
          </Table>
          {/* 8 starts here */}
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>1</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text>Company Name & address</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>{companydetails}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>2</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text>Contact person</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>{contactperson}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>3</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text>Off / Mob no</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>{officeno}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>4</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text>Delivery Schedule</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>{deliveryschedule}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>5</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text>Delivery address</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>{deliveryaddress}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>6</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text>Site contact person</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>{contactperson}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>7</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text>GST No</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>{gstno}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={{ textAlign: 'center' }}>8</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text>PO No</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={{ textAlign: 'center' }}>{pono}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
            key={i}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Material</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total weight</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Ready stock</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>1st trip</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>2nd trip</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>3rd trip</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont}>Remarks</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => r.itemname}
              />
              <DataTableCell weighting={0.2} getContent={r => r.totalweight} />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.readystock} />
              <DataTableCell weighting={0.2} getContent={r => r.firstround} />
              <DataTableCell weighting={0.2} getContent={r => r.secondround} />
              <DataTableCell weighting={0.2} getContent={r => r.thirdround} />
              <DataTableCell weighting={0.4} getContent={r => r.remarks} />
            </TableBody>
          </Table>
          
          <Text>{'\n'}</Text>
          
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>
          <View style={styles.header_sub_name3}>
            <Text style={styles.header_tintext}>Managing Director</Text>
            <Text style={styles.header_tintext}>Manger</Text>
            <Text style={styles.header_tintext}>Prepared by</Text>
          </View>
          {/* ends here */}
        </View>
      );
    } else {
      return (
        <View>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
            key={i}
          >
            <TableHeader textAlign={'center'} style={styles.tableheaderbgcolor}>
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.weighteach} />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
        </View>
      );
    }
  });

  return (
    <Document>
      <Page size='A4' style={styles.pagepadding} wrap>
        <View style={styles.section}>
          <Image src={logo} />
          <Text style={styles.logo}></Text>
          <Text style={styles.headerwidthAddress}>
          <SmallBold>Office & Works</SmallBold>{'\n'}
            Plot no.40 & 41 (corner) Road, no. 5,{'\n'}
            KIADB Obadenahalli industrial Area,{'\n'}
            3rd phase Doddaballapura,{'\n'}
            Bangalore Rural District.-561203{'\n'}
            Karnataka, INDIA{'\n'}
            GST No. 29AAGCP4018E1ZJ{'\n'}
            Mob: +91 9900069150{'\n'}
            Email id: info@pondhan.com{'\n'}
            Visit Us: www.pondhan.com{'\n'}
          </Text>
        </View>
        <Text style={{ fontSize: 14, fontWeight: 900, textAlign: 'center', margin: 8 }}>WORK ORDER</Text>
        <Text style={{ fontSize: 12, fontWeight: 500, textAlign: 'right', margin: 8 }}>Workorder Date : {orderdate}</Text>
        <View>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>Slno</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={[styles.headerfont3, { textAlign: 'left' }]}>Particulars</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={[styles.headerfont3, { textAlign: 'left' }]}>Remarks</Text>
              </TableCell>
            </TableHeader>
          </Table>
          {/* 8 starts here */}
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>1</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Order ID</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{workorderno}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>2</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Company Name & address</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{companydetails}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>3</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Contact person</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{contactperson}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>4</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Off / Mob no</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{officeno}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          {/* <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>5</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Workorder Date</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{orderdate}</Text>
              </TableCell>
            </TableHeader>
          </Table> */}

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>5</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Delivery Schedule</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{deliveryschedule}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>6</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Delivery address</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{deliveryaddress}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>7</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Site contact person</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{contactperson}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>8</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>GST No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{gstno}</Text>
              </TableCell>
            </TableHeader>
          </Table>

          

          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.1}>
                <Text style={styles.headerfont3}>9</Text>
              </TableCell>
              <TableCell weighting={0.4}>
                <Text style={styles.headerfont4}>Distance</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont4}>{distance} Km</Text>
              </TableCell>
            </TableHeader>
          </Table>

          
          <Text>{'\n'}</Text>

          {pageoneTable}

          <Text>{'\n'}</Text>
          <Text style={styles.headerfont4}>Total Weight : {totalwt}</Text>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>

          <View style={styles.header_sub_name3}>
            <Text style={styles.header_tintext}>Managing Director</Text>
            <Text style={styles.header_tintext}>Manger</Text>
            <Text style={styles.header_tintext}>Prepared by</Text>
          </View>
          {/* ends here */}
        </View>
      </Page>
      {pagetwoTable}
    </Document>
  );
};

export default MyDocument;
