import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { connect } from 'react-redux';
import { getChallan } from '../../../../actions/challan';
import { Button, Alert } from 'reactstrap';
import DataTable from '../../../../Components/Table/DatatablePage';
import CustomLoader from '../../../../Components/loader/loader';
import {
  getMasterItemList,
  getMasterItemTypes
} from '../../../../actions/Transaction';
import { getUomList } from '../../../../actions/uom';
import { getCustomerList } from '../../../../actions/customerContact';
class ViewChallan extends React.Component {
  state = {
    count: 0,
    loading: true,
    challanlistvalues: []
  };
  componentDidMount() {
    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getChallan();
    
    console.log(this.props);
    if (this.props.challanlistvalues) {
      let quotationlist = Object.values(this.props.challanlistvalues);

      quotationlist.sort(function(a, b){
        return parseInt(b.deliverychallanno) - parseInt(a.deliverychallanno);
      });
      const challanlistvalues = quotationlist.map(quotationvalue => {
        return {
          deliverychallanno: quotationvalue.deliverychallanno,
          ponumber: quotationvalue.ponumber,
          vehiclenumber: quotationvalue.vehiclenumber,
          view: (
            <div className="actionbuttons">
            <Button
              Label
              color='primary'
              onClick={() => {
                this.updateButton(quotationvalue._id);
              }}
            >
              Print
            </Button>

            <Button
            Label
            color='primary'
            onClick={() => {
              this.updatechallan(quotationvalue._id);
            }}
            >
          Edit
          </Button>
          </div>
          )
        };
      });
      this.setState({ ...this.state, challanlistvalues });
      this.dataHandler(false);
    }
  }
  componentWillReceiveProps(props) {
    let quotationlist = Object.values(props.challanlistvalues);
    //quotationlist= quotationlist.sort((a, b) => b.deliverychallanno.toLowerCase().localeCompare(a.deliverychallanno.toLowerCase()));
    quotationlist.sort(function(a, b){
      return parseInt(b.deliverychallanno) - parseInt(a.deliverychallanno);
    });
    const challanlistvalues = quotationlist.map(quotationvalue => {
      return {
        deliverychallanno: quotationvalue.deliverychallanno,
        ponumber: quotationvalue.ponumber,
        vehiclenumber: quotationvalue.vehiclenumber,
        view: (
          <div className="actionbuttons">
            <Button
              Label
              color='primary'
              onClick={() => {
                this.updateButton(quotationvalue._id);
              }}
            >
              Print
            </Button>

            <Button
            Label
            color='primary'
            onClick={() => {
              this.updatechallan(quotationvalue._id);
            }}
            >
          Edit
          </Button>
          </div>
        )
      };
    });
    this.setState({ ...this.state, challanlistvalues });
    this.dataHandler(false);
  }

  updateButton = id => {
    // let path = `/view_invoicepdf/${id}`;
    // window.open(path, '_blank');
    let path = `/view_challanpdf/${id}`;
    //window.open(path, "_blank");
    this.props.history.push(path);
  };

  updatechallan(id) {
    let path = `/editchallan/${id}`;
    this.props.history.push(path);
  }

  dataHandler = value => {
    this.setState({ loading: value });
  };

  render() {
    const { loading, count, challanlistvalues } = this.state;
    console.log(challanlistvalues)
    const columnvalue = [
      {
        label: 'Delivery Challan',
        field: 'deliverychallanno',
        sort: 'asc',
        width: 40
      },
      {
        label: 'PO Number',
        field: 'ponumber',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Vehicle Number',
        field: 'vehiclenumber',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Print/Edit',
        field: 'view',
        sort: 'asc',
        width: 40
      }
    ];
    return (
      <div className='container mg-t-20'>
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/admin_dashboard'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/transaction_page'>Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/delivery-challan'>Delivery Challan</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>List</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <DataTable 
          rowvalues={challanlistvalues} 
          columnvalue={columnvalue}
          className="challanlist" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    challanlistvalues: state.challanlistvalues
  };
}

export default connect(
  mapStateToProps,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    getCustomerList,
    getChallan 
    }
)(ViewChallan);
