import React from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Card, CardBody, CardHeader } from "reactstrap";

class ReceiptTable extends React.Component {
  state = {
    receiptlistitems: []
  };
  componentDidMount() {
    const receiptlistitems = this.props.itemlist;
    this.setState({
      ...this.state,
      receiptlistitems
    });
  }

  componentWillReceiveProps(props) {
    const receiptlistitems = props.itemlist;
    this.setState({
      ...this.state,
      receiptlistitems
    });
  }
  render() {
    const { receiptlistitems } = this.state;
    const receiptlisttable = receiptlistitems.map((receiptvalue, i) => {
      let loc = receiptvalue.itemlocation ? receiptvalue.itemlocation.locationname : '-'
      let nowDate = new Date(receiptvalue.date);
      let date =
        nowDate.getDate() +
        "/" +
        (nowDate.getMonth() + 1) +
        "/" +
        nowDate.getFullYear();
      return (
        <tr key={receiptvalue.id}>
          <td>{i + 1}</td>
          <td>{receiptvalue.itemid.masteritemname}</td>
          <td>{receiptvalue.itemid.masteritemdescription}</td>
          <td>{receiptvalue.itemuom.uomname}</td>
          <td>{receiptvalue.itemquantity}</td>
          <td>{receiptvalue.recevingqty}</td>
          <td>{loc}</td>
          <td>{date}</td>
        </tr>
      );
    });
    return (
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>SI No</th>
              <th>Item Name</th>
              <th>Item Description</th>
              <th>UOM</th>
              <th>PO Qty</th>
              <th>Received Qty</th>
              <th>location</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>{receiptlisttable}</tbody>
        </table>
      </div>
    );
  }
}

export default ReceiptTable;
