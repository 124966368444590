
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class BOMPage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/transaction_page'>Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>BOM</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
                

            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/addproduct">
                  <i className="fas fa-plus-circle fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Add BOM</span>
                  </div>
                </Link>
              </div>
            </li>
            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/viewproductlist">
                  <i className="fas fa-list fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>BOM List</span>
                  </div>
                </Link>
              </div>
            </li> 



            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default BOMPage;
