import { normalize } from 'normalizr';
import api from '../Services/moveorderapi';
import { ADD_MOVEORDER, MOVEORDER_FETCHED } from '../Services/types';
import { moveorderListSchema } from '../Schemas/schemas';

const moveorderCreated = moverorderlist => {
  return {
    type: ADD_MOVEORDER,
    moverorderlist
  };
};

const moveorderFetched = moverorderlist => {
  return {
    type: MOVEORDER_FETCHED,
    moverorderlist
  };
};

export const addMoveOrder = data => {
  return dispatch => {
    return api.moveorder.addmoveorder(data).then(moverorderlistvalues => {
      return dispatch(
        moveorderCreated(normalize(moverorderlistvalues, moveorderListSchema))
      );
    });
  };
};

export const getMoveOrder = () => {
  return dispatch => {
    return api.moveorder.getmoveorder().then(moverorderlist => {
      console.log(moverorderlist);
      const moverorderlistvalues = moverorderlist.moveorderdata;
      console.log(moverorderlistvalues);
      return dispatch(
        moveorderFetched(normalize(moverorderlistvalues, [moveorderListSchema]))
      );
    });
  };
};

export const getItemsMoveOrder = (id) => {
    return api.moveorder.getItemsMoveOrder(id).then(itemorderlist => {
      console.log(itemorderlist);
      const itemorderlistvalues = itemorderlist.itemdata;
      console.log(itemorderlistvalues);
      return itemorderlistvalues;
    });
}

export const searchquotation = customerid => {
  return dispatch => {
    return api.quotation.searchquotation(customerid);
  };
};
