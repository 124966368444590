import React from 'react';
import logo from '../../../../Images/logo.png';
import seal from '../../../../Images/seal.jpg';
import client from '../../../../Images/client.png';
import { connect } from 'react-redux';
import './ViewInvoice.scss';
import moment from 'moment';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import {
  TableCell,
  Table,
  TableHeader,
  TableBody,
  DataTableCell
} from '@david.kucsai/react-pdf-table';
import styled from '@react-pdf/styled-components';

// Create styles
const styles = StyleSheet.create({
  textcenter: { textAlign: 'center' },
  textright: { textAlign: 'right' },
  textleft: { textAlign: 'left' },
  paddingleft: { paddingLeft: 5 },
  margin: {
    margin: 5
  },
  brandname: {
    fontSize: 10,
    fontWeight: 600,
    paddingTop: 10,
    paddingBottom: 10
  },
  padding1: {
    paddingTop: 6
  },
  pagepadding: {
   padding:5,
   paddingLeft:10,
   paddingRight:10,

    // display: 'flex',
    // flexDirection: 'row',
    // overflow: 'hidden'
    border: 'none',
    boxShadow: 'none',
    border: '30px solid green',
    background:'#000'
  },
  section_column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  myHeight: {
    height: '100px'
  },
  headerfont: {
    fontSize: 10,
    fontWeight: 'bold',
    padding: 5
  },
  descriptionText: {
    fontSize: 10,
    fontWeight: 'normal',
    padding: 8,
    wordWrap: 'break-word'
  },
  headerfont2: {
    fontSize: 10,
    fontWeight: 'bold',
    padding: 5
  },
  headerfont4: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  headerfont5: {
    fontSize: 10,
    fontWeight: 'bold',
    paddingRight: 5
  },
  headerfont3: {
    fontSize: 10,
    paddingLeft: 8
  },
  headerfontleft: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    padding: 5
  },
  billingaddress: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    // height: 70,
    // maxWidth: 200,
    wordWrap: 'break-word',
    padding: 5
  },
  shippingaddress: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    // height: 130,
    // maxWidth: 200,
    wordWrap: 'break-word',
    padding: 5
  },
  row: {
    flexDirection: 'row',
    // justifyContent: 'space-between'
  },
  column: {
    width: '50%'
    // flex: 1
  },
  headerwidth: {
    // width: 210,
    // textAlign: 'center',
    marginLeft: 75,
    fontWeight: 900
  },
  headerwidthdate: {
    width: 210,
    paddingTop: 23,
    fontSize: 10
  },
  headerwidthlogo: {
    width: 350,
    fontSize: 10
  },
  logo: {
    width: 225,
    height: 90
  },
  seal: {
    width: 50,
    height: 50
  },
  headerwidthAddress: {
    fontSize: 10
  },
  refstyle: {
    paddingBottom: 20,
    fontSize: 10
  },
  headerpadding: {
    padding: 20
  },
  flexgrow: {
    flexGrow: 1
  },
  fontsize18: {
    fontSize: 10
  },
  kindattend: {
    paddingTop: 15,
    fontSize: 10
  },
  yourref: {
    fontSize: 13,
    paddingBottom: 10
  },
  main: {
    fontSize: 10
  },
  tableheaderbgcolor: {
    backgroundColor: 'red',
    color: 'red',
    background: 'red'
  },
  footerfixed: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  invoice_header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  nobordertable: {
    borderWidth: 0, borderRightWidth: 0, borderBottomWidth: 0
  }
});

const Small = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, padding: 5 }}>{children}</Text>
  )
}

const Description = ({ children }) => {
  return (
    <Text style={{
      fontSize: 10,
      padding: 5,
      wordWrap: 'break-word',
      paddingRight: 16,
      textAlign: 'left'
    }}>{children}</Text>
  )
}

const SmallBold = ({ children }) => {
  return (
    <Text style={{ fontSize: 10, padding: 5, fontWeight: 'bold' }}>{children}</Text>
  )
}


// Create Document Component

const MyDocument = ({ invoicelistvalues, data_cond }) => {
  const financialYear = invoicelistvalues.financialyear;
  let amountinwords = ''
  let Rs = function (amount) {
    var words = new Array();
    words[0] = 'Zero'; words[1] = 'One'; words[2] = 'Two'; words[3] = 'Three'; words[4] = 'Four'; words[5] = 'Five'; words[6] = 'Six'; words[7] = 'Seven'; words[8] = 'Eight'; words[9] = 'Nine'; words[10] = 'Ten'; words[11] = 'Eleven'; words[12] = 'Twelve'; words[13] = 'Thirteen'; words[14] = 'Fourteen'; words[15] = 'Fifteen'; words[16] = 'Sixteen'; words[17] = 'Seventeen'; words[18] = 'Eighteen'; words[19] = 'Nineteen'; words[20] = 'Twenty'; words[30] = 'Thirty'; words[40] = 'Forty'; words[50] = 'Fifty'; words[60] = 'Sixty'; words[70] = 'Seventy'; words[80] = 'Eighty'; words[90] = 'Ninety'; var op;
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++ , j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++ , j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split(" ").join(" ");
    }
    return words_string;
  }

  let RsPaise = function (n) {
    var op;
    var nums = n.toString().split('.')
    var whole = Rs(nums[0])
    if (nums[1] == null) nums[1] = 0;
    if (nums[1].length == 1) nums[1] = nums[1] + '0';
    if (nums[1].length > 2) { nums[1] = nums[1].substring(2, nums[1].length - 1) }
    if (nums.length == 2) {
      if (nums[0] <= 9) { nums[0] = nums[0] * 10 } else { nums[0] = nums[0] };
      var fraction = Rs(nums[1])
      if (whole == '' && fraction == '') { op = 'Zero only'; }
      if (whole == '' && fraction != '') { op = 'paise ' + fraction + ' only'; }
      if (whole != '' && fraction == '') { op = 'INR: ' + whole + ' only'; }
      if (whole != '' && fraction != '') { op = 'INR: ' + whole + 'and ' + fraction + ' paise only'; }
      amountinwords = op
    }
  }



  invoicelistvalues['total'] = Number(invoicelistvalues['total']).toFixed(2)
  console.log(JSON.stringify(invoicelistvalues));
  const data = invoicelistvalues.addeditemlist;
  console.log('data');
  console.log(data);
  const totalrs = invoicelistvalues.totalvalue
    ? invoicelistvalues.totalvalue
    : '';
  let taxableAmount = invoicelistvalues.taxableamount;
  taxableAmount = parseFloat(taxableAmount)
  taxableAmount = taxableAmount.toFixed(2)
  console.log(taxableAmount)
  const date = moment(invoicelistvalues.date).format('DD MMM, YYYY');
  const invoicedate = moment(invoicelistvalues.invoicedate).format(
    'DD MMM, YYYY'
  );
  const purchaseorderdate = moment(invoicelistvalues.purchaseorderdate).format(
    'DD MMM, YYYY'
  );
  const billingaddress = invoicelistvalues.billingaddress;
  const deliveryaddress = invoicelistvalues.deliveryaddress;
  const vendorcode = invoicelistvalues.vendorcode;
  let {
    freightcharges,
    subtotal,
    cgst,
    sgst,
    igst,
    tcs,
    tcsamount,
    total,
    grandtotal,
    quotationnumber,
    goodsDescription,
    invoicenumber,
    vehiclenumber,
    hsncode,
    saccode,
    roundoffamount,
    customername
  } = invoicelistvalues;
  let purchaseordernumber = invoicelistvalues.purchaseordernumber;

  const podate = invoicelistvalues.purchaseorderdate ? moment(invoicelistvalues.purchaseorderdate).format('DD MMM, YYYY') : '-'
  let customergstnumber = invoicelistvalues.customergstnumber && invoicelistvalues.customergstnumber != '' ? invoicelistvalues.customergstnumber : ''
  if(purchaseordernumber != '' && purchaseordernumber != null){
    if(purchaseordernumber.toString().lastIndexOf('$') > -1){
      purchaseordernumber = purchaseordernumber.toString().split('$')[0]
    }
  }
   
  const remarks = invoicelistvalues.remarks
  const tax = invoicelistvalues.tax ? invoicelistvalues.tax : '';
  const taxpercentage = invoicelistvalues.tax
    ? invoicelistvalues.tax / 100
    : '';




  freightcharges = freightcharges != '' && freightcharges != null ? parseFloat(freightcharges) : 0
  freightcharges = freightcharges.toFixed(2)
  subtotal = parseFloat(taxableAmount) + parseFloat(freightcharges)
  subtotal = parseFloat(subtotal)
  subtotal = subtotal.toFixed(2)
  
  let cgstval = cgst != '' && cgst != null ? (parseFloat(subtotal) * parseFloat(cgst)) / 100 : 0
  if (cgstval == 0)
    cgst = 0

  cgstval = cgstval.toFixed(2)
  let sgstval = sgst != '' && sgst != null ? (parseFloat(subtotal) * parseFloat(sgst)) / 100 : 0
  if (sgstval == 0)
    sgst = 0
  sgstval = sgstval.toFixed(2)
  let igstval = igst != '' && igst != null ? (parseFloat(subtotal) * parseFloat(igst)) / 100 : 0
  if (igstval == 0)
    igst = 0
  igstval = igstval.toFixed(2)

  let tcsval = tcsamount != '' && tcsamount != null ? parseFloat(tcsamount) : 0
  if (tcsval == 0)
    tcs = 0

  tcsval = tcsval.toFixed(2)


  total = parseFloat(subtotal) + parseFloat(cgstval) + parseFloat(sgstval) + parseFloat(igstval) + parseFloat(tcsval)
  total = total.toFixed(2)

  roundoffamount = roundoffamount != '' && roundoffamount != null ? parseFloat(roundoffamount) : 0
  roundoffamount = roundoffamount.toFixed(2)

  grandtotal = parseFloat(total) + parseFloat(roundoffamount)
  grandtotal = grandtotal.toFixed(2)

  RsPaise(grandtotal)

  let pageone = [];
  let pageTwo = [];
  let pageThree = [];

  data.forEach((ele, i) => {
    if (i == 0 && data.length > 1) {
        if(ele.itemname.length < 1021) {
            pageone.push(ele);
            if (ele.itemname.length + data[i + 1].itemname.length < 1021) {
                pageone.push(data[i + 1]);
                if (data.length > 2) {
                    if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length < 1021) {
                        pageone.push(data[i + 2]);
                        if (data.length > 3) {
                            if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length < 1021) {
                                pageone.push(data[i + 3]);
                                if (data.length > 4) {
                                    if (ele.itemname.length + data[i + 1].itemname.length + data[i + 2].itemname.length + data[i + 3].itemname.length + data[i + 4].itemname.length < 1021) {
                                        pageone.push(data[i + 4]);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (pageone.length > 0) {
            if (data.length > pageone.length) {
                if (data[pageone.length].itemname.length < 1361) {
                    pageTwo.push(data[pageone.length])
                }
                if(data.length > (pageone.length + 1) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length < 1361) {
                    pageTwo.push(data[pageone.length + 1])
                }
                if(data.length > (pageone.length + 2) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length < 1361) {
                    pageTwo.push(data[pageone.length + 2])
                }
                if(data.length > (pageone.length + 3) && data[pageone.length].itemname.length + data[pageone.length + 1].itemname.length + data[pageone.length + 2].itemname.length + data[pageone.length + 3].itemname.length < 1361) {
                    pageTwo.push(data[pageone.length + 3])
                }
            }
        }
        if(pageTwo.length > 0) {
            if (data.length > (pageone.length + pageTwo.length)) {
                if (data[pageone.length + pageTwo.length].itemname.length < 1361) {
                    pageThree.push(data[pageone.length  + pageTwo.length])
                }
                if(data.length > (pageone.length  + pageTwo.length + 1) && data[pageone.length  + pageTwo.length].itemname.length + data[pageone.length  + pageTwo.length + 1].itemname.length < 1361) {
                    pageThree.push(data[pageone.length  + pageTwo.length + 1])
                }
                if(data.length > (pageone.length  + pageTwo.length + 2) && data[pageone.length  + pageTwo.length].itemname.length + data[pageone.length  + pageTwo.length + 1].itemname.length + data[pageone.length  + pageTwo.length + 2].itemname.length < 1361) {
                    pageThree.push(data[pageone.length  + pageTwo.length + 2])
                }
                if(data.length > (pageone.length  + pageTwo.length + 3) && data[pageone.length  + pageTwo.length].itemname.length + data[pageone.length  + pageTwo.length + 1].itemname.length + data[pageone.length  + pageTwo.length + 2].itemname.length + data[pageone.length  + pageTwo.length + 3].itemname.length < 1361) {
                    pageThree.push(data[pageone.length  + pageTwo.length + 3])
                }
            }
        }
    }
    else if((i == 0 && data.length == 1)) {
        pageone.push(data[i]);
    }
});

let pageoneTable;
let pagetwoTable;
let pagethreeTable;

  if (pageone.length > 0) {
    pageoneTable = pageone.map((pageonedata, index) => {
      if(index == 0) {
        return (
        <Table
          style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
          data={pageone}
        >
          <TableHeader
            textAlign={'center'}
            style={{ backgroundColor: 'tomato' }}
          >
            <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
              <Small>SL No</Small>
            </TableCell>
            <TableCell weighting={0.6}>
              {/* style={styles.headerfont} */}
              <Description >Description</Description>
            </TableCell>
            <TableCell weighting={0.2}>
              <Small>UOM</Small>
            </TableCell>
            <TableCell weighting={0.2}>
              <Small>Qty</Small>
            </TableCell>
            <TableCell weighting={0.2}>
              <Small>Rate</Small>
            </TableCell>
            {/* <TableCell weighting={0.2}>
              <Text style={styles.headerfont}>Dist %</Text>
            </TableCell>
            <TableCell weighting={0.2}>
              <Text style={styles.headerfont}>
                After Dist Rate/Each{'\n'}Rs
              </Text>
            </TableCell> */}

            <TableCell weighting={0.2}>
              <Small>Amount</Small>
            </TableCell>
          </TableHeader>
          <TableBody textAlign={'center'}>
            <DataTableCell weighting={0.08} getContent={r => <Small>{r.slno}</Small>} />
            <DataTableCell styles={{ height: 100 }} weighting={0.6} getContent={r => <Description>{r.itemname}</Description>} />
            <DataTableCell weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
            <DataTableCell weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
            <DataTableCell weighting={0.2} getContent={r => <Small>{r.discountamount}</Small>} />
            {/* <DataTableCell weighting={0.2} getContent={r => <Small>{r.itemdiscount.toString()}</Small>} />
            <DataTableCell weighting={0.2} getContent={r => <Small>{r.itemdiscount.toString()}</Small>} />
          */}
            <DataTableCell weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
          </TableBody>
        </Table>
        )
      }
    });
  }

  if(pageTwo.length > 0) {
    pagetwoTable = pageTwo.map((pagetwodata, index) => {
      if(index == 0) {
        return (
          <Page style={styles.pagepadding} wrap>
          <View style={styles.invoice_header}>
            <Image style={styles.logo} src={logo} />
            <View style={{ flex: 1, paddingLeft: 25 }}>
              <Text style={ { fontWeight: 200, marginLeft: 75 }}>Office &amp; Works:</Text>
              <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>Plot no. 40 &amp;
			41(corner) Road, no.5, KIADB Obadenahalli Industrial Area, 3rd Phase</Text>
              <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>Doddaballapura, Bangalore
			Rural District-561203 Karnatak, India GST No. 29AAGCP4018E1ZJ</Text>
              <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>Mob:+91 9900069150</Text>
              <Text style={[styles.headerwidthAddress, { marginTop: 4, marginBottom: 4 }]}>Email id: info@pondhan.com, www.pondhan.com</Text>
            
            
            </View>
          </View>
          <View> 
         <Table
           style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
           data={pageTwo}
         >
           <TableHeader
             textAlign={'center'}
             style={{ backgroundColor: 'tomato' }}
           >
             <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
               <Small>SL No</Small>
             </TableCell>
             <TableCell weighting={0.6}>
               {/* style={styles.headerfont} */}
               <Description >Description</Description>
             </TableCell>
             <TableCell weighting={0.2}>
               <Small>UOM</Small>
             </TableCell>
             <TableCell weighting={0.2}>
               <Small>Qty</Small>
             </TableCell>
             <TableCell weighting={0.2}>
               <Small>Rate</Small>
             </TableCell>
             {/* <TableCell weighting={0.2}>
               <Text style={styles.headerfont}>Dist %</Text>
             </TableCell>
             <TableCell weighting={0.2}>
               <Text style={styles.headerfont}>
                 After Dist Rate/Each{'\n'}Rs
               </Text>
             </TableCell> */}
 
             <TableCell weighting={0.2}>
               <Small>Amount</Small>
             </TableCell>
           </TableHeader>
           <TableBody textAlign={'center'}>
             <DataTableCell weighting={0.08} getContent={r => <Small>{r.slno}</Small>} />
             <DataTableCell styles={{ height: 100 }} weighting={0.6} getContent={r => <Description>{r.itemname}</Description>} />
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.discountamount}</Small>} />
             {/* <DataTableCell weighting={0.2} getContent={r => <Small>{r.itemdiscount.toString()}</Small>} />
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.itemdiscount.toString()}</Small>} />
           */}
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
           </TableBody>
         </Table>        
         <Text> {'\n'}</Text>
         {/* Three divs for remarks, tax details and amount */}
         </View>
         
         {/* Two */}
         {/* <Table style={styles.section}>
           <TableHeader>
             <TableCell weighting={0.5}>
               <Text style={styles.headerfont3}>
                 {'\n'}
                 {'\n'}
 
               </Text>
             </TableCell>
             <TableCell weighting={0.5}>
     
             </TableCell>
           </TableHeader>
         </Table> */}
         {/* Three */}
         {/* <Table style={styles.section}>
           <TableHeader>
             <TableCell weighting={0.5}>
               <Text style={styles.headerfont3}>
               {amountinwords}
               </Text>
             </TableCell>
  
           </TableHeader>
         </Table> */}
         {/* ends here */}
         <Text> {'\n'}</Text>
         </Page> 
        );
      }
    })
  }


  if(pageThree.length > 0) {
    pagethreeTable = pageThree.map((pagethreedata, index) => {
      if(index == 0) {
        return (
          <Page style={styles.pagepadding} wrap>
          <View style={styles.invoice_header}>
            <Image style={styles.logo} src={logo} />
            <View style={{ flex: 1, paddingLeft: 25 }}>
              <Text style={ { fontWeight: 200, marginLeft: 75 }}>Office &amp; Works:</Text>
              <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>Plot no. 40 &amp;
      41(corner) Road, no.5, KIADB Obadenahalli Industrial Area, 3rd Phase</Text>
              <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>Doddaballapura, Bangalore
      Rural District-561203 Karnatak, India GST No. 29AAGCP4018E1ZJ</Text>
              <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>Mob:+91 9900069150</Text>
              <Text style={[styles.headerwidthAddress, { marginTop: 4, marginBottom: 4 }]}>Email id: info@pondhan.com, www.pondhan.com</Text>
            
            
            </View>
          </View>
          <View> 
         <Table
           style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
           data={pageThree}
         >
           <TableHeader
             textAlign={'center'}
             style={{ backgroundColor: 'tomato' }}
           >
             <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
               <Small>SL No</Small>
             </TableCell>
             <TableCell weighting={0.6}>
               {/* style={styles.headerfont} */}
               <Description >Description</Description>
             </TableCell>
             <TableCell weighting={0.2}>
               <Small>UOM</Small>
             </TableCell>
             <TableCell weighting={0.2}>
               <Small>Qty</Small>
             </TableCell>
             <TableCell weighting={0.2}>
               <Small>Rate</Small>
             </TableCell>
             {/* <TableCell weighting={0.2}>
               <Text style={styles.headerfont}>Dist %</Text>
             </TableCell>
             <TableCell weighting={0.2}>
               <Text style={styles.headerfont}>
                 After Dist Rate/Each{'\n'}Rs
               </Text>
             </TableCell> */}
  
             <TableCell weighting={0.2}>
               <Small>Amount</Small>
             </TableCell>
           </TableHeader>
           <TableBody textAlign={'center'}>
             <DataTableCell weighting={0.08} getContent={r => <Small>{r.slno}</Small>} />
             <DataTableCell styles={{ height: 100 }} weighting={0.6} getContent={r => <Description>{r.itemname}</Description>} />
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.discountamount}</Small>} />
             {/* <DataTableCell weighting={0.2} getContent={r => <Small>{r.itemdiscount.toString()}</Small>} />
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.itemdiscount.toString()}</Small>} />
           */}
             <DataTableCell weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
           </TableBody>
         </Table>        
         <Text> {'\n'}</Text>
         {/* Three divs for remarks, tax details and amount */}
         </View>
         
         {/* Two */}
         {/* <Table style={styles.section}>
           <TableHeader>
             <TableCell weighting={0.5}>
               <Text style={styles.headerfont3}>
                 {'\n'}
                 {'\n'}
  
               </Text>
             </TableCell>
             <TableCell weighting={0.5}>
     
             </TableCell>
           </TableHeader>
         </Table> */}
         {/* Three */}
         {/* <Table style={styles.section}>
           <TableHeader>
             <TableCell weighting={0.5}>
               <Text style={styles.headerfont3}>
               {amountinwords}
               </Text>
             </TableCell>
  
           </TableHeader>
         </Table> */}
         {/* ends here */}
         <Text> {'\n'}</Text>
         </Page> 
        );
      }
    })
  }

  console.log(amountinwords)
  // const grandtotal = taxpercentage * totalrs;
  //   const selectecontactlist = invoicelistvalues.selectedcontactlist[0]
  //     ? invoicelistvalues.selectedcontactlist[0].suppliercontactname
  //     : "";
  const cond_data = data_cond;
  const dispDyn = data.map((datanew, i) => {
    if(i == 0 && i <= 10 && data.length < 11) {
      let firstDat = [];
      let secondDat = [];
      data.forEach((element,i) => {
        if(i <= 2) {
          firstDat.push(data[i])
        }
        if(i > 2 && i <= 8) {
          secondDat.push(data[i])
        }
      });
      return ( 
      <>
      <Page size='A4' style={styles.pagepadding} wrap>
      <View style={styles.invoice_header}>
        <Image style={styles.logo} src={logo} />
        <View style={{ flex: 1, paddingLeft: 25 }}>
          <Text style={{ fontSize: 11, textAlign: 'right' }}>Original/Duplicate/Office Copy</Text>
          <Text style={styles.headerwidth}>INVOICE</Text>
          <Text style={{ fontSize: 8, marginTop: 8 }}>(Invoice for removal of Excisable goods from a Factory or Warehouse under Rule 11)</Text>
          <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>Email: info@pondhan.com, Website: www.pondhan.com</Text>
          <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>TIN No: 29710660725</Text>
          <Text style={[styles.headerwidthAddress, { marginTop: 4 }]}>GSTIN:29AAGCP4018E1ZJ RCC No: U28110KA2012PTC062181</Text>
         
         
        </View>
      </View>
      {/* <View style={styles.section}>
        <Text style={styles.headerwidth}></Text>

        <Text style={styles.headerwidthdate}>
          Date:{invoicelistvalues.requestdeliverydate}
        </Text>
      </View> */}
      <View>
        {/*  style={styles.section} */}
        <Table>
          <TableHeader>
            <TableCell weighting={0.9}>
              <Text style={styles.descriptionText}>
              Address:Plot no.40 & 41 (corner) Road,no.5, KIADB Obadenahalli Industrial Area,3rd phase Doddaballapura, {'\n'} Bangalore Rural District-561203.

              </Text>
            </TableCell>
            <TableCell weighting={0.1} styles={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Image style={styles.seal} src={seal} />
            </TableCell>
          </TableHeader>
        </Table>
        {/* <View style={{ borderLeftWidth: 1, borderRightWidth: 1, borderColor: 'black' }}>
          <Text>Hello World!</Text>
        </View> */}
        
        <Table style={styles.section}>
          <TableHeader>
            <TableCell weighting={0.5}>
              <Text style={[styles.headerfontleft]} textAlign={'left'}>
                Invoice No: {invoicenumber}/{financialYear}
               
              </Text>
            </TableCell>
            <TableCell weighting={0.5}>
              <Text style={[styles.headerfontleft, { textAlign: 'center', fontWeight: 'bold' }]}>
                {invoicedate}</Text>
            </TableCell>
          </TableHeader>
        </Table>

        
        <Text> {'\n'}</Text>
        <Table>
          <TableHeader>
            <TableCell weighting={0.5}>
              {/* <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    
                  </TableCell>
                </TableHeader>
              </Table> */}
              <Text style={[styles.headerfont, { textAlign: 'center', marginTop: 5 }]}>
                Billing To
              </Text>
              <Text style={[styles.billingaddress]}>
                {customername}  {'\n'}
                {billingaddress} {'\n'}
                GST No: {customergstnumber}
              </Text>
            </TableCell>
            <TableCell weighting={0.5}>
              <Table style={styles.section}>
                <TableHeader>
                  <TableCell weighting={0.5}>
                    <Text style={styles.headerfont2}>
                      Quotation No:
                        </Text>
                  </TableCell>
                  <TableCell weighting={0.5}>
                    <Text style={[styles.headerfont]}>
                      {quotationnumber}/{financialYear}
                    </Text>
                  </TableCell>
                </TableHeader>
              </Table>
              {/* <Table style={styles.section}>
                <TableHeader>
                  <TableCell weighting={0.5}>
                    <Text style={styles.headerfont2}>Delivery Challan Date</Text>
                  </TableCell>
                  <TableCell weighting={0.5}>
                    <Text style={[styles.headerfont]}>{date}</Text>
                  </TableCell>
                </TableHeader>
              </Table> */}
              <Table style={styles.section}>
                <TableHeader>
                  <TableCell weighting={0.5}>
                    <Text style={styles.headerfont2}>
                      Purchase Order No:
                        </Text>
                  </TableCell>
                  <TableCell weighting={0.5}>
                    <Text style={[styles.headerfont]}>
                      {purchaseordernumber}
                    </Text>
                  </TableCell>
                </TableHeader>
              </Table>

              <Table style={styles.section}>
                <TableHeader>
                  <TableCell weighting={0.5}>
                    <Text style={styles.headerfont2}>
                      Purchase Order Date:
                        </Text>
                  </TableCell>
                  <TableCell weighting={0.5}>
                    <Text style={[styles.headerfont]}>
                      {podate}
                    </Text>
                  </TableCell>
                </TableHeader>
              </Table>

              
              <Table style={styles.section}>
                <TableHeader>
                  <TableCell weighting={0.5}>
                    <Text style={styles.headerfont2}>
                      Vendor Code No:
                        </Text>
                  </TableCell>
                  <TableCell weighting={0.5}>
                    <Text style={[styles.headerfont]}>
                      {vendorcode}
                    </Text>
                  </TableCell>
                </TableHeader>
              </Table>
              {/* <Table style={styles.section}>
                    <TableHeader>
                      <TableCell weighting={0.5}>
                        <Text style={styles.headerfont2}>Date:</Text>
                      </TableCell>
                      <TableCell weighting={0.5}>
                        <Text style={[styles.headerfont]}>
                          {purchaseorderdate}
                        </Text>
                      </TableCell>
                    </TableHeader>
                  </Table> */}
            </TableCell>
          </TableHeader>
        </Table>
        <Text> {'\n'}</Text>
        <Table styles={{'borderWidth':'0'}}>
          <TableHeader>
            <TableCell weighting={0.5}>
              {/* <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    
                  </TableCell>
                </TableHeader>
              </Table> */}
              <Text style={[styles.headerfont, { textAlign: 'center', marginTop: 5 }]}>
                Delivery To
              </Text>
              <Text style={[styles.shippingaddress]}>
             
                {deliveryaddress} 
              </Text>
            </TableCell>

            <TableCell weighting={0.5} >
              <Table style={styles.section} style={{borderWidth: 0}}>
                <TableHeader>
                  <TableCell weighting={0.5}>
                    <Text style={styles.headerfont2}>Vehicle No:</Text>
                  </TableCell>
                  <TableCell weighting={0.5}>
                    <Text style={[styles.headerfont]}>
                      {vehiclenumber}
                    </Text>
                  </TableCell>
                </TableHeader>
              </Table>
              <Table style={styles.section}>
                <TableHeader>
                  <TableCell weighting={0.5}>
                    <Text style={styles.headerfont2}>HSN Code</Text>
                  </TableCell>
                  <TableCell weighting={0.5}>
                    <Text style={[styles.headerfont]}>{hsncode}</Text>
                  </TableCell>
                </TableHeader>
              </Table>
              <Table style={styles.section}>
                <TableHeader>
                  <TableCell weighting={0.5}>
                    <Text style={styles.headerfont2}>SAC Code:</Text>
                  </TableCell>
                  <TableCell weighting={0.5}>
                    <Text style={[styles.headerfont]}>
                      {saccode}
                    </Text>
                  </TableCell>
                </TableHeader>
              </Table>
              {/* <Table style={styles.section}>
                    <TableHeader>
                      <TableCell weighting={0.5}>
                        <Text style={styles.headerfont2}>
                          Time of invoice:
                        </Text>
                      </TableCell>
                      <TableCell weighting={0.5}>
                        <Text style={[styles.headerfont]}></Text>
                      </TableCell>
                    </TableHeader>
                  </Table> */}
              {/* <Table style={styles.section}>
                    <TableHeader>
                      <TableCell weighting={0.5}>
                        <Text style={styles.headerfont2}>
                          Date of invoice:
                        </Text>
                      </TableCell>
                      <TableCell weighting={0.5}>
                        <Text style={[styles.headerfont]}>
                          {invoicedate}
                        </Text>
                      </TableCell>
                    </TableHeader>
                  </Table> */}
              {/* <Table style={styles.section}>
                    <TableHeader>
                      <TableCell weighting={0.5}>
                        <Text style={styles.headerfont2}>
                          Description of Excisible Goods:
                        </Text>
                      </TableCell>
                      <TableCell weighting={0.5}>
                        <Text style={[styles.headerfont]}>
                          {goodsDescription}
                        </Text>
                      </TableCell>
                    </TableHeader>
                  </Table> */}
            </TableCell>
          </TableHeader>
        </Table>
        <Text> {'\n'}</Text>
        {pageoneTable}
        </View>
        </Page>
        {pagetwoTable}
        {pagethreeTable}
        <Page size='A4' style={styles.pagepadding} wrap>
          <Table style={[styles.section,styles.nobordertable]}>
            <TableHeader>
              <TableCell weighting={0.5}>
                <Text style={styles.headerfont3}>
                  Remarks:
                  {'\n'}
                  {'\n'}
                  {remarks}
                  {'\n'}
                  {'\n'}
                </Text>
                <Text style={styles.headerfont3}>{amountinwords}</Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Table style={[styles.section,styles.nobordertable]}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>Taxable Amount</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                        {taxableAmount}
                      </Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>Freight,w.s,loading and handling charges</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                        {freightcharges}
                      </Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>Sub Total</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>{subtotal}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>

                  <Table style={styles.section}>
                    <TableHeader>
                      <TableCell weighting={1.087}>
                        <Text style={styles.headerfont4}>CGST@{cgst}%</Text>
                      </TableCell>
                      <TableCell weighting={0.45}>
                        <Text style={[styles.headerfont, { textAlign: 'center' }]}>{cgstval}</Text>
                      </TableCell>
                    </TableHeader>
                  </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>SGST@{sgst}%</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>{sgstval}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>IGST@{igst}%</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>{igstval}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>TCS@{tcs}%</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>{tcsval}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>                
                <Table style={[styles.section,styles.nobordertable]}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>Total</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>{total}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>

                      <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>Round Off</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                        {roundoffamount}
                      </Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont4}>Grand Total</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>{grandtotal}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
            
              </TableCell>
            </TableHeader>
          </Table>            
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.descriptionText}>
                  Certified that the particulars given above are true and the
                  amount indicated represents the price actually charged and
                  that there is no additional flow directly or indirectly from
                  the buyer
                </Text>
              </TableCell>
            </TableHeader>
          </Table>
          {/* <Text>
            {'\n'}
            {'\n'}
          </Text> */}
          <View style={styles.row}>
            <View style={styles.column}>
              <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    <Text style={[styles.headerfont, { textAlign: 'center', padding: 5 }]}>
                      <SmallBold>Authentication</SmallBold>
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      E & O.E.
                    </Text>
                  </TableCell>
                </TableHeader>
                {/* > */}
              </Table>
            </View>
            <View style={styles.column}>
              <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    <Text style={[styles.headerfont, { textAlign: 'center', padding: 5 }]}>
                      <SmallBold>For PONDHAN SCAFFOLDING PVT LTD</SmallBold>
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      <SmallBold>Authorised Signatories</SmallBold>
                    </Text>
                  </TableCell>
                </TableHeader>
              </Table>
          
            </View>

       

          </View>

          <View style={styles.main}>
            
              <View>
              <Text style={styles.brandname} > Note:</Text>
          
              <Text style={styles.padding1}>1,  Advance payment 60% against Purchase order  & Balance against Pro-forma invoice / deduction of advance on Pro Rata basis..</Text>
              <Text style={styles.padding1}>2, Validity: All prices are Current Ruling.</Text>
              <Text style={styles.padding1}>3, Delivery Schedule: 8 to 10 days after confirmation.</Text>
              <Text style={styles.padding1}>4, Transportation Charges extra as actual.</Text>
              <Text style={styles.padding1}>5, In case of any Uncircumstances, The Advance Amount paid to materials will be returned within 7 working days.</Text>
              <Text style={styles.padding1}>6, Unloading should be done by party.</Text>
              <Text style={styles.padding1}>7, After Delivery if not satisfied you should return within 2 days from the supply date & collect payment within 5 working days but materials should not be used.</Text>
              <Text style={styles.padding1}>8, We are not responsible for Breakage & Damage while Transportaion or Unloading.</Text>          
              <Text style={styles.padding1}>9, Any Legal matter will be under "Bangalore Jurisdiction".</Text>          
              </View>
            
          </View>
          </Page>
        </>
         )
    }
  })
  const tableComponentTwo = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i] && data[i + 1]) {
      tableComponentTwo.push([data[i], data[i + 1]]);
    } else {
      tableComponentTwo.push([data[i]]);
    }
    ++i;
  }
  const datalength = tableComponentTwo.length - 1;
  const tableComponent = tableComponentTwo.map((data, i) => {
    if (i >= 1 && i === datalength) {
      return (
        <Page size='A4' style={styles.pagepadding} wrap key={i}>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-5%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => {
                  return r.weighteach;
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
          <Table style={styles.section}>
            <TableHeader style={styles.section}>
              <TableCell weighting={1.087} style={styles.section}>
                <Text style={styles.headerfont2}>Taxable Amount</Text>
              </TableCell>
              <TableCell weighting={0.13} style={styles.section}>
                <Text style={{ textAlign: 'center' }}>{taxableAmount}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>GST %</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>{tax}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.headerfont2}>Grand Total Rs</Text>
              </TableCell>
              <TableCell weighting={0.13}>
                <Text style={{ textAlign: 'center' }}>
                  {/* {(grandtotal + +totalrs).toFixed(2)} */}
                  23131
                </Text>
              </TableCell>
            </TableHeader>
          </Table>
        </Page>
      );
    } else if (i >= 1) {
      return (
        <Page size='A4' style={styles.pagepadding} wrap key={i}>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-3%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.weighteach} />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
        </Page>
      );
    } else if (i < 1 && i === datalength) {
      return (
        <View>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1}>
                <Text style={styles.descriptionText}>
                  {'\n'}
                  Range : Yelahanka -I Basaveshwara Building, Cresent Road (Opp Mallige Nursing Home) - Bangalore, Karnataka {
                    '\n'
                  }{' '}
                  Division : Bangalore-C Basaveshwara Building, Cresent Road (Opp Mallige Nursing Home) - Bangalore, Karnataka {
                    '\n'
                  }{' '}
                  Commissionerte : Obadenahalli Industrial Area, {'\n'} Doddabalapura,
                  Bangalore Rural District PIN : 561203
                  {'\n'}
                  {'\n'}
                </Text>
              </TableCell>
            </TableHeader>
          </Table>
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.5}>
                <Text style={styles.headerfontleft} textAlign={'left'}>
                  Invoice No: {invoicenumber}
                </Text>
              </TableCell>
              <TableCell weighting={0.5}>
                <Text style={[styles.headerfont, { textAlign: 'center' }]}>{date}</Text>
              </TableCell>
            </TableHeader>
          </Table>
          <View style={styles.row}>
            <View style={styles.column}>
              <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    <Text style={styles.billingaddress}>
                      Billing To {'\n'}
                      {billingaddress}
                      {'\n'}
          
                      GST No: {customergstnumber}
                    </Text>
                  </TableCell>
                </TableHeader>
                >
              </Table>
            </View>
            <View style={styles.column}>
              <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>
                            Quotation No:
                          </Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                            {quotationnumber}
                          </Text>
                        </TableCell>
                      </TableHeader>
                    </Table>
                    <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>Date</Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>{date}</Text>
                        </TableCell>
                      </TableHeader>
                    </Table>
                    <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>
                            Purchase Order No:
                          </Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                            {purchaseordernumber}
                          </Text>
                        </TableCell>
                      </TableHeader>
                    </Table>
                    {/* <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>Date:</Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                            {purchaseorderdate}
                          </Text>
                        </TableCell>
                      </TableHeader>
                    </Table> */}
                  </TableCell>
                </TableHeader>
              </Table>
            </View>
          </View>
          {/* Shipping Address */}
          <View style={styles.row}>
            <View style={styles.column}>
              <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    <Text style={styles.shippingaddress}>
                      Delivery To {'\n'}
                      {deliveryaddress}
                      {'\n'}
                      GST No. {customergstnumber}
                    </Text>
                  </TableCell>
                </TableHeader>
                >
              </Table>
            </View>
            <View style={styles.column}>
              <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>Vehicle No:</Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                            {taxableAmount}
                          </Text>
                        </TableCell>
                      </TableHeader>
                    </Table>
                    <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>HSN Code</Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>{date}</Text>
                        </TableCell>
                      </TableHeader>
                    </Table>
                    <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>
                            Vendor Code No:
                          </Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                            {vendorcode}
                          </Text>
                        </TableCell>
                      </TableHeader>
                    </Table>
                    <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>SAC Code:</Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                            {vendorcode}
                          </Text>
                        </TableCell>
                      </TableHeader>
                    </Table>
                    {/* <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>
                            Time of invoice:
                          </Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}></Text>
                        </TableCell>
                      </TableHeader>
                    </Table> */}
                    <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>
                            Date of invoice:
                          </Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                            {invoicedate}
                          </Text>
                        </TableCell>
                      </TableHeader>
                    </Table>
                    {/* <Table style={styles.section}>
                      <TableHeader>
                        <TableCell weighting={0.5}>
                          <Text style={styles.headerfont2}>
                            Description of Excisible Goods:
                          </Text>
                        </TableCell>
                        <TableCell weighting={0.5}>
                          <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                            {goodsDescription}
                          </Text>
                        </TableCell>
                      </TableHeader>
                    </Table> */}
                  </TableCell>
                </TableHeader>
              </Table>
            </View>
          </View>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
            key={i}
          >
            <TableHeader
              textAlign={'center'}
              style={{ backgroundColor: 'tomato' }}
            >
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-3%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => r.slno}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text>{r.itemname + '\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => r.weighteach} />
              <DataTableCell weighting={0.2} getContent={r => r.costperunit} />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => r.itemdiscount.toString()}
              />
              <DataTableCell weighting={0.2} getContent={r => r.quantity} />
              <DataTableCell weighting={0.2} getContent={r => r.totalcost} />
            </TableBody>
          </Table>
          {/* Three divs for remarks, tax details and amoun */}
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.5}>
                <Text style={{ fontSize: 10 }}>
                  Remarks
                  {'\n'}
                  {'\n'}

                </Text>
              </TableCell>
              <TableCell weighting={0.73}>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>Taxable Amount</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={{ textAlign: 'center' }}>
                        {taxableAmount}
                      </Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>Freight Charges RS</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={{ textAlign: 'center' }}>
                        {freightcharges}
                      </Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>Sub Total Rs</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>{subtotal}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>CGST @ 9%</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={[styles.headerfont, { textAlign: 'center' }]}>{cgst}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
              </TableCell>
            </TableHeader>
          </Table>
          {/* Two */}
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.5}>
                <Text style={styles.headerfont2}>
                  {'\n'}
                  {'\n'}
                  {'\n'}
                  {'\n'}
                </Text>
              </TableCell>
              <TableCell weighting={0.73}>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>SGST @ 9%</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={{ textAlign: 'center' }}>{sgst}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>IGST @ 18%</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={{ textAlign: 'center' }}>{igst}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>Total</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={{ textAlign: 'center' }}>{total}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
              </TableCell>
            </TableHeader>
          </Table>
          {/* Three */}
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={0.5}>
                <Text style={styles.headerfont2}>
                  {'\n'}
                  {'\n'}
                </Text>
              </TableCell>
              <TableCell weighting={0.73}>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>Round Off</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={{ textAlign: 'center' }}>
                        {roundoffamount}
                      </Text>
                    </TableCell>
                  </TableHeader>
                </Table>
                <Table style={styles.section}>
                  <TableHeader>
                    <TableCell weighting={1.087}>
                      <Text style={styles.headerfont2}>Grand total</Text>
                    </TableCell>
                    <TableCell weighting={0.45}>
                      <Text style={{ textAlign: 'center' }}>{grandtotal}</Text>
                    </TableCell>
                  </TableHeader>
                </Table>
              </TableCell>
            </TableHeader>
          </Table>
          {/* ends here */}
          <Table style={styles.section}>
            <TableHeader>
              <TableCell weighting={1.087}>
                <Text style={styles.descriptionText}>
                  Certified that the particulars given above are true and the
                  amount indicated represents the price actually charged and
                  that there is no additional flow directly or indirectly from
                  the buyer
                </Text>
              
              </TableCell>
            </TableHeader>
          </Table>
          <View style={styles.row}>
            <View style={styles.column}>
              <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                      Authentication
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      E & O.E.
                    </Text>
                  </TableCell>
                </TableHeader>
                >
              </Table>
            </View>
            <View style={styles.column}>
              <Table>
                <TableHeader>
                  <TableCell weighting={1}>
                    <Text style={[styles.headerfont, { textAlign: 'center' }]}>
                      For PONDHAN SCAFFOLDING PVT LTD
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      {'\n'}
                      Authorised Signatories
                    </Text>
                  </TableCell>
                </TableHeader>
                >
              </Table>
            </View>
            
         


          </View>
        </View>
      );
    } else {
      return (
        <View>
          <Table
            style={(styles.section, styles.padding, styles.tableheaderbgcolor)}
            data={data}
            key={i}
          >
            <TableHeader textAlign={'center'} style={styles.tableheaderbgcolor}>
              <TableCell weighting={0.08} style={{ backgroundColor: 'tomato' }}>
                <Text style={styles.headerfont}>SL No</Text>
              </TableCell>
              <TableCell weighting={0.6}>
                <Text style={styles.headerfont}>Particular</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Weight Each kgs(+/-3%)</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Rate/{'\n'}Each RS</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Dist %</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>
                  After Dist Rate/Each{'\n'}Rs
                </Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Qty</Text>
              </TableCell>
              <TableCell weighting={0.2}>
                <Text style={styles.headerfont}>Total RS</Text>
              </TableCell>
            </TableHeader>
            <TableBody textAlign={'center'}>
              <DataTableCell
                weighting={0.08}
                style={styles.padding}
                getContent={r => <Small>{r.slno}</Small>}
              />
              <DataTableCell
                style={styles.padding}
                weighting={0.6}
                getContent={r => {
                  return (
                    <View>
                      <Text><Small>{r.itemname}</Small>{'\n'}</Text>
                      <Text>{'\n'}</Text>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${r.itemimage}`}
                      />
                      <Text>{'\n'}</Text>
                    </View>
                  );
                }}
              />
              <DataTableCell weighting={0.2} getContent={r => <Small>{r.weighteach}</Small>} />
              <DataTableCell weighting={0.2} getContent={r => <Small>{r.costperunit}</Small>} />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
              />
              <DataTableCell
                weighting={0.2}
                getContent={r => <Small>{r.itemdiscount.toString()}</Small>}
              />
              <DataTableCell weighting={0.2} getContent={r => <Small>{r.quantity}</Small>} />
              <DataTableCell weighting={0.2} getContent={r => <Small>{r.totalcost}</Small>} />
            </TableBody>
          </Table>
        </View>
      );
    }
  });

  return (
    <Document>
          {dispDyn}
    </Document >
  );
};

export default MyDocument;