import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Select from "react-select";
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import client from '../../../../../Images/client.png';
import CustomLoader from "../../../../../Components/loader/loader";
class QuotationForm extends React.Component {

  state = {
    customerID: null,
    isRemovedCalled: false,
    isVisible: true,
    transaction: [],
    data: {
      customerdetail: {
        id: "",
        customernumber: "",
        customergstnumber: "",
        customercontactnumber: "",
        customeraddress1: "",
        customeraddress2: "",
        city: "",
        state: "",
        country: "",
        pincode: ""
      },
      selectedItemlist: {
        id: [],
        itemname: [],
        itemimage: [],
        itemsecondimage: [],
        itemdescription: [],
        itemshortdescription: [],
        itemtype: [],
        itemrate: [],
        itemuom: [],
        itemdiscount: [],
        itemdiscountamount: [],
        costperunit: [],
        quantity: [],
        totalcost: []
      },
      quotationnumber: null,
      subject: "",
      gst: null,
      tcs: '',
      tcsamount: '',
      user_email: '',
      totalvalue: "",
      values: [],
      tcharge: "",
      transportationgst: "",
      transitinsurance: "",
      ws: "",
      loadingcharge: "",
      weighttons: "",
      scheduledays: "",
      date: new Date()
    },
    tcshecked: "false",
    errors: {},
    customerobjects: {},
    user: {},
    customerlistvalues: [],
    selectedCustomerID: '',
    selectedCustomerName: '',
    uom: [],
    customerlistSelect: [],
    itemlistSelectedOption: [],
    masteritemlistvalues: [],
    masteritemlistvaluesobject: {},
    masteritemtypelistvalues: [],
    masteritemtypelistvaluesobject: {},
    loading: true,
    success: false,
    visible: false
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  getQuotationDetails(id) {
    this.dataHandler(true)
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/quotation/getsinglequotation/${id}`)
      .then(res => {


        if (res.data.quotationlist.quotationdata.length > 0) {
          let founddata = res.data.quotationlist.quotationdata;

          const newState = Object.assign({}, this.state);
          newState.data.customerdetail.id = founddata[0].customerid;

          newState.data.values = founddata[0].addeditemlist;


          if (founddata[0].addeditemlist.length > 0) {
            founddata[0].addeditemlist.filter((item, index) => {
              this.selectitemchange(item.id._id, index);
            })
          }


          newState.data.subject = founddata[0].subject;
          newState.data.date = new Date(founddata[0].date);
          newState.data.quotationnumber = founddata[0].quotationnumber;
          localStorage.setItem('QTno', founddata[0].quotationnumber)
          newState.data.totalvalue = founddata[0].totalvalue;
          newState.data.tcs = founddata[0].tcs;
          newState.data.tcsamount = founddata[0].tcsamount;
          if (newState.data.tcs != '' && newState.data.tcs != null) {
            newState.tcshecked = "true";
          }
          newState.data.gst = founddata[0].tax;
          newState.data.ws = founddata[0].ws != undefined && founddata[0].ws != null ? founddata[0].ws : '';
          newState.data.tcharge = founddata[0].tcharge != undefined && founddata[0].tcharge != null ? founddata[0].tcharge : '';
          newState.data.transportationgst = founddata[0].transportationgst != undefined && founddata[0].transportationgst != null ? founddata[0].transportationgst : '';

          newState.data.transitinsurance = founddata[0].transitinsurance != undefined && founddata[0].transitinsurance != null ? founddata[0].transitinsurance : '';

          newState.data.loadingcharge = founddata[0].loadingcharge != undefined && founddata[0].loadingcharge != null ? founddata[0].loadingcharge : '';
          newState.data.weighttons = founddata[0].weighttons != undefined && founddata[0].weighttons != null ? founddata[0].weighttons : '';
          newState.data.scheduledays = founddata[0].scheduledays ? founddata[0].scheduledays : "";


          if (founddata[0].addeditemlist.length > 0) {

            for (var i = 0; i < founddata[0].addeditemlist.length; i++) {
              founddata[0].addeditemlist[i].itemdiscount = founddata[0].addeditemlist[i].itemdiscount == null || founddata[0].addeditemlist[i].itemdiscount == '' ?
                founddata[0].addeditemlist[i].itemdiscount = 0 : founddata[0].addeditemlist[i].itemdiscount
              founddata[0].addeditemlist[i].itemdiscount = (founddata[0].addeditemlist[i].itemdiscount).toString()


              founddata[0].addeditemlist[i].quantity = founddata[0].addeditemlist[i].quantity == null || founddata[0].addeditemlist[i].quantity == '' ?
                founddata[0].addeditemlist[i].quantity = 0 : founddata[0].addeditemlist[i].quantity
              founddata[0].addeditemlist[i].quantity = (founddata[0].addeditemlist[i].quantity).toString()

              founddata[0].addeditemlist[i].costperunit = founddata[0].addeditemlist[i].costperunit == null || founddata[0].addeditemlist[i].costperunit == '' ?
                founddata[0].addeditemlist[i].costperunit = 0 : founddata[0].addeditemlist[i].costperunit
              founddata[0].addeditemlist[i].costperunit = (founddata[0].addeditemlist[i].costperunit).toString()

              founddata[0].addeditemlist[i].totalcost = founddata[0].addeditemlist[i].totalcost == null || founddata[0].addeditemlist[i].totalcost == '' ?
                founddata[0].addeditemlist[i].totalcost = 0 : founddata[0].addeditemlist[i].totalcost
              founddata[0].addeditemlist[i].totalcost = (founddata[0].addeditemlist[i].totalcost).toString()

              founddata[0].addeditemlist[i].itemdiscountamount = founddata[0].addeditemlist[i].itemdiscountamount == null || founddata[0].addeditemlist[i].itemdiscountamount == '' ?
                founddata[0].addeditemlist[i].itemdiscountamount = 0 : founddata[0].addeditemlist[i].itemdiscountamount
              founddata[0].addeditemlist[i].itemdiscountamount = (founddata[0].addeditemlist[i].itemdiscountamount).toString()






              newState.data.selectedItemlist.itemdescription.push(founddata[0].addeditemlist[i].itemdescription)
              if (founddata[0].addeditemlist[i].itemshortdescription) {
                newState.data.selectedItemlist.itemshortdescription.push(founddata[0].addeditemlist[i].itemshortdescription)
              }
              newState.data.selectedItemlist.itemtype.push(founddata[0].addeditemlist[i].itemtype._id)
              newState.data.selectedItemlist.itemdiscount.push(founddata[0].addeditemlist[i].itemdiscount)


              newState.data.selectedItemlist.itemimage.push(founddata[0].addeditemlist[i].itemimage);
              newState.data.selectedItemlist.itemsecondimage.push(founddata[0].addeditemlist[i].itemsecondimage);
              newState.data.selectedItemlist.itemdiscountamount.push(founddata[0].addeditemlist[i].itemdiscountamount)
              newState.data.selectedItemlist.itemuom.push(founddata[0].addeditemlist[i].itemuom._id)
              newState.data.selectedItemlist.quantity.push(founddata[0].addeditemlist[i].quantity)

              newState.data.selectedItemlist.costperunit.push(founddata[0].addeditemlist[i].costperunit)

              newState.data.selectedItemlist.totalcost.push(founddata[0].addeditemlist[i].totalcost)
              newState.data.selectedItemlist.id.push(founddata[0].addeditemlist[i].id)

            }
          }


          this.setState(newState);
          this.handleChange(founddata[0].customerid)
          this.dataHandler(false)
        }
        else {
          this.setState({ count: 0 })
          this.dataHandler(false)
        }
      },
        error => {
          this.dataHandler(false)
          this.setState({ success: false, message: 'Server error.' });
        });
  }

  componentDidMount() {
    if (this.props.ID != undefined) {
      this.getQuotationDetails(this.props.ID)
    }
    else {
      setTimeout(() => {
        this.dataHandler(false)
      }, 100)
      // quotation/getquotation
    }

    const quotationlistvalues = Object.keys(this.props.quotationlistvalues);
    const customerlistvalues = Object.values(this.props.customerlistvalues);
    const customerobjects = this.props.customerlistvalues;
    const user = this.props.user;
    const masteritemlistvalues = Object.values(this.props.masteritemlistvalues);
    const masteritemlistvaluesobject = this.props.masteritemlistvalues;
    const masteritemtypelistvalues = Object.values(
      this.props.masteritemtypelistvalues
    );
    const masteritemtypelistvaluesobject = this.props.masteritemtypelistvalues;

    const uom = Object.values(this.props.uomlistvalues);


    if (this.props.ID == undefined) {

      let quotationnumber = '';
      quotationnumber =
        quotationlistvalues.length > 0 ? 17389 + quotationlistvalues.length : 18000;
      this.setState({
        data: {
          ...this.state.data,
          quotationnumber
        }
      })

    }
    // else{
    //   alert(this.state.data.quotationnumber)
    // }

    this.setState({
      ...this.state,
      customerlistvalues,
      customerobjects,
      masteritemlistvalues,
      masteritemlistvaluesobject,
      masteritemtypelistvalues,
      masteritemtypelistvaluesobject,
      uom,
      user
    });

    //this.dataHandler(false)
  }

  async componentWillReceiveProps(props) {
    const quotationlistvalues = Object.keys(props.quotationlistvalues);
    const customerlistvalues = Object.values(props.customerlistvalues);
    const user = this.props.user;
    const customerobjects = props.customerlistvalues;
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    const masteritemlistvaluesobject = props.masteritemlistvalues;
    const masteritemtypelistvalues = Object.values(
      props.masteritemtypelistvalues
    );
    const masteritemtypelistvaluesobject = props.masteritemtypelistvalues;
    const uom = Object.values(props.uomlistvalues);
    let quotationnumber = ''
    if (this.props.ID == undefined) {
      quotationnumber =
        quotationlistvalues.length > 0 ? 17389 + quotationlistvalues.length : 18000;
    }

    this.dataHandler(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/quotation/getquotno`)
      .then(res => {
        if (res.data.quoteno) {
          quotationnumber = parseInt(res.data.quoteno);
        }
      },
        error => {
          this.setState({ loading: false });
        });

    this.dataHandler(false);
    let arraySet = [];
    customerlistvalues.map((option, i) => {
      let objset = {
        value: option.id, label: option.customername
      }
      arraySet.push(objset);
    });

    let newArraySet = [];
    masteritemlistvalues.map((option, i) => {
      if (option.masteritemtype.masteritemtypename == "FinishedGoods") {
        let name = "";
        if (option.masteritemshortdescription) {
          name = option.masteritemshortdescription;
        }
        else {
          name = option.masteritemdescription;
        }
        let objset = {
          value: option.id, label: name
        }
        newArraySet.push(objset);
      }
    });

    this.setState({
      ...this.state,
      customerlistvalues,
      customerobjects,
      masteritemlistvalues,
      masteritemlistvaluesobject,
      masteritemtypelistvalues,
      itemlistSelectedOption: newArraySet,
      masteritemtypelistvaluesobject,
      customerlistSelect: arraySet,
      uom,
      data: {
        ...this.state.data,
        quotationnumber,
        user_email: user.email
      }
    });
  }

  onhandlerChange = e => {
    let name = e.target.name;
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    }, () => {
      if (name === 'gst') {
        this.totalValue();
      }
    });
  };

  onCustomerChange = e => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customerdetail: {
          ...this.state.data.customerdetail,
          [e.target.name]: e.target.value
        }
      }
    });
  };

  onSubjectHandler = e => {

    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    }, () => {
      console.log(this.state.data)
    });
  };
  onhandletcsChange = e => {
    console.log("tcs value is", e.target.value);
    if (e.target.value == "false") {
      this.setState(
        {
          ...this.state,
          tcshecked: "true",
          data: {
            ...this.state.data,
            [e.target.name]: '0.1'
          }
        });
    }
    else if (e.target.value == "true") {
      this.setState(
        {
          ...this.state,
          tcshecked: "false",
          data: {
            ...this.state.data,
            [e.target.name]: '',
            tcsamount: ''
          }
        },
        () => {
          this.totalValue();
        });
    }
  }

  onTcsChange = e => {
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value
        }
      },
      () => {
        this.totalValue();
      }
    );
  }

  createSelectcustomer = () => {
    let items = [];
    const customerlistvalues = this.state.customerlistvalues;
    customerlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.customername}
        </option>
      );
    });
    return items;
  };

  selectitemchange2 = (e, index) => {
    // /console.log(e.target.value + '::' + index)
    let selected_id = e.target ? e.target.value : e;
    if (selected_id) {
      const selected_item = this.state.masteritemlistvaluesobject[selected_id];
      console.log(selected_item)
      const selectedItemlist = { ...this.state.data.selectedItemlist };
      selectedItemlist.id[index] = selected_item.id;
      selectedItemlist.itemname[index] = selected_item.masteritemtypename;

      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          selectedItemlist
        }
      }, () => {
        console.log(this.state.data.values)
      });
    }
  }

  selectitemchange = (e, index) => {
    // /console.log(e.target.value + '::' + index)
    let selected_id = e.value ? e.value : e;
    if (selected_id) {
      let selected_item = this.state.masteritemlistvaluesobject[selected_id];
      if (selected_item) {
        const selectedItemlist = { ...this.state.data.selectedItemlist };
        selectedItemlist.id[index] = selected_item.id;
        selectedItemlist.itemname[index] = selected_item.masteritemtypename;
        selectedItemlist.itemimage[index] = selected_item.masteritemimage;
        selectedItemlist.itemsecondimage[index] = selected_item.masteritemsecondimage;
        selectedItemlist.itemdescription[index] =
          selected_item.masteritemdescription;
        if (selected_item.masteritemshortdescription) {
          selectedItemlist.itemshortdescription[index] =
            selected_item.masteritemshortdescription;
        }
        selectedItemlist.itemtype[index] = selected_item.masteritemtype._id;
        selectedItemlist.itemrate[index] = selected_item.masteritemrate._id;
        selectedItemlist.itemuom[index] = selected_item.masteritemuom._id;

        if (this.props.ID == undefined) {
          selectedItemlist.itemdiscount[index] = "0";
          selectedItemlist.costperunit[index] = selected_item.masteritemcostperunit
            ? selected_item.masteritemcostperunit.toString()
            : "";
          selectedItemlist.quantity[index] = (1).toString();
          selectedItemlist.totalcost[index] = selected_item.masteritemcostperunit
            ? selected_item.masteritemcostperunit.toString()
            : "";
        }


        this.setState({
          ...this.state.data,
          data: {
            ...this.state.data,
            selectedItemlist
          }
        }, () => {
          this.totalValue();
        });
      }

    }

  };

  quantityChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const costperunit = selectedItemlist.costperunit[index] || null;
    selectedItemlist.quantity[index] = e.target.value;
    const quantity = e.target.value;
    const discountpercentagevalue = selectedItemlist.itemdiscount[index];

    if (discountpercentagevalue && costperunit && quantity) {
      const totalcost =
        parseFloat(quantity) * parseFloat(selectedItemlist.costperunit[index]);
      const discountvalue = parseFloat(discountpercentagevalue / 100).toFixed(
        2
      );
      const totaldiscountcost = parseFloat(totalcost * discountvalue).toFixed(
        2
      );
      const totalvaluecost = totalcost - totaldiscountcost;
      selectedItemlist.totalcost[index] = totalvaluecost
        ? totalvaluecost.toFixed(2)
        : null || "";
    } else {
      const totalValue = costperunit
        ? e.target.value * costperunit
        : null || undefined || "";
      selectedItemlist.totalcost[index] = totalValue
        ? parseFloat(totalValue).toFixed(2)
        : "";
    }

    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  itemdiscountChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const discount = Number(e.target.value);
    selectedItemlist.itemdiscount[index] = e.target.value;
    const unitcost = selectedItemlist.costperunit[index];
    console.log(unitcost)
    const quantity = selectedItemlist.quantity[index];
    const particularindextotalvalue = parseFloat(unitcost * quantity).toFixed(
      2
    );
    if (particularindextotalvalue && discount) {

      const discountvalue = parseFloat(discount / 100);
      const totaldiscountcost = parseFloat(
        particularindextotalvalue * discountvalue
      ).toFixed(2);
      const totalvaluecost = particularindextotalvalue - totaldiscountcost;
      selectedItemlist.totalcost[index] = parseFloat(totalvaluecost).toFixed(2);
      //   selectedItemlist.itemdiscountamount[index] = (parseFloat(unitcost)-parseFloat(totaldiscountcost)).toFixed(2);
      selectedItemlist.itemdiscountamount[index] = (parseFloat(unitcost) - (parseFloat(unitcost) * discount) / 100).toFixed(2);
    } else {
      selectedItemlist.totalcost[index] = particularindextotalvalue;
      selectedItemlist.itemdiscountamount[index] = null;
    }
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  unitChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.costperunit[index] = e.target.value;
    const discountpercentagevalue = selectedItemlist.itemdiscount[index];
    if (discountpercentagevalue) {
      const totalcost =
        parseFloat(selectedItemlist.quantity[index]) *
        parseFloat(selectedItemlist.costperunit[index]);
      const discountvalue = parseFloat(discountpercentagevalue / 100).toFixed(
        2
      );
      const totaldiscountcost = parseFloat(totalcost * discountvalue).toFixed(
        2
      );
      const totalvaluecost = totalcost - totaldiscountcost;
      selectedItemlist.totalcost[index] = totalvaluecost
        ? totalvaluecost.toFixed(2)
        : null || "";
      selectedItemlist.itemdiscountamount[index] = totaldiscountcost

      selectedItemlist.itemdiscountamount[index] = (parseFloat(selectedItemlist.costperunit[index]) -
        (parseFloat(selectedItemlist.costperunit[index]) * selectedItemlist.itemdiscount[index]) / 100).toFixed(2);

    } else {
      const totalcost =
        parseFloat(selectedItemlist.quantity[index]) *
        parseFloat(selectedItemlist.costperunit[index]);
      selectedItemlist.totalcost[index] = totalcost
        ? totalcost.toFixed(2)
        : null || "";
      selectedItemlist.itemdiscountamount[index] = null;
    }
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };


  /*itemdiscountChange = (e, index) => {
    
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const discount = Number(e.target.value);
    selectedItemlist.itemdiscount[index] = e.target.value;
    const unitcost = selectedItemlist.costperunit[index];
    const quantity = selectedItemlist.quantity[index];
    const particularindextotalvalue = parseFloat(unitcost * quantity).toFixed(
      2
    );
    if (particularindextotalvalue && discount) {
      const discountvalue = parseFloat(discount / 100);
      const totaldiscountcost = parseFloat(
        particularindextotalvalue * discountvalue
      ).toFixed(2);
      const totalvaluecost = particularindextotalvalue - totaldiscountcost;
      selectedItemlist.totalcost[index] = parseFloat(totalvaluecost).toFixed(2);
      selectedItemlist.itemdiscountamount[index] = (parseFloat(unitcost)-parseFloat(totaldiscountcost)).toFixed(2);
    
    } else {
      selectedItemlist.totalcost[index] = particularindextotalvalue;
      selectedItemlist.itemdiscountamount[index] = null;
    }
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  unitChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.costperunit[index] = e.target.value;
    const discountpercentagevalue = selectedItemlist.itemdiscount[index];
    if (discountpercentagevalue) {
      const totalcost =
        parseFloat(selectedItemlist.quantity[index]) *
        parseFloat(selectedItemlist.costperunit[index]);
      const discountvalue = parseFloat(discountpercentagevalue / 100).toFixed(
        2
      );
      const totaldiscountcost = parseFloat(totalcost * discountvalue).toFixed(
        2
      );
      const totalvaluecost = totalcost - totaldiscountcost;
      selectedItemlist.totalcost[index] = totalvaluecost
        ? totalvaluecost.toFixed(2)
        : null || "";
      selectedItemlist.itemdiscountamount[index] = totaldiscountcost
    } else {
      const totalcost =
        parseFloat(selectedItemlist.quantity[index]) *
        parseFloat(selectedItemlist.costperunit[index]);
      selectedItemlist.totalcost[index] = totalcost
        ? totalcost.toFixed(2)
        : null || "";
        selectedItemlist.itemdiscountamount[index] = null;
    }

    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };*/

  createSelectItemTypes = () => {
    let items = [];
    const masteritemtypelistvalues = this.state.masteritemtypelistvalues;
    masteritemtypelistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  totalValue = () => {
    const { totalcost } = this.state.data.selectedItemlist;
    if (totalcost.length > 0) {
      let totalvalue = totalcost
        .map((value, i) => {
          if (parseFloat(value)) {
            const floatvalue = parseFloat(value).toFixed(2);
            return floatvalue;
          } else {
            return parseFloat(0);
          }
        })
        .reduce((a, b) => {
          const parsedvalue = parseFloat(a) + parseFloat(b);
          return parsedvalue;
        });
      // Check if tax exists and handle tax  
      let { gst } = this.state.data;
      if (gst !== null) {
        gst = parseFloat(gst).toFixed(2);
        let per = (gst * totalvalue) / 100;
        totalvalue = parseFloat(Number(per) + Number(totalvalue)).toFixed(2);
      }
      let { tcsamount } = this.state.data;
      if (tcsamount != '' && tcsamount != null) {
        let newtcs = tcsamount;
        totalvalue = parseFloat(Number(newtcs) + Number(totalvalue)).toFixed(2);
      }
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          totalvalue
        }
      });
    }
  };

  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uom;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };

  handleChange = e => {
    let selected_id = e.value ? e.value : e
    this.updateQuotation(selected_id)
    let customerdetail = this.state.customerobjects[selected_id];
    console.log(customerdetail)
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customerdetail
      },
      selectedCustomerID: selected_id,
      selectedCustomerName: customerdetail.customername
    });

  };

  handleClick = e => {

    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, [""]],
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id: [...this.state.data.selectedItemlist.id, ""],
          itemname: [...this.state.data.selectedItemlist.itemname, ""],
          itemdescription: [
            ...this.state.data.selectedItemlist.itemdescription,
            ""
          ],
          itemshortdescription: [
            ...this.state.data.selectedItemlist.itemshortdescription,
            ""
          ],
          itemtype: [...this.state.data.selectedItemlist.itemtype, ""],
          itemrate: [...this.state.data.selectedItemlist.itemrate, ""],
          itemuom: [...this.state.data.selectedItemlist.itemuom, ""],
          itemdiscount: [...this.state.data.selectedItemlist.itemdiscount, "0"],
          itemdiscountamount: [...this.state.data.selectedItemlist.itemdiscountamount, ""],
          costperunit: [...this.state.data.selectedItemlist.costperunit, ""],
          quantity: [...this.state.data.selectedItemlist.quantity, "1"],
          totalcost: [...this.state.data.selectedItemlist.totalcost, ""]
        }
      }
    }, () => {
      console.log(this.state.data.values)
    });
  };

  filterItems(itemarray, key) {
    return itemarray.sort((a, b) => { return a[key].toLowerCase().localeCompare(b[key].toLowerCase()) })
  }

  createSelectItems = () => {
    let items = [];
    let masteritemlistvalues = this.state.masteritemlistvalues;
    masteritemlistvalues = this.filterItems(masteritemlistvalues, 'masteritemtypename')
    console.log(masteritemlistvalues)
    masteritemlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  removeClick = (e, index) => {
    console.log(index)

    let removeItem = { ...this.state.data };
    console.log(removeItem.selectedItemlist)
    let values = removeItem.values;
    let id = removeItem.selectedItemlist.id;
    let itemname = removeItem.selectedItemlist.itemname;
    let itemimage = removeItem.selectedItemlist.itemimage;
    let itemsecondimage = removeItem.selectedItemlist.itemsecondimage;
    let itemdescription = removeItem.selectedItemlist.itemdescription;
    let itemshortdescription = removeItem.selectedItemlist.itemshortdescription;
    let itemtype = removeItem.selectedItemlist.itemtype;
    let itemrate = removeItem.selectedItemlist.itemrate;
    let itemuom = removeItem.selectedItemlist.itemuom;
    let itemdiscount = removeItem.selectedItemlist.itemdiscount;
    let costperunit = removeItem.selectedItemlist.costperunit;
    let quantity = removeItem.selectedItemlist.quantity;
    let totalcost = removeItem.selectedItemlist.totalcost;
    let itemdiscountamount = removeItem.selectedItemlist.itemdiscountamount;

    values.splice(index, 1);

    id.splice(index, 1);
    itemname.splice(index, 1);
    itemimage.splice(index, 1);
    itemsecondimage.splice(index, 1);
    itemdescription.splice(index, 1);
    itemshortdescription.splice(index, 1);
    itemtype.splice(index, 1);
    itemrate.splice(index, 1);
    itemuom.splice(index, 1);

    costperunit.splice(index, 1);
    itemdiscount.splice(index, 1);
    quantity.splice(index, 1);
    itemdiscountamount.splice(index, 1);

    totalcost.splice(index, 1);

    console.log(removeItem.selectedItemlist)



    //setTimeout(() =>{
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id,
          itemname,
          itemimage,
          itemsecondimage,
          itemdescription,
          itemshortdescription,
          itemtype,
          itemrate,
          itemuom,
          itemdiscount,
          itemdiscountamount,
          costperunit,
          quantity,
          totalcost
        }
      },
      isVisible: false,
      isRemovedCalled: true
    }, () => {
      // setTimeout(() =>{
      this.setState({
        'isVisible': true
      })
      console.log(this.state.data.values)
      console.log(this.state.data.selectedItemlist)
      if (this.state.data.selectedItemlist.id.length > 0) {
        this.state.data.selectedItemlist.id.filter((item, index) => {
          if (typeof item == 'string' && item != '') {

            this.selectitemchange2(item, index)
          }

        })

      }
      // },200)

      this.totalValue();

    });
    //},200)


  };

  handleValidSubmit = () => {
    let AddedIteminfo = [];
    const { customerdetail, selectedItemlist } = this.state.data;
    const {
      quotationnumber,
      subject,
      gst,
      values,
      date,
      totalvalue,
      tcharge,
      transportationgst,
      tcs,
      tcsamount,
      transitinsurance,
      ws,
      user_email,
      loadingcharge,
      weighttons,
      scheduledays
    } = this.state.data;
    const id = selectedItemlist.id;
    const itemname = selectedItemlist.itemname;
    const itemimage = selectedItemlist.itemimage;
    const itemsecondimage = selectedItemlist.itemsecondimage;
    const itemdescription = selectedItemlist.itemdescription;
    const itemshortdescription = selectedItemlist.itemshortdescription || null;
    const itemtype = selectedItemlist.itemtype;
    const itemrate = selectedItemlist.itemrate;
    const itemuom = selectedItemlist.itemuom;
    const itemdiscount = selectedItemlist.itemdiscount;
    const costperunit = selectedItemlist.costperunit;
    const quantity = selectedItemlist.quantity;
    const totalcost = selectedItemlist.totalcost;
    const itemdiscountamount = selectedItemlist.itemdiscountamount;
    values.forEach(function (value, i) {
      let obj = {};
      obj.id = id[i] || null;
      obj.itemname = itemname[i] || null;
      obj.itemimage = itemimage[i] || null;
      obj.itemsecondimage = itemsecondimage[i] || null;
      obj.itemdescription = itemdescription[i] || null;
      obj.itemshortdescription = itemshortdescription[i] || null;
      obj.itemtype = itemtype[i] || null;
      obj.itemrate = itemrate[i] || null;
      obj.itemuom = itemuom[i] || null;
      obj.itemdiscount = itemdiscount[i] || null;
      obj.costperunit = costperunit[i] || null;
      obj.quantity = quantity[i] || null;
      obj.totalcost = totalcost[i] || null;
      obj.itemdiscountamount = itemdiscountamount[i] || null;
      AddedIteminfo.push(obj);
    });
    let data = {
      customerdetail,
      AddedIteminfo,
      quotationnumber,
      subject,
      gst,
      values,
      date,
      totalvalue,
      tcharge,
      transportationgst,
      tcs,
      tcsamount,
      transitinsurance,
      ws,
      user_email,
      loadingcharge,
      weighttons,
      scheduledays
    };
    console.log(transportationgst)
    data['quotationnumber'] = data['quotationnumber'] == '' ? localStorage.getItem('QTno') : data['quotationnumber']
    data['customeraddress'] = document.getElementById('customeraddress').value ? document.getElementById('customeraddress').value : ''

    this.setState({ loading: true });

    if (localStorage.getItem('customerId') != null) {
      let date = (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()) +
        "/" + (new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) + "/" + new Date().getFullYear();

      let obj = {}
      obj['customerid'] = localStorage.getItem('customerId')
      obj['Qno'] = this.state.data.quotationnumber
      obj['quotation'] = date



      if (this.props.ID != undefined) {
        console.log(data)
        this.props
          .submit(data)
          .then(() => {
            this.dataHandler(false)
            this.setState({ loading: false, errors: {}, success: true });
            this.onShowAlert();
          })
          .catch(err => {
            this.setState({ loading: false, errors: {}, success: true });
            if (err.response) {
              this.setState({ errors: err.response.data.errors, loading: false });
              this.onShowAlert();
            }

          });

      }
      else {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/customerTransaction/addcustomertransaction`, obj)
          .then(res => {
            this.props
              .submit(data)
              .then(() => {
                this.setState({ loading: false, errors: {}, success: true });
                this.myFormRef.reset();
                this.onShowAlert();
              })
              .catch(err => {
                this.setState({ errors: err.response.data.errors, loading: false });
                this.onShowAlert();
              });
          },
            error => {
              this.setState({ errors: error.response.data.errors, loading: false });
              this.onShowAlert();
            });
      }
    }
    else {
      this.props
        .submit(data)
        .then(() => {
          this.setState({ loading: false, errors: {}, success: true });
          this.myFormRef.reset();
          this.onShowAlert();
        })
        .catch(err => {
          this.setState({ errors: err.response.data.errors, loading: false });
          this.onShowAlert();
        });
    }
  };

  updateQuotation(id) {
    if (id != '') {
      localStorage.setItem('customerId', id)
      // this.setState({ loading: true,transaction:[] });
      // axios
      // .get(`${process.env.REACT_APP_BASE_URL}/customerTransaction/getcustomertransaction/` + id)
      // .then(res => {
      //   this.setState({ loading: false });
      //   if(res.data.customerlist.customerdata.length > 0){
      //     let customervalues = res.data.customerlist.customerdata;
      //     if(customervalues.length > 0){
      //       customervalues = customervalues.filter((item,index) =>{
      //         return index == customervalues.length-1
      //       })
      //       if(customervalues.length == 1){
      //         this.setState({ transaction: customervalues },() =>{
      //           console.log(this.state.transaction)
      //         });
      //       }
      //     }
      //   }
      // },
      // error =>{
      //   this.setState({ loading: false });
      // });
    }

  }

  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
    });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  handleInvalidSubmit = () => {
    console.log("invalid submit");
  };

  render() {
    const customStyles = {
      control: base => ({
        ...base,
        height: 35,
        minHeight: 35
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '35px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '35px',
      }),
    };
    const { data, selectedCustomerID, selectedCustomerName, errors, loading, success, visible, isVisible } = this.state;
    const successmsg = this.props.ID != undefined ? 'Quotation is updated successfully' : 'Quotation is added successfully'
    let selCustVal = {};
    if (selectedCustomerID) {
      selCustVal = {
        value: selectedCustomerID, label: selectedCustomerName
      }
    }
    const customerID = selectedCustomerID ? (
      //   <AvField
      //   Label
      //   type="select"
      //   name={`customername`}
      //   value={data.customerdetail.id}
      //   required={true}
      //   onChange={e => this.handleChange(e)}
      // >
      //   <option value="">select customer name</option>
      //   {this.createSelectcustomer()}
      // </AvField>
      <Select name={`customername`} classNamePrefix="select" onChange={this.handleChange.bind(this)} styles={customStyles} required value={selCustVal} options={this.state.customerlistSelect} />
    ) : (
      //   <AvField
      //   Label
      //   type="select"
      //   name={`customername`}

      //   required={true}
      //   onChange={e => this.handleChange(e)}
      // >
      //   <option value="">select customer name</option>
      //   {this.createSelectcustomer()}
      // </AvField>
      <Select name={`customername`} classNamePrefix="select" onChange={this.handleChange.bind(this)} styles={customStyles} required options={this.state.customerlistSelect} />
    )

    const customerNumber = data.customerdetail ? (
      <AvField
        Label
        name="customernumber"
        type="number"
        readOnly
        id="customernumber"
        value={data.customerdetail.customernumber}
        onChange={this.onCustomerChange}
        validate={{
          required: {
            value: true,
            errorMessage: "Please enter Customer Number"
          },

          pattern: {
            value: "^[0-9]+$",
            errorMessage: "Please enter valid Number"
          }
        }}
      />
    ) : (
      <AvField
        Label
        name="customernumber"
        type="number"
        readOnly
        id="customernumber"
        onChange={this.onCustomerChange}
        validate={{
          required: {
            value: true,
            errorMessage: "Please enter Customer Number"
          },

          pattern: {
            value: "^[0-9]+$",
            errorMessage: "Please enter valid Number"
          }
        }}
      />
    )



    const customerGST = data.customerdetail ? (
      <AvField
        Label
        name="customergstnumber"
        type="text" readOnly
        id="customergstnumber"
        value={data.customerdetail.customergstnumber}
        onChange={e => this.onCustomerChange}
        validate={{
          minLength: {
            value: 15,
            errorMessage:
              "Customer GST Number should contain 15 characters"
          },
          maxLength: {
            value: 15,
            errorMessage:
              "Customer GST Number should contain 15 characters"
          },
          pattern: {
            value: "^[a-zA-z0-9]+$",
            errorMessage: "Please enter valid GST Number"
          }
        }}
      />
    ) : (
      <AvField
        Label
        name="customergstnumber"
        type="text"
        readOnly
        id="customergstnumber"
        onChange={e => this.onCustomerChange}
        validate={{
          minLength: {
            value: 15,
            errorMessage:
              "Customer GST Number should contain 15 characters"
          },
          maxLength: {
            value: 15,
            errorMessage:
              "Customer GST Number should contain 15 characters"
          },
          pattern: {
            value: "^[a-zA-z0-9]+$",
            errorMessage: "Please enter valid GST Number"
          }
        }}
      />
    )



    const customerAddress = data.customerdetail ? (
      <AvField
        Label
        name="customeraddress"
        type="text"
        readOnly
        id="customeraddress"
        value={
          data.customerdetail.customeraddress1 +
          " " +
          data.customerdetail.customeraddress2 +
          " " +
          data.customerdetail.city +
          " " +
          data.customerdetail.state +
          " " +
          data.customerdetail.country +
          " " +
          data.customerdetail.pincode
        }
        onChange={this.onCustomerChange}
      />
    ) : (
      <AvField
        Label
        name="customeraddress"
        type="text"
        readOnly
        id="customeraddress"

        onChange={this.onCustomerChange}
      />
    )





    const dataelem = isVisible ? (

      <div className="purchaseorder-item-row">
        {data.values.map((values, index) => {

          let optionsel = "";
          let SelVal = {};
          if (values.id != undefined) {
            if (values.itemshortdescription) {
              optionsel = values.itemshortdescription;
            }
            else {
              optionsel = values.itemdescription;
            }
            SelVal = {
              value: values.id._id, label: optionsel
            }
          }
          const itemnameinfo = values.id != undefined ? (
            // <AvField
            //   type="select"
            //   value={values.id._id}
            //   name={`selectitem${index}`}
            //   onChange={e => this.selectitemchange(e, index)}
            //   required={true}
            // >
            //   <option value="">select Item</option>
            //   {this.createSelectItems()}
            // </AvField>
            <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index)} styles={customStyles} required value={SelVal} options={this.state.itemlistSelectedOption} />
          ) :
            (
              // <AvField
              //   type="select"
              //   name={`selectitem${index}`}
              //   value={
              //     data.selectedItemlist.id[index]
              //   }
              //   onChange={e => this.selectitemchange(e, index)}
              //   required={true}
              // >
              //   <option value="">select Item</option>
              //   {this.createSelectItems()}
              // </AvField>
              <Select name={`selectitem${index}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, index)} styles={customStyles} required defaultValue={data.selectedItemlist.id[index]} options={this.state.itemlistSelectedOption} />
            )
          return (
            <div key={index}>

              <div className="purchaseorder-table">
                <Row key={index}>
                  <Col sm={2}>

                    <AvGroup>

                      <Label for="selectitem">Item Code</Label>
                      <span className="required-label">*</span>
                      {itemnameinfo}


                    </AvGroup>
                  </Col>
                  <Col sm={2}>
                    <AvGroup>
                      <Label for={`masteritemdescription${index}`}>
                        Description
                      </Label>
                      <span className="required-label">*</span>
                      <AvField
                        name={`masteritemdescription${index}`}
                        type="text"
                        readOnly
                        id="masteritemdescription"
                        value={
                          data.selectedItemlist.itemdescription[index]
                        }
                        onChange={this.onChange}
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "Please enter Item Description"
                          }


                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label for={`masteritemtype${index}`}>Type</Label>
                      <span className="required-label">*</span>
                      <AvField
                        style={{ width: "150px" }}
                        type="select"
                        readOnly
                        name={`masteritemtype${index}`}
                        onChange={this.onChange}
                        required={true}
                        value={
                          data.selectedItemlist.itemtype[index]
                        }
                      >
                        <option value="">select</option>
                        {this.createSelectItemTypes()}
                      </AvField>
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label for={`masteritemuom${index}`}>UOM</Label>
                      <span className="required-label">*</span>
                      <AvField
                        type="select"
                        readOnly
                        name={`masteritemuom${index}`}
                        onChange={this.onChange}
                        required={true}
                        value={
                          data.selectedItemlist.itemuom[index]
                        }
                      >
                        <option value="">uom</option>
                        {this.createSelectUomItems()}
                      </AvField>
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <AvGroup>
                      <Label for={`discount${index}`}>Discount %</Label>
                      <span className="required-label">*</span>
                      <AvField
                        name={`itemdiscount${index}`}
                        type="number"
                        id={`itemdiscount${index}`}
                        value={
                          data.selectedItemlist.itemdiscount[index]
                        }
                        onChange={e =>
                          this.itemdiscountChange(e, index)
                        }
                        validate={{
                          minLength: {
                            value: 1,
                            errorMessage:
                              "item Discount value is required"
                          },
                          maxLength: { value: 100 },
                          pattern: {
                            value: "^[0-9.]*$",
                            errorMessage: "Please enter valid value"
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>

                  <Col sm={1}>
                    <AvGroup>
                      <Label for={`quantity${index}`}>Quantity</Label>
                      <span className="required-label">*</span>
                      <AvField
                        name={`quantity${index}`}
                        type="number"
                        id={`quantity${index}`}
                        value={
                          data.selectedItemlist.quantity[index]
                        }
                        onChange={e => this.quantityChange(e, index)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Item Quantity"
                          },
                          minLength: {
                            value: 1,
                            errorMessage: "quantity value is required"
                          },
                          pattern: {
                            value: "^[0-9.]*$",
                            errorMessage: "Please enter valid quantity"
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>

                  <Col sm={1}>
                    <AvGroup>
                      <Label for={`costperunit${index}`}>
                        MRP Cost
                      </Label>
                      <AvField
                        name={`costperunit${index}`}
                        type="number"
                        id={`costperunit${index}`}
                        value={
                          data.selectedItemlist.costperunit[index]
                        }
                        onChange={e => this.unitChange(e, index)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Please enter cost'
                          },

                          pattern: {
                            value: "^[0-9.]*$",
                            errorMessage:
                              "Please enter valid costperunit"
                          }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  {/* Discount cost */}
                  <Col sm={1}>
                    <AvGroup>
                      <Label for={`itemdiscountamount${index}`}>
                        Discount Cost
                      </Label>
                      <AvField
                        name={`itemdiscountamount${index}`}
                        type="number"
                        id={`itemdiscountamount${index}`}
                        value={
                          data.selectedItemlist.itemdiscountamount[index]
                        }
                        onChange={e => this.unitChange(e, index)}
                      />
                    </AvGroup>
                  </Col>
                  {/* Discount cost */}

                  <Col sm={1}>
                    <AvGroup>
                      <Label for={`totalcost${index}`}>
                        Total Cost
                      </Label>
                      <AvField
                        name={`totalcost${index}`}
                        type="number"
                        id={`totalcost${index}`}
                        value={
                          data.selectedItemlist.totalcost[index]
                            ? data.selectedItemlist.totalcost[index]
                            : " "
                        }
                        onChange={e => this.onChange(e, index)}
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={1}>
                    <Button
                      type="button"
                      onClick={e => this.removeClick(e, index)}
                      style={{ marginTop: "30px" }}
                    >
                      -
                    </Button>
                  </Col>
                </Row>
              </div>


            </div>
          );
        })}
      </div>


    ) : null



    return (
      <div>
        <div>

          {loading ? <CustomLoader /> : null}
          {!!success ? (
            // <Alert color="info" isOpen={visible}>
            //   Quotation is added successfully
            // </Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Quotation</ModalHeader>
              <ModalBody >{successmsg}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggle}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <div>
          {!!errors.quotationnumber ? (
            <Alert color="danger" isOpen={visible} Label>
              Quotation number should have unique value.
            </Alert>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader Label>Quotation</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="ponumber">Quotation Number</Label>
                    <span className="required-label">*</span>
                    <AvField
                      Label
                      name="quotationnumber"
                      type="number"
                      id="quotationnumber"
                      value={data.quotationnumber || null}
                      onChange={this.onhandlerChange}

                      validate={{
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Please enter valid Number"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <Label className='small-font-size' for="totalcost" Label>Total Value</Label>
                  <span className="required-label">*</span>
                  <AvField
                    Label
                    name="totalvalue"
                    type="Number"
                    id="totalcost"
                    value={data.totalvalue || " " || null}
                    onChange={e => this.onhandlerChange(e)}
                    readOnly
                  />
                </Col>
                <Col sm={1}>
                  <Label className='small-font-size' for="date">Date</Label>
                  <span className="required-label">*</span>
                  <DatePicker
                    autoComplete="new-password"
                    selected={data.date}
                    onChange={this.handleChangeDeliveryDate}
                    className="form-control small-font-size"
                    dateFormat="dd/MM/yyyy"
                    id="startdate"
                  />
                </Col>
                <Col sm={1}>
                  <Label className='small-font-size' for="gst">Tax %</Label>
                  <span className="required-label">*</span>
                  <AvField
                    Label F
                    name="gst"
                    type="Number"
                    id="gst"
                    value={data.gst || null}
                    onChange={e => this.onhandlerChange(e)}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter Tax Value in Percentage"
                      },
                      pattern: {
                        value: "^[0-9.]+$",
                        errorMessage: "Please enter valid value"
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customername">Customer Name</Label>
                    <span className="required-label">*</span>
                    {/* <AvField
                      Label
                      type="select"
                      name={`customername`}
                      value={data.customerdetail.id || null}
                      required={true}
                      onChange={e => this.handleChange(e)}
                    >
                      <option value="">select customer name</option>
                      {this.createSelectcustomer()}
                    </AvField> */}
                    {customerID}
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customernumber">Customer Number</Label>
                    <span className="required-label">*</span>
                    {customerNumber}
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customergstnumber">Customer GST </Label>
                    {customerGST}
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="customeraddress">Customer Address</Label>
                    {customerAddress}
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="scheduledays">Delivery Schedule</Label>
                    <AvField
                      name="scheduledays"
                      type="text"
                      id="scheduledays"
                      value={data.scheduledays || ""}
                      onChange={this.onSubjectHandler}

                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="subject">Subject</Label>

                    <AvField
                      Label
                      name="subject"
                      type="text"
                      id="subject"
                      value={data.subject || ""}
                      onChange={this.onSubjectHandler}

                    />
                  </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="loadingcharge">
                      Loading and Handling Charges </Label>
                    <span className="required-label">*</span>
                    <AvField
                      Label
                      name="loadingcharge"
                      type="number"
                      id="loadingcharge"
                      value={data.loadingcharge || ""}
                      onChange={this.onSubjectHandler}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Loading and Handling Charges"
                        }


                      }}
                    />
                  </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="weighttons">
                      Total Weight(in tons) </Label>
                    <span className="required-label">*</span>
                    <AvField
                      Label
                      name="weighttons"
                      type="number"
                      id="weighttons"
                      value={data.weighttons || ""}
                      onChange={this.onSubjectHandler}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Weight in Tons"
                        }


                      }}
                    />
                  </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="ws">
                      W.S</Label>
                    <span className="required-label">*</span>
                    <AvField
                      Label
                      name="ws"
                      type="number"
                      id="ws"
                      value={data.ws || ""}
                      onChange={this.onSubjectHandler}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter W.S"
                        }


                      }}
                    />
                  </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="transitinsurance">
                      Transit Insurance</Label>

                    <AvField
                      Label
                      name="transitinsurance"
                      type="number"
                      id="transitinsurance"
                      value={data.transitinsurance || ""}
                      onChange={this.onSubjectHandler}

                    />
                  </AvGroup>
                </Col>




                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="tcharge">
                      Transportation Charges</Label>

                    <AvField
                      Label
                      name="tcharge"
                      type="number"
                      id="tcharge"
                      value={data.tcharge || ""}
                      onChange={this.onSubjectHandler}

                    />
                  </AvGroup>
                </Col>

                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="transportationgst">Transportation GST(%) </Label>
                    <AvField
                      Label
                      name="transportationgst"
                      type="number"
                      id="transportationgst"
                      value={data.transportationgst || ""}
                      onChange={this.onSubjectHandler}

                    />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <AvGroup>
                    <Label className='small-font-size' for='tcs'>TCS</Label>
                    <AvField
                      Label
                      name='tcs'
                      type='checkbox'
                      id='tcs'
                      value={this.state.tcshecked}
                      onChange={e => this.onhandletcsChange(e)}
                    />
                  </AvGroup>
                </Col>


              </Row>
              {!!data.tcs ? (
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for="tcsamount">TCS Amount</Label>
                    <span className="required-label">*</span>
                    <AvField
                      Label
                      name="tcsamount"
                      type="number"
                      id="tcsamount"
                      value={data.tcsamount || ""}
                      onChange={this.onTcsChange}

                    />
                  </AvGroup>
                </Col>
              </Row> ) : null }
              <Row>
                <div className="mg-t-20">
                  <Button color="info" onClick={this.handleClick} type="button">
                    Add Item
                  </Button>
                </div>
              </Row>

              {dataelem}

              <Row>
                <div className="mg-20">
                  <Button
                    Label
                    color="primary"
                    disabled={loading}
                    className="lgn-loader"
                  >
                    Submit
                    {/* {loading} */}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerlistvalues: state.customerlistvalues,
    masteritemlistvalues: state.masteritemlistvalues,
    masteritemtypelistvalues: state.masteritemtypenamevalue,
    uomlistvalues: state.uomlistvalue,
    locationlistvalue: state.locationlistvalue,
    user: state.user,
    quotationlistvalues: state.quotationlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(QuotationForm);
