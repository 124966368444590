import { normalize } from "normalizr";
import api from "../Services/invoiceapi";
import { ADD_INVOICE, INVOICE_FETCHED,PROFORMAINVOICE_FETCHED } from "../Services/types";
import { invoiceListSchema,proformainvoiceListSchema } from "../Schemas/schemas";

const invoiceCreated = invoicelist => {
  return {
    type: ADD_INVOICE,
    invoicelist
  };
};

const invoiceFetched = invoicelist => {
  return {
    type: INVOICE_FETCHED,
    invoicelist
  };
};

const proformainvoiceFetched = proformainvoicelist => {
  return {
    type: PROFORMAINVOICE_FETCHED,
    proformainvoicelist
  };
};

export const addinvoice = data => {
  return dispatch => {
    return api.invoice.addinvoice(data).then(invoicelistvalues => {
      return dispatch(
        invoiceCreated(normalize(invoicelistvalues, invoiceListSchema))
      );
    });
  };
};

export const updateinvoice = data => {
  return dispatch => {
    return api.invoice.updateinvoice(data).then(invoicelistvalues => {
      return dispatch(
        invoiceCreated(normalize(invoicelistvalues, invoiceListSchema))
      );
    });
  };
};



export const getinvoice = () => {
  return dispatch => {
    return api.invoice.getinvoice().then(invoicelist => {
      const invoicelistvalues = invoicelist.invoicedata;
      console.log(invoicelistvalues)
      return dispatch(
        invoiceFetched(normalize(invoicelistvalues, [invoiceListSchema]))
      );
    });
  };
};

export const getproformainvoice = () => {
  return dispatch => {
    return api.invoice.getproformainvoice().then(invoicelist => {
      const proformainvoicelist = invoicelist.invoicedata;
      console.log(proformainvoicelist)
      return dispatch(
        proformainvoiceFetched(normalize(proformainvoicelist, [proformainvoiceListSchema]))
      );
    });
  };
};

export const searchquotation = customerid => {
  return dispatch => {
    return api.quotation.searchquotation(customerid);
  };
};
