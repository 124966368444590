import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import MasterItemListForm from "../Form/ItemList/AddItemListForm";
import ViewMasterItemList from "./ViewMasterItemList";
import {
  addMasterItemList,
  getMasterItemList,
  getMasterItemTypes
} from "../../../../actions/Transaction";
import { getUomList } from "../../../../actions/uom";
import CustomLoader from "../../../../Components/loader/loader";

class AddmasterItemList extends React.Component {
  state = {
    loading: false
  };
  componentDidMount() {
    this.props.getMasterItemTypes();
    this.props.getUomList();
    this.props.getMasterItemList();
  }
  submit = data => {
    this.dataHandler(true);
    return this.props.addMasterItemList(data).then(data => {
      this.dataHandler(false);
      this.props.getMasterItemList();
      return data;
    });
  };
  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Item</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <MasterItemListForm submit={this.submit} error={this.errorHandler}/>
        </div>
        {loading ? (
        <CustomLoader/>
        ) : null}
        {/* <div>
          <ViewMasterItemList />
        </div> */}
      </div>
    );
  }
}

AddmasterItemList.propTypes = {
  addMasterItemList: PropTypes.func.isRequired,
  getMasterItemList: PropTypes.func.isRequired
};

export default connect(
  null,
  { addMasterItemList, getMasterItemList, getUomList, getMasterItemTypes }
)(AddmasterItemList);
