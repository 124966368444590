
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
class CRMPage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/transaction_page'>Transaction</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>CRM</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
          

            <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/enquiry'>
                    <i className='fas fa-envelope fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>New Enquiry</span>
                    </div>
                    </Link>
                </div>
                </li>
                     
                <li className='bg-color-lg-blue'>
                  <div className='icon-eleme'>
                    <Link to='/quotationpage'>
                      <i className='fas fa-credit-card fa-3x'></i>
                      <div style={{ paddingTop: '5px' }}>
                        <span>Quotations</span>
                      </div>
                    </Link>
                  </div>
                </li>
                <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/customerlist'>
                    <i className='fas fa-users fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>Customer Transaction</span>
                    </div>
                    </Link>
                </div>
            </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default CRMPage;
