import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import SupplierContactForm from "../SupplierForm/SupplierContact";
import {
  addSupplierContact,
  getSupplierContact
} from "../../../../actions/Supplier";

class SupplierContact extends React.Component {
  componentDidMount() {
    this.props.getSupplierContact();
  }
  submit = data => {
    return this.props.addSupplierContact(data);
  };
  render() {
    return (
      <div className="container mg-t-20">
        <div>
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/admin_dashboard">Admin Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/transaction_page">Transaction</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/supplier">Supplier</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Supplier Contact</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <div>
          <SupplierContactForm submit={this.submit} />
        </div>
      </div>
    );
  }
}

SupplierContact.propTypes = {
  addSupplierContact: PropTypes.func.isRequired,
  getSupplierContact: PropTypes.func.isRequired
};

export default connect(
  null,
  { addSupplierContact, getSupplierContact }
)(SupplierContact);
