import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Label, Alert } from "reactstrap";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

class ItemQuantitySearch extends React.Component {
  state = {
    data: {
      locationid: "",
      itemid: ""
    },
    locationlistvalue: [],
    masteritemlistvalues: [],
    loading: false
  };
  componentDidMount() {
    const locationlistvalue = Object.values(this.props.locationlistvalue);
    const masteritemlistvalues = Object.values(this.props.masteritemlistvalues);
    this.setState({
      ...this.state,
      locationlistvalue,
      masteritemlistvalues
    });
  }
  componentWillReceiveProps(props) {
    const locationlistvalue = Object.values(props.locationlistvalue);
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);
    this.setState({
      ...this.state,
      locationlistvalue,
      masteritemlistvalues
    });
  }
  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  filterItems(itemarray, key) {
    return itemarray.sort((a, b) => { return a[key].toLowerCase().localeCompare(b[key].toLowerCase()) })
  }

  createSelectItems = () => {
    let items = [];
    let masteritemlistvalues = this.state.masteritemlistvalues;
    console.log('XXXXXXXXXXXXX');
    masteritemlistvalues = this.filterItems(masteritemlistvalues, 'masteritemtypename')
    masteritemlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };
  selectitemchange = () => { };
  
  onhandlerChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  handleValidSubmit = () => {
    const { data } = this.state;
    this.props
      .submit(data)
      .then(() => {
        this.setState({
          ...this.state,
          loading: false
        });
      })
      .catch(err => { });
  };
  render() {
    const { loading } = this.state;
    return (
      <div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader Label>Search Item Quantity</CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size'>location</Label>
                    <AvField
                      Label
                      type="select"
                      name="locationid"
                      onChange={this.onhandlerChange}
                    >
                      <option value="">select location</option>
                      {this.createSelectLocationItems()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size'>Item</Label>
                    <AvField
                      Label
                      type="select"
                      name={`itemid`}
                      onChange={this.onhandlerChange}
                    >
                      <option value="">select Item</option>
                      {this.createSelectItems()}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <Button
                    Label
                    color="primary"
                    disabled={loading}
                    type="submit"
                    onClick={this.submit}
                    style={{ marginTop: "30px" }}
                  >
                    Find
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationlistvalue: state.locationlistvalue,
    masteritemlistvalues: state.masteritemlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(ItemQuantitySearch);
