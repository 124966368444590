import React from "react";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col
} from "reactstrap";

class ProductBilling extends React.Component {
  state = {
    data: {
      product: "",
      profit: "",
      qty: "",
      labourcost: ""
    },
    productlistvalues: [],
    errors: {},
    materiallistvalues: {},
    loading: false,
    success: false
  };

  componentDidMount() {
    const productlistvalues = Object.values(this.props.productlistvalues);
    this.setState({ ...this.state, productlistvalues });
  }

  componentWillReceiveProps(props) {
    const productlistvalues = Object.values(props.productlistvalues);
    const materiallistvalues = props.materiallistvalues;
    this.setState({ ...this.state, productlistvalues, materiallistvalues });
  }

  createSelectItems = () => {
    let items = [];
    const productlistvalues = this.state.productlistvalues;
    const materiallistvalues = this.state.materiallistvalues;
    productlistvalues
      .map((option, i) => option)
      .filter((option, i) => {
        if (materiallistvalues[option.productname._id]) {
          items.push(
            <option key={i} value={option.id}>
              {materiallistvalues[option.productname._id].masteritemdescription}
            </option>
          );
        }
      });
    return items;
  };

  onChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  handleValidSubmit = () => {
    this.setState({ loading: true });
    const data = this.props.submit(this.state.data);
    console.log(data);
    if (data) {
      this.setState({ loading: false, errors: {}, success: true });
    }
  };

  render() {
    const { data, errors, loading, success } = this.state;
    return (
      <div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>Product Billing</CardHeader>
            <CardBody>
              <Row>
                <Col sm={6}>
                  <AvGroup>
                    <Label className='small-font-size' for="product">Product Id</Label>
                    <span className="required-label">*</span>
                    <AvField
                      type="select"
                      name="product"
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="">Please select value</option>
                      {this.createSelectItems()}
                    </AvField>
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for="profit">Profit %</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="profit"
                      type="text"
                      id="profit"
                      value={data.profit}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter profit value"
                        },
                        minLength: {
                          value: 1,
                          errorMessage:
                            "profit value should contain atleast 1 charcter"
                        },
                        pattern: {
                          value: "^[0-9]",
                          errorMessage: "Please enter valid number"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={6}>
                  <AvGroup>
                    <Label className='small-font-size' for="qty">Quantity</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="qty"
                      type="text"
                      id="qty"
                      value={data.qty}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter product quantity value"
                        },
                        minLength: {
                          value: 1,
                          errorMessage:
                            "quantity value should contain atleast 1 charcter"
                        },
                        pattern: {
                          value: "^[0-9]",
                          errorMessage: "Please enter valid number"
                        }
                      }}
                    />
                  </AvGroup>
                  <AvGroup>
                    <Label className='small-font-size' for="labourcost">Labour Cost</Label>
                    <span className="required-label">*</span>
                    <AvField
                      name="labourcost"
                      type="text"
                      id="labourcost"
                      value={data.labourcost}
                      onChange={this.onChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter labour cost value"
                        },
                        minLength: {
                          value: 1,
                          errorMessage:
                            "labour cost value should contain atleast 1 charcter"
                        },
                        pattern: {
                          value: "^[0-9]",
                          errorMessage: "Please enter valid number"
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="mg-20" style={{'textAlign':'center'}}>
            <Button color="primary" disabled={loading} className="lgn-loader">
              Submit
              {/* {loading} */}
            </Button>
          </div>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productlistvalues: state.productlistvalues,
    materiallistvalues: state.masteritemlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(ProductBilling);
