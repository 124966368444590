import { normalize } from "normalizr";
import api from "../Services/api";
import setAuthorizationHeader from "../utils/setAuthorizationHeader";
import jwtDecode from "jwt-decode";
import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_FETCHED
} from "../Services/types";
import { userSchema } from "../Schemas/schemas";

export const userLoggedIn = user => {
  return {
    type: USER_LOGGED_IN,
    user: user
  };
};

export const userLoggedOut = () => {
  return {
    type: USER_LOGGED_OUT,
    user: {}
  };
};

export const userFetched = userlist => {
  return {
    type: USER_FETCHED,
    userlist
  };
};

export const userRegister = data => {
  return dispatch => {
    return api.user.userRegister(data);
  };
};

export const getUser = () => {
  console.log("dispatch action calling");
  return dispatch => {
    return api.user.getUser().then(userlist => {
      const userlistvalue = userlist.userdata;
      return dispatch(userFetched(normalize(userlistvalue, [userSchema])));
    });
  };
};

export const updateUser = data => {
  return dispatch => {
    return api.user.updateUser(data);
  };
};

export const deleteUser = id => {
  return dispatch => {
    return api.user.deleteUser(id);
  }
}

export const login = credentials => {
  return dispatch => {
    return api.user.login(credentials).then(user => {
      localStorage.Mob_JWT = user.token;
      setAuthorizationHeader(user.token);
      var decoded = jwtDecode(localStorage.Mob_JWT);
      console.log("Decoded ", decoded)
      const userinfo = {
        email: decoded.email,
        role: decoded.role,
        second_role: decoded.second_role,
        token: localStorage.Mob_JWT
      };
      dispatch(userLoggedIn(userinfo));
    });
  };
};

export const logout = e => {
  return dispatch => {
    localStorage.removeItem("Mob_JWT");
    dispatch(userLoggedOut());
  };
};
