import axios from 'axios';

export default {
  order: {
    addorder: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/order/addorder`, {
          data
        })
        .then(res => {
          return res.data.orderdata;
        });
    },

    updateaddorder: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/order/updateworkorder`, {
          data
        })
        .then(res => {
          return true;
        });
    },

    getorder: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/order/getorder`)
        .then(res => {
          return res.data.orderlist;
        });
    }
  }
};
