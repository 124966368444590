
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { getproformainvoice } from '../../../../actions/Invoice';
import { Button, Alert } from 'reactstrap';
import DataTable from '../../../../Components/Table/DatatablePage';
import CustomLoader from '../../../../Components/loader/loader';
import axios from 'axios';

import {
  getMasterItemList,
  getMasterItemTypes
} from "../../../../actions/Transaction";

import { getUomList } from "../../../../actions/uom";
import { getCustomerList } from "../../../../actions/customerContact";


import { getChallan } from '../../../../actions/challan';
import { tr } from 'date-fns/esm/locale';

class ViewProformaInvoice extends React.Component {
  state = {
    count: 0,
    loading: true,
    success:false,
    invoiceid:'',
    proformainvoicelistvalues: [],
    invoicelistvalues: []
  };
  componentDidMount() {


    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getproformainvoice();

    this.props.getUomList();
    this.getProformaInvoice();
    this.props.getChallan();
  }


  getProformaInvoice(){
     axios
        .get(`${process.env.REACT_APP_BASE_URL}/proformainvoice/getproformainvoice`)
        .then(res => {
         console.log(res)
         this.dataHandler(false);
         if (res.data.invoicelist.invoicedata.length > 0) {
           let invoicelist = res.data.invoicelist.invoicedata;
           //invoicelist= invoicelist.sort((a, b) => b.invoicenumber.toLowerCase().localeCompare(a.invoicenumber.toLowerCase()));
           invoicelist.sort(function(a, b){
            return parseInt(b.invoicenumber) - parseInt(a.invoicenumber);
          });
          const proformainvoicelistvalues = invoicelist.map(quotationvalue => {
            return {
              invoicenumber: quotationvalue.invoicenumber,
              customername: quotationvalue.customername,
              customernumber: quotationvalue.customernumber,
              customercontactnumber: quotationvalue.customercontactnumber,
      
              view: (
                <div className="actionbuttons">
                  <Button
                    Label
                    color='primary'
                    onClick={() => {
                      this.updateButton(quotationvalue);
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    Label
                    color='primary'
                    onClick={() => {
                      this.updateinvoice(quotationvalue._id);
                    }}
                  >
                    Edit
                  </Button>
                  </div>
              )
            };
          });
          this.setState({ ...this.state, proformainvoicelistvalues });
         }
        },error =>{
          this.dataHandler(false)
         
        });
  }

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };


  updateButton = obj => {
    sessionStorage.setItem("pformadata",JSON.stringify(obj))
    let path = `/view_proformainvoicepdf/123`;
    this.props.history.push(path);
  };

  originalInvoice = () =>{
    let path = `/view_invoicepdf/${this.state.invoiceid}`;
    this.props.history.push(path);
  }

  profomaInvoice = () =>{
    let path = `/view_proformainvoicepdf/${this.state.invoiceid}`;
    this.props.history.push(path);
  }

  updateinvoice = id => {
    // let path = `/view_invoicepdf/${id}`;
    // window.open(path, '_blank');
    let path = `/editproformainvoice/${id}`;
    //window.open(path, "_blank");
    this.props.history.push(path);
  };

  dataHandler = value => {
    this.setState({ loading: value });
  };

  render() {
    const { loading, count, proformainvoicelistvalues,success } = this.state;
    console.log(proformainvoicelistvalues)
    const columnvalue = [
      {
        label: 'Invoice Number',
        field: 'invoicenumber',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Customer Name',
        field: 'customername',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Customer Number',
        field: 'customernumber',
        sort: 'asc',
        width: 40
      },

      {
        label: 'Contact Number',
        field: 'customercontactnumber',
        sort: 'asc',
        width: 40
      },
      {
        label: 'Print/Edit',
        field: 'view',
        sort: 'asc',
        width: 40
      }
    ];
    return (
      <div className='container mg-t-20'>

          {!!success ? (
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Invoice</ModalHeader>
              <ModalBody style={{'textAlign': 'center'}}>Please select type of invoice</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.originalInvoice}>
                  Original Invoice
                </Button>
                <Button color="primary" onClick={this.profomaInvoice}>
                  Proforma Invoice
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}

        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/transaction_page">Transactions</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/invoice">Proforma Invoice</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>List</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <DataTable
            rowvalues={proformainvoicelistvalues}
            columnvalue={columnvalue}
            className="invoicelist" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    proformainvoicelistvalues: state.proformainvoicelistvalues
  };
}

export default connect(
  mapStateToProps,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    getCustomerList,
    getproformainvoice,
    getChallan
  }
)(ViewProformaInvoice);
