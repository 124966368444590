import { MATERIAL_VALUES_FETCHED } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case MATERIAL_VALUES_FETCHED:
      return {
        ...state,
        ...action.materialsvalues.entities.materialslistvalue
      };
    default:
      return state;
  }
}
