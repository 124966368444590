import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactPDF from "@react-pdf/renderer";
import moment from "moment";
import { PDFViewer } from "@react-pdf/renderer";
import { getquotation } from "../../../../actions/quotation";
import MyDocument from "./Component";
import logo from "../../../../Images/logo.png";
import axios from "axios";
import "./ViewQuotation.scss";

class ViewPurchaseOrderPdf extends React.Component {
  state = {
    quotationlistvalues: {},
    status: false,
    text: null
  };
  async componentDidMount() {
    this.props.getquotation();


    const quotationid = this.props.match.params.id;
    const quotationlistvalues = this.props.quotationlistvalues[quotationid];
    console.log("quotation values ",quotationlistvalues)
    await axios.get(`${process.env.REACT_APP_BASE_URL}/quotation/generatequotaionPDF/`+quotationlistvalues._id).then(
        (res) => {
          if(res.data) {
            this.setState({
                link: process.env.REACT_APP_IMAGE_BASE_URL+"/uploads/"+res.data.data+".pdf"
                // status : undefined,
              });
      
              setTimeout(() => {
                document.getElementById("D1").click();
                this.props.history.push("/quotationlist");
              }, 400);
          }
          else {
            quotationlistvalues.financialyear = "2020-21";          
          }
        },
        (error) => {
          quotationlistvalues.financialyear = "2020-21";
        }
    )
    const customerid = quotationlistvalues.customerid;
    const customernumber = quotationlistvalues.customernumber;
    const customername = quotationlistvalues.customername;
    console.log("--COMPONENTDIDMOUNT--");
    console.log(quotationlistvalues);
  }
  dynamicClick() {
    if (document.getElementsByClassName("pdf-class")) {
      if (
        document.getElementsByClassName("pdf-class")[0].children.length <= 1
      ) {
        setTimeout(() => {
          this.dynamicClick();
        }, 7000);
      } else if (
        document.getElementsByClassName("pdf-class")[0].children.length > 1
      ) {
        this.setState({
          link: document.getElementsByClassName("pdf-class")[0].children[1].src
          // status : undefined,
        });

        setTimeout(() => {
          document.getElementById("D1").click();
          this.props.history.push("/quotationlist");
        }, 200);
    
      }
    } else {
    }
  }



  render() {
    const data_cond = [
      {
        slno: "1",
        title: "Invoices",
        value: "2 copies Invoices (original & duplicate)"
      },
      {
        slno: "2",
        title: "Tax & Duty",
        value: "Included"
      },
      {
        slno: "3",
        title: "Payment Schedule",
        value: "15 days from the date of delivery"
      },
      {
        slno: "4",
        title: "Delivery Schedule",
        value: "Immediately"
      },
      {
        slno: "5",
        title: "Billing address & GST No",
        value:
          "M/s Pondhan Scaffolding Pvt Ltd \n Plot no. 40/41, Road no.5, Obedanahalli,"
      },
      {
        slno: "6",
        title: "Delivery Address",
        value: "M/s Pondhan Scaffolding Pvt Ltd"
      }
    ];
    const { status, quotationlistvalues,link,text } = this.state;
    return (
      <div>
        <div className="pdf-class">
        Downloading file.Please wait.
          <a href={link} download={text} id="D1"></a>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    quotationlistvalues: state.quotationlistvalues
  };
}
export default connect(
  mapStateToProps,
  { getquotation }
)(ViewPurchaseOrderPdf);
