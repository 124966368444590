import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import { getproducts } from "../../actions/Product";
import { getMasterItemList } from "../../actions/Transaction";
import BillingForm from "./BillingForm/BillingForm";
import BillingTable from "./BillingTable";

class ProductBilling extends React.Component {
  state = {
    productformvalues: {},
    productlistvalue: {},
    materiallistvalues: {},
    selectedProduct: ""
  };
  componentDidMount() {
    this.props.getproducts();
    this.props.getMasterItemList();
  }
  componentWillReceiveProps(props) {
    const productlistvalues = props.productlistvalues;
    const materiallistvalues = props.materiallistvalues;
    this.setState({ ...this.state, productlistvalues, materiallistvalues });
  }
  submit = productformvalues => {
    const selectedProduct = this.props.productlistvalues[
      productformvalues.product
    ];
    if (selectedProduct) {
      this.setState({ ...this.state, productformvalues, selectedProduct });
      return {
        success: { gloabl: "data exist" }
      };
    } else {
      return {
        errors: { global: "something went wrong" }
      };
    }
  };

  render() {
    const {
      selectedProduct,
      productformvalues,
      materiallistvalues
    } = this.state;

    return (
      <div className="container mg-20">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
            <Link to="/inventory_page">Setup</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Item Cost</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <BillingForm submit={this.submit} />
        {!!selectedProduct && (
          <BillingTable
            selectedProduct={selectedProduct}
            productformvalues={productformvalues}
            materiallistvalues={materiallistvalues}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productlistvalues: state.productlistvalues,
    materiallistvalues: state.masteritemlistvalues
  };
}

export default connect(
  mapStateToProps,
  { getproducts, getMasterItemList }
)(ProductBilling);
