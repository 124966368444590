import axios from 'axios';

export default {
  moveorder: {
    addmoveorder: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/moveorder/addmoveorder`, {
          data
        })
        .then(res => {
          return res.data.moveorderdata;
        });
    },
    getmoveorder: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/moveorder/getmoveorder`)
        .then(res => {
          return res.data.moveorderlist;
        });
    },
    getItemsMoveOrder: (id) => {
      return axios
      .get(`${process.env.REACT_APP_BASE_URL}/moveorder/getItemsMoveOrder/`+id)
      .then(res => {
        return res.data.itemlist;
      });
    }
  }
};
