import React from "react";
import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./DataTable.css";

const DatatablePage = props => {
  let scroll = false;
  if(props.scrollx) {
    scroll = props.scrollx
  }
  const data = {
    columns: props.columnvalue,
    rows: props.rowvalues
  };

  return (
    <MDBDataTable
      scrollX={scroll}
      striped
      bordered
      small
      data={data}
      className={props.className}
    />
  );
};

export default DatatablePage;
