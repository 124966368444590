
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
class ManufacturingPage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/transaction_page'>Transaction</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Manufacturing</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
            <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/work-order'>
              <i className='fas fa-shopping-cart fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Work Order</span>
              </div>
            </Link>
          </div>
        </li>
        <li className='bg-color-lg-blue'>
          <div className='icon-eleme'>
            <Link to='/production'>
              <i className='fas fa-industry fa-3x'></i>
              <div style={{ paddingTop: '5px' }}>
                <span>Production</span>
              </div>
            </Link>
          </div>
        </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default ManufacturingPage;
