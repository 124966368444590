import api from "../Services/api";


 export const addYear = data => {
    return dispatch => {
      return api.year.addyear(data);
    }
  }

  export const updateYear = data => {
    return dispatch => {
      return api.year.updateyear(data);
    }
  }