import axios from "axios";

export default {
  currency: {
    getCurrencyList: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/currency/getcurrency`)
        .then(res => {
          return res.data.currencylist;
        });
    }
  }
};
