import { CURRENCY_LIST } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case CURRENCY_LIST:
      return {
        ...state,
        ...action.currencylist.entities.currencylistvalue
      };
    default:
      return state;
  }
}
