import React from "react";
import { Link } from "react-router-dom";

class AdminDashboard extends React.Component {
  render() {
    return (
      <div className="container mg-20">
        <div className="admin-dashboard-icons">
          <ul className="clearfix">
            <li className="bg-colo-lg-red">
              <div className="icon-eleme">
                <Link to="/inventory_page">
                  <i className="fas fa-dolly-flatbed fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Setup</span>
                  </div>
                </Link>
              </div>
            </li>
            <li className="bg-color-lg-blue">
              <div className="icon-eleme">
                <Link to="/transaction_page">
                  <i className="fas fa-cubes fa-3x"></i>
                  <div style={{ paddingTop: "5px" }}>
                    <span>Transactions</span>
                  </div>
                </Link>
              </div>
            </li>

            {/* <li className="bg-color-lg-pk">
              <div className="icon-eleme">
                <Link to="/">
                  <i className="fas fa-user fa-3x"></i>
                </Link>
              </div>
            </li>
            <li className="bg-color-lg-org">
              <div className="icon-eleme">
                <Link to="/">
                  <i className="fas fa-users-cog fa-3x"></i>
                </Link>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

export default AdminDashboard;
