import axios from 'axios';

export default {
  challan: {
    addchallan: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/challan/addchallan`, {
          data
        })
        .then(res => {
          return res.data.challandata;
        });
    },
    updatechallan: data => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}/challan/updatechallan`, {
          data
        })
        .then(res => {
          return res.data.challandata;
        });
    },
    
    getchallan: () => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/challan/getchallan`)
        .then(res => {
          return res.data.challanlist;
        });
    }
  }
};
