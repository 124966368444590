import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import Select from "react-select";
import {
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";

class ProductionForm extends React.Component {
  state = {
    data: {
      selectedItemlist: {
        id: [null],
        itemname: [null],
        itemdescription: [null],
        itemtype: [null],
        itemrate: [null],
        itemuom: [null],
        itemlocation: [null],
        costperunit: [null],
        quantity: [null],
        totalcost: [null],
        remarks: [null],
        totalweight: [null],
        readystock: [null],
        firstround: [null],
        secondround: [null],
        thirdround: [null]
      },
      products: [],
      productionorderno: '',
      customerid: '',
      item: '',
      date: new Date(),
      quantity: '',
      productiondate: new Date(),
      subAssembly: [],
      values: ['']
    },
    errors: {},
    itemname:'',
    materialoptionlistvalues: [],
    productMaterial: [],
    customerlistvalues: [],
    productlistvalues: [],
    supplierdetaillistvalue: [],
    itemlistSelectedOption: [],
    supplierdetaillistvalueobject: {},
    masteritemlistvalues: [],
    masteritemlistvaluesobject: {},
    currencylistvalues: [],
    uom: [],
    locationlistvalue: [],
    customerlistSelect: [],
    loading: false,
    success: false
  };
  dummyProductMapping = {
    props: [
      { assembly: '50 NB H.R. Tubes', unit: 'Kgs' },
      { assembly: '40 NB H.R. Tubes', unit: 'Kgs' },
      { assembly: '6 mm MS Plate', unit: 'Kgs' },
      { assembly: 'Prop nut', unit: 'Kgs' }
    ]
  };

 

  componentWillReceiveProps(props) {
   

    const materialoptionlistvalues = Object.values(
      props.materialoptionlistvalues
    );
    // const supplierdetaillistvalue = Object.values(
    //   props.supplierdetaillistvalue
    // );
    // const supplierdetaillistvalueobject = props.supplierdetaillistvalue;
    const customerlistvalues = Object.values(this.props.customerlistvalues);
    const productlistvalues = Object.values(this.props.productlistvalues);
    console.log(productlistvalues)
    const masteritemlistvalues = Object.values(props.masteritemlistvalues);

    const masteritemlistvaluesobject = props.masteritemlistvalues;
    const currencylistvalues = Object.values(props.currencylistvalues);
    const uomlistvalues = Object.values(props.uomlistvalues);
    const locationlistvalue = Object.values(props.locationlistvalue);
    const purchaseorderlist = Object.keys(this.props.purchaseorderlistvalues);
    const productionlistvalues = Object.keys(this.props.productionlistvalues);
    const productionorderno =
      productionlistvalues.length > 0
        ? 1 + productionlistvalues.length
        : 1;
    let arraySet = [];    
    customerlistvalues.map((option, i) => {
      let objset = {
        value: option.id, label: option.customername
      }
      arraySet.push(objset);
    });


    let newArraySet = [];
    masteritemlistvalues.map((option, i) => {
      let name = "";
      if(option.masteritemshortdescription) {
        name = option.masteritemshortdescription;
      }
      else {
        name = option.masteritemdescription;
      }
      let objset = {
        value: option.id, label: name
      }
      newArraySet.push(objset);
    });


    this.setState({
      ...this.state,
      masteritemlistvalues,
      customerlistvalues,
      productlistvalues,
      itemlistSelectedOption: newArraySet,
      masteritemlistvaluesobject,
      materialoptionlistvalues,
      currencylistvalues,
      locationlistvalue,
      customerlistSelect: arraySet,
      uom: uomlistvalues,
      data: {
        ...this.state.data,
        productionorderno
      }
    },()=>{
      if(productionlistvalues.length == 0)
        this.getProddata()
    });
  }

  getProddata(){
    if(Object.keys(this.props.productionlistvalues).length == 0){
      axios
      .get(`${process.env.REACT_APP_BASE_URL}/production/getproduction`)
      .then(res => {
         //if (res.data.productionlist.productiondata.length > 0) {
           
          let productionorderno =
          res.data.productionlist.productiondata.length > 0 ? 1 + res.data.productionlist.productiondata.length : 1;
          
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              productionorderno
            }
          });
        //}
      },
        error => {
          this.setState({ success: false, message: 'Server error.' });
        });
    }
  }

  handleChangeDeliveryDate = date => {
    this.setState({
      data: {
        ...this.state.data,
        requestdeliverydate: date
      }
    });
  };
  handleChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  handleChangeCust = (e,index) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, customerid:  e.value}
    });
  }

  totalValue = () => {
    const { totalcost } = this.state.data.selectedItemlist;
    if (totalcost.length > 0) {
      const totalvalue = totalcost
        .map((value, i) => {
          if (value) {
            return parseFloat(value);
          } else {
            return 0;
          }
        })
        .reduce((a, b) => {
          return a + b;
        });
      this.setState({
        ...this.state.data,
        data: {
          ...this.state.data,
          totalvalue
        }
      });
    }
  };

  selectitemchange = (e, index) => {
    let products2 = this.state.data.products;
    console.log(products2[index].quantity)

    let item = e.label;
    console.log(item)
    const selected_id = e.value ? e.value : e;
    console.log(selected_id)
    let productMaterial2 = [],found=0;
    let obj = {},arr = []
    productMaterial2.push(this.props.masteritemlistvalues[selected_id])
    let itemname = productMaterial2[0].masteritemtypename
    console.log(productMaterial2)
 
    for(var i=0;i<this.state.productlistvalues.length;i++){
      if(this.state.productlistvalues[i].productname.toString().trim().toLowerCase() == productMaterial2[0].masteritemdescription.toString().trim().toLowerCase()){
        found = 1;
        break;
      }
    }
    if(found == 1){
      if(this.state.productlistvalues[i].productMaterial != null && this.state.productlistvalues[i].productMaterial.length > 0){
        this.state.productlistvalues[i].productMaterial.map((item,index) =>{
          console.log(item)
          obj = {}
          obj['masteritemdescription'] = item.productqty
          obj['masteritemuom'] = new Object()
          obj['permeter'] = this.state.productlistvalues[i].permeter
          obj['perfeet'] = this.state.productlistvalues[i].perfeet
          obj['perinch'] = this.state.productlistvalues[i].perinch
          obj['quantityPerMeter'] = this.state.productlistvalues[i].productsqtyinmeter[index] ? this.state.productlistvalues[i].productsqtyinmeter[index] : 0
          obj['quantityPerKg'] = this.state.productlistvalues[i].productsweight[index] ? this.state.productlistvalues[i].productsweight[index] : 0
          obj['masteritemuom']['uomname'] =  this.state.productlistvalues[i].productsuom[index]
          arr.push(obj)
        })
      }
      productMaterial2 = arr
    }
    console.log(arr)
    let subAssembly = [];
    console.log(productMaterial2)
    let calculationArray = [];
    productMaterial2.map(product => {

      let calculationdata = { permeter: null, perfeet: null, perinch: null };
      product.quantityPerMeter = parseInt(product.quantityPerMeter)
      product.quantityPerKg = parseInt(product.quantityPerKg)
      product.quantityPerMeter = products2[index].quantity != 0 && products2[index].quantity != '0' && products2[index].quantity != '' ? parseInt(products2[index].quantity) * parseInt(product.quantityPerMeter) : product.quantityPerMeter
      product.quantityPerKg = products2[index].quantity != 0 && products2[index].quantity != '0' && products2[index].quantity != '' ? parseInt(products2[index].quantity) * parseInt(product.quantityPerMeter) * product.quantityPerKg : product.quantityPerMeter * product.quantityPerKg

      subAssembly.push({
        name: product.masteritemdescription,
        unit: product.masteritemuom.uomname,
        thickness: 0,
        quantity: 0,
        quantityPerMeter:product.quantityPerMeter,
        quantityPerKg: product.quantityPerKg,
        total: 0
      });
      if (product.permeter && product.permeter !== null) {
        calculationdata.permeter = Number(product.permeter);
      }

      if (product.perfeet && product.perfeet !== null) {
        calculationdata.perfeet = Number(product.perfeet);
      }

      if (product.perinch && product.perinch !== null) {
        calculationdata.perinch = Number(product.perinch);
      }
      calculationArray.push(calculationdata);
    });
    console.log(this.state.data.products)
    let products = this.state.data.products;
    products[index].subAssembly = subAssembly;
    products[index].item = item;
    products[index].calculationArray = calculationArray;
    console.log("item selected is ",item);
    this.setState({
      ...this.state,
      data: { ...this.state.data, item, subAssembly, products },
      itemname: 'Item code: ' + '\n' + itemname,
      productMaterial2
    });
  };

  locationChange = (e, index) => {
    const selected_location = e.target.value;
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.itemlocation[index] = selected_location;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  onhandlerChange = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  onSubjectHandler = e => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  createSelectItems = () => {
    let items = [];
    let productlistvalues = this.state.masteritemlistvalues;
    console.log(productlistvalues)
    productlistvalues = productlistvalues.filter((item) =>{
      return item.masteritemtype.masteritemtypename == 'FinishedGoods'
    })
    productlistvalues.map((option, i) => {
     option['masteritemdescription2'] = option.masteritemshortdescription != '' ?  option.masteritemshortdescription : option.masteritemdescription
      
     items.push(
        <option key={i} value={option.id}>
          {option.masteritemdescription}
        </option>
      );
    });
    return items;
  };

  createSelectItemTypes = () => {
    let items = [];
    const materialoptionlistvalues = this.state.materialoptionlistvalues;
    materialoptionlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.masteritemtypename}
        </option>
      );
    });
    return items;
  };

  createSelectCurrencyItems = () => {
    let items = [];
    const currencylistvalues = this.state.currencylistvalues;
    currencylistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.currencyname}
        </option>
      );
    });
    return items;
  };

  createSelectUomItems = () => {
    let items = [];
    const uomlistvalues = this.state.uom;
    uomlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.uomname}
        </option>
      );
    });
    return items;
  };
  createSelectLocationItems = () => {
    let items = [];
    const locationlistvalue = this.state.locationlistvalue;
    locationlistvalue.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.locationname}
        </option>
      );
    });
    return items;
  };

  handleClick = e => {
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        values: [...this.state.data.values, ''],
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id: [...this.state.data.selectedItemlist.id, ''],
          itemname: [...this.state.data.selectedItemlist.itemname, ''],
          itemdescription: [
            ...this.state.data.selectedItemlist.itemdescription,
            ''
          ],
          itemtype: [...this.state.data.selectedItemlist.itemtype, ''],
          itemrate: [...this.state.data.selectedItemlist.itemrate, ''],
          itemuom: [...this.state.data.selectedItemlist.itemuom, ''],
          itemlocation: [...this.state.data.selectedItemlist.itemlocation, ''],
          costperunit: [...this.state.data.selectedItemlist.costperunit, ''],
          quantity: [...this.state.data.selectedItemlist.quantity, ''],
          totalcost: [...this.state.data.selectedItemlist.totalcost, '']
        }
      }
    });
  };
  removeClick = (e, index) => {
    const removeItem = { ...this.state.data };
    const values = removeItem.values;
    const id = removeItem.selectedItemlist.id;
    const itemname = removeItem.selectedItemlist.itemname;
    const itemdescription = removeItem.selectedItemlist.itemdescription;
    const itemtype = removeItem.selectedItemlist.itemtype;
    const itemrate = removeItem.selectedItemlist.itemrate;
    const itemuom = removeItem.selectedItemlist.itemuom;
    const itemlocation = removeItem.selectedItemlist.itemlocation;
    const costperunit = removeItem.selectedItemlist.costperunit;
    const quantity = removeItem.selectedItemlist.quantity;
    const totalcost = removeItem.selectedItemlist.totalcost;
    values.splice(index, 1);
    id.splice(index, 1);
    itemname.splice(index, 1);
    itemdescription.splice(index, 1);
    itemtype.splice(index, 1);
    itemrate.splice(index, 1);
    itemuom.splice(index, 1);
    itemlocation.splice(index, 1);
    costperunit.splice(index, 1);
    quantity.splice(index, 1);
    totalcost.splice(index, 1);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        values,
        selectedItemlist: {
          ...this.state.data.selectedItemlist,
          id,
          itemname,
          itemdescription,
          itemtype,
          itemrate,
          itemuom,
          itemlocation,
          costperunit,
          quantity,
          totalcost
        }
      }
    });
  };

  quantityChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    const costperunit = selectedItemlist.costperunit[index] || null;
    selectedItemlist.quantity[index] = e.target.value;
    const totalValue = costperunit
      ? e.target.value * costperunit
      : null || undefined || '';
    selectedItemlist.totalcost[index] = totalValue
      ? parseFloat(totalValue).toFixed(2)
      : '';
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };
  remarkChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.remarks[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  weightChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.totalweight[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  createSelectcustomer = () => {
    let items = [];
    const customerlistvalues = this.state.customerlistvalues;
    customerlistvalues.map((option, i) => {
      items.push(
        <option key={i} value={option.id}>
          {option.customername}
        </option>
      );
    });
    return items;
  };
  stockChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.readystock[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  secondChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.secondround[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  firstChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.firstround[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };
  thirdChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.thirdround[index] = e.target.value;
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
  };

  unitChange = (e, index) => {
    const selectedItemlist = { ...this.state.data.selectedItemlist };
    selectedItemlist.costperunit[index] = e.target.value;
    const totalcost =
      parseFloat(selectedItemlist.quantity[index]) *
      parseFloat(selectedItemlist.costperunit[index]);
    selectedItemlist.totalcost[index] = totalcost
      ? totalcost.toFixed(2)
      : null || '';
    this.setState({
      ...this.state.data,
      data: {
        ...this.state.data,
        selectedItemlist
      }
    });
    this.totalValue();
  };

  handleValidSubmit = () => {
    const { data } = this.state;
    this.setState({ loading: true });
    console.log(JSON.stringify(data));
    this.props
      .submit(data)
      .then(() => {
        this.setState({
          loading: false,
          errors: {},
          success: true,
          ...this.state.data,
          data: {
            ...this.state.data,
            products: [],
            subAssembly: []
          }
        });
        this.myFormRef.reset();
      })
      .catch(err => {
        this.setState({ errors: err.response.data.errors, loading: false });
      });
  };

  toggle = () => {
    this.setState({ ...this.state, success: false });
  };

  toggelreload = () => {
    this.setState({ ...this.state, success: false });
    window.location.reload()
  }

  handleInvalidSubmit = () => {
    console.log('invalid submit');
  };
  onthicknessChange = (e, index, mainindex) => {
    let products = this.state.data.products;
    let subAssembly = products[mainindex].subAssembly;
    subAssembly[index]['thickness'] = e.target.value;
    products[mainindex].subAssembly = subAssembly;
    this.setState({
      ...this.state,
      data: { ...this.state.data, products }
    });
  };
  onQuantityChange = (e, index, mainindex) => {
    let products = this.state.data.products;
    let subAssembly = products[mainindex].subAssembly;
    subAssembly[index]['quantity'] = e.target.value;
    products[mainindex].subAssembly = subAssembly;
    this.setState({
      ...this.state,
      data: { ...this.state.data, products }
    });
  };
  // METER
  onQuantityChangeMeter = (e, index, mainindex) => {
    let products = this.state.data.products;
    let subAssembly = products[mainindex].subAssembly;
    subAssembly[index]['quantityPerMeter'] = e.target.value;
    let calc = products[mainindex].calculationArray[index];
    if (calc.permeter && calc.permeter !== null) {
      let kg = Number(e.target.value) / Number(calc.permeter);
      subAssembly[index]['quantityPerKg'] = kg;
    }

    products[mainindex].subAssembly = subAssembly;
    this.setState({
      ...this.state,
      data: { ...this.state.data, products }
    });
  };
  // KG
  onQuantityChangeKg = (e, index, mainindex) => {
    let products = this.state.data.products;
    let subAssembly = products[mainindex].subAssembly;
    subAssembly[index]['quantityPerKg'] = e.target.value;
    let calc = products[mainindex].calculationArray[index];
    if (calc.permeter && calc.permeter !== null) {
      let kg = Number(e.target.value) * Number(calc.permeter);
      subAssembly[index]['quantityPerMeter'] = kg;
    }
    products[mainindex].subAssembly = subAssembly;
    this.setState({
      ...this.state,
      data: { ...this.state.data, products }
    });
  };
  onTotalChange = (e, index, mainindex) => {
    let products = this.state.data.products;
    let subAssembly = products[mainindex].subAssembly;
    subAssembly[index]['total'] = e.target.value;
    products[mainindex].subAssembly = subAssembly;
    this.setState({
      ...this.state,
      data: { ...this.state.data, products }
    });
  };
  addItemComp = () => {
    let products = this.state.data.products;
    let items = { item: '', quantity: 0, subAssembly: [] };
    products.push(items);
    this.setState({
      ...this.state.data,
      products
    });
  };

  handleChangeDeliveryDate = date => {
    this.setState({
      data: {
        ...this.state.data,
        productiondate: date
      }
    });
  };
  changeQuantity = (e, index) => {
    let products = this.state.data.products;
    products[index].quantity = e.target.value;
    console.log(products[index])
    this.setState({
      ...this.state,
      data: { ...this.state.data, products }
    });
  };
  render() {
    const customStyles = {
      control: base => ({
        ...base,
        height: 35,
        minHeight: 35
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '35px',
        padding: '0 6px'
      }),
  
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '35px',
      }),
    };
    const { data, errors, loading, success, productMaterial,itemname } = this.state;
    return (
      <div>
        <div>
          {!!success ? (
            // <Alert color="info">Purchase Order is generated successfully</Alert>
            <Modal
              isOpen={success}
              toggle={this.toggle}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle}>Production</ModalHeader>
              <ModalBody>Production order is generated successfully</ModalBody>
              <ModalFooter>
                <Button color='primary' onClick={this.toggelreload}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          ) : null}
        </div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          ref={el => (this.myFormRef = el)}
        >
          <Card>
            <CardHeader>
              Production
              <Button
                color='primary'
                disabled={loading}
                className='lgn-loader'
                style={{ float: 'right' }}
              >
                Submit
                {/* {loading} */}
              </Button>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='productionorderno'>Production No</Label>
                    <span className='required-label'>*</span>
                    <AvField
                      name='productionorderno'
                      type='text'
                      id='productionorderno'
                      value={data.productionorderno || null}
                      onChange={this.handleChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Please enter production order no'
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col sm={2}>
                  <AvGroup>
                    <Label className='small-font-size' for='customerid'>Customer Name</Label>
                    {/* <AvField
                      type='select'
                      name={`customerid`}
                      required={true}
                      onChange={e => this.handleChange(e)}
                    >
                      <option value=''>select customer name</option>
                      {this.createSelectcustomer()}
                    </AvField> */}
                    <Select name={`customerid`} classNamePrefix="select" onChange={this.handleChangeCust.bind(this)} styles={customStyles} defaultValue={this.state.customerlistSelect[0]} options={this.state.customerlistSelect} />
                  </AvGroup>
                </Col>
                <Col sm={1}>
                  <Label className='small-font-size' for='productiondate'>Date</Label>
                  <DatePicker
                    autoComplete="new-password"
                    selected={data.productiondate}
                    onChange={this.handleChangeDeliveryDate}
                    className='form-control'
                    dateFormat='dd/MM/yyyy'
                    id='productiondate'
                  />
                </Col>
              </Row>
              {data.products &&
                data.products.map((item, maninindex) => {
                  let optionsel = "";
                  let SelVal = {};
                  if(item.id != undefined) {
                    if(item.itemshortdescription) {
                      optionsel = item.itemshortdescription;
                    }
                    else {
                      optionsel = item.itemdescription;
                    }
                    SelVal = {
                      value: item.id._id, label: optionsel
                    }
                  }
                  const itemnameinfo = item.id != undefined ? (
                    // <AvField
                    //   type="select"
                    //   value={values.id._id}
                    //   name={`selectitem${index}`}
                    //   onChange={e => this.selectitemchange(e, index)}
                    //   required={true}
                    // >
                    //   <option value="">select Item</option>
                    //   {this.createSelectItems()}
                    // </AvField>
                    <Select name={`selectitem${maninindex}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, maninindex)} styles={customStyles} required value={SelVal} options={this.state.itemlistSelectedOption} />            
                    ) :
                    (
                      // <AvField
                      //   type="select"
                      //   name={`selectitem${index}`}
                      //   value={
                      //     data.selectedItemlist.id[index]
                      //   }
                      //   onChange={e => this.selectitemchange(e, index)}
                      //   required={true}
                      // >
                      //   <option value="">select Item</option>
                      //   {this.createSelectItems()}
                      // </AvField>
                      <Select name={`selectitem${maninindex}`} classNamePrefix="select" onChange={(selectedOption) => this.selectitemchange(selectedOption, maninindex)} styles={customStyles} required defaultValue={data.selectedItemlist.id[maninindex]} options={this.state.itemlistSelectedOption} />
                    )
                  return (
                    <div>
                      <Row>
                        <Col sm={2}>
                          <Label className='small-font-size' for='item'>Item</Label>
                          {/* <AvField
                            type='select'
                            name='item'
                          
                            onChange={e => this.selectitemchange(e, maninindex)}
                            //required={true}
                          >
                            <option value=''>select Item</option>
                            {this.createSelectItems()}
                          </AvField> */}
                          {itemnameinfo}
                          <div style={{'color':'#707070','fontSize':'12px','fontWeight':'bold'}}>{itemname}</div>
                        </Col>
                        <Col sm={2}>
                          <Label className='small-font-size' for='quantity'>Quantity</Label>
                          <AvField
                            name='quantity'
                            type='number'
                            id='quantity'
                            value={data.quantity || ' ' || null}
                            onChange={e => this.changeQuantity(e, maninindex)}
                          />
                        </Col>
                      </Row>
                      {/* Get table based on selected value */}
                      {data.products[maninindex].subAssembly.length > 0 && (
                        <Table>
                          <thead>
                            <tr>
                              <th>Sub Assembly</th>
                              <th>UOM</th>
                              <th>Thickness</th>
                              <th>Quantity (in meters)</th>
                              <th>Quantity (in Kgs)</th>
                              {/* <th>Total</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {data.products[maninindex].subAssembly.map(
                              (product, index) => {
                                return (
                                  <tr>
                                    <td>{product.name}</td>
                                    <td>{product.unit}</td>
                                    <td>
                                      <AvGroup>
                                        <AvField
                                          name={`thickness${index}`}
                                          type='number'
                                          id={`thickness${index}`}
                                          placeholder='thickness'
                                          onChange={e =>
                                            this.onthicknessChange(
                                              e,
                                              index,
                                              maninindex
                                            )
                                          }
                                        />
                                      </AvGroup>
                                    </td>
                                    <td>
                                      <AvGroup>
                                        <AvField
                                          name={`quantity${index}`}
                                          type='number'
                                          id={`quantity${index}`}
                                          placeholder='In meterts'
                                          onChange={e =>
                                            this.onQuantityChangeMeter(
                                              e,
                                              index,
                                              maninindex
                                            )
                                          }
                                          value={product.quantityPerMeter}
                                        />
                                      </AvGroup>
                                    </td>
                                    <td>
                                      <AvGroup>
                                        <AvField
                                          name={`quantity${index}`}
                                          type='number'
                                          id={`quantity${index}`}
                                          placeholder='In Kgs'
                                          onChange={e =>
                                            this.onQuantityChangeKg(
                                              e,
                                              index,
                                              maninindex
                                            )
                                          }
                                          value={product.quantityPerKg}
                                        />
                                      </AvGroup>
                                    </td>
                                    {/* <td>
                                      <AvGroup>
                                        <AvField
                                          name={`total${index}`}
                                          type='number'
                                          id={`total${index}`}
                                          placeholder='total'
                                          onChange={e =>
                                            this.onTotalChange(
                                              e,
                                              index,
                                              maninindex
                                            )
                                          }
                                        />
                                      </AvGroup>
                                    </td> */}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  );
                })}

              <Row>
                <div className='mg-20'>
                  <Button
                    color='primary'
                    disabled={loading}
                    className='lgn-loader'
                    onClick={e => this.addItemComp()}
                  >
                    Add item
                    {/* {loading} */}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierdetaillistvalue: state.supplierdetaillistvalue,
    masteritemlistvalues: state.masteritemlistvalues,
    materialoptionlistvalues: state.masteritemtypenamevalue,
    currencylistvalues: state.currencylistvalue,
    uomlistvalues: state.uomlistvalue,
    locationlistvalue: state.locationlistvalue,
    purchaseorderlistvalues: state.purchaseorderlistvalue,
    challanlistvalues: state.challanlistvalues,
    productionlistvalues: state.productionlistvalues,
    customerlistvalues: state.customerlistvalues,
    productlistvalues: state.productlistvalues
  };
}

export default connect(
  mapStateToProps,
  null
)(ProductionForm);
