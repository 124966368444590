

import { ADD_PROFORMA_INVOICE, PROFORMAINVOICE_FETCHED } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case PROFORMAINVOICE_FETCHED:
    case ADD_PROFORMA_INVOICE:
      return {
        ...state,
        ...action.proformainvoicelist.entities.proformainvoicelistvalues
      };
    default:
      return state;
  }
}
