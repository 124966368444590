import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
class QuotationPage extends Component {
  render() {
    return (
      <div>
        <div className='container mg-t-20'>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin_dashboard'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to='/crmlist'>CRM</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Quotation</BreadcrumbItem>
          </Breadcrumb>
          <div class='admin-dashboard-icons'>
            <ul className='clearfix'>
               <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/addquotation'>
                    <i className='fas fa-plus-circle fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>Add Quotation</span>
                    </div>
                    </Link>
                </div>
                </li>
                <li className='bg-color-lg-blue'>
                <div className='icon-eleme'>
                    <Link to='/quotationlist'>
                    <i className='fas fa-list fa-3x'></i>
                    <div style={{ paddingTop: '5px' }}>
                        <span>List Quotation</span>
                    </div>
                    </Link>
                </div>
                </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default QuotationPage;
