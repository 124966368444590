import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactPDF from "@react-pdf/renderer";
import moment from "moment";
import { PDFViewer } from "@react-pdf/renderer";
import { getinvoice } from '../../../../actions/Invoice';
import MyDocument from "./Component";
import logo from "../../../../Images/logo.png";
import axios from "axios";
import "./ViewInvoice";

class ViewInvoicePdf extends React.Component {
  state = {
    invoicelistvalues: {},
    status: false,
    link: "",
    text: null,
  };
  async componentDidMount() {
    this.props.getinvoice();
    const quotationid = this.props.match.params.id;
    const invoicelistvalues = this.props.invoicelistvalues[quotationid];
    console.log("invoice list values is", invoicelistvalues);
    invoicelistvalues.requestdeliverydate = moment(
      invoicelistvalues.date
    ).format("DD MMM, YYYY");
    invoicelistvalues.addeditemlist = invoicelistvalues.addeditemlist.map(
      (itemlist, i) => {
        let discountamount = itemlist.costperunit
        ? itemlist.costperunit
        : 0;

        let disc = itemlist.itemdiscount ? 
        itemlist.itemdiscount
        : 0;

        if(disc != 0) {
          discountamount = parseFloat(discountamount) * parseFloat(disc) / 100;
          discountamount = parseFloat(itemlist.costperunit) - discountamount;
        }
        
        if(itemlist.itemdescription.length < 340) {
          console.log("coming here");
          let totalSpacerequired = 340 - itemlist.itemdescription.length;
          let numSpaces = totalSpacerequired; // Change this to the number of spaces you want to add

          let spaces = " ".repeat(numSpaces); // Create a string of spaces
          itemlist.itemdescription = itemlist.itemdescription + spaces;

          console.log("after adding space", itemlist.itemdescription);
        }
        return {
          slno: (i + 1).toString(),
          itemname: itemlist.itemdescription ? itemlist.itemdescription : "",
          weighteach: itemlist.itemuom
            ? itemlist.itemuom.uomname
            : "",
          itemimage: itemlist.id.masteritemimage
            ? itemlist.id.masteritemimage
            : "",
          quantity: itemlist.quantity ? itemlist.quantity.toString() : "",
          discountamount: discountamount.toString(),
          itemdiscount: itemlist.itemdiscount ? 
              itemlist.itemdiscount.toString()
            : "0",
          costperunit: itemlist.costperunit
            ? itemlist.costperunit.toString()
            : "",
          totalcost: itemlist.totalcost
            ? itemlist.totalcost.toFixed(2).toString()
            : ""
        };
      }
    );
    const customerid = invoicelistvalues.customerid;
    const customernumber = invoicelistvalues.customernumber;
    const customername = invoicelistvalues.customername;
    await axios.get(`${process.env.REACT_APP_BASE_URL}/year/getyear`).then(
      (res) => {
        if(res.data.year) {
          invoicelistvalues.financialyear = res.data.year.currentyear;
        }
        else {
          invoicelistvalues.financialyear = "2020-21";          
        }
      },
      (error) => {
        invoicelistvalues.financialyear = "2020-21";
      }
    )
    console.log("--COMPONENTDIDMOUNT--");
    console.log(invoicelistvalues);
    setTimeout(() => {
      this.setState({
        ...this.state.purchaseorderlistvalues,
        invoicelistvalues,
        status: true,
        text: quotationid.toString()+customername.toString()+".pdf"
      });
    },2000);
   
    this.dynamicClick();
  }
  dynamicClick() {
    if (document.getElementsByClassName("pdf-class")) {
      if (
        document.getElementsByClassName("pdf-class")[0].children.length <= 1
      ) {
        setTimeout(() => {
          this.dynamicClick();
        }, 7000);
      } else if (
        document.getElementsByClassName("pdf-class")[0].children.length > 1
      ) {
        this.setState({
          link: document.getElementsByClassName("pdf-class")[0].children[1].src
          // status : undefined,
        },()=>{
          setTimeout(() => {
            document.getElementById("D1").click();
            this.props.history.goBack();
          }, 300);
        });
  
      }
    } else {
    }
  }
  render() {
    const data_cond = [
      {
        slno: "1",
        title: "Invoices",
        value: "2 copies Invoices (original & duplicate)"
      },
      {
        slno: "2",
        title: "Tax & Duty",
        value: "Included"
      },
      {
        slno: "3",
        title: "Payment Schedule",
        value: "15 days from the date of delivery"
      },
      {
        slno: "4",
        title: "Delivery Schedule",
        value: "Immediately"
      },
      {
        slno: "5",
        title: "Billing address & GST No",
        value:
          "M/s Pondhan Scaffolding Pvt Ltd \n Plot no. 40/41, Road no.5, Obedanahalli,"
      },
      {
        slno: "6",
        title: "Delivery Address",
        value: "M/s Pondhan Scaffolding Pvt Ltd"
      }
    ];
    const { status, invoicelistvalues,link,text } = this.state;
    console.log(this.state)
    return (
      <div>
        <div className="pdf-class">
          Downloading file.Please wait.
          {/* {!status && (
          <h1 style={{ 'position': 'absolute','left': '50%','top': '50%','transform': 'translate(-50%, -50%);'}}>Downloading file.Please wait.</h1>
        )} */}
          <a href={link} download={text} id="D1"></a>
          {status && (
            <PDFViewer>
              <MyDocument
                invoicelistvalues={this.state.invoicelistvalues}
                data_cond={data_cond}
                logo={logo}
              />
            </PDFViewer>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    invoicelistvalues: state.invoicelistvalues
  };
}
export default connect(
  mapStateToProps,
  { getinvoice }
)(ViewInvoicePdf);
