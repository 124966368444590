import React from "react";

class EmployeeRole extends React.Component {
  render() {
    return (
      <div className="container">
        <h6>Employee List</h6>
      </div>
    );
  }
}

export default EmployeeRole;
