import { PRODUCT_VALUES_DELETED, PRODUCT_VALUES_FETCHED } from "../Services/types";

export default function(state = {}, action) {
  switch (action.type) {
    case PRODUCT_VALUES_FETCHED:
      return {
        ...state,
        ...action.productlistvalues.entities.productlistvalues
      };
      case PRODUCT_VALUES_DELETED  :
        console.log(action);
        let arrayData = [];
        Object.keys(state).forEach(function(key, index) {
          arrayData.push(state[key]);
        });
        let fileteredData = arrayData.filter((item, id) => item.id !== action.payload);
        let obj = {};
        fileteredData.map((data,ind) => {
          obj[data.id] = data;
        });
        return obj;
    default:
      return state;
  }
}
