import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
  getMasterItemList,
  getMasterItemTypes
} from "../../../../actions/Transaction";
import { connect } from "react-redux";
import { getUomList } from "../../../../actions/uom";
import { getCustomerList } from "../../../../actions/customerContact";
import { addquotation } from "../../../../actions/quotation";
import { getquotation } from "../../../../actions/quotation";
import { updatequotation } from "../../../../actions/quotation";

import QuotationForm from "./QuotationForm/QuotationForm";
import CustomLoader from "../../../../Components/loader/loader";

class AddQuotation extends React.Component {

  state = {
    loading: false,
    ID:this.props.match.params.id
  }

  componentDidMount() {

    this.props.getCustomerList();
    this.props.getMasterItemTypes();
    this.props.getMasterItemList();
    this.props.getUomList();
    this.props.getquotation();
  }

  submit = data => {
    this.dataHandler(true);
    
    if(this.props.match.params.id != undefined){
      data['_id'] = this.props.match.params.id;
      console.log(data)
      return this.props
      .updatequotation(data)
      .then(data => {
        this.dataHandler(false);
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
    }
    else{
      
      return this.props
      .addquotation(data)
      .then(data => {
        this.dataHandler(false);
        return data;
      })
      .catch(err => {
        this.dataHandler(false);
        throw err;
      });
    }
  }

  dataHandler = value => {
    this.setState({ loading: value });
  };
  render() {
    const { loading,ID } = this.state;
    return (
      <div className="container mg-t-20">
        {loading ? <CustomLoader /> : null}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/admin_dashboard">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
          <Link to='/crmlist'>CRM</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Quotation</BreadcrumbItem>
        </Breadcrumb>
        <div>
          <QuotationForm submit={this.submit} ID={ID}/>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getMasterItemList,
    getMasterItemTypes,
    getUomList,
    addquotation,
    updatequotation,
    getCustomerList,
    getquotation
  }
)(AddQuotation);
